import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import style from "./ForBusinessPage.module.scss";

import {
  forBusinessKitImage,
  forBusinessBloodKitImage,
  medicinaPractica,
  synlab,
  diagnolita,
  womanImage,
  revolabLogoWithText,
  roundedBus,
  roundedBox,
  roundedArrows,
  roundedTests,
} from "assets/image";
import RequestPopup from "components/ForBusinessPage/RequestPopup";
import NotificationPopup from "components/NotificationPopup";

const ForBusinessPage = (): JSX.Element => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [notificationPopupVisible, setNotificationPopupVisible] =
    useState(false);
  const [isDemoRequest, setIsDemoRequest] = useState(true);
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <div
        className={
          popupVisible
            ? `${style.fakeHeader} ${style.hidden}`
            : style.fakeHeader
        }
      >
        <Link to="/">
          <img
            className={style.fakeHeader__image}
            src={revolabLogoWithText}
            alt="Logo"
          />
        </Link>
      </div>
      {popupVisible && (
        <RequestPopup
          isDemoRequest={isDemoRequest}
          close={() => setPopupVisible(false)}
          showSuccessPopup={() => setNotificationPopupVisible(true)}
        />
      )}
      {notificationPopupVisible && (
        <NotificationPopup
          text="Request sent successfully"
          isSuccessful={true}
          close={() => setNotificationPopupVisible(false)}
          delayBeforeClose={6000}
        />
      )}
      <div className={style.promo}>
        <div className={style.promo__textContent}>
          <div className={style.promo__title}>
            {t("ForBusiness.Promo.Title")}
          </div>
          <div className={style.promo__subtitle}>
            {t("ForBusiness.Promo.SubTitle")}
          </div>
          <div
            className={style.promo__button}
            onClick={() => {
              setPopupVisible(true);
              setIsDemoRequest(true);
            }}
          >
            {t("ForBusiness.Promo.Button")}
          </div>
        </div>
        <img
          className={style.promo__image}
          src={forBusinessKitImage}
          alt="Test Kit"
        />
      </div>
      <div className={style.howitworks}>
        <div className={style.howitworks__title}>
          {t("ForBusiness.HowItWorks.Title")}
        </div>
        <div className={style.howitworks__subtitle}>
          {t("ForBusiness.HowItWorks.SubTitle")}
        </div>
        <div className={style.howitworks__content}>
          <img
            className={style.howitworks__image}
            src={forBusinessBloodKitImage}
            alt="Blood Kit"
          />
          <div className={style.benefits}>
            <div className={style.benefits__title}>
              {t("ForBusiness.HowItWorks.BenefitsTitle")}
            </div>
            <div className={style.benefits__item}>
              <img
                className={style.benefits__image}
                src={roundedBus}
                alt="Bus"
              />
              <div className={style.benefits__text}>
                {t("ForBusiness.HowItWorks.Benefit1")}
              </div>
            </div>
            <div className={style.benefits__item}>
              <img
                className={style.benefits__image}
                src={roundedBox}
                alt="Box"
              />
              <div className={style.benefits__text}>
                {t("ForBusiness.HowItWorks.Benefit2")}
              </div>
            </div>
            <div className={style.benefits__item}>
              <img
                className={style.benefits__image}
                src={roundedArrows}
                alt="Arrows"
              />
              <div className={style.benefits__text}>
                {t("ForBusiness.HowItWorks.Benefit3")}
              </div>
            </div>
            <div className={style.benefits__item}>
              <img
                className={style.benefits__image}
                src={roundedTests}
                alt="Tests"
              />
              <div className={style.benefits__text}>
                {t("ForBusiness.HowItWorks.Benefit3")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.partners}>
        <div className={style.partners__title}>
          {t("ForBusiness.Partners.Title")}
        </div>
        <div className={style.partners__list}>
          <img
            className={style.partners__image}
            src={medicinaPractica}
            alt="Medicina Practica"
          />
          <img className={style.partners__image} src={synlab} alt="Synlab" />
          <img
            className={style.partners__image}
            src={diagnolita}
            alt="Diagnolita"
          />
        </div>
      </div>
      <div className={style.freesample}>
        <div className={style.freesample__textContent}>
          <div className={style.freesample__title}>
            {t("ForBusiness.FreeSample.Title")}
          </div>
          <div
            className={style.freesample__button}
            onClick={() => {
              setPopupVisible(true);
              setIsDemoRequest(false);
            }}
          >
            {t("ForBusiness.FreeSample.Button")}
          </div>
        </div>
        <img className={style.freesample__image} src={womanImage} alt="Woman" />
        <div className={style.freesample__background}></div>
      </div>
    </div>
  );
};

export default ForBusinessPage;
