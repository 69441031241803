import * as React from "react";

import styles from "./NotificationPopup.module.scss";

import { success, errorIcon } from "assets/image";

type Props = {
  text: string;
  isSuccessful: boolean;
  onCartPage?: boolean;
  close: () => void;
  delayBeforeClose?: number;
};

const NotificationPopup = ({
  text,
  isSuccessful,
  onCartPage,
  close,
  delayBeforeClose,
}: Props): JSX.Element => {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      close();
    }, delayBeforeClose);

    return () => {
      clearTimeout(timeout);
    };
  }, [close, delayBeforeClose]);

  return (
    <div
      className={
        onCartPage
          ? `${styles.popup} ${styles.popup__onCartPage}`
          : styles.popup
      }
    >
      <div className={styles.popup__closeIconHolder}>
        <div className={styles.popup__closeIcon} onClick={() => close()}></div>
      </div>
      <img
        className={styles.popup__image}
        src={isSuccessful ? success : errorIcon}
        alt={isSuccessful ? "Success" : "Error"}
      />
      <div className={styles.popup__text}>{text}</div>
    </div>
  );
};

export default NotificationPopup;
