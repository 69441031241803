import i18next from "internationalization";
import {
  TestPackageType,
  PackageCategory,
  TestPackagesAction,
  ResearchItemType,
  TestPackagesState,
} from "types";
import {
  covidImage,
  womanResearchImage,
  manResearchImage,
  generalResearchImage,
} from "assets/image";

const filterProducts = (packages: { items: TestPackageType[] }) => {
  return {
    covidResearches: packages.items.filter((research: TestPackageType) => {
      return research.category === PackageCategory.Covid;
    }),
    womanResearches: packages.items.filter((research: TestPackageType) => {
      return research.category === PackageCategory.WomensHealth;
    }),
    manResearches: packages.items.filter((research: TestPackageType) => {
      return research.category === PackageCategory.MensHealth;
    }),
    wellnessResearches: packages.items.filter((research: TestPackageType) => {
      return research.category === PackageCategory.Wellness;
    }),
  };
};

export const getResearchTypes = (
  testPackagesList: TestPackagesState,
  setIsOpenCovidTestPackage: (value: boolean) => TestPackagesAction,
  setIsOpenManHealthTestPackage: (value: boolean) => TestPackagesAction,
  setIsOpenWomanHealthTestPackage: (value: boolean) => TestPackagesAction,
  setIsOpenWellnessTestPackage: (value: boolean) => TestPackagesAction
): ResearchItemType[] => {
  const researches = filterProducts(testPackagesList);

  const researchTypes: ResearchItemType[] = [
    {
      id: 0,
      nameT: i18next.t("TestPackages.Covid19"),
      divId: "covid19",
      image: covidImage,
      hasDescription: true,
      description: i18next.t("TestPackages.DescriptionCovid19"),
      boldDescription: i18next.t("TestPackages.BoldDescriptionCovid19"),
      popular: false,
      isNew: true,
      researches: researches.covidResearches,
      setOpened: setIsOpenCovidTestPackage,
      opened: testPackagesList.isOpenCovidTestPackage,
    },
    {
      id: 1,
      nameT: i18next.t("TestPackages.WomensHealth"),
      divId: "womanHealth",
      image: womanResearchImage,
      hasDescription: false,
      description: "",
      boldDescription: "",
      popular: false,
      isNew: false,
      researches: researches.womanResearches,
      setOpened: setIsOpenWomanHealthTestPackage,
      opened: testPackagesList.isOpenWomanHealthTestPackage,
    },
    {
      id: 2,
      nameT: i18next.t("TestPackages.MensHealth"),
      divId: "manHealth",
      image: manResearchImage,
      hasDescription: false,
      description: "",
      boldDescription: "",
      popular: false,
      isNew: false,
      researches: researches.manResearches,
      setOpened: setIsOpenManHealthTestPackage,
      opened: testPackagesList.isOpenManHealthTestPackage,
    },
    {
      id: 3,
      nameT: i18next.t("TestPackages.Wellness"),
      divId: "wellness",
      image: generalResearchImage,
      hasDescription: false,
      description: "",
      boldDescription: "",
      popular: false,
      isNew: false,
      researches: researches.wellnessResearches,
      setOpened: setIsOpenWellnessTestPackage,
      opened: testPackagesList.isOpenWellnessTestPackage,
    },
  ];

  return researchTypes;
};
