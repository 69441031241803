import { Country } from ".";

export type SurveyListItem = {
  id: string;
  nameT: string;
  questions: string[];
  alias: string;
  isOriginal: boolean;
  campaign?: string;
};

export type Survey = {
  id: string;
  alias: string;
  consents: string[];
  nameT: string;
  descriptionT: string;
  subDescriptionT: string;
  questions: Question[];
  files: SurveyTemplateFile[];
  market: Country;
};

export type SurveyTemplateFile = {
  fileId: string;
  descriptionT: string[];
  headerT: string;
};

export enum Answer {
  numeric = "numeric",
  boolean = "boolean",
  text = "text",
  checkbox = "checkbox",
  dropdown = "dropdown",
  gender = "gender",
  number = "number",
}

export type BasicQuestion = {
  answerType: Answer;
  id: string;
  questionT: string;
};

export type NumberQuestionType = BasicQuestion & {
  dimension: string;
  range: {
    minNum: number;
    maxNum: number;
  };
  placeholder: string;
};

export type NumericQuestionType = BasicQuestion & {
  maxValueDescriptionT: string;
  minValueDescriptionT: string;
};

export type CheckboxQuestionType = BasicQuestion & {
  numberOfPossibleAnswers: number;
  answerVariants: string[];
};

export type GenderQuestionType = BasicQuestion & {
  answerVariants: string[];
};

export type DropdownQuestionType = BasicQuestion & {
  answerVariants: string[];
};

export type Question =
  | NumericQuestionType
  | CheckboxQuestionType
  | DropdownQuestionType
  | NumberQuestionType
  | GenderQuestionType;

export type SurveyDTO = {
  files: Record<string, File>;
  answers: string;
  templateId: string;
  email: string;
  consents: Record<string, boolean>;
  pdfPassword: Record<string, string>;
  market: Country;
};
