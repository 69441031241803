import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./UserPersonalDataBlock.module.scss";
import CustomInput from "components/CustomInput";
import Checkbox from "components/Utilities/Checkbox";
import { useTypedSelector } from "hooks";
import { normalizeMobileNumber } from "validation";
import { CheckoutFormDataAndFunctions } from "types";

type Props = {
  formDataAndFunctions: CheckoutFormDataAndFunctions;
  requiresIdentificationCode: boolean;
  isChildOrder: boolean;
  setIsChildOrder: (value: boolean) => void;
};

const UserPersonalDataBlock = ({
  formDataAndFunctions,
  requiresIdentificationCode,
  isChildOrder,
  setIsChildOrder,
}: Props): JSX.Element => {
  const { values, errors, touched, handleChange, setFieldValue } =
    formDataAndFunctions;
  const { createOrderErrorObject } = useTypedSelector((state) => state.orders);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const { t } = useTranslation();

  const mobileNumberChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const normalizedPhoneNumber = normalizeMobileNumber(e.target.value);
    setFieldValue("mobileNumber", normalizedPhoneNumber);
  };

  return (
    <div className={styles.block}>
      <div className={styles.block__head}>
        <div className={styles.block__headText}>
          {t("Checkout.UserInfo.UserDetails")}
        </div>
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.block__detailsContent}>
          <CustomInput
            contentContainerStyle="mediumInput"
            text={t("Checkout.UserInfo.FirstName")}
            value={values.firstName}
            error={errors.firstName || createOrderErrorObject.firstName}
            verticalError={true}
            touched={touched.firstName}
            changeHandler={handleChange("firstName")}
            disabled
          />
          <CustomInput
            contentContainerStyle="mediumInput"
            text={t("Checkout.UserInfo.LastName")}
            value={values.lastName}
            error={errors.lastName || createOrderErrorObject.lastName}
            verticalError={true}
            touched={touched.lastName}
            changeHandler={handleChange("lastName")}
            disabled
          />
          <CustomInput
            contentContainerStyle="mediumInput"
            text={t("Checkout.UserInfo.MobileNumber")}
            value={values.mobileNumber}
            error={errors.mobileNumber || createOrderErrorObject.mobileNumber}
            verticalError={true}
            touched={touched.mobileNumber}
            changeHandler={mobileNumberChangeHandler}
          />
          {requiresIdentificationCode && (
            <CustomInput
              contentContainerStyle="mediumInput"
              text={t("Checkout.UserInfo.PersonalCode")}
              subText={t("Checkout.UserInfo.PersonalCodeSubText")}
              value={values.identificationCode}
              error={
                errors.identificationCode ||
                createOrderErrorObject.identificationCode
              }
              verticalError={true}
              touched={touched.identificationCode}
              changeHandler={handleChange("identificationCode")}
            />
          )}
        </div>
        {currentMarket.config.availableChildTests && (
          <div className={styles.block__childCheckboxBlock}>
            <div className={styles.block__checkboxWrapper}>
              <Checkbox
                id="childCheckbox"
                checked={isChildOrder}
                setChecked={() => setIsChildOrder(!isChildOrder)}
              />
              <label
                className={styles.block__childCheckboxLabel}
                htmlFor="childCheckbox"
              >
                {t("Checkout.UserInfo.BuyingForAChild")}
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserPersonalDataBlock;
