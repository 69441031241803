import * as pdfjs from "pdfjs-dist";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const isFileEncrypted = async (file: File): Promise<boolean> => {
  try {
    await pdfjs.getDocument(URL.createObjectURL(file)).promise;
    return false;
  } catch (e: any) {
    return e.message === "No password given";
  }
};

export const isFilePasswordCorrect = async (
  file: File,
  password: string
): Promise<boolean> => {
  try {
    await pdfjs.getDocument({
      url: URL.createObjectURL(file),
      password,
    }).promise;
    return true;
  } catch (e: any) {
    return false;
  }
};
