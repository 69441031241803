import * as React from "react";

import styles from "./HowItWorksUS.module.scss";
import {
  howItWorksUSManInFrontOfPC,
  howItWorksUSBloodTests,
  howItWorksUSMobileApp,
} from "assets/image";

const HowItWorksUS = (): JSX.Element => {
  return (
    <div className={styles.howItWorksUs} id="howdoesitworks">
      <div className={styles.howItWorksUs__title}>
        How does home health tests work?
      </div>
      <div className={styles.howItWorksUs__list}>
        <div className={styles.howItWorksUs__item}>
          <img
            className={styles.howItWorksUs__image}
            src={howItWorksUSManInFrontOfPC}
            alt="Man in front of a computer"
          />
          <div className={styles.howItWorksUs__itemTitle}>
            1. Order a health test
          </div>
          <div className={styles.howItWorksUs__itemDescription}>
            Test tools and instructions will arrive within 1 - 24 hours. You can
            also order a health test for a loved one.
          </div>
        </div>
        <div className={styles.howItWorksUs__item}>
          <img
            className={styles.howItWorksUs__image}
            src={howItWorksUSBloodTests}
            alt="Blood tests"
          />
          <div className={styles.howItWorksUs__itemTitle}>
            2. Get health test tools
          </div>
          <div className={styles.howItWorksUs__itemDescription}>
            Mail your blood sample and get insights into your health within 24
            hours.
          </div>
        </div>
        <div className={styles.howItWorksUs__item}>
          <img
            className={styles.howItWorksUs__image}
            src={howItWorksUSMobileApp}
            alt="Mobile app on phone"
          />
          <div className={styles.howItWorksUs__itemTitle}>
            3. Get insights in 24 hours
          </div>
          <div className={styles.howItWorksUs__itemDescription}>
            Understand your body; be healthier and happier.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksUS;
