import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Survey.module.scss";

import ResizableSpinner from "components/ResizableSpinner";
import { useAppDispatch, useTypedSelector } from "hooks";
import { getSurveyList } from "store/thunks";
import SurveyItem from "components/Survey/SurveyItem";
import { LanguageContext } from "context";

const SurveyList = (): JSX.Element => {
  const { surveyList, isSurveyListLoading } = useTypedSelector(
    (state) => state.survey
  );
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const { currentLanguage } = React.useContext(LanguageContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(
      getSurveyList({
        currentLanguage: currentLanguage,
        marketId: currentMarket.country,
      })
    );
  }, [dispatch, currentLanguage, currentMarket.country]);

  if (isSurveyListLoading) {
    return (
      <div className={styles.surveys}>
        <ResizableSpinner />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.surveys}>
        <div className={styles.surveys__title}>{t("Survey.Title")}</div>
        <div className={styles.surveys__content}>
          {surveyList
            .filter((survey) => survey.isOriginal)
            .map(({ id, nameT, alias }) => {
              return (
                <SurveyItem nameT={nameT} link={`/survey/${alias}`} key={id} />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SurveyList;
