import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { LanguageContext } from "context";
import { getSurvey, getSurveyList } from "store/thunks";
import { useAppDispatch, useTypedSelector } from "hooks";
import { setSurveyAnswers } from "store/slices";
import {
  convertAnswersToArrayOfObjects,
  isCardiacButtonDisabled,
} from "helpers";
import ResizableSpinner from "components/ResizableSpinner";
import SurveyQuestion from "components/Survey/SurveyQuestion";
import Stage from "components/Stage";
import { StagePhase } from "types";

import style from "./CardiacRiskSurvey.module.scss";

const CardiacRiskSurvey = (): JSX.Element => {
  const { currentLanguage } = React.useContext(LanguageContext);
  const { t } = useTranslation();

  const {
    survey,
    surveyList,
    isSurveyListLoading,
    isSurveyLoading,
    isPendingSurveyResult,
    email,
    surveyAnswers,
  } = useTypedSelector((state) => state.survey);
  const { currentMarket } = useTypedSelector((state) => state.markets);

  const [answers, setAnswers] = React.useState<{
    [key: string]: number | string | null;
  }>({});
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (surveyList.length === 0 && !isSurveyListLoading) {
      dispatch(
        getSurveyList({
          currentLanguage: currentLanguage,
          marketId: currentMarket.country,
        })
      );
    }
  }, [
    dispatch,
    currentLanguage,
    isSurveyListLoading,
    currentMarket.country,
    surveyList,
  ]);

  React.useEffect(() => {
    if (surveyList.length > 0) {
      const currentSurveyId =
        surveyList.find((survey) => survey.alias === "cardiac-risk-assessment")
          ?.id || "";
      dispatch(getSurvey(currentSurveyId));
    }
  }, [dispatch, surveyList, currentLanguage]);

  React.useEffect(() => {
    if (Object.keys(survey).length !== 0) {
      setAnswers(
        survey.questions.reduce(
          (o, key) => Object.assign(o, { [key.id]: null }),
          {}
        )
      );
    }
  }, [survey]);

  if (
    isSurveyListLoading ||
    isSurveyLoading ||
    isPendingSurveyResult ||
    Object.entries(survey).length === 0
  ) {
    return (
      <div className={style.surveyList}>
        <div className={style.survey}>
          <ResizableSpinner />
        </div>
      </div>
    );
  }

  const isButtonDisabled = isCardiacButtonDisabled(survey, answers);

  const submitAnswers = () => {
    const convertedAnswers = convertAnswersToArrayOfObjects(answers);
    dispatch(setSurveyAnswers(convertedAnswers));
  };

  if (!email) {
    return <Redirect to={"/survey/cardiac-risk-assessment"} />;
  }

  if (Object.keys(surveyAnswers).length) {
    return <Redirect to={"/cardiac-risk-assessment/upload-file"} />;
  }

  return (
    <div className={style.wrapper}>
      <Stage stage={StagePhase.secondStage} />
      <div className={style.title}>{t("Survey.CardiacRiskSurveyTitle")}</div>
      <div className={style.survey__container}>
        {survey.questions.map((question) => (
          <div className={style.survey__questionWrapper} key={question.id}>
            <SurveyQuestion
              submitCount={null}
              answers={answers}
              question={question}
              setAnswers={setAnswers}
            />
          </div>
        ))}
        <div className={style.survey__btnWrap}>
          <button
            disabled={!isButtonDisabled}
            className={
              !isButtonDisabled
                ? `${style.survey__btn} ${style.survey__btn_disabled}`
                : style.survey__btn
            }
            onClick={submitAnswers}
          >
            {t("Survey.SurveyButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardiacRiskSurvey;
