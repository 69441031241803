import * as React from "react";

import styles from "./Radio.module.scss";

type Props = {
  checked: boolean;
  setChecked: () => void;
  id?: string;
  size?: number;
};

const Radio = ({ checked, setChecked, id, size }: Props): JSX.Element => {
  return (
    <div
      className={
        size ? `${styles.radio} ${styles[`radio_size${size}`]}` : styles.radio
      }
      onClick={() => setChecked()}
    >
      <input
        className={styles.input}
        type="radio"
        id={id || "Unknown radio ID"}
        checked={checked}
        readOnly
      />
      <span className={styles.checkMark}></span>
    </div>
  );
};

export default Radio;
