import { Locale, Prices, PaymentType } from "types";

export type CartItemType = {
  paymentType: PaymentType;
  quantity: number;
  testPackageId: string;
  testPackageName: string;
  testPackageLabId: string;
  price: number;
  preview: string;
  prices: Prices;
  locale: Omit<Locale, "language">;
};

export enum ClickType {
  SetType = "SetType",
  SetCart = "SetCart",
}
