import { createAsyncThunk } from "@reduxjs/toolkit";

import httpService from "services/httpService";
import { Child, ChildDTO } from "types";

export const getChildren = createAsyncThunk<
  Child[],
  void,
  { rejectValue: string }
>("child/getChild", async (_, { rejectWithValue }) => {
  try {
    const children = await httpService.get("/user/child");
    return children;
  } catch (e: any) {
    console.error("GET CHILD ERROR: ", e);
    return rejectWithValue(e);
  }
});

export const addChild = createAsyncThunk<Child, any, { rejectValue: string }>(
  "child/addChild",
  async (childData: ChildDTO, { rejectWithValue }) => {
    try {
      const newChildren = await httpService.post("/user/child", {
        body: JSON.stringify(childData),
      });
      return newChildren;
    } catch (e: any) {
      console.error("ADDING CHILD ERROR: ", e);
      return rejectWithValue(e);
    }
  }
);

export const getChildResults = createAsyncThunk<
  any,
  any,
  { rejectValue: string }
>("child/getChildResults", async (childId: string, { rejectWithValue }) => {
  try {
    const childResults = await httpService.get(`/result/child/${childId}`);
    return { id: childId, results: childResults };
  } catch (e: any) {
    console.error("GET CHILD RESULTS ERROR: ", e);
    return rejectWithValue(e);
  }
});
