import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Contacts.module.scss";
import config from "config";

type Props = { onProfilePage?: boolean };

const Contacts = ({ onProfilePage }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      className={
        onProfilePage
          ? `${styles.contacts} ${styles.contacts_onProfilePage}`
          : styles.contacts
      }
    >
      <div className={styles.contacts__name}>Revolab UAB</div>
      <div className={styles.contacts__email}>{config.revolabEmail}</div>
      <a
        href={config.socialMediaLinks.facebookMessenger}
        className={styles.contacts__link}
        target="_blank"
        rel="noreferrer"
      >
        {t("Contacts.Facebook")}
      </a>
      <div className={styles.contacts__mobileNumber}>+370 606 90710</div>
      <div className={styles.contacts__address}>
        J.Basanavičiaus g.26, Vilnius, LT-03224
      </div>
    </div>
  );
};

export default Contacts;
