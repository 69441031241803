import React from "react";
import { useTranslation } from "react-i18next";

import style from "./CustomInput.module.scss";

type Props = {
  contentContainerStyle?: string;
  type?: string;
  text: string;
  value: string;
  subText?: string;
  error?: string | undefined;
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  verticalError?: boolean;
  touched?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
};

export default function CustomInput({
  contentContainerStyle,
  type,
  text,
  value,
  subText,
  changeHandler,
  error,
  verticalError,
  touched,
  autoComplete,
  disabled,
  onFocus,
  onBlur,
}: Props): JSX.Element {
  const hasError = error && touched;
  const { t } = useTranslation();

  return (
    <div
      className={
        contentContainerStyle
          ? `${style.wrapper} ${style[contentContainerStyle]}`
          : `${style.wrapper}`
      }
    >
      <span className={style.text}>{text}</span>
      <span className={style.subText}>{subText}</span>
      <input
        type={type}
        value={value}
        className={
          hasError ? `${style.input} ${style.inputError}` : style.input
        }
        onChange={(e) => changeHandler(e)}
        autoComplete={autoComplete}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <div
        className={
          hasError
            ? `${style.error} ${style[`error__${contentContainerStyle}`]} ${
                verticalError ? style.verticalError : ""
              }`
            : style.blockHidden
        }
      >
        <div className={style.errorText}>{error && t(error)}</div>
      </div>
    </div>
  );
}

CustomInput.defaultProps = {
  contentContainerStyle: "",
};
