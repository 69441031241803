import { Dispatch } from "redux";

import testPackageService from "services/testPackageService";
import {
  TestPackagesActionTypes,
  TestPackagesAction,
  Country,
  Language,
} from "types";

const fetchTestPackages = (
  currentLanguage: Language,
  currentMarketId: Country
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: TestPackagesActionTypes.PENDING_TEST_PACKAGES,
    });
    try {
      const result = await testPackageService.fetchTestPackagesList(
        currentMarketId
      );

      dispatch({
        type: TestPackagesActionTypes.FULFILLED_TEST_PACKAGES,
        payload: result,
      });

      dispatch({
        type: TestPackagesActionTypes.TRANSLATE_TEST_PACKAGES,
        payload: currentLanguage,
      });
    } catch (e: any) {
      dispatch({
        type: TestPackagesActionTypes.REJECTED_TEST_PACKAGES,
        payload: e.message,
      });
    }
  };
};

const translateTestPackages = (
  currentLanguage: Language
): TestPackagesAction => ({
  type: TestPackagesActionTypes.TRANSLATE_TEST_PACKAGES,
  payload: currentLanguage,
});

const setIsOpenCovidTestPackage = (value: boolean): TestPackagesAction => ({
  type: TestPackagesActionTypes.SET_IS_OPEN_COVID_TEST_PACKAGE,
  payload: value,
});

const setIsOpenManHealthTestPackage = (value: boolean): TestPackagesAction => ({
  type: TestPackagesActionTypes.SET_IS_OPEN_MANHEALTH_TEST_PACKAGE,
  payload: value,
});

const setIsOpenWomanHealthTestPackage = (
  value: boolean
): TestPackagesAction => ({
  type: TestPackagesActionTypes.SET_IS_OPEN_WOMANHEALTH_TEST_PACKAGE,
  payload: value,
});

const setIsOpenWellnessTestPackage = (value: boolean): TestPackagesAction => ({
  type: TestPackagesActionTypes.SET_IS_OPEN_WELLNESS_TEST_PACKAGE,
  payload: value,
});

export {
  fetchTestPackages,
  translateTestPackages,
  setIsOpenCovidTestPackage,
  setIsOpenManHealthTestPackage,
  setIsOpenWomanHealthTestPackage,
  setIsOpenWellnessTestPackage,
};
