import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./CartItem.module.scss";

import { trashBin } from "assets/image";
import { PaymentType, ClickType, CartItemType } from "types";
import SubscribePopup from "components/ShoppingCart/SubscribePopup";
import { useTypedSelector } from "hooks";
import { getCurrencySymbol } from "helpers";

type Props = {
  item: CartItemType;
  deleteTest: (test: CartItemType) => void;
  setTestType: (id: string, newType: PaymentType) => void;
  setTestQuantity: (id: string, quantity: number) => void;
};

const CartItem = ({
  item,
  deleteTest,
  setTestType,
  setTestQuantity,
}: Props): JSX.Element => {
  const [testCount, setTestCount] = React.useState(item.quantity);
  const [showPopup, setShowPopup] = React.useState(false);
  const { t } = useTranslation();
  const { items } = useTypedSelector((state) => state.testPackages);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const isCartItemActive = !!items.find(
    (testPackage) => testPackage.id === item.testPackageId
  );
  const testMaxQuantityForPurchase = items.find(
    (testPackage) => testPackage.id === item.testPackageId
  )?.maxQuantityForPurchase;
  const isMaxQuantityForPurchaseExcepted =
    testMaxQuantityForPurchase && testMaxQuantityForPurchase <= testCount;
  const currency = getCurrencySymbol(currentMarket.currency);

  return (
    <div className={styles.cartItem}>
      {showPopup && (
        <SubscribePopup
          paymentType={item.paymentType}
          testPackageId={item.testPackageId}
          preview={item.preview}
          name={item.testPackageName}
          price={item.price}
          prices={item.prices}
          currency={currentMarket.currency}
          clickType={ClickType.SetCart}
          setTestType={setTestType}
          closePopup={() => setShowPopup(false)}
        />
      )}
      <div className={styles.cartItem__content}>
        <div className={styles.cartItem__imageHolder}>
          <img
            className={styles.cartItem__image}
            src={item.preview}
            alt="Test"
          />
        </div>
        <div className={styles.cartItem__textContent}>
          <div className={styles.cartItem__name}>{item.testPackageName}</div>
          {!isCartItemActive && (
            <div className={styles.cartItem__unavailable}>
              {t("Cart.CartItem.UnavailableItem")}
            </div>
          )}
          <div className={styles.cartItem__prices}>
            <div className={styles.cartItem__price}>
              {item.price / 100}
              <span className={styles.euro}>{currency}</span>
            </div>
            {item.paymentType !== PaymentType.OneTime && (
              <div className={styles.cartItem__priceBeforeDiscount}>
                {item.prices.OneTime.price / 100}
                <span className={styles.euro}>{currency}</span>
              </div>
            )}
          </div>
          {item.paymentType === PaymentType.OneTime && (
            <Link
              to={`#`}
              className={styles.cartItem__subscription}
              onClick={(e) => {
                e.preventDefault();

                setShowPopup(true);
              }}
            >
              {t("Cart.CartItem.Subscribe")}
            </Link>
          )}
          {item.paymentType !== PaymentType.OneTime && (
            <div className={styles.cartItem__selectedSubscription}>
              <span className={styles.cartItem__selectedSubscription_bold}>
                {t(`Cart.CartItem.${item.paymentType}`)}
              </span>
              <span>{t("Cart.CartItem.Save")}</span>
            </div>
          )}
        </div>
      </div>
      {item.paymentType === PaymentType.OneTime && (
        <div className={styles.cartItem__countBlock}>
          {testCount === 1 && (
            <div
              className={styles.cartItem__button}
              onClick={() => {
                deleteTest(item);
              }}
            >
              <img
                className={styles.cartItem__trashBin}
                src={trashBin}
                alt="Trash bin"
              />
            </div>
          )}
          {testCount > 1 && (
            <div
              className={styles.cartItem__button}
              onClick={() => {
                if (testCount !== 1) {
                  setTestCount(testCount - 1);
                  setTestQuantity(item.testPackageId, testCount - 1);
                }
              }}
            >
              <div className={styles.cartItem__minus}></div>
            </div>
          )}
          <div className={styles.cartItem__count}>{testCount}</div>
          <div
            className={
              isMaxQuantityForPurchaseExcepted
                ? `${styles.cartItem__button} ${styles.cartItem__button_disabled}`
                : styles.cartItem__button
            }
            onClick={() => {
              if (!isMaxQuantityForPurchaseExcepted) {
                setTestCount(testCount + 1);
                setTestQuantity(item.testPackageId, testCount + 1);
              }
            }}
          >
            <div className={styles.cartItem__plus}></div>
          </div>
        </div>
      )}
      {item.paymentType !== PaymentType.OneTime && (
        <div className={styles.cartItem__countBlock_small}>
          <div
            className={styles.cartItem__button}
            onClick={() => deleteTest(item)}
          >
            <img
              className={styles.cartItem__trashBin}
              src={trashBin}
              alt="Trash bin"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CartItem;
