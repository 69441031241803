import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Benefits.module.scss";

import { womanImage } from "assets/image";

const Benefits = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.benefits}>
      <div className={styles.benefits__background}></div>
      <div className={styles.benefits__content}>
        <div className={styles.benefits__title}>{t("Main.Benefits.Title")}</div>
        <div className={styles.benefits__subtitle}>
          {t("Main.Benefits.Subtitle")}
        </div>
        <div className={styles.benefits__subContent}>
          <div className={styles.description}>
            <div className={styles.description__title}>
              {t("Main.Benefits.DescriptionTitle1")}
            </div>
            <div className={styles.description__subtitle}>
              {t("Main.Benefits.DescriptionText1")}
            </div>
            <div className={styles.description__title}>
              {t("Main.Benefits.DescriptionTitle2")}
            </div>
            <div className={styles.description__subtitle}>
              {t("Main.Benefits.DescriptionText2")}
            </div>
          </div>
          <img
            className={styles.benefits__image}
            src={womanImage}
            alt="Woman with phone"
          />
        </div>
      </div>
    </div>
  );
};

export default Benefits;
