import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import style from "./SuccessMessage.module.scss";

type Props = {
  link: string;
  text: string;
  buttonClickHandler: () => void;
};

const SuccessMessage = ({
  link,
  text,
  buttonClickHandler,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <span className={style.header}>{t("SuccessMessage.Header")}</span>
        <span className={style.text}>{text}</span>
        <Link className={style.button} to={link} onClick={buttonClickHandler}>
          OK
        </Link>
      </div>
    </div>
  );
};

export default SuccessMessage;
