import * as React from "react";

import ProcessingPage from "components/StatusPages/ProcessingPage";

const IdentificationProcessing = (): JSX.Element => {
  return (
    <ProcessingPage
      titleTextKeys={["IdentificationProcessingTitle"]}
      descriptionTextKeys={["IdentificationProcessingDescription"]}
    />
  );
};

export default IdentificationProcessing;
