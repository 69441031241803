import * as React from "react";
import { Link } from "react-router-dom";

import styles from "./Choice.module.scss";
import { useTranslation } from "react-i18next";

const Choice = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.choice}>
      <div className={styles.choice__title}>{t("Choice.Text")}</div>
      <div className={styles.choice__buttons}>
        <Link to={"/signup"} className={styles.choice__registration}>
          {t("Header.ButtonRegister")}
        </Link>
        <Link to={"/login"} className={styles.choice__login}>
          {t("Header.ButtonLogin")}
        </Link>
      </div>
    </div>
  );
};

export default Choice;
