import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { Field, Formik } from "formik";
import dayjs from "dayjs";

import style from "./Signup.module.scss";

import { useAppDispatch, useTypedSelector } from "hooks";
import Spinner from "components/Spinner";
import SuccessMessage from "components/SuccessMessage";
import CustomInput from "components/CustomInput";
import { RegistrationSchema, normalizeMobileNumber } from "validation";
import { setSignupErrorObject, signup } from "store/actions";
import { signupForm } from "types";
import { LanguageContext } from "context";
import { addLinksToText } from "helpers";

function SignupPage(): JSX.Element {
  const { t } = useTranslation();
  const { signupErrorsObject, signupLoading } = useTypedSelector(
    (state) => state.signup
  );
  const { consents } = useTypedSelector((state) => state.consents);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const [isPrivatePolicy, setIsPrivatePolicy] = useState(false);
  const [isGatherPrivateData, setIsGatherPrivateData] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const dispatch = useAppDispatch();
  const { currentLanguage } = React.useContext(LanguageContext);

  const successHandler = (): void => {
    setShowMain(false);
    setSuccess(true);
  };

  const localSignup = (formData: signupForm) => {
    const {
      firstName,
      lastName,
      email,
      sex,
      password,
      repeatPassword,
      mobileNumber,
    } = formData;

    dispatch(
      signup(
        {
          firstName,
          lastName,
          email,
          sex,
          password,
          repeatPassword,
          mobileNumber,
          birthDate: convertDate(formData.year, formData.month, formData.day),
          agreedToTerms: isPrivatePolicy,
          agreedToProcessHealthData: isGatherPrivateData,
          market: currentMarket.id,
        },
        successHandler
      )
    );
  };

  const convertDate = (year: string, month: string, day: string): string =>
    dayjs(new Date(+year, +month - 1, +day)).format("DD-MM-YYYY");

  const validateDateError = (
    e: React.ChangeEvent<HTMLInputElement> | null,
    years: string | null,
    months: string,
    days: string,
    submitCount: number
  ): void => {
    if (
      e?.target.value.length === 4 &&
      submitCount > 0 &&
      new Date(
        years ? +years : +e.target.value,
        months ? +months - 1 : 0,
        days ? +days - 1 : 1
      ) > dayjs().subtract(18, "year").toDate()
    ) {
      dispatch(
        setSignupErrorObject({
          birthDate: t("OlderThan18"),
        })
      );
    } else {
      dispatch(
        setSignupErrorObject({
          birthDate: "",
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setSignupErrorObject({}));
  }, [dispatch]);

  return (
    <div className={style.signupPageWrapper}>
      {signupLoading && <Spinner />}
      {success && (
        <SuccessMessage
          text={t("Signup.SuccessMessageText")}
          link="/login"
          buttonClickHandler={() => setSuccess(false)}
        />
      )}
      {showMain && (
        <>
          <span className={style.signupPageHeader}>{t("Signup.Title")}</span>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              sex: "",
              year: "",
              month: "",
              day: "",
              password: "",
              repeatPassword: "",
              mobileNumber: currentMarket.config.mobileCountryCode,
              agreedToTerms: isPrivatePolicy,
              agreedToProcessHealthData: isGatherPrivateData,
            }}
            onSubmit={(values, { setErrors }) => {
              if (
                new Date(+values.year, +values.month - 1, +values.day) >
                dayjs().subtract(18, "year").toDate()
              ) {
                setErrors({
                  year: t("OlderThan18"),
                });
              } else {
                localSignup(values);
              }
            }}
            validationSchema={RegistrationSchema}
          >
            {({
              handleSubmit,
              setFieldValue,
              handleChange,
              values,
              errors,
              touched,
              setValues,
              submitCount,
            }) => {
              const birthdayError =
                errors.year ||
                errors.month ||
                errors.day ||
                signupErrorsObject.birthDate;

              const mobileNumberChangeHandler = (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                const normalizedPhoneNumber = normalizeMobileNumber(
                  e.target.value
                );
                setFieldValue("mobileNumber", normalizedPhoneNumber);
              };

              return (
                <div
                  className={style.formWrapper}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <div className={style.signupPageNamesWrapper}>
                    <CustomInput
                      contentContainerStyle="name"
                      text={t("Signup.FirstName")}
                      value={values.firstName}
                      autoComplete="temporarily-disabled"
                      error={errors.firstName || signupErrorsObject.firstName}
                      touched={touched.firstName}
                      changeHandler={handleChange("firstName")}
                      verticalError
                    />
                    <CustomInput
                      contentContainerStyle="name"
                      text={t("Signup.LastName")}
                      value={values.lastName}
                      autoComplete="temporarily-disabled"
                      error={errors.lastName || signupErrorsObject.lastName}
                      touched={touched.lastName}
                      changeHandler={handleChange("lastName")}
                      verticalError
                    />
                  </div>
                  <CustomInput
                    contentContainerStyle="email"
                    text={t("Signup.Email")}
                    value={values.email}
                    autoComplete="email"
                    error={errors.email || signupErrorsObject.email}
                    touched={touched.email}
                    changeHandler={handleChange("email")}
                  />
                  <div className={style.signupPageSexWrapper}>
                    <span className={style.signupPageInputText}>
                      {t("Signup.Sex")}
                    </span>
                    <div className={style.signupPageSexRadioWrapper}>
                      <Field
                        type="radio"
                        className={style.signupPageSexRadio}
                        id="female"
                        name="sex"
                        value="female"
                      />
                      <label
                        htmlFor="female"
                        className={style.signupPageSexLabel}
                      >
                        {t("Signup.Female")}
                      </label>
                      <Field
                        type="radio"
                        className={style.signupPageSexRadio}
                        id="male"
                        name="sex"
                        value="male"
                      />
                      <label
                        htmlFor="male"
                        className={style.signupPageSexLabel}
                      >
                        {t("Signup.Male")}
                      </label>
                    </div>
                    <div
                      className={
                        (touched.sex && errors.sex) || signupErrorsObject.sex
                          ? `${style.signupPageNameError} ${style.signupPageNameError__sex}`
                          : style.blockHidden
                      }
                    >
                      <div className={style.signupPageErrorText}>
                        {(errors.sex && t(errors.sex)) ||
                          signupErrorsObject.sex}
                      </div>
                    </div>
                  </div>
                  <div className={style.signupPageInputWrapper}>
                    <span className={style.signupPageInputText}>
                      {t("Signup.BirthDate")}
                    </span>
                    <div
                      className={
                        (touched.year && errors.year) ||
                        (touched.month && errors.month) ||
                        (touched.day && errors.day) ||
                        signupErrorsObject.birthDate
                          ? `${style.birthDate} ${style.birthDate_hasError}`
                          : style.birthDate
                      }
                    >
                      <input
                        className={style.birthDate__input}
                        placeholder={t("Signup.Year")}
                        value={values.year}
                        onChange={(e) => {
                          setValues({ ...values, year: e.target.value });

                          validateDateError(
                            e,
                            null,
                            values.month,
                            values.day,
                            submitCount
                          );
                        }}
                      />
                      <input
                        className={style.birthDate__input}
                        placeholder={t("Signup.Month")}
                        value={values.month}
                        onChange={handleChange("month")}
                      />
                      <input
                        className={style.birthDate__input}
                        placeholder={t("Signup.Day")}
                        value={values.day}
                        onChange={handleChange("day")}
                      />
                    </div>
                    <div
                      className={
                        (touched.year && errors.year) ||
                        (touched.month && errors.month) ||
                        (touched.day && errors.day) ||
                        signupErrorsObject.birthDate
                          ? style.signupPageNameError
                          : style.blockHidden
                      }
                    >
                      <div className={style.signupPageErrorText}>
                        {birthdayError && t(birthdayError)}
                      </div>
                    </div>
                  </div>
                  <CustomInput
                    contentContainerStyle="mobileNumber"
                    text={t("Signup.MobileNumber")}
                    value={values.mobileNumber}
                    autoComplete="tel"
                    error={
                      errors.mobileNumber || signupErrorsObject.mobileNumber
                    }
                    touched={touched.mobileNumber}
                    changeHandler={mobileNumberChangeHandler}
                  />
                  <CustomInput
                    contentContainerStyle="password"
                    type="password"
                    text={t("Signup.Password")}
                    value={values.password}
                    autoComplete="current-password"
                    error={errors.password || signupErrorsObject.password}
                    touched={touched.password}
                    changeHandler={handleChange("password")}
                  />
                  <CustomInput
                    contentContainerStyle="password"
                    type="password"
                    text={t("Signup.RepeatPassword")}
                    value={values.repeatPassword}
                    autoComplete="repeat-current-password"
                    error={
                      errors.repeatPassword || signupErrorsObject.repeatPassword
                    }
                    touched={touched.repeatPassword}
                    changeHandler={handleChange("repeatPassword")}
                  />
                  <div
                    className={`${style.signupPageCheckboxWrapper} ${style.signupPageCheckboxWrapper__private}`}
                  >
                    <input
                      type="checkbox"
                      className={style.signupPageCheckbox}
                      id="private policy"
                      onClick={() =>
                        setIsPrivatePolicy(
                          (isPrivatePolicy) => !isPrivatePolicy
                        )
                      }
                    />
                    <label
                      className={style.signupPageCheckboxText}
                      htmlFor="private policy"
                    >
                      {addLinksToText(
                        consents.length > 0
                          ? consents.filter(
                              (consent) => consent.name === "agreedToTerms"
                            )[0].textT[currentLanguage]
                          : "",
                        style.signupPageCheckboxLink
                      )}
                    </label>
                  </div>
                  <div
                    className={`${style.signupPageCheckboxWrapper} ${style.signupPageCheckboxWrapper__data}`}
                  >
                    <input
                      type="checkbox"
                      className={style.signupPageCheckbox}
                      id="data processing"
                      onClick={() =>
                        setIsGatherPrivateData(
                          (isGatherPrivateData) => !isGatherPrivateData
                        )
                      }
                    />
                    <label
                      className={style.signupPageCheckboxText}
                      htmlFor="data processing"
                    >
                      {addLinksToText(
                        consents.length > 0
                          ? consents.filter(
                              (consent) =>
                                consent.name === "agreedToProcessHealthData"
                            )[0].textT[currentLanguage]
                          : "",
                        style.signupPageCheckboxLink
                      )}
                    </label>
                  </div>
                  <button
                    type="button"
                    className={
                      isPrivatePolicy && isGatherPrivateData
                        ? `${style.signupPageButton} ${style.signupPageButton__enabled}`
                        : `${style.signupPageButton} ${style.signupPageButton__disabled}`
                    }
                    disabled={!(isPrivatePolicy && isGatherPrivateData)}
                    onClick={() => {
                      handleSubmit();
                      validateDateError(
                        null,
                        values.year,
                        values.month,
                        values.day,
                        submitCount
                      );
                    }}
                  >
                    {t("Signup.SingUpButton")}
                  </button>
                </div>
              );
            }}
          </Formik>
          <div className={style.signupPageFooterWrapper}>
            <span className={style.signupPageFooterText}>
              {t("Signup.AlredyHaveAccount")}
            </span>
            <Link to="/login" className={style.signupPageFooterLink}>
              {t("Signup.LoginLink")}
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default SignupPage;
