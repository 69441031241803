import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ContentResearch.module.scss";

import { Research } from "components/Research";

const ContentResearch = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.research}>
      <div className={styles.research__title} id="tyrimai">
        {t("TestPackages.Header")}
      </div>
      <Research acceptGlobalValues={true} />
    </div>
  );
};

export default ContentResearch;
