import { createAsyncThunk } from "@reduxjs/toolkit";

import httpService from "services/httpService";
import { UserUpdateDTO, ErrorsObjectType, UserType } from "types";

export const getUser = createAsyncThunk<
  UserType,
  void,
  { rejectValue: ErrorsObjectType }
>("user/getUser", async (_, { rejectWithValue }) => {
  try {
    const user = await httpService.get("/user");
    return user;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const setUser = createAsyncThunk<
  UserType,
  any,
  { rejectValue: ErrorsObjectType }
>(
  "user/setUser",
  async (updatedUserData: UserUpdateDTO, { rejectWithValue }) => {
    try {
      const newUserData = await httpService.put("/user/", {
        body: JSON.stringify(updatedUserData),
      });
      return newUserData;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);
