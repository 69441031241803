import * as React from "react";

import styles from "./Question.module.scss";

import { arrowDown, arrowUp } from "assets/image";

type Props = {
  id: number;
  question: string;
  answer: string | React.ReactNodeArray;
};

const Question = (props: Props): JSX.Element => {
  const [opened, setOpened] = React.useState<boolean>(false);

  return (
    <div className={styles.faq__item}>
      <div className={styles.faq__question} onClick={() => setOpened(!opened)}>
        <div className={styles.faq__question__text}>{props.question}</div>
        <img
          className={styles.faq__arrow}
          src={opened ? arrowUp : arrowDown}
          alt="Arrow"
        />
      </div>
      <div
        className={`${styles.faq__answer} ${
          opened ? styles.faq__answer_opened : ""
        }`}
      >
        {props.answer}
      </div>
    </div>
  );
};

export default Question;
