import { Dispatch } from "redux";

import httpService from "services/httpService";
import errorsService from "services/errorsService";
import { AuthActionTypes, AuthAction, ErrorsObjectType } from "types";
import firebase from "firebaseApp";

const login = (
  email: string,
  password: string,
  rememberMeCheckBox: boolean
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: AuthActionTypes.SET_AUTH_LOADING,
      payload: true,
    });
    try {
      const user = await httpService.post("/auth/signin", {
        body: JSON.stringify({ email, password }),
      });
      if (!user.errors && rememberMeCheckBox) {
        localStorage.setItem("token", JSON.stringify(user.token));
      }
      httpService.setAuthHeader(
        `${user.token.accessTokenType} ${user.token.accessToken}`
      );
      dispatch({
        type: AuthActionTypes.SET_IS_AUTHORIZED,
        payload: true,
      });
    } catch (e: any) {
      dispatch({
        type: AuthActionTypes.SET_AUTH_ERRORS_OBJECT,
        payload: errorsService.createValidationErrorsObject(e),
      });
    }
  };
};

const setAuthorized = (value: boolean): AuthAction => ({
  type: AuthActionTypes.SET_IS_AUTHORIZED,
  payload: value,
});

const setAuthLoading = (value: boolean): AuthAction => ({
  type: AuthActionTypes.SET_AUTH_LOADING,
  payload: value,
});

const setAuthErrorObject = (value: ErrorsObjectType): AuthAction => ({
  type: AuthActionTypes.SET_AUTH_ERRORS_OBJECT,
  payload: value,
});

const logout = (): void => {
  localStorage.removeItem("token");

  setAuthorized(false);
};

const sendResetLink = (email: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: AuthActionTypes.SET_AUTH_LOADING,
      payload: true,
    });
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      dispatch({
        type: AuthActionTypes.SET_AUTH_LOADING,
        payload: false,
      });
    } catch (e: any) {
      console.error(e.message);
    }
  };
};

export {
  login,
  setAuthorized,
  setAuthErrorObject,
  setAuthLoading,
  logout,
  sendResetLink,
};
