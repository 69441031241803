import {
  OrdersActionTypes,
  OrderItemType,
  PaymentDetailsType,
  ErrorsObjectType,
  OrdersAction,
  OrderPaymentDetailsAction,
} from "types";

interface OrdersState {
  ordersLoading: boolean;
  ordersErrorMessage: string;
  orders: OrderItemType[];
  paymentDetailsLoading: boolean;
  paymentDetailsErrorMessage: string;
  paymentDetails: PaymentDetailsType | Record<string, never>;
  createOrderLoading: boolean;
  createOrderErrorObject: ErrorsObjectType | Record<string, never>;
  orderCreated: boolean;
  hash: string;
  identityVerificationUrl: null | string;
  calendlyEventUrl: null | string;
  needCalendlyVerification: boolean;
}

const initialState: OrdersState = {
  ordersLoading: false,
  ordersErrorMessage: "",
  orders: [],
  paymentDetailsLoading: false,
  paymentDetailsErrorMessage: "",
  paymentDetails: {},
  createOrderLoading: false,
  createOrderErrorObject: {},
  orderCreated: false,
  hash: "",
  identityVerificationUrl: null,
  calendlyEventUrl: null,
  needCalendlyVerification: false,
};

export const ordersReducer = (
  state: OrdersState = initialState,
  action: OrdersAction | OrderPaymentDetailsAction
): OrdersState => {
  switch (action.type) {
    case OrdersActionTypes.PENDING_ORDERS: {
      return {
        ...state,
        ordersLoading: true,
      };
    }

    case OrdersActionTypes.FULFILLED_ORDERS: {
      return {
        ...state,
        ordersLoading: false,
        orders: action.payload,
        ordersErrorMessage: "",
      };
    }

    case OrdersActionTypes.REJECTED_ORDERS: {
      return {
        ...state,
        ordersLoading: false,
        ordersErrorMessage: action.payload,
      };
    }

    case OrdersActionTypes.PENDING_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetailsLoading: true,
      };
    }

    case OrdersActionTypes.FULFILLED_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetailsLoading: false,
        paymentDetails: action.payload,
        paymentDetailsErrorMessage: "",
      };
    }

    case OrdersActionTypes.REJECTED_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetailsLoading: false,
        paymentDetailsErrorMessage: action.payload,
      };
    }
    case OrdersActionTypes.PENDING_CREATE_ORDER: {
      return {
        ...state,
        createOrderLoading: true,
      };
    }

    case OrdersActionTypes.FULFILLED_CREATE_ORDER: {
      return {
        ...state,
        createOrderLoading: false,
        orderCreated: true,
        identityVerificationUrl: action.payload.identityVerificationUrl,
        calendlyEventUrl: action.payload.calendlyEventUrl,
      };
    }

    case OrdersActionTypes.REJECTED_CREATE_ORDER: {
      return {
        ...state,
        createOrderLoading: false,
        createOrderErrorObject: action.payload,
      };
    }

    case OrdersActionTypes.CLEAR_CREATE_ORDER_STATE: {
      return {
        ...state,
        createOrderLoading: false,
        createOrderErrorObject: {},
        orderCreated: false,
        identityVerificationUrl: null,
        calendlyEventUrl: null,
      };
    }

    case OrdersActionTypes.PENDING_CREATE_KIT_PURCHASE_ORDER: {
      return {
        ...state,
        createOrderLoading: true,
      };
    }

    case OrdersActionTypes.FULFILLED_CREATE_KIT_PURCHASE_ORDER: {
      return {
        ...state,
        createOrderLoading: false,
        orderCreated: true,
        identityVerificationUrl: action.payload.identityVerificationUrl,
        calendlyEventUrl: action.payload.calendlyEventUrl,
      };
    }

    case OrdersActionTypes.REJECTED_CREATE_KIT_PURCHASE_ORDER: {
      return {
        ...state,
        createOrderLoading: false,
        createOrderErrorObject: { createKitPurchaseOrder: action.payload },
      };
    }

    case OrdersActionTypes.STORE_KIT_PURCHASE_HASH: {
      return {
        ...state,
        hash: action.payload,
      };
    }

    case OrdersActionTypes.CLEAR_HASH: {
      return {
        ...state,
        hash: "",
      };
    }

    case OrdersActionTypes.SET_NEED_CALENDLY_VERIFICATION: {
      return {
        ...state,
        needCalendlyVerification: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
