import { createSlice } from "@reduxjs/toolkit";

import { OmnivaDepartment } from "types";
import { getDepartmentsList } from "store/thunks";

interface OmnivaDepartmentsState {
  departmentsLoading: boolean;
  departmentsList: OmnivaDepartment[];
  departmentsError: any;
}

const initialState: OmnivaDepartmentsState = {
  departmentsLoading: false,
  departmentsList: [],
  departmentsError: null,
};

const omnivaDepartmentsSlice = createSlice({
  name: "omnivaDepartmentsReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentsList.pending, (state) => {
        state.departmentsLoading = true;
      })
      .addCase(getDepartmentsList.fulfilled, (state, action) => {
        state.departmentsLoading = false;
        state.departmentsList = action.payload;
        state.departmentsError = null;
      })
      .addCase(getDepartmentsList.rejected, (state, action) => {
        state.departmentsLoading = false;
        state.departmentsError = action.payload;
      });
  },
});

export default omnivaDepartmentsSlice.reducer;
