export enum ErrorType {
  validation = "validation",
  operational = "operational",
  programmer = "programmer",
}

export type ErrorsObjectType = {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  repeatPassword?: string;
  birthDate?: string;
  sex?: string;
  mobileNumber?: string;
  cardError?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  identificationCode?: string;
  createKitPurchaseOrder?: string;
  childFirstName?: string;
  childLastName?: string;
  childIdentificationCode?: string;
  name?: string;
  county?: string;
};

export enum ErrorFields {
  email = "email",
  password = "password",
  firstName = "firstName",
  lastName = "lastName",
  repeatPassword = "repeatPassword",
  birthDate = "birthDate",
  sex = "sex",
  mobileNumber = "mobileNumber",
}

export type CustomError = {
  errors: {
    errors: { message: string; field: string }[];
    message: string;
    name: string;
    type: ErrorType;
  };
};

export enum ErrorMessages {
  maxQuantityExceeded = "validation/max-quantity-exceeded",
}
