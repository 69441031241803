import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Orders.module.scss";

import { useTypedSelector, useAppDispatch } from "hooks";
import ResizableSpinner from "components/ResizableSpinner";
import { fetchOrders } from "store/actions";

const Orders = (): JSX.Element => {
  const { orders, ordersLoading } = useTypedSelector((state) => state.orders);
  const { items } = useTypedSelector((state) => state.testPackages);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  return (
    <div className={styles.orders}>
      <div className={styles.orders__title}>{t("Profile.OrderList")}</div>
      <div className={styles.orders__content}>
        {ordersLoading ? (
          <div className={styles.spinnerHolder}>
            <ResizableSpinner />
          </div>
        ) : (
          orders.map(({ id, testPackages }) => {
            return (
              <div className={styles.orders__order} key={id}>
                <div className={styles.orders__orderName}>
                  {testPackages
                    .map((testPackage) => {
                      const translatedPackage = items.find(
                        (item) => item.id === testPackage.testPackageId
                      );
                      return translatedPackage
                        ? translatedPackage.nameT
                        : testPackage.testPackageName;
                    })
                    .join(", ")}
                </div>
                <Link
                  className={styles.orders__orderLink}
                  to={`/user/profile/orders/${id}`}
                >
                  {t("Profile.View")}
                </Link>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Orders;
