import { CartItemType, Country } from "types";

export type UserType = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  sex: Sex;
  birthDate: Date;
  cart: CartItemType[];
  devices: [];
  firebaseUserId: string;
  stripeCustomerId: string;
  isAnonymous: boolean;
  isAdmin: boolean;
  isDisabled: boolean;
  agreedToTerms: boolean;
  mobileNumber: string;
  identityVerification: {
    status: VerificationStatus;
    hash: string;
  };
  market: Country;
};

export type UserUpdateDTO = {
  firstName: string;
  lastName: string;
  password?: string;
  repeatPassword?: string;
};

export enum Sex {
  female = "female",
  male = "male",
}

export type UserId = {
  id: string;
  firebaseId: string;
};

export enum VerificationStatus {
  idle = "idle",
  started = "started",
  approved = "approved",
  declined = "declined",
  expired = "expired",
}
