import traverse from "traverse";

import { Language } from "types";

const translateObject = (
  multiLanguageObject: { [key: string]: any },
  language: Language
): any => {
  const cloneMultiLanguageObject = traverse.clone(multiLanguageObject);
  const internationalizedObject = traverse(cloneMultiLanguageObject).map(
    function (item) {
      if (this.key === language) {
        this.parent?.update(item);
      }
    }
  );
  return internationalizedObject;
};

export { translateObject };
