export type PromoCode = {
  enabled: boolean;
  exp?: number;
  id: string;
  name: string;
  operation: PromoCodeOperation;
  valueOff: number;
};

export enum PromoCodeOperation {
  subtraction = "subtraction",
  percentage = "percentage",
}

export enum PriceView {
  default = "default",
  withDiscount = "withDiscount",
}
