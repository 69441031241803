import * as React from "react";

import styles from "./Checkbox.module.scss";

type Props = {
  id: string;
  checked: boolean;
  setChecked: () => void;
};

const Checkbox = ({ id, checked, setChecked }: Props): JSX.Element => {
  return (
    <div className={styles.checkbox}>
      <input
        type="checkbox"
        className={styles.checkbox__input}
        id={id}
        checked={checked}
        onChange={() => setChecked()}
      />
    </div>
  );
};

export default Checkbox;
