import * as React from "react";
import { Redirect, useParams } from "react-router";

import styles from "./KitPurchase.module.scss";

import ErrorPage from "components/Error";
import { useAppDispatch, useTypedSelector } from "hooks";
import {
  clearHash,
  clearTestKitsState,
  getActiveTestKit,
  storeKitPurchaseHash,
} from "store/actions";
import ResizableSpinner from "components/ResizableSpinner";

const KitPurchase = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { hash }: { hash: string } = useParams();
  const { isAuthorized } = useTypedSelector((state) => state.auth);
  const { activeTestKitLoading, activeTestKit, activeTestKitError } =
    useTypedSelector((state) => state.testKits);

  React.useEffect(() => {
    dispatch(storeKitPurchaseHash(hash));
  }, [dispatch, hash]);

  React.useEffect(() => {
    if (
      hash &&
      activeTestKit &&
      Object.keys(activeTestKit).length === 0 &&
      !activeTestKitError
    ) {
      dispatch(getActiveTestKit(hash));
    }
  }, [dispatch, activeTestKit, activeTestKitError, hash]);

  React.useEffect(() => {
    if (activeTestKitError || activeTestKit.isUsed) {
      return () => {
        dispatch(clearTestKitsState());
        dispatch(clearHash());
      };
    }
  }, [dispatch, activeTestKitError, activeTestKit]);

  if (activeTestKitLoading) {
    return (
      <div className={styles.wrapper}>
        <ResizableSpinner />
      </div>
    );
  }

  if (!activeTestKitLoading && activeTestKitError) {
    return (
      <ErrorPage
        errorTextKeys={[
          "QrCodeNotExistText1Part",
          "ContactsLink",
          "QrCodeNotExistText2Part",
        ]}
      />
    );
  }

  if (activeTestKit && activeTestKit.isUsed) {
    return (
      <ErrorPage
        errorTextKeys={[
          "UsedQrCodeText1Part",
          "ContactsLink",
          "UsedQrCodeText2Part",
        ]}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      {!activeTestKitLoading && Object.keys(activeTestKit).length !== 0 && (
        <Redirect to={isAuthorized ? "/checkout" : "/signup"} />
      )}
    </div>
  );
};

export default KitPurchase;
