import * as React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useParams } from "react-router-dom";

import styles from "./SurveyPage.module.scss";

import SurveyQuestion from "components/Survey/SurveyQuestion";
import CustomConsent from "components/Consent";
import ResizableSpinner from "components/ResizableSpinner";
import CustomInput from "components/CustomInput";
import SurveySuccessMessage from "components/Survey/SurveySuccessMessage";
import FileUploadForm from "components/Survey/FileUploadForm";

import { useAppDispatch, useTypedSelector } from "hooks";
import { getSurvey, getSurveyList, uploadSurveyResult } from "store/thunks";
import { convertAnswersToArrayOfObjects, addLinksToText } from "helpers";
import { SurveySchema } from "validation";
import { clearCurrentSurveyState } from "store/slices";
import { Consent } from "types";
import { LanguageContext } from "context";

const SurveyPage = (): JSX.Element => {
  const [files, setFiles] = React.useState<{ [key: string]: File | null }>({});
  const {
    isSurveyListLoading,
    isSurveyLoading,
    survey,
    surveyResultSended,
    isFileUploaded,
    isPendingSurveyResult,
    isFileEncrypted,
    filePassword,
    isFilePasswordCorrect,
    surveyList,
  } = useTypedSelector((state) => state.survey);
  const { consents } = useTypedSelector((state) => state.consents);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const { surveyAlias, surveyCampaign } =
    useParams<{ surveyAlias: string; surveyCampaign?: string }>();

  const [consentsAgreements, setConsentsAgreements] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [answers, setAnswers] = React.useState<{
    [key: string]: number | string | null;
  }>({});
  const [requiredConsents, setRequiredConsents] = React.useState<Consent[]>([]);

  const consentAgreements =
    Object.values(consentsAgreements).length ===
      Object.values(requiredConsents).length &&
    Object.values(consentsAgreements).every((consent) => consent);
  const isAllFilesUploaded = Object.keys(isFileUploaded).every(
    (fileId) => isFileUploaded[fileId]
  );
  const hasAllRequiredPasswords =
    Object.keys(files).length > 0 &&
    Object.keys(isFileEncrypted).length === Object.keys(files).length &&
    Object.keys(isFileEncrypted).every((fileId) =>
      isFileEncrypted[fileId] ? isFilePasswordCorrect[fileId] : true
    );
  const questionsAnswered =
    Object.values(answers).every((answer) => answer) &&
    Object.values(answers).length === survey.questions?.length;
  const { currentLanguage } = React.useContext(LanguageContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSubmitEnabled =
    consentAgreements && isAllFilesUploaded && hasAllRequiredPasswords;

  React.useEffect(() => {
    if (surveyList.length === 0 && !isSurveyListLoading) {
      dispatch(
        getSurveyList({
          currentLanguage: currentLanguage,
          marketId: currentMarket.country,
        })
      );
    }
  }, [
    dispatch,
    currentLanguage,
    isSurveyListLoading,
    currentMarket.country,
    surveyList,
  ]);

  React.useEffect(() => {
    if (surveyList.length > 0) {
      const currentSurveyId =
        surveyList.find(
          (survey) =>
            survey.alias === surveyAlias && survey.campaign === surveyCampaign
        )?.id || "";
      dispatch(getSurvey(currentSurveyId));
    }
  }, [dispatch, surveyList, surveyAlias, currentLanguage, surveyCampaign]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCurrentSurveyState());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (Object.keys(survey).length !== 0) {
      setRequiredConsents(
        survey.consents.map(
          (surveyConsent) =>
            consents.find((consent) => consent.id === surveyConsent)!
        )
      );
      setAnswers(
        survey.questions.reduce(
          (o, key) => Object.assign(o, { [key.id]: null }),
          {}
        )
      );
    }
  }, [survey, survey.consents, consents]);

  if (
    isSurveyListLoading ||
    isSurveyLoading ||
    isPendingSurveyResult ||
    Object.entries(survey).length === 0
  ) {
    return (
      <div className={styles.surveyList}>
        <div className={styles.survey}>
          <ResizableSpinner />
        </div>
      </div>
    );
  }

  if (surveyResultSended) {
    return <SurveySuccessMessage />;
  }

  return (
    <div className={styles.survey}>
      <div className={styles.background}></div>
      <div className={styles.survey__top}>
        <div className={styles.survey__title}>{survey.nameT}</div>
        <div className={styles.survey__description}>
          {addLinksToText(survey.descriptionT, styles.survey__link)}
        </div>
        <div className={styles.survey__subDescription}>
          {addLinksToText(survey.subDescriptionT, styles.survey__link)}
        </div>
      </div>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values) => {
          if (consentAgreements && questionsAnswered) {
            dispatch(
              uploadSurveyResult({
                files: Object.fromEntries(
                  Object.entries(files).filter(
                    ([fileId, file]) => file != null
                  ) as [string, File][]
                ),
                answers: JSON.stringify(
                  convertAnswersToArrayOfObjects(answers)
                ),
                templateId: survey.id,
                email: values.email,
                pdfPassword: filePassword,
                consents: consentsAgreements,
                market: currentMarket.country,
              })
            );
          }
        }}
        validationSchema={SurveySchema}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          submitCount,
        }) => (
          <div className={styles.form}>
            <div className={styles.form__headBlock}>
              <CustomInput
                contentContainerStyle="bigInput"
                text={t("Survey.Email")}
                value={values.email}
                error={errors.email}
                verticalError={true}
                touched={touched.email}
                changeHandler={handleChange("email")}
              />
            </div>
            <div className={styles.form__uploadBlock}>
              {survey.files.map((file) => (
                <FileUploadForm
                  files={files}
                  setFiles={setFiles}
                  file={file}
                  key={file.fileId}
                  isBigHeader={false}
                  noBottomBorder={false}
                />
              ))}
            </div>
            <div className={styles.form__questionsBlock}>
              {survey.questions.map((question) => (
                <div className={styles.form__questionWrapper} key={question.id}>
                  <SurveyQuestion
                    submitCount={submitCount}
                    answers={answers}
                    question={question}
                    setAnswers={setAnswers}
                  />
                </div>
              ))}
            </div>
            <div className={styles.form__bottomBlock}>
              <div className={styles.form__consents}>
                {requiredConsents.map((consent) => (
                  <CustomConsent
                    key={consent.id}
                    id={consent.id}
                    checked={consentsAgreements[consent.name] || false}
                    setChecked={() =>
                      setConsentsAgreements({
                        ...consentsAgreements,
                        [consent.name]: !consentsAgreements[consent.name],
                      })
                    }
                  />
                ))}
              </div>
              <button
                className={
                  isSubmitEnabled
                    ? styles.form__button
                    : `${styles.form__button} ${styles.form__button_disabled}`
                }
                disabled={!isSubmitEnabled}
                onClick={() => {
                  handleSubmit();
                  window.scrollTo({
                    top: 125,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                {t("Survey.Button")}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SurveyPage;
