import * as React from "react";
import { Link } from "react-router-dom";

import styles from "./Stressometer.module.scss";

import { stressometerWoman } from "assets/image";
import config from "config";

const Stressometer = (): JSX.Element => {
  return (
    <div className={styles.stressometer}>
      <img
        className={styles.stressometer__image}
        src={stressometerWoman}
        alt="Woman with pain"
      />
      <div className={styles.stressometer__content}>
        <div className={styles.stressometer__title}>Stressometer</div>
        <div className={styles.stressometer__text}>
          The Stressometer helps to determine the level of burnout by analyzing
          blood data and your answers to 6 questions. Upload the results of the
          Complete Blood Count (CBC) test in pdf format and answer the questions
          below.
        </div>
        <div className={styles.stressometer__textBold}>
          Take this quiz to find out if you are burned out
        </div>
        <Link
          to={`/survey/${config.burnoutAliasId}`}
          className={styles.stressometer__button}
        >
          Start
        </Link>
      </div>
    </div>
  );
};

export default Stressometer;
