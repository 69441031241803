import { TestKit, TestKitsAction, TestKitsActionTypes } from "types";

interface TestKitsState {
  activeTestKitLoading: boolean;
  activeTestKit: TestKit;
  activeTestKitError: string;
}

const initialState: TestKitsState = {
  activeTestKitLoading: false,
  activeTestKit: {} as TestKit,
  activeTestKitError: "",
};

export const testKitsReducer = (
  state: TestKitsState = initialState,
  action: TestKitsAction
): TestKitsState => {
  switch (action.type) {
    case TestKitsActionTypes.PENDING_ACTIVE_TEST_KITS: {
      return {
        ...state,
        activeTestKitLoading: true,
      };
    }

    case TestKitsActionTypes.FULFILLED_ACTIVE_TEST_KITS: {
      return {
        ...state,
        activeTestKitLoading: false,
        activeTestKit: action.payload,
        activeTestKitError: "",
      };
    }

    case TestKitsActionTypes.REJECTED_ACTIVE_TEST_KITS: {
      return {
        ...state,
        activeTestKitLoading: false,
        activeTestKitError: action.payload,
      };
    }

    case TestKitsActionTypes.CLEAR_TEST_KITS_STATE: {
      return {
        ...initialState,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
