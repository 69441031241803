import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./SingleParthner.module.scss";

import { diagnolita } from "assets/image";

const SingleParthner = (props: { parthner: string }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.singleParthner}>
      {props.parthner === "diagnolita" && (
        <div className={styles.diagnolita}>
          <div className={styles.diagnolita__title}>
            {t("Product.SinglePartner.Title")}
          </div>
          <div className={styles.diagnolita__content}>
            <img
              className={styles.diagnolita__image}
              src={diagnolita}
              alt="Diagnolita"
            />
            <div className={styles.diagnolita__textContent}>
              <div className={styles.diagnolita__text}>
                {t("Product.SinglePartner.Text1")}
              </div>
              <div className={styles.diagnolita__text}>
                {t("Product.SinglePartner.Text2")}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleParthner;
