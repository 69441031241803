import dayjs from "dayjs";
import * as Yup from "yup";

import {
  UserDeliveryInformationSchema,
  USCourierDeliveryInformationSchema,
  OmnivaDeliveryInformationSchema,
  IdentificationCodeSchema,
  childOrderSchema,
} from "validation";
import { DeliverySystem } from "types";

export const getOrderValidationSchema = (
  requiresIdentificationCode: boolean,
  childOrder: boolean,
  deliverySystem: DeliverySystem
): Yup.ObjectSchema<any> => {
  let DeliveryInformationSchema = UserDeliveryInformationSchema;
  if (deliverySystem === DeliverySystem.Omniva) {
    DeliveryInformationSchema = DeliveryInformationSchema.concat(
      OmnivaDeliveryInformationSchema
    );
  } else if (deliverySystem === DeliverySystem.USCourier) {
    DeliveryInformationSchema = DeliveryInformationSchema.concat(
      USCourierDeliveryInformationSchema
    );
  }

  if (childOrder && requiresIdentificationCode) {
    return DeliveryInformationSchema.concat(IdentificationCodeSchema).concat(
      childOrderSchema
    );
  }

  if (requiresIdentificationCode) {
    return DeliveryInformationSchema.concat(IdentificationCodeSchema);
  }

  if (childOrder) {
    return DeliveryInformationSchema.concat(childOrderSchema);
  }

  return DeliveryInformationSchema;
};

export const validateDateError = (
  yearsInputValue: string | null,
  years: string,
  months: string,
  days: string,
  submitCount = 1
): string => {
  if (submitCount === 0) {
    return "";
  }

  const childAge = new Date(
    yearsInputValue ? +yearsInputValue : +years,
    months ? +months - 1 : 0,
    days ? +days : 1
  );

  if (childAge < dayjs().subtract(18, "year").toDate()) {
    return "NotAChild";
  } else if (childAge > dayjs().subtract(3, "year").toDate()) {
    return "LessThan3Years";
  } else {
    return "";
  }
};
