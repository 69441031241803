import * as React from "react";

import styles from "./CheckboxQuestion.module.scss";

import { CheckboxQuestionType } from "types";
import { useTypedSelector } from "hooks";

type Props = {
  question: CheckboxQuestionType;
  answers: { [key: string]: string | number | null };
  setAnswers: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | number | null;
    }>
  >;
};

const CheckboxQuestion = ({
  question,
  setAnswers,
  answers,
}: Props): JSX.Element => {
  const survey = useTypedSelector((state) => state.survey);
  const isSurveyAliasEqualCardiac =
    survey.survey.alias === "cardiac-risk-assessment";

  const [checkedAnswers, setCheckedAnswers] = React.useState<string[]>([]);
  const answerValue = answers[question.id];

  const changeHandler = (currentVariant: string) => {
    const copyOfCheckedAnswers = [...checkedAnswers];
    const currentIndex = checkedAnswers.indexOf(currentVariant);
    if (currentIndex > -1) {
      copyOfCheckedAnswers.splice(currentIndex, 1);
      setCheckedAnswers(copyOfCheckedAnswers);
    } else {
      setCheckedAnswers(copyOfCheckedAnswers.concat([currentVariant]));
    }
  };

  React.useEffect(() => {
    if (checkedAnswers.length > 0) {
      setAnswers({ ...answers, [question.id]: checkedAnswers.join() });
    } else {
      const { [question.id]: currentAnswer, ...otherAnswers } = answers;
      setAnswers(otherAnswers);
    }
  }, [checkedAnswers]);

  return (
    <div
      className={
        isSurveyAliasEqualCardiac ? styles.modifiedWrapper : styles.wrapper
      }
    >
      {question.answerVariants.map((answerVariant) => {
        return (
          <div
            className={
              isSurveyAliasEqualCardiac
                ? answerValue === answerVariant
                  ? `${styles.modifiedAnswerContainer} ${styles.active}`
                  : styles.modifiedAnswerContainer
                : styles.answerContainer
            }
            key={answerVariant}
          >
            <input
              type="checkbox"
              className={
                isSurveyAliasEqualCardiac ? styles.modifiedInput : styles.input
              }
              id={answerVariant}
              disabled={
                !(
                  checkedAnswers.length < question.numberOfPossibleAnswers ||
                  checkedAnswers.some(
                    (checkedAnswer) => checkedAnswer === answerVariant
                  )
                )
              }
              checked={checkedAnswers.some(
                (checkedAnswer) => checkedAnswer === answerVariant
              )}
              onChange={() => changeHandler(answerVariant)}
            />
            <label className={styles.text} htmlFor={answerVariant}>
              {answerVariant}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxQuestion;
