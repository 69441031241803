import { PackageType } from "types";

export enum Colors {
  green = "green",
  red = "red",
}

export enum ColorValues {
  green = "#90E0B6",
  red = "#F16A87",
  white = "#FFF",
  black = "#000",
  grey = "rgba(14, 14, 14, 0.4)",
  lightGrey = "rgba(20, 20, 20, 0.1)",
}

export enum FontFamily {
  rubik = "Rubik",
}

export enum NormStatus {
  normal = "normal",
  high = "high",
  low = "low",
  notCompleted = "NotCompleted",
}

export type ResultItemType = {
  id: string;
  date: string;
  testPackage: string;
  lab: Laboratory;
  isCustom: boolean;
};

export type ChildResultItemType = {
  id: string;
  date: string;
  testPackage: string;
  lab: Laboratory;
  type: string;
};

export type ChartItemType = {
  date: string;
  result: number;
  current: boolean;
  previous: boolean;
};

export type ScaleItemType = {
  status: NormStatus;
  color: Colors;
  min: number;
  max: number;
};

export type TestResultItemType = {
  scale: ScaleItemType[];
  chart: ChartItemType[];
  testId: string;
  testName: string;
  testType: PackageType;
  dimension: string;
  status: NormStatus;
  description: string;
  booleanValue?: boolean;
  numericValue?: string;
};

export type CalculatedResult = {
  date: string;
  testPackageName: string;
  packageType: PackageType;
  testResults: { [key: string]: TestResultItemType };
};

export type SelectItemType = {
  label: string;
  value: string;
};

export enum Laboratory {
  Anteja = "Anteja",
  MedicinaPractica = "MedicinaPractica",
  Synlab = "Synlab",
}
