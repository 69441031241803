import * as React from "react";

import CompletedPage from "components/StatusPages/CompletedPage";

const IdentificationCompleted = (): JSX.Element => {
  return (
    <CompletedPage
      titleTextKeys={["IdentificationCompletedTitle"]}
      descriptionTextKeys={["IdentificationCompletedDescription"]}
    />
  );
};

export default IdentificationCompleted;
