import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./FAQ.module.scss";

import config from "config";
import { addLinksToText } from "helpers";
import Question from "components/Product/FAQ/Question";

const FAQ = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.faq}>
      <div className={styles.faq__background}></div>
      <div className={styles.faq__title}>{t("Product.FAQ.Title")}</div>
      <div className={styles.faq__list}>
        {new Array(config.faqNumber).fill(undefined).map((_, index) => {
          return (
            <Question
              id={index}
              question={t(`Product.FAQ.Question${index}`)}
              answer={addLinksToText(t(`Product.FAQ.Answer${index}`))}
              key={`question ${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FAQ;
