import React, { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { Formik, Field } from "formik";

import style from "./Login.module.scss";

import CustomInput from "components/CustomInput";
import Spinner from "components/Spinner";
import { useAppDispatch, useTypedSelector } from "hooks";
import { login, setAuthErrorObject } from "store/actions";
import { LoginSchema } from "validation";
import { ErrorsObjectType } from "types";

function LoginPage(): JSX.Element {
  const { t } = useTranslation();
  const { authErrorsObject, authLoading } = useTypedSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAuthErrorObject({} as ErrorsObjectType));
  }, [dispatch]);

  return (
    <div className={style.loginPageWrapper}>
      {authLoading && <Spinner />}
      <div className={style.loginPageContainer}>
        <span className={style.loginPageHeader}>{t("Login.Title")}</span>
        <span className={style.loginPageHeaderText}>{t("Login.Welcome")}</span>
        <Formik
          initialValues={{
            email: "",
            password: "",
            rememberMeCheckBox: false,
          }}
          onSubmit={(values) => {
            dispatch(
              login(values.email, values.password, values.rememberMeCheckBox)
            );
          }}
          validationSchema={LoginSchema}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => {
            return (
              <div
                className={style.formWrapper}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <CustomInput
                  text={t("Login.Email")}
                  value={values.email}
                  autoComplete="email"
                  error={errors.email || authErrorsObject.email}
                  touched={touched.email}
                  changeHandler={handleChange("email")}
                />
                <CustomInput
                  contentContainerStyle="password"
                  type="password"
                  text={t("Login.Password")}
                  value={values.password}
                  autoComplete="current-password"
                  error={errors.password || authErrorsObject.password}
                  touched={touched.password}
                  changeHandler={handleChange("password")}
                />
                <div className={style.loginPageLinkWrapper}>
                  <Link to="/resetpassword" className={style.loginPageLink}>
                    {t("Login.ForgotPassword")}
                  </Link>
                </div>
                <div className={style.loginPageCheckboxWrapper}>
                  <Field
                    type="checkbox"
                    className={style.loginPageCheckbox}
                    name="rememberMeCheckBox"
                    id="remember"
                  />
                  <label
                    className={style.loginPageCheckboxText}
                    htmlFor="remember"
                  >
                    {t("Login.RememberMeCheckbox")}
                  </label>
                </div>
                <button
                  type="button"
                  className={style.loginPageButton}
                  onClick={() => handleSubmit()}
                >
                  {t("Login.LogInButton")}
                </button>
              </div>
            );
          }}
        </Formik>
        <div className={style.loginPageFooterWrapper}>
          <span className={style.loginPageFooterText}>
            {t("Login.DontHaveAccount")}
          </span>
          <Link to="/signup" className={style.loginPageLink}>
            {t("Login.RegistrationLink")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
