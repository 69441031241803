import libphonenumber from "google-libphonenumber";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const mobileNumberValidator = (number?: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(number));
  } catch {
    return false;
  }
};

export const normalizeMobileNumber = (number: string): string => {
  let result = number;
  try {
    const parsedNumber = phoneUtil.parse(number);
    result = phoneUtil.format(
      parsedNumber,
      libphonenumber.PhoneNumberFormat.E164
    );
  } catch (e) {
    console.log(e);
  }

  return result;
};
