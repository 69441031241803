import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./ResearchType.module.scss";

import { ResearchLink } from "components/Research/ResearchLink";
import { arrowUp, arrowDown } from "assets/image";
import { ResearchItemType } from "types";

type Props = {
  research: ResearchItemType;
  acceptGlobalValues: boolean;
};

export const ResearchType = ({
  research,
  acceptGlobalValues,
}: Props): JSX.Element => {
  const [openedLocally, setOpenedLocally] = React.useState(false);
  const { t } = useTranslation();
  const {
    opened,
    divId,
    setOpened,
    image,
    nameT,
    hasDescription,
    description,
    boldDescription,
    isNew,
    popular,
    researches,
  } = research;

  return (
    <div className={styles.wrapper}>
      <Link
        to="/"
        className={styles.hiddenAnchor}
        id={divId}
        onClick={(e) => e.preventDefault()}
        tabIndex={-1}
      >
        .
      </Link>
      <div
        className={
          (opened && acceptGlobalValues) ||
          (openedLocally && !acceptGlobalValues)
            ? `${styles.type} ${styles.type_opened}`
            : `${styles.type}`
        }
        onClick={() => {
          if (acceptGlobalValues) {
            setOpened(!opened);
          } else {
            setOpenedLocally((openedLocally) => !openedLocally);
          }
        }}
      >
        <div className={styles.type__content}>
          <img className={styles.type__image} src={image} alt="Research type" />
          <div className={styles.type__name}>{nameT}</div>
          {hasDescription && (
            <div className={styles.type__description}>
              {description}
              <div className={styles.type__description_bold}>
                {boldDescription}
              </div>
            </div>
          )}
          {popular && (
            <div className={styles.type__popular}>
              {t("TestPackages.Popular")}
            </div>
          )}
          {isNew && (
            <div className={styles.type__new}>{t("TestPackages.New")}</div>
          )}
          <img
            className={styles.type__arrow}
            src={
              (opened && acceptGlobalValues) ||
              (openedLocally && !acceptGlobalValues)
                ? arrowUp
                : arrowDown
            }
            alt="Arrow"
          />
        </div>
      </div>
      {((opened && acceptGlobalValues) ||
        (openedLocally && !acceptGlobalValues)) && (
        <div className={styles.content}>
          {researches.map((research: ResearchItemType) => {
            return <ResearchLink research={research} key={research.id} />;
          })}
        </div>
      )}
    </div>
  );
};
