import React from "react";

import style from "./PrivacyPolicy.module.scss";

import config from "config";

function PrivacyPolicy(): JSX.Element {
  const { stateDataProtectonLink, revolabLink } = config;

  return (
    <div className={style.wrapper}>
      <span className={style.header}>
        BENDROVĖS „REVOLAB“ PRIVATUMO IR SLAPUKŲ POLITIKA
      </span>
      <span className={style.title}>PRIVATUMO POLITIKA</span>
      <p className={style.text}>
        Šioje bendrovės „Revolab“ privatumo politikoje (toliau –{" "}
        <b>Privatumo politika</b>) aprašoma, kaip tvarkome asmens duomenis.
        Prašome atidžiai susipažinti su šia Privatumo politika prieš pradėdami
        naudoti mūsų Programėlėje arba Tinklalapyje teikiamas paslaugas.
      </p>
      <p className={style.text}>
        <b>Jūsų privatumas –mūsų pagrindinis prioritetas</b>
      </p>
      <p className={style.text}>
        Mes gerbiame ir saugome jūsų privatumą bei įsipareigojame sąžiningai ir
        teisėtai tvarkyti jūsų Asmens duomenis. Mes naudojame tinkamas technines
        ir organizacines priemones, kad apsaugotume asmens duomenis nuo
        neteisėto naudojimo, atskleidimo, netyčinio praradimo, pakeitimo,
        sunaikinimo ir neteisėto tvarkymo kitokiais būdais.
      </p>
      <p className={style.text}>
        Ši Privatumo politika gali būti laikas nuo laiko keičiama pasikeitus
        įstatymams, pakeitus Bendrovės veiklą ir dėl kitokių veiksnių. Apie
        pakeitimus jus informuosime, pakeistą Privatumo politikos tekstą
        paskelbdami mūsų Tinklalapyje, Programėlėje ir (arba) atsiųsdami jį jums
        el. paštu. Bet kuriuo atveju patariame reguliariai peržiūrėti aktualią
        ir galiojančią Privatumo politiką
      </p>
      <p className={style.text}>
        Jei turite kokių nors klausimų, susijusių su jūsų Asmens duomenų
        tvarkymu, prašome kreiptis į mus el. paštu, adresas{" "}
        {config.revolabEmail}. Esame visuomet pasirengę jums padėti.
      </p>
      <p className={style.text}>
        <b>Apibrėžimai</b>
      </p>
      <p className={style.text}>
        <b>Programėlė</b> – mobilioji programėlė „Revolab“.
      </p>
      <p className={style.text}>
        <b>Bendrovė</b> – Revolab, UAB, pagal Lietuvos Respublikos įstatymus
        įsteigta ir veikianti uždaroji akcinė bendrovė, įmonės kodas 305579878.
        Šioje Privatumo politikoje Bendrovė taip pat gali būti vadinama{" "}
        <b>mes</b>.
      </p>
      <p className={style.text}>
        <b>Duomenų subjektas</b> – bet kuris fizinis asmuo, kuris parsisiunčia
        arba parsisiuntė Programėlę, perka arba nusipirkto prekių ir (arba)
        naudoja arba naudojo Programėlėje ir Tinklalapyje teikiamas paslaugas.
        Šioje Privatumo politikoje jis taip pat gali būti vadinamas <b>jūs</b>.
      </p>
      <p className={style.text}>
        <b>Duomenų valdytojas</b> – bet koks asmuo, kuris savarankiškai arba
        kartu su kitais asmenimis nustato Asmens duomenų tvarkymo tikslus ir
        priemones. Vykdant šioje Privatumo politikoje aprašomus tvarkymo
        veiksmus, Duomenų valdytojas yra Bendrovė.
      </p>
      <p className={style.text}>
        <b>Duomenų tvarkytojas</b> – bfizinis arba juridinis asmuo, tvarkantis
        Asmens duomenis Duomenų valdytojo vardu.
      </p>
      <p className={style.text}>
        <b>BDAR</b> – Reglamentas (ES) Nr. 2016/679 (Bendrasis duomenų apsaugos
        reglamentas).
      </p>
      <p className={style.text}>
        <b>Asmens duomenys</b> – bet kokia informacija, tiesiogiai arba
        netiesiogiai suteikianti galimybę nustatyti gyvo fizinio asmens
        tapatybę.
      </p>
      <p className={style.text}>
        <b>Tvarkymas</b> – bet koks su Asmens duomenimis automatiškai ar
        kitokiais būdais atliekamas veiksmas arba veiksmai, pavyzdžiui,
        rinkimas, užrašymas, sisteminimas, saugojimas, adaptavimas, keitimas,
        išgavimas, kaupimas, naudojimas, jungimas, ištrynimas arba sunaikinimas.
      </p>
      <p className={style.text}>
        <b>Gavėjas</b> – fizinis arba juridinis asmuo, valdžios institucija ar
        kitokia organizacija, kuriai Bendrovė gali teisėtai atskleisti Asmens
        duomenis.
      </p>
      <p className={style.text}>
        <b>Sąlygos</b> – Revolab, UAB klientams taikomos sąlygos, su kuriomis
        galima susipažinti Tinklalapyje ir Programėlėje.
      </p>
      <p className={style.text}>
        <b>Tinklalapis</b> –{" "}
        <a className={style.link} href={revolabLink}>
          {revolabLink}
        </a>
      </p>
      <p className={style.text}>
        Kitų šioje Privatumo politikoje vartojamų terminų reikšmės yra tokios,
        kokios nustatytos Sąlygose ir BDAR.
      </p>
      <p className={style.text}>
        <b>Kokius Asmens duomenis turime ir kaip juos gauname?</b>
      </p>
      <p className={style.text}>Šiuo metu tvarkome tokią informaciją:</p>
      <ul>
        <li>
          asmens tapatybės ir kontaktinius duomenis, kuriuos sudaro vardas ir
          pavardė, gimimo data, asmens kodas, lytis, slaptažodis, el. pašto
          adresas, fizinis adresas. Šią informaciją mums suteikiate Programėlėje
          sukurdami paskyrą, Tinklalapyje arba Programėlėje užsakydami prekes ir
          (arba) paslaugas ir (arba) patvirtindami savo tapatybę atliekant tam
          tikrą testą;
        </li>
        <li>
          sveikatos duomenis, kuriuos sudaro duomenys, susiję su jūsų
          užsakytomis ir jums pristatytomis prekėmis ir paslaugomis, įskaitant
          konkrečių rinkinių užsakymus, užsakytus testus ir laboratorijos
          pateiktus testų rezultatus;
        </li>
        <li>
          susirašinėjimo duomenis, susijusius su jūsų susirašinėjimu su Bendrove
          el. paštu ar kitokiomis priemonėmis;
        </li>
        <li>
          techninius duomenis, kuriuos sudaro IP adresas, informacija apie
          prietaisą, prisijungimo duomenys. Tokius duomenis pateikiate jūs /
          jūsų prietaisas, be to, jie renkami automatiškai.
        </li>
      </ul>
      <p className={style.text}>
        <b>Asmens duomenų tvarkymo teisiniai pagrindai ir tikslai</b>
      </p>
      <p className={style.text}>
        Jūsų Asmens duomenis renkame ir tvarkome tik turėdami tam teisinį
        pagrindą. Teisinį pagrindą sudaro sutikimas (kuomet suteikiate mums
        aiškų sutikimą), sutartis (kuomet duomenis tvarkyti būtina siekiant
        įvykdyti konkrečius užsakymus ir Sąlygas), mums taikomos teisinės
        prievolės ir mūsų teisėti interesai.
      </p>
      <p className={style.text}>
        <b>Sutikimas</b>
      </p>
      <p className={style.text}>
        Asmens duomenys apie sveikatą tvarkomi tik jeigu davėte aiškų sutikimą
        tvarkyti šią informaciją konkrečių rinkinių užsakymo, laboratorinių
        testų atlikimo ir testų rezultatų pateikimo jums tikslais.
      </p>
      <p className={style.text}>
        Jeigu Asmens duomenis tvarkome jūsų sutikimo pagrindu, jūs turite teisę
        bet kuriuo metu atšaukti savo sutikimą parašydami mums el. paštu,
        adresas {config.revolabEmail}. Atšaukus sutikimą, sutikimo pagrindu
        pirmiau vykdytas duomenų tvarkymas netampa neteisėtu.
      </p>
      <p className={style.text}>
        Prašome atkreipti dėmesį, kad galimybė tiesiogiai jums teikti paslaugas
        priklauso nuo to, ar sutinkate, kad būtų tvarkomi sveikatos duomenys.
        Todėl, jums atšaukus savo sutikimą, paslaugų jums teikti nebegalėsime.
      </p>
      <p className={style.text}>
        <b>Sąlygų vykdymas</b>
      </p>
      <p className={style.text}>
        Vienas iš Asmens duomenų tvarkymo tikslų yra teikti paslaugas (vykdyti
        užsakymus) ir vykdyti Sąlygas.
      </p>
      <p className={style.text}>
        Tokio duomenų tvarkymo tikslų pavyzdžiai yra tokie:
      </p>
      <ul>
        <li>
          suteikti teisę ir galimybę naudotis Programėle, Tinklalapiu bei
          Programėlėje ir Tinklalapyje teikiamomis paslaugomis, taip pat
          kontroliuoti ir tvarkyti prieigą prie Programėlės ir Tinklalapio;
        </li>
        <li>valdyti mūsų teisinius santykius su jumis.</li>
      </ul>
      <p className={style.text}>
        <b>Įstatymuose numatytų prievolių vykdymas</b>
      </p>
      <p className={style.text}>
        Bendrovė privalo vykdyti įstatymuose numatytas prievoles. Todėl pagal
        galiojančius įstatymus privalome tvarkyti Asmens duomenis.
      </p>
      <p className={style.text}>
        Tokio duomenų tvarkymo tikslų pavyzdžiai yra tokie:
      </p>
      <ul>
        <li>
          laikytis su prekėmis ir paslaugomis susijusių, taip pat buhalterinės
          apskaitos, teisminių tyrimų ir pan. taisyklių ir reglamentų;
        </li>
        <li>
          vykdyti galiojančiuose teisminio bylų nagrinėjimo teisės aktuose
          nustatytas prievoles.
        </li>
      </ul>
      <p className={style.text}>
        <b>Teisėti interesai</b>
      </p>
      <p className={style.text}>
        Bendrovė Asmens duomenis tvarko savo teisėtais interesais. Tokiu būdu
        duomenis tvarkyti yra būtina, siekiant įvykdyti teisėtus Bendrovės
        interesus, kurie mūsų nuomone nusveria Duomenų subjekto interesą
        apsaugoti Asmens duomenis.
      </p>
      <p className={style.text}>
        Tokio duomenų tvarkymo tikslų pavyzdžiai yra tokie:
      </p>
      <ul>
        <li>
          kurti, analizuoti ir tobulinti Programėlę, Tinklalapį ir (arba)
          paslaugas, ir (arba) gerinti Duomenų subjekto kaip kliento aptarnavimo
          kokybę;
        </li>
        <li>
          užkirsti kelią galimai apgaulei arba Sąlygų pažeidimams ir vykdyti su
          šiais dalykais susijusius tyrimus;
        </li>
        <li>atsakyti į Duomenų subjekto užklausas;</li>
        <li>nustatyti ir ginti teisinius reikalavimus.</li>
      </ul>
      <p className={style.text}>
        <b>Kam atskleidžiame Asmens duomenis?</b>
      </p>
      <p className={style.text}>
        Kai kuriais Asmens duomenimis Bendrovė dalinasi su Gavėjais. Bet kuriuo
        atveju, Bendrovė neatskleidžia daugiau Asmens duomenų nei reikia tuo
        tikslu, kuriuo jie yra atskleidžiami. Gavėjai Asmens duomenis gali
        tvarkyti kaip Duomenų tvarkytojai ir (arba) Duomenų valdytojai. Asmens
        duomenis tvarkydamas savo, t. y. Duomenų valdytojo vardu, Gavėjas
        privalo apie tokį Asmens duomenų tvarkymą informuoti Duomenų subjektą.
      </p>
      <p className={style.text}>
        Bendrovė gali Asmens duomenis atskleisti tokiems Gavėjams, kaip:
      </p>
      <ul>
        <li>
          tyrimus atliekanti ir tyrimų rezultatus jums teikianti laboratorija;
        </li>
        <li>
          kurjeriai, paimantys ir jums pristatantys konkrečius rinkinius ir iš
          jūsų paimantys pavyzdžius;
        </li>
        <li className={style.listItem}>
          Duomenų tvarkytojai, kuriuos Bendrovė pasitelkė Programėlei,
          Tinklalapiui ir (arba) paslaugoms kurti ir (arba) prižiūrėti;
        </li>
        <li>
          kompetentingosios institucijos, tokios, kaip sveikatos priežiūros,
          mokesčių įstaigos ir teisėsaugos institucijos;
        </li>
        <li>
          konsultantai finansų ir teisės klausimai, auditoriai, kiti paslaugų
          teikėjai ir Bendrovės partneriai, kuriems pagal įstatymus būtina
          atskleisti Asmens duomenis.
        </li>
      </ul>
      <p className={style.text}>
        <b>Kaip ilgai saugome Asmens duomenis?</b>
      </p>
      <p className={style.text}>
        Visi Asmens duomenys yra saugiai tvarkomi ES.
      </p>
      <p className={style.text}>
        Asmens duomenis saugome ribotą laiką ir bet kuriuo atveju ne ilgiau nei
        reikia tais tikslais, kuriais Asmens duomenys buvo surinkti. Saugojimo
        laikotarpiai nustatomi pagal įstatymus arba teisėtus Bendrovės
        interesus, pvz., pagal galiojančius teisės aktus tyrimų rezultatus
        būtina saugoti ne trumpiau kaip 5 metus. Pasibaigus saugojimo
        laikotarpiui, Bendrovė Asmens duomenis ištrina.
      </p>
      <p className={style.text}>
        <b>
          Kokias su duomenų apsauga susijusias teises turiu ir kaip galiu
          pareikšti skundą?
        </b>
      </p>
      <p className={style.text}>Pagal BDAR turite tokias teises:</p>
      <p className={style.text}>
        <b>Jūsų teisė susipažinti su duomenimis</b> – jūs turite teisę prašyti
        gauti savo asmeninės informacijos kopijas.
      </p>
      <p className={style.text}>
        <b>Jūsų teisė ištaisyti duomenis</b> – jūs turite teisę prašyti mūsų
        ištaisyti informaciją, kuri jūsų nuomone yra netiksli. Taip pat turite
        teisę prašyti mūsų papildyti jūsų manymu neišsamią informaciją.
      </p>
      <p className={style.text}>
        <b>Jūsų teisė ištrinti duomenis</b> – jūs turite teisę prašyti mūsų tam
        tikromis aplinkybėmis ištrinti jūsų asmeninę informaciją.
      </p>
      <p className={style.text}>
        <b>Jūsų teisė apriboti duomenų tvarkymą</b> – jūs turite teisę prašyti
        mūsų tam tikromis aplinkybėmis apriboti jūsų informacijos tvarkymą.
      </p>
      <p className={style.text}>
        <b>Jūsų teisė prieštarauti, kad jūsų duomenys būtų tvarkomi</b> – jūs
        turite teisę prieštarauti, kad tam tikromis aplinkybėmis jūsų
        informacija būtų tvarkoma.
      </p>
      <p className={style.text}>
        <b>Jūsų teisė prašyti perkelti duomenis</b> – jūs turite teisę prašyti
        tam tikromis aplinkybėmis perduoti jūsų mums suteiktą informaciją kitai
        organizacijai arba jums.
      </p>
      <p className={style.text}>
        Pateikus prašymą, į jį privalome atsakyti per vieną mėnesį.
      </p>
      <p className={style.text}>
        Norint pateikti prašymą, prašome kreiptis į mus el. paštu, adresas{" "}
        {config.revolabEmail}.
      </p>
      <p className={style.text}>
        Be to, jei jums nepatinka, kaip naudojome jūsų Asmens duomenis, galite
        pateikti skundą Lietuvos valstybinei duomenų apsaugos inspekcijai
        (tinklalapio adresas{" "}
        <a className={style.link} href={stateDataProtectonLink}>
          {stateDataProtectonLink}
        </a>
        ).
      </p>
      <p className={style.text}>
        <b>Mūsų kontaktiniai duomenys</b>
      </p>
      <p className={style.text}>Revolab, UAB</p>
      <p className={style.text}>{config.revolabEmail}</p>
      <p className={style.text}>+370 686 60555</p>
      <p className={style.text}>J. Basanavičiaus g. 26,</p>
      <p className={style.text}>LT-03224, Vilnius, Lietuva</p>
      <p className={style.text}>
        Paskutinį kartą atnaujinta 2021 m. gegužės 26 d.
      </p>
      <span className={style.title} id={"cookies"}>
        BENDROVĖS „REVOLAB“ SLAPUKŲ POLITIKA
      </span>
      <p className={style.text}>
        Šioje bendrovės „Revolab“ slapukų politikoje (toliau –{" "}
        <b>Slapukų politika</b>) pateikiama informacija apie UAB „Revolab“,
        pagal Lietuvos Respublikos įstatymus įsteigtos uždarosios akcinės
        bendrovės, įmonės kodas 305579878 (toliau – <b>Bendrovė</b>), naudojamus
        slapukus.
      </p>
      <p className={style.text}>
        Slapukų politika gali būti laikas nuo laiko keičiama. Apie pakeitimus
        jus informuosime pakeistą Slapukų politikos tekstą paskelbdami mūsų
        tinklapyje www.revolab.lt (toliau – <b>Tinklapis</b>). Rekomenduojame
        reguliariai apsilankyti Tinklapyje ir perskaityti aktualią ir
        galiojančią Slapukų politiką.
      </p>
      <p className={style.text}>
        Jei turite kokių nors klausimų apie šią Slapukų politiką, prašome
        kreiptis į mus el. paštu {config.revolabEmail}.
      </p>
      <p className={style.text}>
        Slapukai – tai nedidelės informacinės bylos, kurias jūsų naršyklė (pvz.,
        įdiegta kompiuteryje, mobiliajame telefone ar planšetiniame
        kompiuteryje) naudoja ir išsaugo jūsų prietaise. Panašiu tikslu
        naudojamos ir kitokios technologijos, įskaitant interneto naršyklėje
        arba prietaise saugomus duomenis, su prietaisu susijusius identifikavimo
        duomenis ir kitokią programinę įrangą. Jos plačiai naudojamos tam, kad
        tinklapiai veiktų arba veiktų dar geriau ir veiksmingiau. Šioje Slapukų
        politikoje visas šias technologijas vadiname slapukais.
      </p>
      <p className={style.text}>Bendrovės naudojamų slapukų aprašymas</p>
      <table>
        <thead>
          <tr>
            <td>Slapukas</td>
            <td>Slapuko paskirtis</td>
            <td>Naudojami duomenys</td>
            <td>Galiojimo trukmė</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>VISITOR_INFO1_LIVE</td>
            <td>Reklama</td>
            <td>
              Šį slapuką nustato „Youtube“. Naudojamas įdėtų „YouTube“ vaizdo
              įrašų informacijai stebėti svetainėje.
            </td>
            <td>5 mėnesiai</td>
          </tr>
          <tr>
            <td> _fbp</td>
            <td>Reklama</td>
            <td>
              Šį slapuką nustato „Facebook“, kad jis galėtų pateikti reklamą,
              kai jie yra „Facebook“ ar skaitmeninėje platformoje, kurią palaiko
              „Facebook“ reklama, apsilankius šioje svetainėje.
            </td>
            <td>5 mėnesiai</td>
          </tr>
          <tr>
            <td>IDE</td>
            <td>Reklama</td>
            <td>
              IDE .doubleclick.net reklama, kurią naudoja „Google DoubleClick“,
              ir saugo informaciją apie tai, kaip vartotojas naudoja svetainę ir
              bet kokį kitą skelbimą prieš apsilankydamas svetainėje. Tai
              naudojama norint pateikti vartotojams skelbimus, kurie yra
              aktualūs jiems pagal vartotojo profilį.
            </td>
            <td>1 metai ir 24d</td>
          </tr>
          <tr>
            <td>test_cookie</td>
            <td>Reklama</td>
            <td>
              Slapuko tikslas yra nustatyti, ar vartotojo naršyklė palaiko
              slapukus.
            </td>
            <td>15 min</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>Duomenų analitika</td>
            <td>
              Šį slapuką įdiegia „Google Analytics“. Slapukas naudojamas norint
              apskaičiuoti lankytojo, seanso, kampanijos duomenis ir stebėti
              svetainės naudojimą svetainės analizės ataskaitai. Slapukai saugo
              informaciją anonimiškai ir priskiria atsitiktinai sugeneruotą
              skaičių unikaliems lankytojams identifikuoti
            </td>
            <td>2 metai</td>
          </tr>
        </tbody>
      </table>
      <p className={style.text}>
        Galite nustatyti, kad jūsų naršyklę priimtų visus slapukus, atmestų
        visus slapukus arba praneštų, kai parsisiunčiamas slapukas. Kiekviena
        naršyklė yra kitokia, todėl, jei nežinote, kaip pakeisti jūsų slapukų
        nustatymus, rekomenduojame perskaityti šį vartotojo vadovą. Jei
        nenorite, kad slapukai rinktų informaciją, pakeisdami savo naršyklės
        nustatymus galite atsisakyti naudoti slapukus. Tačiau tokiais atvejais
        ištrynus slapukus gali nukentėti naršymo greitis, būtų apribotos tam
        tikros Tinklapio funkcijos arba jums netgi gali būti neleidžiama
        prisijungti prie Tinklapio.
      </p>
      <p className={style.text}>
        Taip pat pranešame, kad mūsų Tinklapyje yra nuorodų į kitų bendrovių
        arba organizacijų tinklapius. Bendrovė neatsako už tokių tinklapių
        turinį arba juose naudojamus privatumo principus. Iš mūsų Tinklapio
        prisijungę prie kitų tinklapių, turėtumėte atskirai susipažinti su juose
        galiojančia privatumo ir (arba) slapukų politika.
      </p>
      <p className={style.text}>
        Paskutinį kartą atnaujinta 2021 m. gegužės 27 d.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
