import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./BurnoutSymptoms.module.scss";

import {
  burnoutHead,
  symptomsHumanHappy,
  symptomsSadFace,
  symptomsBrainGears,
  symptomsBrainPuzzle,
  symptomsBrainGrow,
  symptomsHumanWeak,
  symptomsHumanRuns,
  symptomsPhone,
  symptomsHeart,
  symptomsApple,
  symptomsHappyFace,
} from "assets/image";

const BurnoutSymptoms = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.symptoms}>
      <div className={styles.symptoms__title}>
        <img
          className={styles.symptoms__titleImage}
          src={burnoutHead}
          alt="Head"
        />
        {t("Docs.BurnoutSymptoms.Title")}
      </div>
      <div className={styles.symptoms__content}>
        <div className={styles.symptoms__columns}>
          <div className={styles.symptoms__column}>
            <div className={styles.symptoms__blockTitle}>
              {t("Docs.BurnoutSymptoms.Titles.Title1")}
            </div>
            <div className={styles.symptoms__text}>
              {t("Docs.BurnoutSymptoms.Blocks.Block1.Text")}
            </div>
            <div className={styles.symptoms__text}>
              {t("Docs.BurnoutSymptoms.Blocks.Block2.Text")}
            </div>
            <div className={styles.symptoms__text}>
              {t("Docs.BurnoutSymptoms.Blocks.Block3.Text")}
            </div>
            <div className={styles.symptoms__textWrapper}>
              <div className={styles.symptoms__listTitle}>
                <img
                  className={styles.symptoms__textImage}
                  src={symptomsHumanWeak}
                  alt="Weak human"
                />
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Lists.List1.Title")}
                </span>
              </div>
              <div className={styles.symptoms__text}>
                <ul className={styles.symptoms__list}>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List1.Item1")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List1.Item2")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List1.Item3")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List1.Item4")}
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.symptoms__textWrapper}>
              <div className={styles.symptoms__listTitle}>
                <img
                  className={styles.symptoms__textImage}
                  src={symptomsSadFace}
                  alt="Sad face"
                />
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Lists.List2.Title")}
                </span>
              </div>
              <div className={styles.symptoms__text}>
                <ul className={styles.symptoms__list}>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List2.Item1")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List2.Item2")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List2.Item3")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List2.Item4")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List2.Item5")}
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.symptoms__textWrapper}>
              <div className={styles.symptoms__listTitle}>
                <img
                  className={styles.symptoms__textImage}
                  src={symptomsBrainPuzzle}
                  alt="Brain with puzzle"
                />
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Lists.List3.Title")}
                </span>
              </div>
              <div className={styles.symptoms__text}>
                <ul className={styles.symptoms__list}>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List3.Item1")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List3.Item2")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List3.Item3")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List3.Item4")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List3.Item5")}
                  </li>
                  <li className={styles.symptoms__item}>
                    {t("Docs.BurnoutSymptoms.Lists.List3.Item6")}
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.symptoms__blockTitle}>
              {t("Docs.BurnoutSymptoms.Titles.Title2")}
            </div>
            <div className={styles.symptoms__text}>
              {t("Docs.BurnoutSymptoms.Blocks.Block4.Text")}
            </div>
          </div>
          <div className={styles.symptoms__column}>
            <div className={styles.symptoms__blockTitle}>
              {t("Docs.BurnoutSymptoms.Titles.Title3")}
            </div>
            <div className={styles.symptoms__textWrapper}>
              <img
                className={styles.symptoms__textImage}
                src={symptomsHumanHappy}
                alt="Happy human"
              />
              <div className={styles.symptoms__text}>
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Blocks.Block5.TextBlue")}
                </span>
                {t("Docs.BurnoutSymptoms.Blocks.Block5.Text")}
              </div>
            </div>
            <div className={styles.symptoms__textWrapper}>
              <img
                className={styles.symptoms__textImage}
                src={symptomsSadFace}
                alt="Sad face"
              />
              <div className={styles.symptoms__text}>
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Blocks.Block6.TextBlue")}
                </span>
                {t("Docs.BurnoutSymptoms.Blocks.Block6.Text")}
              </div>
            </div>
            <div className={styles.symptoms__textWrapper}>
              <img
                className={styles.symptoms__textImage}
                src={symptomsBrainGears}
                alt="Brain with gears inside"
              />
              <div className={styles.symptoms__text}>
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Blocks.Block7.TextBlue")}
                </span>{" "}
                {t("Docs.BurnoutSymptoms.Blocks.Block7.Text")}
              </div>
            </div>
            <div className={styles.symptoms__textWrapper}>
              <img
                className={styles.symptoms__textImage}
                src={symptomsBrainPuzzle}
                alt="Brain with puzzle"
              />
              <div className={styles.symptoms__text}>
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Blocks.Block8.TextBlue")}
                </span>
                {t("Docs.BurnoutSymptoms.Blocks.Block8.Text")}
              </div>
            </div>
            <div className={styles.symptoms__textWrapper}>
              <img
                className={styles.symptoms__textImage}
                src={symptomsBrainGrow}
                alt="Brain with grow inside"
              />
              <div className={styles.symptoms__text}>
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Blocks.Block9.TextBlue")}
                </span>
                {t("Docs.BurnoutSymptoms.Blocks.Block9.Text")}
              </div>
            </div>
            <div className={styles.symptoms__textWrapper}>
              <img
                className={styles.symptoms__textImage}
                src={symptomsApple}
                alt="Apple"
              />
              <div className={styles.symptoms__text}>
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Blocks.Block10.TextBlue")}
                </span>
                {t("Docs.BurnoutSymptoms.Blocks.Block10.Text")}
              </div>
            </div>
            {/*  */}
            <div className={styles.symptoms__textWrapper}>
              <img
                className={styles.symptoms__textImage}
                src={symptomsHumanRuns}
                alt="Human run"
              />
              <div className={styles.symptoms__text}>
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Blocks.Block11.TextBlue")}
                </span>
                {t("Docs.BurnoutSymptoms.Blocks.Block11.Text")}
              </div>
            </div>
            <div className={styles.symptoms__textWrapper}>
              <img
                className={styles.symptoms__textImage}
                src={symptomsPhone}
                alt="Phone"
              />
              <div className={styles.symptoms__text}>
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Blocks.Block12.TextBlue")}
                </span>
                {t("Docs.BurnoutSymptoms.Blocks.Block12.Text")}
              </div>
            </div>
            <div className={styles.symptoms__textWrapper}>
              <img
                className={styles.symptoms__textImage}
                src={symptomsHeart}
                alt="Heart"
              />
              <div className={styles.symptoms__text}>
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Blocks.Block13.TextBlue")}
                </span>
                {t("Docs.BurnoutSymptoms.Blocks.Block13.Text")}
              </div>
            </div>
            <div className={styles.symptoms__textWrapper}>
              <img
                className={styles.symptoms__textImage}
                src={symptomsHappyFace}
                alt="Haapy face"
              />
              <div className={styles.symptoms__text}>
                <span className={styles.symptoms__text_blue}>
                  {t("Docs.BurnoutSymptoms.Blocks.Block14.TextBlue")}
                </span>
                {t("Docs.BurnoutSymptoms.Blocks.Block14.Text")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BurnoutSymptoms;
