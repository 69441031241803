import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Reviews.module.scss";

import { reviewWoman1, reviewWoman2 } from "assets/image";

const Reviews = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.reviews}>
      <div className={styles.reviews__background}></div>
      <div className={styles.reviews__content}>
        <div className={styles.reviews__title}>{t("Main.Reviews.Title")}</div>
        <div className={styles.reviews__subtitle}>
          {t("Main.Reviews.Subtitle")}
        </div>
        <div className={styles.reviews__list}>
          <div className={`${styles.reviews__item} ${styles.firstReview}`}>
            <div
              className={`${styles.reviews__imageHolder} ${styles.imageLeft}`}
            >
              <img
                className={styles.reviews__image}
                src={reviewWoman1}
                alt="Woman"
              />
            </div>
            <div>
              <div className={styles.reviews__name}>
                {t("Main.Reviews.Name1")}
              </div>
              <div className={styles.reviews__text}>
                {t("Main.Reviews.Text1Part1")}
              </div>
              <div className={styles.reviews__text}>
                {t("Main.Reviews.Text1Part2")}
              </div>
              <div className={styles.reviews__text}>
                {t("Main.Reviews.Text1Part3")}
              </div>
              <div className={styles.reviews__text}>
                {t("Main.Reviews.Text1Part4")}
              </div>
            </div>
          </div>
          <div className={`${styles.reviews__item} ${styles.secondReview}`}>
            <div>
              <div className={styles.reviews__name}>
                {t("Main.Reviews.Name2")}
              </div>
              <div className={styles.reviews__text}>
                {t("Main.Reviews.Text2Part1")}
              </div>
              <div className={styles.reviews__text}>
                {t("Main.Reviews.Text2Part2")}
              </div>
              <div className={styles.reviews__text}>
                {t("Main.Reviews.Text2Part3")}
              </div>
            </div>
            <div
              className={`${styles.reviews__imageHolder} ${styles.imageRight}`}
            >
              <img
                className={styles.reviews__image}
                src={reviewWoman2}
                alt="Woman"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
