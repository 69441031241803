import { SignupActionTypes, ErrorsObjectType, SignupAction } from "types";

export interface SignupState {
  signupErrorsObject: ErrorsObjectType | Record<string, never>;
  signupLoading: boolean;
}

const initialState: SignupState = {
  signupLoading: false,
  signupErrorsObject: {},
};

export const signupReducer = (
  state: SignupState = initialState,
  action: SignupAction
): SignupState => {
  switch (action.type) {
    case SignupActionTypes.SET_SIGNUP_ERRORS_OBJECT: {
      return {
        signupLoading: false,
        signupErrorsObject: action.payload,
      };
    }

    case SignupActionTypes.SET_SIGNUP_LOADING: {
      return {
        ...state,
        signupLoading: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
