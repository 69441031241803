import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { withTranslation, useTranslation } from "react-i18next";

import style from "./HeaderMenuMobile.module.scss";

import { useAction, useTypedSelector } from "hooks";
import { ShowMenusType, LanguageItemType } from "components/Header";
import i18next from "internationalization";
import {
  headerArrowDown,
  headerArrowUp,
  covidTest,
  womanHealthTest,
  manHealthTest,
  wellnessTest,
} from "assets/image";
import { Language } from "types";
import config from "config";

type Props = {
  showMenus: ShowMenusType;
  setShowMenus: React.Dispatch<React.SetStateAction<ShowMenusType>>;
  language: LanguageItemType[];
  languageItemClickHandler: (currentLanguage: Language) => void;
};

function HeaderMenuMobile({
  showMenus,
  setShowMenus,
  language,
  languageItemClickHandler,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { isAuthorized } = useTypedSelector((state) => state.auth);
  const { currentMarket } = useTypedSelector((state) => state.markets);

  const {
    setIsOpenCovidTestPackage,
    setIsOpenManHealthTestPackage,
    setIsOpenWomanHealthTestPackage,
    setIsOpenWellnessTestPackage,
  } = useAction();

  return (
    <>
      {!isAuthorized && !showMenus.mobileMenu && (
        <div className={style.buttonWrapper}>
          <Link
            className={style.joinItem}
            to="/login"
            onClick={() => setShowMenus({ ...showMenus, mobileMenu: false })}
          >
            {t("Header.ButtonLogin")}
          </Link>
          <Link
            className={style.registerItem}
            to="/signup"
            onClick={() => setShowMenus({ ...showMenus, mobileMenu: false })}
          >
            {t("Header.ButtonRegister")}
          </Link>
        </div>
      )}
      {showMenus.mobileMenu && (
        <div className={style.wrapper}>
          <div className={style.testsWrapper}>
            <div
              className={style.testItem}
              onClick={() =>
                setShowMenus({
                  ...showMenus,
                  mobileTestsMenu: !showMenus.mobileTestsMenu,
                })
              }
            >
              {t("TestPackages.Header")}
              <img
                src={
                  showMenus.mobileTestsMenu ? headerArrowUp : headerArrowDown
                }
                alt="burger"
              />
            </div>
            {showMenus.mobileTestsMenu && (
              <div className={style.testsMenuWrapper}>
                <Link
                  className={style.testsMenuItem}
                  to="/#covid19"
                  onClick={() => setIsOpenCovidTestPackage(true)}
                >
                  <img
                    className={style.testMenuImage}
                    src={covidTest}
                    alt="covid"
                  />
                  {t("TestPackages.Covid19")}
                </Link>
                <Link
                  className={style.testsMenuItem}
                  to="/#womanHealth"
                  onClick={() => setIsOpenWomanHealthTestPackage(true)}
                >
                  <img
                    className={style.testMenuImage}
                    src={womanHealthTest}
                    alt="woman health"
                  />
                  {t("TestPackages.WomensHealth")}
                </Link>
                <Link
                  className={style.testsMenuItem}
                  to="/#manHealth"
                  onClick={() => setIsOpenManHealthTestPackage(true)}
                >
                  <img
                    className={style.testMenuImage}
                    src={manHealthTest}
                    alt="man health"
                  />
                  {t("TestPackages.MensHealth")}
                </Link>
                <Link
                  className={style.testsMenuItem}
                  to="/#wellness"
                  onClick={() => setIsOpenWellnessTestPackage(true)}
                >
                  <img
                    className={style.testMenuImage}
                    src={wellnessTest}
                    alt="wellness"
                  />
                  {t("TestPackages.Wellness")}
                </Link>
              </div>
            )}
          </div>
          <Link className={style.menuItem} to="/#howdoesitworks">
            {t("Header.HowItWorks")}
          </Link>
          {currentMarket.languages.length > 1 && (
            <div className={style.languageWrapper}>
              <div
                className={style.languageItem}
                onClick={() =>
                  setShowMenus({
                    ...showMenus,
                    languageMenu: !showMenus.languageMenu,
                  })
                }
              >
                <img
                  className={style.flagImage}
                  src={
                    language.find(
                      (item) => item.shortName === i18next.language
                    )!.flag
                  }
                  alt="flag"
                />
                {
                  language.find((item) => item.shortName === i18next.language)!
                    .name
                }
                <img
                  className={style.arrowImage}
                  src={showMenus.languageMenu ? headerArrowUp : headerArrowDown}
                  alt="burger"
                />
              </div>
              {showMenus.languageMenu && (
                <div className={style.languageMenuPopup}>
                  {language
                    .filter(
                      (languageItem) =>
                        languageItem.shortName !== i18next.language &&
                        currentMarket.languages.includes(languageItem.shortName)
                    )
                    .map((langaugeItem) => {
                      return (
                        <div
                          className={style.languageItem}
                          key={langaugeItem.name}
                          onClick={() =>
                            languageItemClickHandler(langaugeItem.shortName)
                          }
                        >
                          <img
                            className={style.flagImage}
                            src={langaugeItem.flag}
                            alt="flag"
                          />
                          <div className={style.languageMenuItemText}>
                            {langaugeItem.name}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          )}
          <Link
            className={style.menuItem}
            to={`/survey/${config.burnoutAliasId}`}
          >
            {t("Header.Surveys")}
          </Link>
          {isAuthorized && (
            <Link className={style.menuItem} to="/dashboard">
              {t("Header.TestsResults")}
            </Link>
          )}
        </div>
      )}
    </>
  );
}

export default withTranslation()(HeaderMenuMobile);
