import * as Yup from "yup";
import { mobileNumberValidator } from "./mobile-phone";

export const UserDeliveryInformationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  mobileNumber: Yup.string()
    .trim()
    .required("Required")
    .test("mobile phone number", "InvalidMobileNumber", mobileNumberValidator),
});

export const OmnivaDeliveryInformationSchema = Yup.object().shape({
  address: Yup.string()
    .min(2, "Min2")
    .max(80, "Max80")
    .trim()
    .required("Required"),
  name: Yup.string()
    .min(2, "Min2")
    .max(80, "Max80")
    .trim()
    .required("Required"),
  county: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  postalCode: Yup.string()
    .matches(/^[0-9]{5}$/, "FiveDigits")
    .required("Required"),
});

export const USCourierDeliveryInformationSchema = Yup.object().shape({
  addressLine1: Yup.string()
    .min(2, "Min2")
    .max(80, "Max80")
    .trim()
    .required("Required"),
  addressLine2: Yup.string().min(2, "Min2").max(80, "Max0").trim(),
  state: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  city: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  postalCode: Yup.string()
    .matches(/^[0-9]{5}$/, "FiveDigits")
    .required("Required"),
});

export const IdentificationCodeSchema = Yup.object().shape({
  identificationCode: Yup.string()
    .trim()
    .matches(/^[0-9]{11}$/, "IdentificationCode")
    .required("Required"),
});
