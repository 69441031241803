import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./OrderDetails.module.scss";
import { useTypedSelector } from "hooks";
import { CartItemType, PaymentType, PriceView } from "types";
import analyticsService from "services/analyticsService";
import { getPrice } from "components/ShoppingCart/getPrice";
import { getCurrencySymbol } from "helpers";
import ResizableSpinner from "components/ResizableSpinner";

const TestDetails = (): JSX.Element => {
  const { hash } = useTypedSelector((state) => state.orders);
  const { isCartLoading, cart } = useTypedSelector((state) => state.cart);
  const { activeTestKitLoading, activeTestKit } = useTypedSelector(
    (state) => state.testKits
  );
  const { isLoadingTestPackages, items } = useTypedSelector(
    (state) => state.testPackages
  );
  const activeTestPackage = items.find(
    (testPackage) => testPackage.id === activeTestKit.testPackageId
  );
  const { activePromoCode } = useTypedSelector((state) => state.promoCodes);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const currency = getCurrencySymbol(currentMarket.currency);
  const { t } = useTranslation();
  const isKitPurchaseOrder = Boolean(hash);

  React.useEffect(() => {
    if (!isKitPurchaseOrder) {
      analyticsService.createGAEvent("begin_checkout", {
        items: cart.map((cartItem) => ({
          item_id: cartItem.testPackageId,
          item_name: cartItem.testPackageName,
          price: cartItem.price / 100,
          quantity: cartItem.quantity,
        })),
      });
    }
  }, [isKitPurchaseOrder, cart]);

  if (isCartLoading || activeTestKitLoading || isLoadingTestPackages) {
    return (
      <div className={styles.details}>
        <ResizableSpinner />
      </div>
    );
  }

  if (isKitPurchaseOrder) {
    return (
      <div className={styles.details}>
        <div className={styles.details__tests}>
          <div className={styles.details__test}>
            <div className={styles.details__testImageHolder}>
              <img
                className={styles.details__testImage}
                src={activeTestPackage?.photos[0]}
                alt="Test"
              />
            </div>
            <div className={styles.details__testContent}>
              <div className={styles.details__testName}>
                {activeTestPackage?.name}
              </div>
              <div className={styles.details__testQuantity}>
                <div className={styles.details__testQuantity__text}>
                  {t("Header.CartPopup.Quantity")}
                </div>
                <div className={styles.details__testQuantity__value}>
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.details__delivery}>
          <div className={styles.details__delivery__title}>
            {t("Cart.Delivery")}
          </div>
          <div className={styles.details__delivery__value}>
            {t("Cart.Free")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.details}>
      <div className={styles.details__tests}>
        {cart.map((test: CartItemType) => {
          return (
            <div className={styles.details__test} key={test.testPackageId}>
              <div className={styles.details__testImageHolder}>
                <img
                  className={styles.details__testImage}
                  src={test.preview}
                  alt="Test"
                />
              </div>
              <div className={styles.details__testContent}>
                <div className={styles.details__testName}>
                  {test.testPackageName}
                </div>
                <div className={styles.details__testQuantity}>
                  <div className={styles.details__testQuantity__text}>
                    {t("Header.CartPopup.Quantity")}&nbsp;
                  </div>
                  <div className={styles.details__testQuantity__value}>
                    {test.quantity}
                  </div>
                </div>
                <div className={styles.details__testPrices}>
                  <div className={styles.details__testPrices__price}>
                    {test.price / 100}
                    <span className={styles.euro}>{currency}</span>
                  </div>
                  {test.paymentType !== PaymentType.OneTime && (
                    <div
                      className={
                        styles.details__testPrices__priceBeforeDiscount
                      }
                    >
                      {test.prices.OneTime.price / 100}
                      <span className={styles.euro}>{currency}</span>
                    </div>
                  )}
                </div>
                {test.paymentType !== PaymentType.OneTime && (
                  <div className={styles.details__testDiscount}>
                    <span className={styles.details__testDiscount_bold}>
                      {t(`Cart.CartItem.${test.paymentType}`)}
                    </span>
                    <span>{t("Cart.CartItem.Save")}</span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.details__delivery}>
        <div className={styles.details__delivery__title}>
          {t("Cart.Delivery")}
        </div>
        <div className={styles.details__delivery__value}>{t("Cart.Free")}</div>
      </div>
      <div className={styles.details__totalPrices}>
        <div className={styles.details__totalPrices__title}>
          {t("Cart.Total")}
        </div>
        <div className={styles.details__totalPrices__prices}>
          {(cart.some(
            (test: CartItemType) => test.paymentType !== PaymentType.OneTime
          ) ||
            activePromoCode) && (
            <div className={styles.details__totalPrices__beforeDiscount}>
              {getPrice(PriceView.default, activePromoCode, cart)}
              <span className={styles.euro}>{currency}</span>
            </div>
          )}
          <div className={styles.details__totalPrices__price}>
            {getPrice(PriceView.withDiscount, activePromoCode, cart)}
            <span className={styles.euro}>{currency}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestDetails;
