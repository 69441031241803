import httpService from "services/httpService";
import { b2bDTO } from "types";

const sendBusinessRequest = (requestData: b2bDTO) => {
  return async (): Promise<void> => {
    try {
      await httpService.post("/b2b/", {
        body: JSON.stringify(requestData),
      });
    } catch (e: any) {
      console.error("SENDING REQUEST ERROR: ", e);
    }
  };
};

export { sendBusinessRequest };
