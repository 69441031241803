import { createSlice } from "@reduxjs/toolkit";

import { getMarkets } from "store/thunks";
import { Market } from "types";

interface MarketsState {
  marketsLoading: boolean;
  markets: Market[];
  marketsError: string | undefined;
  currentMarket: Market;
  stayOnIncorrectMarket: boolean;
}

const initialState: MarketsState = {
  marketsLoading: false,
  markets: [],
  marketsError: "",
  currentMarket: {} as Market,
  stayOnIncorrectMarket: false,
};

const marketsSlice = createSlice({
  name: "marketsReducer",
  initialState,
  reducers: {
    setCurrentMarket: (state, action) => {
      state.currentMarket = action.payload;
    },
    setStayOnIncorrectMarket: (state, action) => {
      state.stayOnIncorrectMarket = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarkets.pending, (state) => {
        state.marketsLoading = true;
      })
      .addCase(getMarkets.fulfilled, (state, action) => {
        state.marketsLoading = false;
        state.markets = action.payload;
      })
      .addCase(getMarkets.rejected, (state, action) => {
        state.marketsLoading = false;
        state.marketsError = action.payload;
      });
  },
});

export default marketsSlice.reducer;

export const { setCurrentMarket, setStayOnIncorrectMarket } =
  marketsSlice.actions;
