import * as React from "react";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

import styles from "./Product.module.scss";

import { useTypedSelector, useAppDispatch } from "hooks";
import Head from "components/Product/Head";
import Benefits from "components/Product/Benefits";
import Response from "components/Product/Response";
import Instruction from "components/Product/Instruction";
import HowItWorks from "components/MainPage/HowItWorks";
import SingleParthner from "components/Product/SingleParthner";
import FAQ from "components/Product/FAQ";
import Partners from "components/MainPage/Partners";
import ResizableSpinner from "components/ResizableSpinner";
import Error from "components/Error";
import { CollectionMethod } from "types";
import { fetchTestPackages } from "store/actions";
import { LanguageContext } from "context";

const Product = (): JSX.Element => {
  const { productId }: { productId: string } = useParams();
  const { isLoadingTestPackages, items, fetchTestPackagesError } =
    useTypedSelector((state) => state.testPackages);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const testPackage = items.find((test) => test.id === productId);

  const dispatch = useAppDispatch();
  const { currentLanguage } = React.useContext(LanguageContext);

  React.useEffect(() => {
    if (
      items.length === 0 &&
      !fetchTestPackagesError &&
      Object.keys(currentMarket).length !== 0
    ) {
      dispatch(fetchTestPackages(currentLanguage, currentMarket.id));
    }
  }, [
    items.length,
    fetchTestPackagesError,
    dispatch,
    currentLanguage,
    currentMarket,
  ]);

  if (isLoadingTestPackages) {
    return (
      <div className={styles.spinnerContainer}>
        <ResizableSpinner />
      </div>
    );
  }

  if (!testPackage || fetchTestPackagesError) {
    return <Error />;
  }

  return (
    <div className={styles.product}>
      <Head testPackage={testPackage} />
      <Benefits collectionMethod={testPackage.collectionMethod} />
      <Response collectionMethod={testPackage.collectionMethod} />
      {testPackage.collectionMethod === CollectionMethod.FingerPrickBlood && (
        <HowItWorks />
      )}
      <Instruction collectionMethod={testPackage.collectionMethod} />
      {testPackage.collectionMethod === CollectionMethod.FingerPrickBlood && (
        <Partners />
      )}
      {(testPackage.collectionMethod === CollectionMethod.SalivaSample ||
        testPackage.collectionMethod === CollectionMethod.UrineSample) && (
        <SingleParthner parthner="diagnolita" />
      )}
      <FAQ />
    </div>
  );
};

export default withTranslation()(Product);
