import firebase from "firebaseApp";
import { TestKit } from "types";

class TestKitsService {
  testKits: firebase.firestore.Query<any>;

  constructor() {
    this.testKits = firebase.firestore().collection("TestKits");
  }

  async fetchTestKitByHash(hash: string): Promise<TestKit> {
    const snapshot = await this.testKits.where("hash", "==", hash).get();
    const result = snapshot.docs.map((doc) => doc.data());

    if (result.length === 0) {
      throw new Error("There is no such collection or collection is empty");
    }

    return result[0];
  }

  async fetchTestKits(): Promise<Array<TestKit>> {
    const snapshot = await this.testKits.get();
    const result = snapshot.docs.map((doc) => doc.data());

    return result;
  }
}

export default new TestKitsService();
