import * as React from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./RequestPopup.module.scss";

import { useAppDispatch } from "hooks";
import { sendBusinessRequest } from "store/actions";
import { revolabLogoWithText } from "assets/image";
import { ForBusinessSchema } from "validation";

type Props = {
  isDemoRequest: boolean;
  close: () => void;
  showSuccessPopup: () => void;
};

const RequestPopup = ({
  isDemoRequest,
  close,
  showSuccessPopup,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.background}></div>
      <div className={styles.fakeHeader}>
        <Link to="/">
          <img
            className={styles.fakeHeader__image}
            src={revolabLogoWithText}
            alt="Logo"
          />
        </Link>
        <div
          className={styles.closeIcon_inHeader}
          onClick={() => close()}
        ></div>
      </div>
      <Formik
        initialValues={{
          fullName: "",
          company: "",
          email: "",
          phone: "",
          reason: "",
        }}
        onSubmit={(values) => {
          dispatch(sendBusinessRequest(values));
          close();
          showSuccessPopup();
        }}
        validationSchema={ForBusinessSchema}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => {
          return (
            <div className={styles.popup}>
              <div className={styles.popup__head}>
                <div className={styles.popup__title}>
                  {t(
                    isDemoRequest
                      ? "ForBusiness.Popup.DemoTitle"
                      : "ForBusiness.Popup.TestsTitle"
                  )}
                </div>
                <div className={styles.closeIcon} onClick={() => close()}></div>
              </div>
              <div className={styles.content}>
                <div className={styles.content__question}>
                  <div className={styles.content__questionHead}>
                    <div className={styles.content__title}>
                      {t("ForBusiness.Popup.Question1")}
                    </div>
                    {errors.fullName && touched.fullName && (
                      <div className={styles.content__error}>
                        {errors.fullName}
                      </div>
                    )}
                  </div>
                  <input
                    className={
                      errors.fullName && touched.fullName
                        ? `${styles.content__input} ${styles.content__input_hasError}`
                        : styles.content__input
                    }
                    value={values.fullName}
                    autoComplete="name"
                    onChange={handleChange("fullName")}
                  />
                </div>
                <div className={styles.content__question}>
                  <div className={styles.content__questionHead}>
                    <div className={styles.content__title}>
                      {t("ForBusiness.Popup.Question2")}
                    </div>
                    {errors.company && touched.company && (
                      <div className={styles.content__error}>
                        {errors.company}
                      </div>
                    )}
                  </div>
                  <input
                    className={
                      errors.company && touched.company
                        ? `${styles.content__input} ${styles.content__input_hasError}`
                        : styles.content__input
                    }
                    value={values.company}
                    autoComplete="organization"
                    onChange={handleChange("company")}
                  />
                </div>
                <div className={styles.content__question}>
                  <div className={styles.content__questionHead}>
                    <div className={styles.content__title}>
                      {t("ForBusiness.Popup.Question3")}
                    </div>
                    {errors.email && touched.email && (
                      <div className={styles.content__error}>
                        {errors.email}
                      </div>
                    )}
                  </div>
                  <input
                    className={
                      errors.email && touched.email
                        ? `${styles.content__input} ${styles.content__input_hasError}`
                        : styles.content__input
                    }
                    value={values.email}
                    autoComplete="email"
                    onChange={handleChange("email")}
                  />
                </div>
                <div className={styles.content__question}>
                  <div className={styles.content__questionHead}>
                    <div className={styles.content__title}>
                      {t("ForBusiness.Popup.Question4")}
                    </div>
                    {errors.phone && touched.phone && (
                      <div className={styles.content__error}>
                        {errors.phone}
                      </div>
                    )}
                  </div>
                  <input
                    className={
                      errors.phone && touched.phone
                        ? `${styles.content__input} ${styles.content__input_hasError}`
                        : styles.content__input
                    }
                    value={values.phone}
                    autoComplete="tel"
                    onChange={handleChange("phone")}
                  />
                </div>
                <div className={styles.content__question}>
                  <div className={styles.content__questionHead}>
                    <div className={styles.content__title}>
                      {t("ForBusiness.Popup.Question5")}
                    </div>
                    {errors.reason && touched.reason && (
                      <div className={styles.content__error}>
                        {errors.reason}
                      </div>
                    )}
                  </div>
                  <textarea
                    className={
                      errors.reason && touched.reason
                        ? `${styles.content__textarea} ${styles.content__textarea_hasError}`
                        : styles.content__textarea
                    }
                    value={values.reason}
                    onChange={handleChange("reason")}
                  />
                </div>
                <button
                  type="button"
                  className={styles.content__button}
                  onClick={() => handleSubmit()}
                >
                  {t("ForBusiness.Popup.Button")}
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default RequestPopup;
