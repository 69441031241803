import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./TroubleDocs.module.scss";

import { LanguageContext } from "context";
import { troubleDocsPagesList } from "components/TroubleDocs/troubleDocsList";

const TroubleDocs = (): JSX.Element => {
  const { currentLanguage } = React.useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <div className={styles.docs}>
      <div className={styles.docs__title}>{t("Docs.Title")}</div>
      <div className={styles.docs__content}>
        {troubleDocsPagesList.map(({ id, nameT, link }) => {
          return (
            <div className={styles.docs__document} key={id}>
              <div className={styles.docs__documentName}>
                {nameT[currentLanguage]}
              </div>
              <Link className={styles.docs__documentLink} to={link}>
                {t("Docs.View")}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TroubleDocs;
