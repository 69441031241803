import { Currency } from "types";

const currencySymbol = {
  [Currency.EUR]: "€",
  [Currency.USD]: "$",
};

const getCurrencySymbol = (currency: Currency): string => {
  return currencySymbol[currency];
};

export { getCurrencySymbol };
