import * as React from "react";

import styles from "./Consent.module.scss";

import Checkbox from "components/Utilities/Checkbox";

import { addLinksToText } from "helpers";
import { LanguageContext } from "context";
import { useTypedSelector } from "hooks";

type Props = { id: string; checked: boolean; setChecked: () => void };

const CustomConsent = ({ id, checked, setChecked }: Props): JSX.Element => {
  const { consents } = useTypedSelector((state) => state.consents);
  const { currentLanguage } = React.useContext(LanguageContext);
  const consentText =
    consents.length > 0
      ? consents.filter((consent) => consent.id === id)[0]?.textT[
          currentLanguage
        ]
      : "";

  return (
    <div className={styles.consent}>
      <Checkbox id={id} checked={checked} setChecked={setChecked} />
      <label className={styles.consent__text} htmlFor={id}>
        {addLinksToText(consentText, styles.consent__link)}
      </label>
    </div>
  );
};

export default CustomConsent;
