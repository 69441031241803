import * as React from "react";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import styles from "./OrderInfo.module.scss";

import { useTypedSelector, useAppDispatch } from "hooks";
import ResizableSpinner from "components/ResizableSpinner";
import {
  backArrowBlue,
  Visa,
  Mastercard,
  AmericanExpress,
  UnionPay,
  creditCard,
  Gjensidige,
  Swedbank,
  SEB,
  Luminor,
  Citadele,
  SiauliuBankas,
  MedicinosBankas,
  CompensaVienna,
} from "assets/image";
import {
  InsuranceCompany,
  OpayPaymentChannel,
  OrderStatus,
  PaymentStatus,
  PaymentSystem,
} from "types";
import { fetchOrders, getOrderPaymentDetails } from "store/actions";
import { buildDeliveryAddress, getCurrencySymbol } from "helpers";
import config from "config";

const brandImages = {
  amex: AmericanExpress,
  diners: creditCard,
  discover: creditCard,
  jcb: creditCard,
  mastercard: Mastercard,
  unionpay: UnionPay,
  visa: Visa,
  unknown: creditCard,
};

const banksData = {
  banklink_swedbank: { image: Swedbank, name: "SwedBank" },
  banklink_seb: { image: SEB, name: "SEB" },
  banklink_dnb: { image: Luminor, name: "Luminor" },
  banklink_citadele: { image: Citadele, name: "Citadele" },
  banklink_sb: { image: SiauliuBankas, name: "Šiaulių Bankas" },
  banklink_medbank: { image: MedicinosBankas, name: "Medicinos Bankas" },
  banktransfer: { image: "", name: "Bank transfer" },
};

const OrderInfo = (): JSX.Element => {
  const { orderId }: { orderId: string } = useParams();
  const { orders, ordersLoading, paymentDetails, paymentDetailsLoading } =
    useTypedSelector((state) => state.orders);
  const { items } = useTypedSelector((state) => state.testPackages);
  const orderInfo = orders.find((order) => order.id === orderId);
  const omnivaTrackingCode =
    orderInfo?.parcelInformation?.fromRevolabToUser?.barcode;
  const orderDate = new Date(orderInfo ? orderInfo.orderDate : "");
  const paymentChannel =
    orderInfo?.paymentInformation.paymentChannel || "banktransfer";
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (orders.length === 0) {
      dispatch(fetchOrders());
    }
    dispatch(getOrderPaymentDetails(orderId));
  }, [orders.length, orderId, dispatch]);

  return (
    <div className={styles.orderInfo}>
      <div className={styles.orderInfo__title}>
        {t("Profile.OrderInfo.Title")}
      </div>
      <div className={styles.orderInfo__content}>
        {ordersLoading || paymentDetailsLoading ? (
          <ResizableSpinner />
        ) : (
          <div className={styles.info}>
            <div className={styles.info__backBlock}>
              <Link
                className={styles.info__backLink}
                to={"/user/profile/orders"}
              >
                <img
                  className={styles.info__backImage}
                  src={backArrowBlue}
                  alt="Arrow"
                />
                {t("Profile.OrderInfo.Back")}
              </Link>
            </div>
            {orderInfo ? (
              <>
                {orderInfo?.status !== OrderStatus.completed ? (
                  <div className={styles.info__resultsBlock}>
                    <div className={styles.info__resultsTitle_notCompleted}>
                      {t("Profile.OrderInfo.ThankYou")}
                    </div>
                  </div>
                ) : (
                  <div className={styles.info__resultsBlock_big}>
                    <div className={styles.info__resultsTitle_completed}>
                      {t("Profile.OrderInfo.OderFulfilled")}
                    </div>
                    <Link
                      className={styles.info__resultsLink}
                      to={`/dashboard`}
                    >
                      {t("Profile.OrderInfo.ViewResults")}
                    </Link>
                  </div>
                )}
                <div className={styles.info__title}>
                  {t("Profile.OrderInfo.DetailedOrderInfo")}
                </div>
                <div className={styles.info__infoBlock}>
                  <div className={styles.info__infoTitle}>
                    {t("Profile.OrderInfo.Status")}
                  </div>
                  <div
                    className={
                      orderInfo?.status === OrderStatus.completed
                        ? styles.info__infoCircle_green
                        : styles.info__infoCircle_yellow
                    }
                  ></div>
                  {orderInfo?.status === OrderStatus.completed ? (
                    <div className={styles.info__infoText_green}>
                      {t("Profile.OrderInfo.Completed")}
                    </div>
                  ) : (
                    <div className={styles.info__infoText_yellow}>
                      {t("Profile.OrderInfo.InProgress")}
                    </div>
                  )}
                </div>
                {omnivaTrackingCode && (
                  <div className={styles.info__infoBlock}>
                    <div className={styles.info__infoTitle}>
                      {t("Profile.OrderInfo.OmnivaTrackingCode")}
                    </div>
                    <div className={styles.info__infoText}>
                      <a
                        href={`${config.omnivaParcelTrackingUrl}${omnivaTrackingCode}`}
                        className={styles.info__link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {omnivaTrackingCode}
                      </a>
                    </div>
                  </div>
                )}
                <div className={styles.info__infoBlock}>
                  <div className={styles.info__infoTitle}>
                    {t("Profile.OrderInfo.OrderedTest")}
                  </div>
                  <div className={styles.info__infoText}>
                    {orderInfo?.testPackages
                      .map((testPackage) => {
                        const translatedPackage = items.find(
                          (item) => item.id === testPackage.testPackageId
                        );
                        return translatedPackage
                          ? translatedPackage.nameT
                          : testPackage.testPackageName;
                      })
                      .join(", ")}
                  </div>
                </div>
                <div className={styles.info__infoBlock}>
                  <div className={styles.info__infoTitle}>
                    {t("Profile.OrderInfo.OrderDate")}
                  </div>
                  <div className={styles.info__infoText}>
                    {dayjs(orderDate).format(`YYYY.MM.DD`)}
                  </div>
                </div>
                {orderInfo?.paymentSystem !== PaymentSystem.KitPurchase && (
                  <div className={styles.info__infoBlock}>
                    <div className={styles.info__infoTitle}>
                      {t("Profile.OrderInfo.TotalSum")}
                    </div>
                    <div className={styles.info__infoText}>
                      {orderInfo ? orderInfo?.totalCost / 100 : ""}
                      <span className={styles.euro}>
                        {getCurrencySymbol(paymentDetails.locale?.currency)}
                      </span>
                    </div>
                  </div>
                )}
                <div className={styles.info__infoBlock}>
                  <div className={styles.info__infoTitle}>
                    {t("Profile.OrderInfo.PaymentMethod")}
                  </div>
                  <div className={styles.info__infoText}>
                    {orderInfo.paymentSystem === PaymentSystem.Opay && (
                      <div className={styles.info__bankBlock}>
                        <div className={styles.info__bankName}>
                          {banksData[paymentChannel].name}
                        </div>
                        {paymentChannel !== OpayPaymentChannel.banktransfer && (
                          <img
                            className={styles.info__bankImage}
                            src={banksData[paymentChannel].image}
                            alt={banksData[paymentChannel].name}
                          />
                        )}
                      </div>
                    )}
                    {orderInfo.paymentSystem === PaymentSystem.Stripe && (
                      <div className={styles.info__cardBlock}>
                        <div className={styles.info__circles}>
                          <div className={styles.info__circlesBlock}>
                            &#9679; &#9679; &#9679; &#9679;
                          </div>
                          <div className={styles.info__circlesBlock}>
                            &#9679; &#9679; &#9679; &#9679;
                          </div>
                          <div className={styles.info__circlesBlock}>
                            &#9679; &#9679; &#9679; &#9679;
                          </div>
                        </div>
                        {paymentDetails.card?.last4 ? (
                          <div className={styles.info__cardNumber}>
                            {paymentDetails.card?.last4}
                          </div>
                        ) : (
                          <div className={styles.info__circlesBlock}>
                            &#9679; &#9679; &#9679; &#9679;
                          </div>
                        )}
                        <img
                          className={
                            brandImages[paymentDetails.card?.brand]
                              ? styles.info__brandImage
                              : styles.info__brandImage_nonexistent
                          }
                          src={brandImages[paymentDetails.card?.brand]}
                          alt="Brand"
                        />
                      </div>
                    )}
                    {orderInfo.paymentSystem === PaymentSystem.Insurance && (
                      <div className={styles.info__insuranceBlock}>
                        <img
                          className={styles.info__insuranceImage}
                          src={
                            orderInfo.paymentInformation.companyName ===
                            InsuranceCompany.GjensidigeInsurance
                              ? Gjensidige
                              : CompensaVienna
                          }
                          alt={orderInfo.paymentInformation.companyName}
                        />
                      </div>
                    )}
                    {orderInfo.paymentSystem === PaymentSystem.KitPurchase && (
                      <div className={styles.info__kitPurchaseBlock}>
                        {t("Profile.OrderInfo.KitPurchaseText")}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.info__infoBlock}>
                  <div className={styles.info__infoTitle}>
                    {t("Profile.OrderInfo.PaymentStatus")}
                  </div>
                  <div className={styles.info__infoText}>
                    {paymentDetails.status === PaymentStatus.failed
                      ? t("Profile.OrderInfo.PaymentFailed")
                      : t("Profile.OrderInfo.PaymentSuccess")}
                  </div>
                </div>
                {orderInfo?.paymentSystem !== PaymentSystem.KitPurchase && (
                  <div className={styles.info__infoBlock}>
                    <div className={styles.info__infoTitle}>
                      {t("Profile.OrderInfo.DeliveryAddress")}
                    </div>
                    <div className={styles.info__infoText}>
                      {buildDeliveryAddress(orderInfo?.deliveryInformation)}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className={styles.info__invalidText}>
                {t("Profile.OrderInfo.OrderDontExists")}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderInfo;
