import {
  OrderItemType,
  PaymentDetailsType,
  CartItemType,
  ErrorsObjectType,
  TestPackageType,
  ResultItemType,
  CalculatedResult,
  UserType,
  MultiLanguageTestPackageType,
  PromoCode,
  TestKit,
  Consent,
  Language,
} from "types";

export enum OrdersActionTypes {
  PENDING_ORDERS = "PENDING_ORDERS",
  FULFILLED_ORDERS = "FULFILLED_ORDERS",
  REJECTED_ORDERS = "REJECTED_ORDERS",
  PENDING_PAYMENT_DETAILS = "PENDING_PAYMENT_DETAILS",
  FULFILLED_PAYMENT_DETAILS = "FULFILLED_PAYMENT_DETAILS",
  REJECTED_PAYMENT_DETAILS = "REJECTED_PAYMENT_DETAILS",
  PENDING_CREATE_ORDER = "PENDING_CREATE_ORDER",
  FULFILLED_CREATE_ORDER = "FULFILLED_CREATE_ORDER",
  REJECTED_CREATE_ORDER = "REJECTED_CREATE_ORDER",
  CLEAR_CREATE_ORDER_STATE = "CLEAR_CREATE_ORDER_STATE",
  SET_OPAY_PAGE_HTML = "SET_OPAY_PAGE_HTML",
  STORE_KIT_PURCHASE_HASH = "STORE_KIT_PURCHASE_HASH",
  PENDING_CREATE_KIT_PURCHASE_ORDER = "PENDING_CREATE_KIT_PURCHASE_ORDER",
  FULFILLED_CREATE_KIT_PURCHASE_ORDER = "FULFILLED_CREATE_KIT_PURCHASE_ORDER",
  REJECTED_CREATE_KIT_PURCHASE_ORDER = "REJECTED_CREATE_KIT_PURCHASE_ORDER",
  CLEAR_HASH = "CLEAR_HASH",
  SET_NEED_CALENDLY_VERIFICATION = "SET_NEED_CALENDLY_VERIFICATION",
}

export type OrdersAction =
  | Pending
  | Fulfilled
  | Rejected
  | PendingCreateOrder
  | FulfilledCreateOrder
  | RejectedCreateOrder
  | ClearCreateOrderState
  | StoreKitPurchaseHash
  | PendingCreateKitPurchaseOrder
  | FulfilledCreateKitPurchaseOrder
  | RejectedCreateKitPurchaseOrder
  | ClearHash
  | SetNeedCalendlyVerification;

interface Pending {
  type: OrdersActionTypes.PENDING_ORDERS;
}

interface Fulfilled {
  type: OrdersActionTypes.FULFILLED_ORDERS;
  payload: OrderItemType[];
}

interface Rejected {
  type: OrdersActionTypes.REJECTED_ORDERS;
  payload: string;
}

interface ClearCreateOrderState {
  type: OrdersActionTypes.CLEAR_CREATE_ORDER_STATE;
}

interface StoreKitPurchaseHash {
  type: OrdersActionTypes.STORE_KIT_PURCHASE_HASH;
  payload: string;
}

interface PendingCreateKitPurchaseOrder {
  type: OrdersActionTypes.PENDING_CREATE_KIT_PURCHASE_ORDER;
}

interface FulfilledCreateKitPurchaseOrder {
  type: OrdersActionTypes.FULFILLED_CREATE_KIT_PURCHASE_ORDER;
  payload: {
    identityVerificationUrl: null | string;
    calendlyEventUrl: null | string;
  };
}

interface RejectedCreateKitPurchaseOrder {
  type: OrdersActionTypes.REJECTED_CREATE_KIT_PURCHASE_ORDER;
  payload: string;
}

interface ClearHash {
  type: OrdersActionTypes.CLEAR_HASH;
}

interface SetNeedCalendlyVerification {
  type: OrdersActionTypes.SET_NEED_CALENDLY_VERIFICATION;
  payload: boolean;
}

export type OrderPaymentDetailsAction =
  | PendingPaymentDetails
  | FulfilledPaymentDetails
  | RejectedPaymentDetails;

interface PendingPaymentDetails {
  type: OrdersActionTypes.PENDING_PAYMENT_DETAILS;
}

interface FulfilledPaymentDetails {
  type: OrdersActionTypes.FULFILLED_PAYMENT_DETAILS;
  payload: PaymentDetailsType;
}

interface RejectedPaymentDetails {
  type: OrdersActionTypes.REJECTED_PAYMENT_DETAILS;
  payload: string;
}

interface PendingCreateOrder {
  type: OrdersActionTypes.PENDING_CREATE_ORDER;
}

interface FulfilledCreateOrder {
  type: OrdersActionTypes.FULFILLED_CREATE_ORDER;
  payload: {
    identityVerificationUrl: null | string;
    calendlyEventUrl: null | string;
  };
}

interface RejectedCreateOrder {
  type: OrdersActionTypes.REJECTED_CREATE_ORDER;
  payload: ErrorsObjectType | Record<string, never>;
}

export enum CartActionTypes {
  PENDING_CART = "PENDING_CART",
  FULFILLED_CART = "FULFILLED_CART",
  REJECTED_CART = "REJECTED_CART",
  SET_IS_OPEN_CART_POPUP = "SET_IS_OPEN_CART_POPUP",
}

export type CartAction =
  | PendingCartAction
  | FulfilledCartAction
  | RejectedCartAction
  | SetIsOpenCartPopupAction;

interface PendingCartAction {
  type: CartActionTypes.PENDING_CART;
}

interface FulfilledCartAction {
  type: CartActionTypes.FULFILLED_CART;
  payload: CartItemType[];
}

interface RejectedCartAction {
  type: CartActionTypes.REJECTED_CART;
  payload: string;
}

interface SetIsOpenCartPopupAction {
  type: CartActionTypes.SET_IS_OPEN_CART_POPUP;
  payload: boolean;
}

export enum SignupActionTypes {
  SET_SIGNUP_ERRORS_OBJECT = "SET_SIGNUP_ERRORS_OBJECT",
  SET_SIGNUP_LOADING = "SET_SIGNUP_LOADING",
}

export type SignupAction = SetSignupErrorsObjectAction | SetSignupLoadingAction;

interface SetSignupErrorsObjectAction {
  type: SignupActionTypes.SET_SIGNUP_ERRORS_OBJECT;
  payload: ErrorsObjectType;
}

interface SetSignupLoadingAction {
  type: SignupActionTypes.SET_SIGNUP_LOADING;
  payload: boolean;
}

export enum TestPackagesActionTypes {
  PENDING_TEST_PACKAGES = "PENDING_TEST_PACKAGES",
  FULFILLED_TEST_PACKAGES = "FULFILLED_TEST_PACKAGES",
  REJECTED_TEST_PACKAGES = "REJECTED_TEST_PACKAGES",
  SET_IS_OPEN_COVID_TEST_PACKAGE = "SET_IS_OPEN_COVID_TEST_PACKAGE",
  SET_IS_OPEN_MANHEALTH_TEST_PACKAGE = "SET_IS_OPEN_MANHEALTH_TEST_PACKAGE",
  SET_IS_OPEN_WOMANHEALTH_TEST_PACKAGE = "SET_IS_OPEN_WOMANHEALTH_TEST_PACKAGE",
  SET_IS_OPEN_WELLNESS_TEST_PACKAGE = "SET_IS_OPEN_WELLNESS_TEST_PACKAGE",
  TRANSLATE_TEST_PACKAGES = "TRANSLATE_TEST_PACKAGES",
}

export type TestPackagesAction =
  | TranslateTestPackages
  | PendingFetchTestPackages
  | FulfilledFetchTestPackages
  | RejectedFetchTestPackages
  | setIsOpenCovidTestPackageAction
  | setIsOpenManHealthTestPackageAction
  | setIsOpenWomanHealthTestPackageAction
  | setIsOpenWellnessTestPackageAction;

interface TranslateTestPackages {
  type: TestPackagesActionTypes.TRANSLATE_TEST_PACKAGES;
  payload: Language;
}

interface PendingFetchTestPackages {
  type: TestPackagesActionTypes.PENDING_TEST_PACKAGES;
}

interface FulfilledFetchTestPackages {
  type: TestPackagesActionTypes.FULFILLED_TEST_PACKAGES;
  payload: MultiLanguageTestPackageType[];
}

interface RejectedFetchTestPackages {
  type: TestPackagesActionTypes.REJECTED_TEST_PACKAGES;
  payload: string;
}

interface setIsOpenCovidTestPackageAction {
  type: TestPackagesActionTypes.SET_IS_OPEN_COVID_TEST_PACKAGE;
  payload: boolean;
}

interface setIsOpenManHealthTestPackageAction {
  type: TestPackagesActionTypes.SET_IS_OPEN_MANHEALTH_TEST_PACKAGE;
  payload: boolean;
}

interface setIsOpenWomanHealthTestPackageAction {
  type: TestPackagesActionTypes.SET_IS_OPEN_WOMANHEALTH_TEST_PACKAGE;
  payload: boolean;
}

interface setIsOpenWellnessTestPackageAction {
  type: TestPackagesActionTypes.SET_IS_OPEN_WELLNESS_TEST_PACKAGE;
  payload: boolean;
}

export type TestPackagesState = {
  isLoadingTestPackages: boolean;
  items: TestPackageType[];
  multiLanguageItems: MultiLanguageTestPackageType[];
  fetchTestPackagesError: string;
  isOpenCovidTestPackage: boolean;
  isOpenManHealthTestPackage: boolean;
  isOpenWomanHealthTestPackage: boolean;
  isOpenWellnessTestPackage: boolean;
};

export enum ResultsActionTypes {
  PENDING_ALL_USER_RESULTS = "PENDING_ALL_USER_RESULTS",
  FULFILLED_ALL_USER_RESULTS = "FULFILLED_ALL_USER_RESULTS",
  REJECTED_ALL_USER_RESULTS = "REJECTED_ALL_USER_RESULTS",
  PENDING_RESULT = "PENDING_RESULT",
  FULFILLED_RESULT = "FULFILLED_RESULT",
  REJECTED_RESULT = "REJECTED_RESULT",
  PENDING_UPLOADED_RESULTS = "PENDING_UPLOADED_RESULTS",
  FULFILLED_UPLOADED_RESULTS = "FULFILLED_UPLOADED_RESULTS",
  REJECTED_UPLOADED_RESULTS = "REJECTED_UPLOADED_RESULTS",
}

export type ResultsAction =
  | PendingAllUserResults
  | FulfilledAllUserResults
  | RejectedAllUserResults
  | PendingResult
  | FulfilledResult
  | RejectedResult
  | PendingCustomResult
  | FulfilledCustomResult
  | RejectedCustomResult;

interface PendingAllUserResults {
  type: ResultsActionTypes.PENDING_ALL_USER_RESULTS;
}

interface FulfilledAllUserResults {
  type: ResultsActionTypes.FULFILLED_ALL_USER_RESULTS;
  payload: ResultItemType[];
}

interface RejectedAllUserResults {
  type: ResultsActionTypes.REJECTED_ALL_USER_RESULTS;
  payload: string;
}

interface PendingResult {
  type: ResultsActionTypes.PENDING_RESULT;
}

interface FulfilledResult {
  type: ResultsActionTypes.FULFILLED_RESULT;
  payload: CalculatedResult;
}

interface RejectedResult {
  type: ResultsActionTypes.REJECTED_RESULT;
  payload: string;
}

interface PendingCustomResult {
  type: ResultsActionTypes.PENDING_UPLOADED_RESULTS;
}

interface FulfilledCustomResult {
  type: ResultsActionTypes.FULFILLED_UPLOADED_RESULTS;
  payload: ResultItemType[];
}

interface RejectedCustomResult {
  type: ResultsActionTypes.REJECTED_UPLOADED_RESULTS;
  payload: string;
}

export enum AuthActionTypes {
  LOGIN = "LOGIN",
  SET_IS_AUTHORIZED = "SET_IS_AUTHORIZED",
  SET_AUTH_ERRORS_OBJECT = "SET_AUTH_ERRORS_OBJECT",
  SET_AUTH_LOADING = "SET_AUTH_LOADING",
}

export type AuthAction =
  | SetAuthorizedAction
  | SetAuthErrorsObjectAction
  | SetAuthLoadingAction;

interface SetAuthorizedAction {
  type: AuthActionTypes.SET_IS_AUTHORIZED;
  payload: boolean;
}

interface SetAuthLoadingAction {
  type: AuthActionTypes.SET_AUTH_LOADING;
  payload: boolean;
}

interface SetAuthErrorsObjectAction {
  type: AuthActionTypes.SET_AUTH_ERRORS_OBJECT;
  payload: ErrorsObjectType;
}

export enum UserActionTypes {
  SET_USER = "SET_USER",
  PENDING_USER = "PENDING_USER",
  FULFILLED_USER = "FULFILLED_USER",
  SET_IS_OPEN_USER_POPUP = "SET_IS_OPEN_USER_POPUP",
  REJECTED_USER = "REJECTED_USER",
  CLEAR_USER_STATE = "CLEAR_USER_STATE",
}

export type UserAction =
  | PendingUserAction
  | FulfilledUserAction
  | SetIsOpenUserPopupAction
  | RejectedUserAction
  | ClearUserStateAction;

interface PendingUserAction {
  type: UserActionTypes.PENDING_USER;
}

interface FulfilledUserAction {
  type: UserActionTypes.FULFILLED_USER;
  payload: UserType;
}

interface SetIsOpenUserPopupAction {
  type: UserActionTypes.SET_IS_OPEN_USER_POPUP;
  payload: boolean;
}

interface RejectedUserAction {
  type: UserActionTypes.REJECTED_USER;
  payload: ErrorsObjectType | Record<string, never>;
}

interface ClearUserStateAction {
  type: UserActionTypes.CLEAR_USER_STATE;
}

export type PromoCodesState = {
  isLoadingPromoCodes: boolean;
  promoCodes: PromoCode[];
  getPromoCodesError: string;
  activePromoCode: PromoCode | null;
};

export enum PromoCodesActionTypes {
  PENDING_PROMO_CODES = "PENDING_PROMO_CODES",
  FULFILLED_PROMO_CODES = "FULFILLED_PROMO_CODES",
  REJECTED_PROMO_CODES = "REJECTED_PROMO_CODES",
  SET_ACTIVE_PROMO_CODE = "SET_ACTIVE_PROMO_CODE",
}

export type PromoCodesAction =
  | PendingPromoCodes
  | FulfilledPromoCodes
  | RejectedPromoCodes
  | SetACtivePromoCode;

interface PendingPromoCodes {
  type: PromoCodesActionTypes.PENDING_PROMO_CODES;
}

interface FulfilledPromoCodes {
  type: PromoCodesActionTypes.FULFILLED_PROMO_CODES;
  payload: PromoCode[];
}

interface RejectedPromoCodes {
  type: PromoCodesActionTypes.REJECTED_PROMO_CODES;
  payload: string;
}

interface SetACtivePromoCode {
  type: PromoCodesActionTypes.SET_ACTIVE_PROMO_CODE;
  payload: PromoCode;
}

export enum TestKitsActionTypes {
  PENDING_ACTIVE_TEST_KITS = "PENDING_ACTIVE_TEST_KITS",
  FULFILLED_ACTIVE_TEST_KITS = "FULFILLED_ACTIVE_TEST_KITS",
  REJECTED_ACTIVE_TEST_KITS = "REJECTED_ACTIVE_TEST_KITS",
  CLEAR_TEST_KITS_STATE = "CLEAR_TEST_KITS_STATE",
}

export type TestKitsAction =
  | PendingActiveTestKits
  | FulfilledActiveTestKits
  | RejectedActiveTestKits
  | ClearTestKitsState;

interface PendingActiveTestKits {
  type: TestKitsActionTypes.PENDING_ACTIVE_TEST_KITS;
}

interface FulfilledActiveTestKits {
  type: TestKitsActionTypes.FULFILLED_ACTIVE_TEST_KITS;
  payload: TestKit;
}

interface RejectedActiveTestKits {
  type: TestKitsActionTypes.REJECTED_ACTIVE_TEST_KITS;
  payload: string;
}

interface ClearTestKitsState {
  type: TestKitsActionTypes.CLEAR_TEST_KITS_STATE;
}

export enum ConsentsActionTypes {
  PENDING_CONSENTS = "PENDING_CONSENTS",
  FULFILLED_CONSENTS = "FULFILLED_CONSENTS",
  REJECTED_CONSENTS = "REJECTED_CONSENTS",
}

export type ConsentsAction =
  | PendingConsents
  | FulfilledConsents
  | RejectedConsents;

interface PendingConsents {
  type: ConsentsActionTypes.PENDING_CONSENTS;
}

interface FulfilledConsents {
  type: ConsentsActionTypes.FULFILLED_CONSENTS;
  payload: Consent[];
}

interface RejectedConsents {
  type: ConsentsActionTypes.REJECTED_CONSENTS;
  payload: string;
}
