import React from "react";
import { useTranslation } from "react-i18next";

import style from "./TestResultBoolean.module.scss";

import { testOk, testNotOk } from "assets/image";
import { TestResultItemType } from "types";

type Props = {
  item: TestResultItemType;
};

export default function TestResultBoolean({ item }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <div className={style.header}>{item.testName}</div>
      {item.numericValue === null ? (
        <div className={`${style.pannel} ${style.pannel__testFailed}`}>
          {t("Result.Failed")}
        </div>
      ) : (
        <div
          className={`${style.pannel} ${
            item.booleanValue
              ? style.pannel__testPositive
              : style.pannel__testNegative
          }`}
        >
          <img
            src={item.booleanValue ? testNotOk : testOk}
            alt="test result"
            className={style.pannel__image}
          ></img>
          <span className={style.pannel__text}>
            {item.booleanValue ? t("Result.Positive") : t("Result.Negative")}
          </span>
          <span
            className={style.pannel__value}
          >{`${item.numericValue} ${item.dimension}`}</span>
        </div>
      )}
    </div>
  );
}
