import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./ShoppingCart.module.scss";

import CartItem from "components/ShoppingCart/CartItem";
import Spinner from "components/Spinner";
import NotificationPopup from "components/NotificationPopup";
import { useTypedSelector, useAction, useAppDispatch } from "hooks";
import {
  CartItemType,
  PaymentType,
  PriceView,
  PromoCodeOperation,
} from "types";
import { setCart } from "store/thunks";
import analyticsService from "services/analyticsService";
import { getPromoCodes, setActivePromoCode } from "store/actions";
import { discountPercent } from "assets/image";
import { getPrice } from "components/ShoppingCart/getPrice";
import { getCurrencySymbol } from "helpers";

const ShoppingCart = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { cart, isCartLoading } = useTypedSelector((state) => state.cart);
  const { items } = useTypedSelector((state) => state.testPackages);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const { isLoadingPromoCodes, promoCodes, activePromoCode } = useTypedSelector(
    (state) => state.promoCodes
  );
  const {
    setIsOpenCovidTestPackage,
    setIsOpenManHealthTestPackage,
    setIsOpenWomanHealthTestPackage,
    setIsOpenWellnessTestPackage,
  } = useAction();
  const currency = getCurrencySymbol(currentMarket.currency);
  const isCartEmpty = !isCartLoading && cart && cart.length === 0;
  const totalCartValue = cart.reduce((acc, test) => {
    return acc + test.price * test.quantity;
  }, 0);
  const [cartSettingError, setCartSettingError] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState("");
  const [promoCodeFieldTouched, setPromoCodeFieldTouched] =
    React.useState(false);
  const [promoCodeValid, setPromoCodeValid] = React.useState(false);
  const [promoCodeError, setPromoCodeError] = React.useState("");

  React.useEffect(() => {
    dispatch(getPromoCodes());
  }, [dispatch]);

  const deleteTest = (test: CartItemType): void => {
    analyticsService.createGAEvent("remove_from_cart", {
      items: [
        {
          item_id: test.testPackageId,
          item_name: test.testPackageName,
          price: test.price / 100,
        },
      ],
    });
    dispatch(
      setCart(
        cart.filter((item) => {
          return item.testPackageId !== test.testPackageId;
        })
      )
    );
    dispatch(setActivePromoCode(null));
  };

  const setTestType = (id: string, newType: PaymentType): void => {
    const changeIndex = cart.findIndex((item) => item.testPackageId === id);

    if (
      cart.some(
        (test) => test.testPackageId !== id && test.paymentType !== newType
      )
    ) {
      setCartSettingError(true);
    } else {
      const newCart = [...cart];

      newCart[changeIndex] = {
        ...newCart[changeIndex],
        quantity: 1,
        paymentType: newType,
      };

      dispatch(setCart(newCart));
      dispatch(setActivePromoCode(null));
    }
  };

  const setTestQuantity = (id: string, quantity: number): void => {
    const changeIndex = cart.findIndex((item) => item.testPackageId === id);
    const newCart = cart.slice();
    newCart[changeIndex] = { ...newCart[changeIndex], quantity: quantity };

    dispatch(setCart(newCart));
  };

  const validatePromoCode = (code: string): void => {
    const matchedPromoCode = promoCodes.find(
      (promoCode) => promoCode.name.toLowerCase() === code.toLowerCase()
    );

    if (cart.length === 0) {
      setPromoCodeValid(false);
      setPromoCodeError("PromoCodeEmptyCart");
    } else if (
      cart.some(
        (test: CartItemType) => test.paymentType !== PaymentType.OneTime
      )
    ) {
      setPromoCodeError("PromoCodeOneTimeOnly");
    } else if (!matchedPromoCode || !matchedPromoCode.enabled) {
      setPromoCodeError("PromoCodeInvalid");
    } else if (
      matchedPromoCode.operation === PromoCodeOperation.subtraction &&
      matchedPromoCode.valueOff >= totalCartValue
    ) {
      setPromoCodeError("PromoCodeCartLessThanDiscount");
    } else if (
      matchedPromoCode.exp &&
      matchedPromoCode.exp < new Date().getTime()
    ) {
      setPromoCodeError("PromoCodeExpired");
    } else {
      dispatch(setActivePromoCode(matchedPromoCode));

      setPromoCodeValid(true);
    }
  };

  const callFacebookEvent = (): void => {
    analyticsService.createFacebookEvent("custom", "AddToCart", {
      contents: cart.map((item) => ({
        id: item.testPackageId,
        name: item.testPackageName,
        quantity: item.quantity,
      })),
      value: (totalCartValue / 100).toFixed(2),
      currency: "EUR",
    });
  };

  const isAllCartItemsActive = cart
    .map(
      (cartItem) =>
        !!items.find((testPackage) => testPackage.id === cartItem.testPackageId)
    )
    .every((element) => element === true);

  return (
    <div className={styles.cart}>
      {cartSettingError && (
        <NotificationPopup
          text={t("Cart.NotificationText")}
          isSuccessful={false}
          onCartPage
          close={() => setCartSettingError(false)}
        />
      )}
      <div className={styles.cart__title}>{t("Cart.Title")}</div>
      <div className={styles.cart__content}>
        <div className={styles.cart__list}>
          {(isCartLoading || isLoadingPromoCodes) && <Spinner />}
          {isCartEmpty && !isLoadingPromoCodes && (
            <>
              <div className={styles.cart__emptyText}>
                {t("Cart.EmptyCart")}
              </div>
              <Link
                className={styles.cart__testsLink}
                to={{
                  pathname: "/",
                  hash: "#tyrimai",
                }}
                onClick={() => {
                  setIsOpenCovidTestPackage(true);
                  setIsOpenManHealthTestPackage(true);
                  setIsOpenWomanHealthTestPackage(true);
                  setIsOpenWellnessTestPackage(true);
                }}
              >
                {t("Cart.OrderResearch")}
              </Link>
            </>
          )}
          {cart.map((item: CartItemType) => {
            return (
              <CartItem
                item={item}
                setTestType={setTestType}
                setTestQuantity={setTestQuantity}
                deleteTest={deleteTest}
                key={item.testPackageId}
              />
            );
          })}
        </div>
        <div className={styles.cart__details}>
          <div className={styles.cart__priceBlock}>
            <div className={styles.cart__priceTitle}>{t("Cart.Total")}</div>
            <div className={styles.cart__prices}>
              {(cart.some(
                (test: CartItemType) => test.paymentType !== PaymentType.OneTime
              ) ||
                activePromoCode) && (
                <div className={styles.cart__priceBeforeDiscount}>
                  {getPrice(PriceView.default, activePromoCode, cart)}
                  <span className={styles.cart__priceBeforeDiscount_euro}>
                    {currency}
                  </span>
                </div>
              )}
              <div className={styles.cart__price}>
                {getPrice(PriceView.withDiscount, activePromoCode, cart)}
                <span className={styles.cart__price_euro}>{currency}</span>
              </div>
            </div>
          </div>
          <div className={styles.cart__deliveryBlock}>
            <div className={styles.cart__deliveryTitle}>
              {t("Cart.Delivery")}
            </div>
            <div className={styles.cart__deliveryPrice}>{t("Cart.Free")}</div>
          </div>
          <div className={styles.cart__promocodeBlock}>
            {activePromoCode ? (
              <div className={styles.cart__promocodeApplied}>
                <img
                  className={styles.cart__promocodeApplied__image}
                  src={discountPercent}
                  alt="Discount"
                />
                <div>
                  <div className={styles.cart__promocodeApplied__text}>
                    {t("Cart.AdjustedCode")}
                  </div>
                  <span className={styles.cart__promocodeApplied__boldText}>
                    {activePromoCode.name}
                  </span>{" "}
                  <span className={styles.cart__promocodeApplied__orangeText}>
                    {activePromoCode.operation === "percentage"
                      ? `(-${activePromoCode.valueOff}%)`
                      : `(-${activePromoCode.valueOff / 100} EUR)`}
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.cart__promocodeTitle}>
                  {t("Cart.HaveDiscount")}
                </div>
                <div className={styles.cart__promocodeHolder}>
                  <input
                    className={styles.cart__promocodeInput}
                    placeholder={t("Cart.Enter")}
                    value={promoCode}
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                      setPromoCodeFieldTouched(false);
                    }}
                  />
                  <div
                    className={
                      promoCodeFieldTouched && !promoCodeValid
                        ? `${styles.cart__promocodeButton} ${styles.cart__promocodeButton_disabled}`
                        : styles.cart__promocodeButton
                    }
                    onClick={() => {
                      validatePromoCode(promoCode);
                      setPromoCodeFieldTouched(true);
                    }}
                  >
                    {t("Cart.ButtonApply")}
                  </div>
                </div>
                {promoCodeFieldTouched && !promoCodeValid && (
                  <div className={styles.cart__promocodeError}>
                    {t(promoCodeError)}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.cart__buyBlock}>
            <Link
              className={
                isCartLoading || isCartEmpty || !isAllCartItemsActive
                  ? `${styles.cart__continueButton} ${styles.cart__continueButton_disabled}`
                  : styles.cart__continueButton
              }
              to={`/checkout`}
              onClick={(e) => {
                if (isCartLoading || isCartEmpty || !isAllCartItemsActive) {
                  e.preventDefault();
                } else {
                  callFacebookEvent();
                }
              }}
            >
              {t("Cart.ButtonContinue")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCart;
