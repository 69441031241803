import { createSlice } from "@reduxjs/toolkit";

import { CustomError } from "types";
import { uploadFiles } from "store/thunks";

interface UploadState {
  uploadFileLoading: boolean;
  fileUploaded: boolean;
  uploadFileLoadingError: string | undefined;
  isUploadFileEncrypted: boolean;
}

const initialState: UploadState = {
  uploadFileLoading: false,
  fileUploaded: false,
  uploadFileLoadingError: undefined,
  isUploadFileEncrypted: false,
};

const validateUploadError = (e: CustomError): string => {
  if (e.errors.message === "This result already exists") {
    return "UploadResultExists";
  } else if (e.errors.message === "recognizedTestResults object is empty") {
    return "UploadResultIsEmpty";
  }

  return "UploadUnknownError";
};

const uploadSlice = createSlice({
  name: "uploadReducer",
  initialState,
  reducers: {
    clearUploadState: (state) => {
      state.uploadFileLoading = false;
      state.fileUploaded = false;
      state.uploadFileLoadingError = undefined;
      state.isUploadFileEncrypted = false;
    },
    setIsUploadFileEncrypted: (state, action) => {
      state.isUploadFileEncrypted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFiles.pending, (state) => {
        state.uploadFileLoading = true;
      })
      .addCase(uploadFiles.fulfilled, (state) => {
        state.uploadFileLoading = false;
        state.fileUploaded = true;
      })
      .addCase(uploadFiles.rejected, (state, action) => {
        console.log(action.payload);
        state.uploadFileLoading = false;
        state.fileUploaded = false;
        if (action.payload) {
          state.uploadFileLoadingError = validateUploadError(action.payload);
        }
      });
  },
});

export default uploadSlice.reducer;

export const { clearUploadState, setIsUploadFileEncrypted } =
  uploadSlice.actions;
