import React from "react";

import { StagePhase } from "types/stage";

import style from "./Stage.module.scss";

type Props = {
  stage: StagePhase;
};

const Stage = ({ stage }: Props) => {
  return (
    <div className={style.stage}>
      <div
        className={`${style.stage__circle} ${
          style["stage__firstCircle_" + stage]
        } `}
      >
        <span
          className={`${style.stage__number} ${
            style["stage__firstNumber_" + stage]
          }`}
        >
          1
        </span>
      </div>
      <div
        className={`${style.stage__dash} ${style["stage__firstDash_" + stage]}`}
      />
      <div
        className={`${style.stage__circle} ${
          style["stage__secondCircle_" + stage]
        } `}
      >
        <span
          className={`${style.stage__number} ${
            style["stage__secondNumber_" + stage]
          }`}
        >
          2
        </span>
      </div>
      <div
        className={`${style.stage__dash} ${
          style["stage__secondDash_" + stage]
        }`}
      />
      <div
        className={`${style.stage__circle} ${
          style["stage__thirdCircle_" + stage]
        } `}
      >
        <span
          className={`${style.stage__number} ${
            style["stage__thirdNumber_" + stage]
          }`}
        >
          3
        </span>
      </div>
    </div>
  );
};

export default Stage;
