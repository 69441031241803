import * as Yup from "yup";

export const ForBusinessSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, "More than 3 characters")
    .max(50, "Less than 50 characters")
    .trim()
    .required("Required"),
  company: Yup.string()
    .min(3, "More than 3 characters")
    .max(50, "Less than 50 characters")
    .trim()
    .required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .trim()
    .lowercase()
    .required("Required"),
  phone: Yup.string().trim().required("Required"),
  reason: Yup.string()
    .min(5, "More than 5 characters")
    .max(1000, "Less than 1000 characters")
    .trim()
    .required("Required"),
});
