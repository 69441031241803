import { CustomError, ErrorsObjectType, ErrorType } from "types";

class ErrorsService {
  createValidationErrorsObject = (e: CustomError): ErrorsObjectType => {
    let result = {};
    if (e?.errors?.type === ErrorType.validation) {
      result = Object.fromEntries(
        e.errors.errors.map((item: { field: string; message: string }) => [
          item.field,
          item.message,
        ])
      );
    }
    return result;
  };
}

export default new ErrorsService();
