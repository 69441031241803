import * as Yup from "yup";

export const childOrderSchema = Yup.object().shape({
  childFirstName: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  childLastName: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  childYear: Yup.string().length(4, "Length4").required("Required"),
  childMonth: Yup.string()
    .min(1, "Min1")
    .max(2, "Max2")
    .test("in-range", "InvalidMonth", (value) =>
      value ? +value > 0 && +value < 13 : true
    )
    .required("Required"),
  childDay: Yup.string()
    .min(1, "Min1")
    .max(2, "Max2")
    .test("in-range", "InvalidDay", (value) =>
      value ? +value > 0 && +value < 32 : true
    )
    .required("Required"),
  childSex: Yup.string().required("Required"),
  childIdentificationCode: Yup.string()
    .matches(/^[0-9]{11}$/, "IdentificationCode")
    .required("Required"),
});
