import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Checkout.module.scss";

import { useAppDispatch, useTypedSelector } from "hooks";
import {
  clearCreateOrderState,
  clearHash,
  clearTestKitsState,
} from "store/actions";
import SuccessOrderPage from "components/Checkout/SuccessOrderPage";
import Order from "components/Checkout/Order";
import OrderDetails from "components/Checkout/OrderDetails";

const Checkout = (): JSX.Element => {
  const { orderCreated } = useTypedSelector((state) => state.orders);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    return () => {
      dispatch(clearCreateOrderState());
      dispatch(clearTestKitsState());
      dispatch(clearHash());
    };
  }, [dispatch]);

  if (orderCreated) {
    return (
      <div className={styles.checkout}>
        <SuccessOrderPage />
      </div>
    );
  }

  return (
    <div className={styles.checkout}>
      <div className={styles.checkout__title}>{t("Checkout.Ordering")}</div>
      <div className={styles.checkout__content}>
        <Order />
        <OrderDetails />
      </div>
    </div>
  );
};

export default Checkout;
