import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Promo.module.scss";

import {
  phoneImage,
  appStoreDownload,
  googlePlayDownload,
  info,
} from "assets/image";
import config from "config";
import { LanguageContext } from "context";

const Promo = (): JSX.Element => {
  const [hintEnabled, setHintEnabled] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const { currentLanguage } = React.useContext(LanguageContext);

  return (
    <div className={styles.promo}>
      <div className={styles.promo__textContent}>
        <div className={styles.promo__title}>
          <div className={styles.promo__title_orange}>
            {t("Main.Promo.TitleOrange")}
          </div>{" "}
          {t("Main.Promo.Title")}
        </div>
        <div className={styles.promo__description}>
          <div className={styles.promo__text}>
            {t("Main.Promo.Text1")}
            <div className={styles.promo__infoHolder}>
              <img
                className={styles.promo__info}
                src={info}
                alt="info"
                onMouseOver={() => setHintEnabled(true)}
                onFocus={() => setHintEnabled(true)}
                onMouseOut={() => setHintEnabled(false)}
                onBlur={() => setHintEnabled(false)}
              />
            </div>
          </div>
          <div className={styles.promo__text}>{t("Main.Promo.Text2")}</div>
        </div>
        <div className={styles.promo__downloadButtons}>
          <a
            className={styles.promo__link}
            href={config.mobileApplicationLinks.appStore}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.promo__appStore}
              src={appStoreDownload}
              alt="Apple app store download"
            />
          </a>
          <a
            className={styles.promo__link}
            href={config.mobileApplicationLinks.googlePlay}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.promo__googlePlay}
              src={googlePlayDownload}
              alt="Google play market download"
            />
          </a>
        </div>
        {hintEnabled && (
          <div
            className={`${styles.hint} ${styles[`hint__${currentLanguage}`]}`}
          >
            <div className={styles.hint__content}>{t("Main.Promo.Hint")}</div>
          </div>
        )}
      </div>
      <img className={styles.promo__image} src={phoneImage} alt="phone" />
    </div>
  );
};

export default Promo;
