import { Country, DeliveryInformation, Market, PaymentDTO } from "types";

export const getDeliveryInformation = (
  orderFormData: PaymentDTO,
  currentMarket: Market,
  requiresIdentificationCode: boolean
): DeliveryInformation => {
  if (currentMarket.country === Country.US) {
    return {
      address: `${orderFormData.addressLine1} ${orderFormData.addressLine2}`,
      city: orderFormData.city,
      postalCode: orderFormData.postalCode,
      state: orderFormData.state,
      mobileNumber: orderFormData.mobileNumber,
    };
  } else {
    return {
      name: orderFormData.name,
      county: orderFormData.county,
      address: orderFormData.address,
      mobileNumber: orderFormData.mobileNumber,
      postalCode: orderFormData.postalCode,
      identificationCode: requiresIdentificationCode
        ? orderFormData.identificationCode
        : undefined,
    };
  }
};
