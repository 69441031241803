import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

import config from "config";

firebase.initializeApp(config.firebaseConfig);
export const firebaseAnalytics = firebase.analytics();

export default firebase;
