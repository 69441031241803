import * as Yup from "yup";
import { mobileNumberValidator } from "validation";

export const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  email: Yup.string()
    .lowercase()
    .trim()
    .email("InvalidEmail")
    .required("Required"),
  year: Yup.string().length(4, "Length4").required("Required"),
  month: Yup.string()
    .min(1, "Min1")
    .max(2, "Max2")
    .test("in-range", "InvalidMonth", (value) =>
      value ? +value > 0 && +value < 13 : true
    )
    .required("Required"),
  day: Yup.string()
    .min(1, "Min1")
    .max(2, "Max2")
    .test("in-range", "InvalidDay", (value) =>
      value ? +value > 0 && +value < 32 : true
    )
    .required("Required"),
  sex: Yup.string().required("Required"),
  password: Yup.string()
    .min(6, "Min6")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  repeatPassword: Yup.string()
    .min(6, "Min6")
    .max(30, "Max30")
    .trim()
    .required("Required")
    .when("password", {
      is: (value: string) => value && value.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref("password"), null], "MatchPassword")
        .required("Required"),
    }),
  mobileNumber: Yup.string()
    .trim()
    .required("Required")
    .test("mobile phone number", "InvalidMobileNumber", mobileNumberValidator),
});
