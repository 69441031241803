import { createAsyncThunk } from "@reduxjs/toolkit";

import marketsService from "services/marketsService";
import { Market } from "types";

export const getMarkets = createAsyncThunk<
  Market[],
  void,
  { rejectValue: string }
>("markets/getMarkets", async (_, { rejectWithValue }) => {
  try {
    const markets = await marketsService.fetchMarkets();
    return markets;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
