import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Burnout.module.scss";

import { burnoutHead, burnoutInfoLt, burnoutInfoEn } from "assets/image";
import config from "config";
import { LanguageContext } from "context";
import { Language } from "types";

const Burnout = (): JSX.Element => {
  const { currentLanguage } = React.useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <div className={styles.burnout}>
      <div className={styles.burnout__title}>
        <img
          className={styles.burnout__titleImage}
          src={burnoutHead}
          alt="Head"
        />
        {t("Docs.Burnout.Title")}
      </div>
      <Link
        className={styles.burnout__button}
        to={`/survey/${config.burnoutAliasId}`}
      >
        {t("Docs.Burnout.Button")}
      </Link>
      <div className={styles.burnout__content}>
        <div className={styles.burnout__contentTitle}>
          {t("Docs.Burnout.Titles.Title1")}
        </div>
        <div className={styles.burnout__columns}>
          <div className={styles.burnout__column}>
            <div className={styles.burnout__text}>
              <span className={styles.burnout__text_blue}>
                {t("Docs.Burnout.Blocks.Block1.TextBlue")}
              </span>
              {t("Docs.Burnout.Blocks.Block1.Text")}
            </div>
            <div className={styles.burnout__text}>
              {t("Docs.Burnout.Blocks.Block2.Text1")}
              <span className={styles.burnout__text_blue}>
                {t("Docs.Burnout.Blocks.Block2.TextBlue")}
              </span>
              {t("Docs.Burnout.Blocks.Block2.Text2")}
            </div>
            <div className={styles.burnout__text}>
              <span className={styles.burnout__text_blue}>
                {t("Docs.Burnout.Blocks.Block3.TextBlue1")}
              </span>
              {t("Docs.Burnout.Blocks.Block3.Text1")}
              <span className={styles.burnout__text_blue}>
                {t("Docs.Burnout.Blocks.Block3.TextBlue2")}
              </span>
              {t("Docs.Burnout.Blocks.Block3.Text2")}
            </div>
            <div className={styles.burnout__text}>
              <span className={styles.burnout__text_blue}>
                {t("Docs.Burnout.Blocks.Block4.TextBlue")}
              </span>
              {t("Docs.Burnout.Blocks.Block4.Text")}
            </div>
            <div className={styles.burnout__text}>
              <span className={styles.burnout__text_blue}>
                {t("Docs.Burnout.Blocks.Block5.TextBlue")}
              </span>
              {t("Docs.Burnout.Blocks.Block5.Text")}
            </div>
            <div className={styles.burnout__text}>
              <span className={styles.burnout__text_blue}>
                {t("Docs.Burnout.Blocks.Block6.TextBlue")}
              </span>
              {t("Docs.Burnout.Blocks.Block6.Text")}
            </div>
            <div className={styles.burnout__text}>
              <span className={styles.burnout__text_blue}>
                {t("Docs.Burnout.Blocks.Block7.TextBlue")}
              </span>
              {t("Docs.Burnout.Blocks.Block7.Text")}
            </div>
          </div>
          <div className={styles.burnout__column}>
            <img
              className={styles.burnout__image}
              src={
                currentLanguage === Language.lt ? burnoutInfoLt : burnoutInfoEn
              }
              alt="Info"
            />
            <div className={styles.burnout__listTitle}>
              {t("Docs.Burnout.Titles.Title2")}
            </div>
            <ol className={styles.burnout__list}>
              <li className={styles.burnout__item}>
                {t("Docs.Burnout.Blocks.Block8.Text")}
              </li>
              <li className={styles.burnout__item}>
                {t("Docs.Burnout.Blocks.Block9.Text")}
                <a
                  className={styles.burnout__link}
                  href="https://biological-psychiatry.eu/wp-content/uploads/2014/06/2009_apzvalgos-Stankus.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://biological-psychiatry.eu/wp-content/uploads/2014/06/2009_apzvalgos-Stankus.pdf
                </a>
                {"   "}
                <a
                  className={styles.burnout__link}
                  href="http://biological-psychiatry.eu/wp-content/uploads/2014/06/2009_apzvalgos-Stankus.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  http://biological-psychiatry.eu/wp-content/uploads/2014/06/2009_apzvalgos-Stankus.pdf
                </a>
              </li>
              <li className={styles.burnout__item}>
                {t("Docs.Burnout.Blocks.Block10.Text")}
                <a
                  className={styles.burnout__link}
                  href="https://www.who.int/mental_health/evidence/burn-out/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.who.int/mental_health/evidence/burn-out/en/
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Burnout;
