import { createSlice } from "@reduxjs/toolkit";

import { getChildren, addChild, getChildResults } from "store/thunks";
import { Child } from "types";

interface ChildState {
  isChildLoading: boolean;
  children: Child[];
  childError: string | undefined;
  addedChild: Child;
  isChildResultsLoading: boolean;
  childrenResults: any;
  getChildResultsError: string | undefined;
}

const initialState: ChildState = {
  isChildLoading: false,
  children: [],
  childError: undefined,
  addedChild: {} as Child,
  isChildResultsLoading: false,
  childrenResults: {},
  getChildResultsError: undefined,
};

const childSlice = createSlice({
  name: "childReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChildren.pending, (state) => {
        state.isChildLoading = true;
      })
      .addCase(getChildren.fulfilled, (state, action) => {
        state.isChildLoading = false;
        state.children = action.payload;
      })
      .addCase(getChildren.rejected, (state, action) => {
        state.isChildLoading = false;
        state.childError = action.payload;
      })
      .addCase(addChild.pending, (state) => {
        state.isChildLoading = true;
      })
      .addCase(addChild.fulfilled, (state, action) => {
        state.isChildLoading = false;
        state.addedChild = action.payload;
      })
      .addCase(addChild.rejected, (state, action) => {
        state.isChildLoading = false;
        state.childError = action.payload;
      })
      .addCase(getChildResults.pending, (state) => {
        state.isChildResultsLoading = true;
      })
      .addCase(getChildResults.fulfilled, (state, action) => {
        state.isChildResultsLoading = false;
        state.childrenResults = {
          ...state.childrenResults,
          [action.payload.id]: action.payload.results,
        };
      })
      .addCase(getChildResults.rejected, (state, action) => {
        state.isChildResultsLoading = false;
        state.getChildResultsError = action.payload;
      });
  },
});

export default childSlice.reducer;
