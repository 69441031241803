import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import style from "./ResetPassword.module.scss";

import CustomInput from "components/CustomInput";
import Spinner from "components/Spinner";
import SuccessMessage from "components/SuccessMessage";
import { ResetPasswordSchema } from "validation";
import { useAction, useTypedSelector } from "hooks";

function ResetPasswordPage(): JSX.Element {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showMain, setShowMain] = useState(true);
  const { sendResetLink } = useAction();
  const { authLoading } = useTypedSelector((state) => state.auth);
  const { t } = useTranslation();

  const clickHandler = async (email: string) => {
    try {
      await sendResetLink(email);
      setShowMain(false);
      setSuccess(true);
    } catch (e: any) {
      setError(e.message);
    }
  };

  return (
    <div className={style.wrapper}>
      {authLoading && <Spinner />}
      {success && (
        <SuccessMessage
          text={t("ResetPassword.SuccessMessage")}
          link="/login"
          buttonClickHandler={() => {
            setSuccess(false);
            setShowMain(true);
          }}
        />
      )}
      {showMain && (
        <div className={style.container}>
          <span className={style.header}>
            {t("ResetPassword.RecoverPassword")}
          </span>
          <span className={style.headerText}>
            {t("ResetPassword.RecoverPasswordText")}
          </span>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values) => {
              clickHandler(values.email);
            }}
            validationSchema={ResetPasswordSchema}
          >
            {({ handleSubmit, handleChange, values, errors, touched }) => {
              return (
                <div
                  className={style.formWrapper}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                >
                  <CustomInput
                    text={t("ResetPassword.Email")}
                    value={values.email}
                    autoComplete="email"
                    error={errors.email || error}
                    touched={touched.email}
                    changeHandler={handleChange("email")}
                  />
                  <button
                    type="button"
                    className={style.button}
                    onClick={() => handleSubmit()}
                  >
                    {t("ResetPassword.ButtonConfirm")}
                  </button>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default ResetPasswordPage;
