import { createAsyncThunk } from "@reduxjs/toolkit";

import httpService from "services/httpService";
import { CustomError } from "types";

export const uploadFiles = createAsyncThunk<
  void,
  { file: File; pdfPassword?: string },
  { rejectValue: CustomError }
>(
  "upload/uploadFiles",
  async (
    { file, pdfPassword }: { file: File; pdfPassword?: string },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append("result", file);
      formData.append("uploadName", file.name);

      if (pdfPassword) {
        formData.append("pdfPassword", pdfPassword);
      }

      await httpService.upload(`/result/`, {
        body: formData,
      });
    } catch (e: any) {
      console.error("UPLOADING CUSTOM RESULT FILE ERROR: ", e);

      return rejectWithValue(e);
    }
  }
);
