import * as React from "react";
import reactStringReplace from "react-string-replace";

export const addLinksToText = (
  text: string,
  linkClassName?: string
): React.ReactNodeArray | string => {
  return reactStringReplace(text, /<a>(.*?)<\/a>/gs, (match) => {
    const linkName = match
      .match(/<name>(.*?)<\/name>/)![0]
      .replace(/<\/?name>/g, "");
    const linkHref = match
      .match(/<href>(.*?)<\/href>/)![0]
      .replace(/<\/?href>/g, "");

    return (
      <a
        href={linkHref}
        className={linkClassName}
        key={`link ${linkName}`}
        target="_blank"
        rel="noreferrer"
      >
        {linkName}
      </a>
    );
  });
};
