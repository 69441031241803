import * as React from "react";
import { NavLink } from "react-router-dom";

import styles from "./ResearchLink.module.scss";

import { arrowRight } from "assets/image";
import { ResearchItemType } from "types";

type Props = {
  research: ResearchItemType;
};

export const ResearchLink = ({ research }: Props): JSX.Element => {
  const { nameT, popular, isNew, id } = research;

  return (
    <NavLink className={styles.link} to={`/product/${id}`}>
      <div className={styles.link__text}>{nameT}</div>
      {popular && <div className={styles.link__popular}>Populiaru</div>}
      {isNew && <div className={styles.link__new}>Naujiena!</div>}
      <img className={styles.link__arrow} src={arrowRight} alt="Arrow" />
    </NavLink>
  );
};
