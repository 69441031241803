export default {
  firebaseConfig: {
    apiKey: process.env.REACT_APP_APIKEY as string,
    authDomain: process.env.REACT_APP_AUTHDOMAIN as string,
    databaseURL: process.env.REACT_APP_DATABASEURL as string,
    projectId: process.env.REACT_APP_PROJECTID as string,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET as string,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID as string,
    appId: process.env.REACT_APP_APPID as string,
    measurementId: process.env.REACT_APP_MEASUREMENTID as string,
  },
  baseUrl: process.env.REACT_APP_BASE_URL as string,
  mobileApplicationLinks: {
    appStore:
      "https://apps.apple.com/ua/app/revolab/id1531315603?l=en" as string,
    googlePlay:
      "https://play.google.com/store/apps/details?id=com.revolab.app" as string,
  },
  chartMonthTicksNames: {
    mobile: {
      en: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
      lt: ["S", "V", "K", "B", "G", "B", "L", "R", "R", "S", "L", "G"],
    },
    desktop: {
      en: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      lt: [
        "Sau",
        "Vas",
        "Kov",
        "Bal",
        "Geg",
        "Bir",
        "Lie",
        "Rugp",
        "Rugs",
        "Spa",
        "Lap",
        "Gruo",
      ],
    },
  },
  chartMonthTicksValues: [
    15, 45, 75, 105, 135, 165, 195, 225, 255, 285, 315, 345,
  ],
  socialMediaLinks: {
    facebook: "https://www.facebook.com/Revolabhealth/",
    facebookMessenger: "https://www.messenger.com/t/100101605106141",
    instagram: "https://www.instagram.com/revolab_health/",
    youTube: "https://www.youtube.com/channel/UCF2WTVE3_jonA-ibHt73M9w",
  },
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
  popularTestId: {
    covidAntibodies: "hOjOscfyXmwMtV31KJh5",
    stressHormone: "NL33GtCcmikFU9ffkTya",
    energyLevel: "dlxKsgrIbc8f1dKKKPTP",
    femaleFertility: "JS6yDzENM8FQrpnIYnNX",
  },
  calendlyVerifyingTestLabId: [
    "COVID19_PGR_SALIVA_TEST",
    "1000000039900",
    "020940",
  ],
  onlineDisputeResolutionLink: "http://ec.europa.eu/consumers/odr/",
  stateDataProtectonLink: "https://vdai.lrv.lt",
  revolabLink: "https://revolab.lt",
  revolabEmail: "revolab@revolab.eu",
  omnivaParcelTrackingUrl:
    "https://www.omniva.lt/verslo/siuntos_sekimas?barcode=",
  appEnvironment: process.env.REACT_APP_ENVIRONMENT as string,
  googleAnalyticsKey: process.env.REACT_APP_GOOGLEANALYTICSKEY as string,
  opayBaseUrl: process.env.REACT_APP_OPAY_BASE_URL as string,
  googleTagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string,
  zendeskKey: process.env.REACT_APP_ZENDESK_KEY as string,
  burnoutAliasId: "burnout",
  faqNumber: 19,
  states: [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "IllinoisIndiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "MontanaNebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "PennsylvaniaRhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ],
};
