import { Dispatch } from "redux";

import httpService from "services/httpService";
import errorsService from "services/errorsService";
import {
  SignupActionTypes,
  SignupDTO,
  ErrorsObjectType,
  SignupAction,
} from "types";

const signup = (formData: SignupDTO, setSuccess: () => void) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SignupActionTypes.SET_SIGNUP_LOADING,
      payload: true,
    });
    try {
      const user = await httpService.post("/auth/signup", {
        body: JSON.stringify(formData),
      });
      dispatch({
        type: SignupActionTypes.SET_SIGNUP_LOADING,
        payload: false,
      });
      setSuccess();
      return user;
    } catch (e: any) {
      dispatch({
        type: SignupActionTypes.SET_SIGNUP_ERRORS_OBJECT,
        payload: errorsService.createValidationErrorsObject(e),
      });
    }
  };
};

const setSignupErrorObject = (value: ErrorsObjectType): SignupAction => ({
  type: SignupActionTypes.SET_SIGNUP_ERRORS_OBJECT,
  payload: value,
});

export { signup, setSignupErrorObject };
