import * as React from "react";

import styles from "./Research.module.scss";

import { useTypedSelector, useAction } from "hooks";
import { ResearchType } from "components/Research/ResearchType";
import { getResearchTypes } from "components/Research/get-research-types";
import { ResearchItemType } from "types";

type Props = {
  acceptGlobalValues: boolean;
};

export const Research = ({ acceptGlobalValues }: Props): JSX.Element => {
  const testPackagesList = useTypedSelector((state) => state.testPackages);
  const {
    setIsOpenCovidTestPackage,
    setIsOpenManHealthTestPackage,
    setIsOpenWomanHealthTestPackage,
    setIsOpenWellnessTestPackage,
  } = useAction();
  const researchTypes = getResearchTypes(
    testPackagesList,
    setIsOpenCovidTestPackage,
    setIsOpenManHealthTestPackage,
    setIsOpenWomanHealthTestPackage,
    setIsOpenWellnessTestPackage
  );

  return (
    <div className={styles.main}>
      {researchTypes.map((research: ResearchItemType) => {
        return (
          <ResearchType
            research={research}
            acceptGlobalValues={acceptGlobalValues}
            key={research.id}
          />
        );
      })}
    </div>
  );
};
