import * as React from "react";

import { useTranslation } from "react-i18next";
import { Test } from "../../../../types";

import ResizableSpinner from "../../../ResizableSpinner";
import Tag from "../Tag";
import styles from "./Description.module.scss";

type Props = {
  description: string;
  shortDescription: string;
  subDescription?: string;
  sampleReport?: string;
  tests: Test[];
  hideTestsList: boolean;
};

const Description = ({
  description,
  shortDescription,
  subDescription,
  sampleReport,
  tests,
  hideTestsList,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [isFullTextAvailable, setIsFullTextAvailable] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const shortDescriptionAvailable = description !== shortDescription;

  const downloadFile = async (url: string) => {
    setIsDownloading(true);
    const response = await fetch(url);
    const blob = await response.blob();

    const a = document.createElement("a");
    a.download = "test.pdf";
    a.href = URL.createObjectURL(blob);
    a.click();
    setIsDownloading(false);
  };

  const readMoreButton = (
    <button
      className={styles.button}
      type="button"
      onClick={() => {
        if (isFullTextAvailable && hideTestsList) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
        setIsFullTextAvailable((value) => !value);
      }}
    >
      {isFullTextAvailable
        ? t("Product.Head.Collapse")
        : t("Product.Head.ReadMore")}
    </button>
  );

  return (
    <div>
      <div className={styles.text__description}>
        {isFullTextAvailable ? description : shortDescription}
      </div>
      {!hideTestsList && shortDescriptionAvailable && readMoreButton}
      {subDescription && (
        <div className={styles.text__description}>{subDescription}</div>
      )}
      {sampleReport && (
        <button
          className={
            isDownloading
              ? `${styles.report__button} ${styles.report__button_disabled}`
              : styles.report__button
          }
          type="button"
          onClick={() => downloadFile(sampleReport)}
        >
          {isDownloading && <ResizableSpinner />}
          <div className={styles.report__buttonText}>
            {t("Product.Head.DownloadSampleReport")}
          </div>
        </button>
      )}
      {(!hideTestsList || isFullTextAvailable) && (
        <div className={styles.tags}>
          {tests.map((test) => (
            <Tag
              testId={test.testId}
              testName={test.testNameT}
              description={test.descriptionT}
              key={test.testId}
            />
          ))}
        </div>
      )}
      {hideTestsList && shortDescriptionAvailable && readMoreButton}
    </div>
  );
};

export default Description;
