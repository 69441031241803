import { DeliveryInformation, OmnivaDeliveryInformation } from "types";

const buildDeliveryAddress = (
  deliveryInformation: DeliveryInformation
): string => {
  const deliveryAddressArray = [
    deliveryInformation.address,
    isOmnivaDelivery(deliveryInformation)
      ? `${deliveryInformation.county} ${deliveryInformation.name}`
      : deliveryInformation.city,
    deliveryInformation.postalCode,
  ];

  return deliveryAddressArray.join(" ");
};

const isOmnivaDelivery = (
  deliveryInformation: DeliveryInformation
): deliveryInformation is OmnivaDeliveryInformation => {
  return (
    (deliveryInformation as OmnivaDeliveryInformation).county !== undefined
  );
};

export { isOmnivaDelivery, buildDeliveryAddress };
