import React, { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import style from "./CartPopup.module.scss";

import analyticsService from "services/analyticsService";
import { useTypedSelector, useAppDispatch } from "hooks";
import { setCart } from "store/thunks";
import { delivery, trashBin } from "assets/image";
import ResizableSpinner from "components/ResizableSpinner";
import { setIsOpenCartPopup } from "store/slices";
import { CartItemType, PriceView } from "types";
import { getPrice } from "components/ShoppingCart/getPrice";
import { setActivePromoCode } from "store/actions";
import { getCurrencySymbol } from "helpers";

export default function CartPopup(): JSX.Element {
  const { t } = useTranslation();
  const { cart, isCartLoading } = useTypedSelector((state) => state.cart);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const currency = getCurrencySymbol(currentMarket.currency);
  const { activePromoCode } = useTypedSelector((state) => state.promoCodes);
  const dispatch = useAppDispatch();

  const deleteTest = (test: CartItemType): void => {
    analyticsService.createGAEvent("remove_from_cart", {
      items: [
        {
          item_id: test.testPackageId,
          item_name: test.testPackageName,
          price: test.price / 100,
        },
      ],
    });
    dispatch(
      setCart(
        cart.filter((item) => {
          return item.testPackageId !== test.testPackageId;
        })
      )
    );
    dispatch(setActivePromoCode(null));
  };

  useEffect(() => {
    if (cart.length === 0) {
      dispatch(setIsOpenCartPopup(false));
    }
  }, [cart, dispatch]);

  return (
    <div className={style.wrapper} onClick={(e) => e.stopPropagation()}>
      {isCartLoading && <ResizableSpinner />}
      <div className={style.listWrapper}>
        {cart.map((item) => {
          return (
            <div className={style.itemWrapper} key={item.testPackageId}>
              <img className={style.itemImage} src={item.preview} alt="test" />
              <div className={style.itemDescription}>
                <div className={style.itemDescription__name}>
                  {item.testPackageName}
                </div>
                <div className={style.itemDescription__quantity}>
                  <span>{t("Header.CartPopup.Quantity")}</span>
                  &nbsp;
                  <span className={style.bold}>{item.quantity}</span>
                </div>
                <div className={style.itemDescription__price}>
                  {`${(item.price / 100).toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} `}
                  <span className={style.euroSymbol}>{currency}</span>
                </div>
              </div>
              <div
                className={style.itemDescription__button}
                onClick={() => {
                  deleteTest(item);
                }}
              >
                <img
                  className={style.itemDescription__trashBin}
                  src={trashBin}
                  alt="Trash bin"
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className={style.footer}>
        <div className={style.footer__totalWrapper}>
          <div className={style.footer__totalWrapper_text}>
            {t("Header.CartPopup.Total")}
          </div>
          <div className={style.footer__totalWrapper_number}>
            <span>
              {`${getPrice(
                PriceView.withDiscount,
                activePromoCode,
                cart
              ).toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} `}
              <span className={style.euroSymbol}>{currency}</span>
            </span>
            {activePromoCode && (
              <div className={style.footer__totalWrapper__orangePrice}>
                {`${getPrice(
                  PriceView.default,
                  activePromoCode,
                  cart
                ).toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} `}
                <span className={style.euroSymbol}>{currency}</span>
              </div>
            )}
          </div>
        </div>
        <div className={style.footer__deliveryWrapper}>
          <img
            className={style.footer__deliveryWrapper_image}
            src={delivery}
            alt="delivery"
          />
          <div className={style.footer__deliveryWrapper_text}>
            {t("Header.CartPopup.Delivery")}
          </div>
        </div>
        <Link className={style.footer__link} to="/user/cart">
          {t("Header.CartPopup.ButtonBuy")}
        </Link>
      </div>
    </div>
  );
}
