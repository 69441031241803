import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as MaleLogo } from "assets/image/male-logo.svg";
import { ReactComponent as FemaleLogo } from "assets/image/female-logo.svg";
import { GenderQuestionType } from "types";

import styles from "./GenderQuestion.module.scss";

type Props = {
  question: GenderQuestionType;
  answers: { [key: string]: string | number | null };
  setAnswers: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | number | null;
    }>
  >;
};

const GenderQuestion = ({
  question,
  setAnswers,
  answers,
}: Props): JSX.Element => {
  const [gender, setGender] = React.useState<string | null>(null);
  const { t } = useTranslation();

  const chooseGender = (e: React.MouseEvent) => {
    const value = e.currentTarget.textContent;
    setGender(gender === value ? null : value);
  };

  React.useEffect(() => {
    setAnswers({ ...answers, [question.id]: gender });
  }, [gender, question.id]);

  return (
    <>
      <div className={styles.gender__wrapper}>
        <div
          className={
            gender === t("Signup.Male")
              ? `${styles.gender__content} ${styles.gender__active}`
              : styles.gender__content
          }
          onClick={chooseGender}
        >
          <div
            className={
              gender === t("Signup.Male")
                ? `${styles.gender__imgWrap} ${styles.gender__activeImgWrap}`
                : styles.gender__imgWrap
            }
          >
            <MaleLogo
              fill={gender === t("Signup.Male") ? "#45CB85" : "#7D8197"}
            />
          </div>
          <div>
            <span className={styles.gender__text}>{t("Signup.Male")}</span>
          </div>
        </div>

        <div
          className={
            gender === t("Signup.Female")
              ? `${styles.gender__content} ${styles.gender__active}`
              : styles.gender__content
          }
          onClick={chooseGender}
        >
          <div
            className={
              gender === t("Signup.Female")
                ? `${styles.gender__imgWrap} ${styles.gender__activeImgWrap}`
                : styles.gender__imgWrap
            }
          >
            <FemaleLogo
              fill={gender === t("Signup.Female") ? "#45CB85" : "#7D8197"}
            />
          </div>
          <div>
            <span className={styles.gender__text}>{t("Signup.Female")}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenderQuestion;
