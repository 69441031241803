import { createSlice } from "@reduxjs/toolkit";

import { getCart, setCart } from "store/thunks";
import { CartItemType } from "types";

interface CartState {
  isCartLoading: boolean;
  cart: CartItemType[];
  cartError: string | undefined;
  isOpenCartPopup: boolean;
}

const initialState: CartState = {
  isCartLoading: false,
  cart: [],
  cartError: "",
  isOpenCartPopup: false,
};

const cartSlice = createSlice({
  name: "cartReducer",
  initialState,
  reducers: {
    setIsOpenCartPopup: (state, action) => {
      state.isOpenCartPopup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.cart = action.payload;
      })
      .addCase(getCart.rejected, (state, action) => {
        state.isCartLoading = false;
        state.cartError = action.payload;
      })
      .addCase(setCart.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(setCart.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.cart = action.payload;
      })
      .addCase(setCart.rejected, (state, action) => {
        state.isCartLoading = false;
        state.cartError = action.payload;
      });
  },
});

export default cartSlice.reducer;

export const { setIsOpenCartPopup } = cartSlice.actions;
