import * as Yup from "yup";

export const UserSettingsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Min2")
    .max(30, "Max30")
    .trim()
    .required("Required"),
  password: Yup.string().min(6, "Min6").max(30, "Max30").trim(),
  repeatPassword: Yup.string()
    .trim()
    .when("password", {
      is: (value: string) => value && value.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref("password"), null], "MatchPassword")
        .required("Required"),
    }),
});
