import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./BurnoutSymptomsQuantity.module.scss";

import {
  burnoutSymptomsQuantityHead,
  burnoutSymptomsQuantityMan,
} from "assets/image";

const BurnoutSymptomsQuantity = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.title}>
        <img
          className={styles.titleImage}
          src={burnoutSymptomsQuantityHead}
          alt="Head"
        />
        {t("Docs.BurnoutSymptomsQuantity.Title")}
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.column}>
          <div className={styles.text}>
            <span className={styles.text_blue}>
              {t("Docs.BurnoutSymptomsQuantity.Blocks.Block1.TextBlue")}
            </span>
            {t("Docs.BurnoutSymptomsQuantity.Blocks.Block1.Text")}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.text}>
            {t("Docs.BurnoutSymptomsQuantity.Blocks.Block2.Text")}
            <span className={styles.text_blue}>
              {t("Docs.BurnoutSymptomsQuantity.Blocks.Block2.TextBlue")}
            </span>
          </div>
          <div className={styles.text}>
            <span className={styles.text_blue}>
              {t("Docs.BurnoutSymptomsQuantity.Blocks.Block3.TextBlue")}
            </span>
            {t("Docs.BurnoutSymptomsQuantity.Blocks.Block3.Text")}
          </div>
          <div className={styles.text}>
            <span className={styles.text_blue}>
              {t("Docs.BurnoutSymptomsQuantity.Blocks.Block4.TextBlue")}
            </span>
            {t("Docs.BurnoutSymptomsQuantity.Blocks.Block4.Text")}
          </div>
          <div className={styles.text}>
            <span className={styles.text_blue}>
              {t("Docs.BurnoutSymptomsQuantity.Blocks.Block5.TextBlue")}
            </span>
            {t("Docs.BurnoutSymptomsQuantity.Blocks.Block5.Text")}
          </div>
          <div className={styles.text}>
            <span className={styles.text_blue}>
              {t("Docs.BurnoutSymptomsQuantity.Blocks.Block6.TextBlue")}
            </span>
            {t("Docs.BurnoutSymptomsQuantity.Blocks.Block6.Text")}
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={burnoutSymptomsQuantityMan}
          alt="Man with problems"
        />
      </div>
    </div>
  );
};

export default BurnoutSymptomsQuantity;
