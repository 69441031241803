import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./CompletedPage.module.scss";

import { success } from "assets/image";

type Props = {
  titleTextKeys: string[];
  descriptionTextKeys: string[];
  customUrl?: string;
  buttonTextKey?: string;
};

const CompletedPage = ({
  titleTextKeys,
  descriptionTextKeys,
  customUrl,
  buttonTextKey,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.completedPage}>
      <div className={styles.completedPage__imageHolder}>
        <img
          className={styles.completedPage__image}
          src={success}
          alt="Success"
        />
      </div>
      <div className={styles.completedPage__title}>
        {titleTextKeys.map((key) => {
          return t(`StatusPages.CompletedPage.${key}`);
        })}
      </div>
      <div className={styles.completedPage__subtitle}>
        {descriptionTextKeys.map((key) => {
          return t(`StatusPages.CompletedPage.${key}`);
        })}
      </div>
      <Link
        className={styles.completedPage__link}
        to={customUrl ? customUrl : "/"}
      >
        {t(
          `StatusPages.CompletedPage.${
            buttonTextKey ? buttonTextKey : "Button"
          }`
        )}
      </Link>
    </div>
  );
};

export default CompletedPage;
