import * as React from "react";

import styles from "./UsStatesDropdown.module.scss";
import config from "config";
import CustomInput from "components/CustomInput";

type Props = {
  stateValue: string;
  stateError: string;
  stateTouched: boolean;
  handleChange: any;
  setState: (state: string) => void;
};

const UsStatesDropdown = ({
  stateValue,
  stateError,
  stateTouched,
  handleChange,
  setState,
}: Props): JSX.Element => {
  const [stateListOpened, setStateListOpened] = React.useState(false);
  const activeStates = stateValue
    ? config.states.filter((state) => state.includes(stateValue))
    : config.states;
  const stateNameCorrect = activeStates.some((state) => state === stateValue);

  return (
    <div className={styles.dropdown}>
      <div className={styles.dropdown__inputWrapper}>
        <CustomInput
          contentContainerStyle="mediumInput"
          text="State"
          value={stateValue}
          autoComplete="address-level1"
          error={stateError}
          touched={stateTouched}
          changeHandler={handleChange("state")}
          verticalError
          onFocus={() => setStateListOpened(true)}
          onBlur={() => {
            if (stateValue && stateNameCorrect) {
              setStateListOpened(false);
            }
          }}
        />
        <div
          className={styles.dropdown__triangleHolder}
          onClick={() =>
            setStateListOpened((stateListOpened) => !stateListOpened)
          }
        >
          <div
            className={
              stateListOpened
                ? `${styles.dropdown__triangle} ${styles.dropdown__triangle_active}`
                : styles.dropdown__triangle
            }
          ></div>
        </div>
      </div>
      {stateListOpened && (
        <div className={styles.dropdown__list}>
          {activeStates.map((state) => (
            <div
              className={styles.dropdown__listItem}
              key={state}
              onClick={() => {
                setState(state);
                setStateListOpened(false);
              }}
            >
              {state}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UsStatesDropdown;
