import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./SurveySuccessMessage.module.scss";

import { success } from "assets/image";

const SurveySuccessMessage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.message}>
      <img className={styles.message__image} src={success} alt="Success" />
      <div className={styles.message__title}>
        {t("Survey.SuccessMessage.Title")}
      </div>
      <div className={styles.message__subTitle}>
        {t("Survey.SuccessMessage.SubTitle")}
      </div>
      <Link to="/" className={styles.message__button}>
        {t("Survey.SuccessMessage.Button")}
      </Link>
    </div>
  );
};

export default SurveySuccessMessage;
