import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import style from "./ResultPage.module.scss";

import TestResultBoolean from "components/Result/TestResultBoolean";
import TestResultNumeric from "components/Result/TestResultNumeric";
import Chart from "components/Chart";
import Spinner from "components/Spinner";
import ErrorPage from "components/Error";
import { PackageType, TestResultItemType } from "types";
import { closeGrey } from "assets/image";
import { useMediaQueryContext } from "components/MediaQueryProvider";
import { useAppDispatch, useTypedSelector } from "hooks";
import { fetchResult } from "store/actions";

export default function ResultPage(): JSX.Element {
  const { resultId }: { resultId: string } = useParams();
  const { resultLoading, result, resultError } = useTypedSelector(
    (state) => state.results
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchResult(resultId));
  }, [resultId, dispatch]);

  const date = new Date(result.date);
  const [showComments, setShowComments] = useState(false);
  const [commentName, setCommentName] = useState<string>("");
  const [commentText, setCommentText] = useState<string>("");
  const { isMobile } = useMediaQueryContext();

  if (resultLoading)
    return (
      <div className={style.wrapper}>
        <Spinner />
      </div>
    );

  if (resultError) return <ErrorPage />;

  return (
    <div className={style.wrapper}>
      {showComments && (
        <div
          className={style.commentPopupWrapper}
          onClick={() => setShowComments(false)}
        >
          <div className={style.commentPopup}>
            <img
              className={style.commentPopup__button}
              src={closeGrey}
              alt="close"
              onClick={() => setShowComments(false)}
            />
            <div className={style.commentPopup__header}>
              {t("Result.CommentHeader")}
            </div>
            <div className={style.commentPopup__name}>{commentName}</div>
            <div className={style.commentPopup__text}>{commentText}</div>
          </div>
        </div>
      )}
      {!(showComments && isMobile) && (
        <div className={style.container}>
          <div className={style.header}>{result.testPackageName}</div>
          <div className={style.textContainer}>
            <div className={style.text}>{t("Result.RecentResults")}</div>
            <div className={style.date}>{dayjs(date).format(`YYYY.MM.DD`)}</div>
          </div>
          {result.testResults &&
            Object.values(result.testResults).map(
              (item: TestResultItemType) => {
                return (
                  <div className={style.testContainer} key={item.testId}>
                    {item.testType === PackageType.Numeric ? (
                      <TestResultNumeric item={item} />
                    ) : (
                      <TestResultBoolean item={item} />
                    )}
                    <div className={style.commentWrapper}>
                      <div className={style.commentWrapper__header}>
                        {t("Result.CommentHeader")}
                      </div>
                      <div className={style.commentWrapper__text}>
                        {item.description}
                      </div>
                      <div
                        className={style.commentWrapper__link}
                        onClick={() => {
                          setShowComments(true);
                          setCommentName(item.testName);
                          setCommentText(item.description);
                          window.scrollTo(0, 0);
                        }}
                      >
                        {t("Result.ReadMore")}
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          {result.testResults &&
            Object.values(result.testResults).some(
              (testResult) => testResult.testType === PackageType.Numeric
            ) && (
              <div>
                <div className={`${style.text} ${style.text__m56}`}>
                  {t("Result.ResultHistory")}
                </div>
                <Chart result={result} />
              </div>
            )}
        </div>
      )}
    </div>
  );
}
