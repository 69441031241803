import firebase from "firebaseApp";
import { Market } from "types";

class MarketsService {
  markets: firebase.firestore.Query<firebase.firestore.DocumentData>;

  constructor() {
    this.markets = firebase.firestore().collection("Markets");
  }

  async fetchMarkets(): Promise<Array<Market>> {
    const snapshot = await this.markets.get();
    const result = snapshot.docs.map((doc) => doc.data());

    return result as Market[];
  }
}

export default new MarketsService();
