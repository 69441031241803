import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./FileUploadForm.module.scss";

import { addFile, errorIcon, success } from "assets/image";
import { filterFiles } from "helpers";
import { useAppDispatch, useTypedSelector } from "hooks";
import {
  setFilePassword,
  setIsFileUploaded,
  setPendingSurveyResultError,
  setTriedUploadFile,
  unloadFile,
} from "store/slices";
import {
  setIsFileEncrypted,
  setIsFilePasswordCorrect,
} from "store/thunks/survey";
import CustomInput from "components/CustomInput";
import { SurveyTemplateFile } from "types";

type Props = {
  files: { [key: string]: File | null };
  setFiles: React.Dispatch<
    React.SetStateAction<{
      [key: string]: File | null;
    }>
  >;
  file: SurveyTemplateFile;
  isBigHeader: boolean;
  noBottomBorder: boolean;
};

const FileUploadForm = ({
  files,
  setFiles,
  file: { headerT, descriptionT, fileId },
  isBigHeader,
  noBottomBorder,
}: Props): JSX.Element => {
  const {
    pendingSurveyResultError,
    triedUploadFile,
    isFileEncrypted,
    filePassword,
    isFilePasswordCorrect,
    filePasswordError,
  } = useTypedSelector((state) => state.survey);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div
      className={`${styles.upload} ${noBottomBorder ? styles.noBorder : null}`}
    >
      {!triedUploadFile[fileId] && (
        <>
          {isBigHeader ? (
            <h1 className={styles.upload__uploadTitleBig}>
              {t("UploadLipidogram")}
            </h1>
          ) : (
            <div className={styles.upload__uploadTitle}>{headerT}</div>
          )}
          <div className={styles.upload__dropBlock}>
            <img className={styles.upload__image} src={addFile} alt="File" />
            <div className={styles.upload__title}>
              {t("Survey.Upload.Title")}
            </div>
            <label className={styles.upload__uploadButton} htmlFor="fileUpload">
              {t("Survey.Upload.Button")}
            </label>
            <input
              className={styles.upload__input}
              id="fileUpload"
              type="file"
              name="file"
              accept=".pdf"
              tabIndex={-1}
              onChange={(e) => {
                const fileToUpload = filterFiles(
                  e.target.files ? e.target.files[0] : null
                );

                setFiles({ ...files, [fileId]: fileToUpload });

                if (fileToUpload) {
                  dispatch(setIsFileUploaded(true));
                  dispatch(setIsFileEncrypted({ file: fileToUpload, fileId }));
                }
                dispatch(setPendingSurveyResultError(""));
                dispatch(
                  setTriedUploadFile({ ...triedUploadFile, [fileId]: true })
                );
              }}
            />
            <div className={styles.upload__hintsList}>
              {descriptionT.map((descriptionItem) => (
                <div
                  className={styles.upload__hint}
                  key={`${fileId} ${descriptionItem}`}
                >
                  {descriptionItem}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {triedUploadFile[fileId] &&
        files[fileId] &&
        !pendingSurveyResultError.errors?.message && (
          <div className={styles.successContent}>
            {!isFileEncrypted[fileId] ||
            (isFileEncrypted[fileId] && isFilePasswordCorrect[fileId]) ? (
              <>
                <img
                  className={styles.successContent__image}
                  src={success}
                  alt="Success"
                />
                <div className={styles.successContent__title}>
                  {t("Survey.Upload.SuccessContent.Title")}
                </div>
              </>
            ) : (
              <div className={styles.successContent__passwordBlock}>
                <span className={styles.successContent__passwordBlock__title}>
                  {t("Survey.Upload.Password.Title")}
                </span>
                <div
                  className={styles.successContent__passwordBlock__inputWrapper}
                >
                  <CustomInput
                    contentContainerStyle="bigInput"
                    text={""}
                    value={filePassword[fileId]}
                    error={filePasswordError[fileId]}
                    touched={!!filePasswordError}
                    verticalError={true}
                    changeHandler={(e) =>
                      dispatch(
                        setFilePassword({ password: e.target.value, fileId })
                      )
                    }
                  />
                </div>
                <div
                  className={styles.successContent__passwordBlock__button}
                  onClick={() => {
                    if (files[fileId]) {
                      dispatch(
                        setIsFilePasswordCorrect({
                          file: files[fileId]!,
                          password: filePassword[fileId],
                          fileId,
                        })
                      );
                    }
                  }}
                >
                  {t("Survey.Upload.Password.Button")}
                </div>
              </div>
            )}
            <div
              className={styles.successContent__button}
              onClick={() => {
                dispatch(unloadFile(fileId));
                setFiles({ ...files, [fileId]: null });
              }}
            >
              {t("Survey.Upload.SuccessContent.Button")}
            </div>
          </div>
        )}
      {triedUploadFile[fileId] && !files[fileId] && (
        <div className={styles.errorContent}>
          <img
            className={styles.errorContent__image}
            src={errorIcon}
            alt="Error"
          />
          <div className={styles.errorContent__title}>
            {t("Survey.Upload.ErrorContent.Title")}
          </div>
          <div className={styles.errorContent__subTitle}>
            {t("Survey.Upload.ErrorContent.SubTitle")}
          </div>
          <div
            className={styles.errorContent__button}
            onClick={() =>
              dispatch(
                setTriedUploadFile({ ...triedUploadFile, [fileId]: false })
              )
            }
          >
            {t("Survey.Upload.ErrorContent.Button")}
          </div>
        </div>
      )}
      {triedUploadFile[fileId] &&
        pendingSurveyResultError.errors?.message ===
          "validation/result-already-exists" && (
          <div className={styles.errorContent}>
            <img
              className={styles.errorContent__image}
              src={errorIcon}
              alt="Error"
            />
            <div className={styles.errorContent__title}>
              {t("Survey.Upload.ErrorContent.ExistFileTitle")}
            </div>
            <div className={styles.errorContent__subTitle}>
              {t("Survey.Upload.ErrorContent.ExistFileSubTitle")}
            </div>
            <div
              className={styles.errorContent__button}
              onClick={() =>
                dispatch(
                  setTriedUploadFile({ ...triedUploadFile, [fileId]: false })
                )
              }
            >
              {t("Survey.Upload.ErrorContent.Button")}
            </div>
          </div>
        )}
    </div>
  );
};

export default FileUploadForm;
