export const isFileAllowed = (
  fileUpload: { [key: string]: boolean },
  fileEncrypt: { [key: string]: boolean },
  filePassword: { [key: string]: boolean },
  key: string
): boolean => {
  const isFileUploaded: boolean = Object.values(fileUpload).length
    ? fileUpload[key]
    : typeof fileUpload !== "object" && Boolean(fileUpload);

  const isFileAllowed: boolean = fileEncrypt[key]
    ? filePassword[key]
    : isFileUploaded;

  return isFileAllowed;
};
