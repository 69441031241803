import { Dispatch } from "redux";

import httpService from "services/httpService";
import { ResultsActionTypes } from "types";

const fetchAllUserResults = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ResultsActionTypes.PENDING_ALL_USER_RESULTS,
    });
    try {
      const results = await httpService.get("/result/");
      dispatch({
        type: ResultsActionTypes.FULFILLED_ALL_USER_RESULTS,
        payload: results,
      });
    } catch (e: any) {
      dispatch({
        type: ResultsActionTypes.REJECTED_ALL_USER_RESULTS,
        payload: e.errors.message,
      });
    }
  };
};

const fetchResult = (resultId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ResultsActionTypes.PENDING_RESULT,
    });
    try {
      const result = await httpService.get(`/result/${resultId}/`);
      dispatch({
        type: ResultsActionTypes.FULFILLED_RESULT,
        payload: result,
      });
    } catch (e: any) {
      console.error("FETCHING RESULT ERROR: ", e);

      dispatch({
        type: ResultsActionTypes.REJECTED_RESULT,
        payload: e.errors.message,
      });
    }
  };
};

const fetchCustomResults = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ResultsActionTypes.PENDING_UPLOADED_RESULTS,
    });
    try {
      const result = await httpService.get(`/result/custom/`);

      dispatch({
        type: ResultsActionTypes.FULFILLED_UPLOADED_RESULTS,
        payload: result,
      });
    } catch (e: any) {
      dispatch({
        type: ResultsActionTypes.REJECTED_UPLOADED_RESULTS,
        payload: e.errors.message,
      });
    }
  };
};

export { fetchAllUserResults, fetchResult, fetchCustomResults };
