import { Country } from "types";

export type OmnivaDepartment = {
  id: string;
  postalCode: string;
  name: string;
  type: DepartmentType;
  country: Country;
  county: string;
  address: string;
  coordinates: { x: string; y: string };
};

export enum DepartmentType {
  parcelMachine = "parcelMachine",
  postOffice = "postOffice",
}

export type Parcel = {
  id: string;
  barcode: string;
};
