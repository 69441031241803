import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import style from "./Error.module.scss";

import { errorIcon } from "assets/image";

type Props = {
  errorTextKeys?: string[];
};

function ErrorPage({ errorTextKeys }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <img className={style.image} src={errorIcon} alt="error"></img>
      <span className={style.title}>{t("ErrorPage.Title")}</span>
      <div className={style.description}>
        {errorTextKeys && errorTextKeys?.length > 0 ? (
          errorTextKeys.map((textKey) => {
            return textKey === "ContactsLink" ? (
              <Link className={style.contactsLink} to="/contacts">
                {t("ErrorPage.ContactsLink")}
              </Link>
            ) : (
              <span className={style.text} key="textKey">
                {t(`ErrorPage.${textKey}`)}
              </span>
            );
          })
        ) : (
          <span className={style.text}>{t("ErrorPage.Text")}</span>
        )}
      </div>
      <Link to="/" className={style.link}>
        {t("ErrorPage.Button")}
      </Link>
    </div>
  );
}

export default ErrorPage;
