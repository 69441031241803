import { Dispatch } from "redux";

import promoCodesService from "services/promoCodesService";
import { PromoCodesActionTypes, PromoCode } from "types";

const getPromoCodes = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: PromoCodesActionTypes.PENDING_PROMO_CODES,
    });
    try {
      const result = await promoCodesService.fetchPromoCodes();

      const withValidDate = result.map((code) => {
        return code.exp
          ? { ...code, exp: code.exp.seconds * 1000 }
          : { ...code };
      });

      dispatch({
        type: PromoCodesActionTypes.FULFILLED_PROMO_CODES,
        payload: withValidDate,
      });
    } catch (e: any) {
      dispatch({
        type: PromoCodesActionTypes.REJECTED_PROMO_CODES,
        payload: e.message,
      });
    }
  };
};

const setActivePromoCode = (promoCode: PromoCode | null) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: PromoCodesActionTypes.SET_ACTIVE_PROMO_CODE,
      payload: promoCode,
    });
  };
};

export { getPromoCodes, setActivePromoCode };
