import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Partners.module.scss";

import { medicinaPractica, synlab, diagnolita } from "assets/image";

const Partners = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.partners}>
      <div className={styles.partners__title}>{t("Main.Partners.Title")}</div>
      <div className={styles.partners__list}>
        <img
          className={styles.partners__image}
          src={medicinaPractica}
          alt="Medicina Practica"
        />
        <img className={styles.partners__image} src={synlab} alt="Synlab" />
        <img
          className={styles.partners__image}
          src={diagnolita}
          alt="Diagnolita"
        />
      </div>
    </div>
  );
};

export default Partners;
