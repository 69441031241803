export const troubleDocsPagesList = [
  {
    id: "1",
    link: "/docs/burnout",
    nameT: {
      en: "Burnout",
      lt: "Perdegimo sindromas",
    },
  },
  {
    id: "2",
    link: "/docs/burnoutsymptoms",
    nameT: {
      en: "Burnout symptoms",
      lt: "Perdegimo sindromo simptomai",
    },
  },
  {
    id: "3",
    link: "/docs/cortisolactivity",
    nameT: {
      en: "Cortisol activity",
      lt: "Kortizolio aktyvumas",
    },
  },
  {
    id: "4",
    link: "/docs/stressresistance",
    nameT: {
      en: "Stress resistance",
      lt: "Bendras atsparumas stresui",
    },
  },
  {
    id: "5",
    link: "/docs/burnoutcompensation",
    nameT: {
      en: "Burnout compensation level",
      lt: "Perdegimo kompensavimo lygis",
    },
  },
  {
    id: "6",
    link: "/docs/burnoutsymptomsquantity",
    nameT: {
      en: "Burnout symptoms quantity",
      lt: "Perdegimo simptomų kiekis",
    },
  },
  {
    id: "7",
    link: "/docs/burnoutbuttery",
    nameT: {
      en: "Burnout buttery",
      lt: "Baterija",
    },
  },
];
