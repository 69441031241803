import * as React from "react";
import { useLocation } from "react-router-dom";

import styles from "./OpayPaymentResult.module.scss";

import ErrorPage from "components/Error";
import SuccessOrderPage from "components/Checkout/SuccessOrderPage";

const OpayPaymentResult = (): JSX.Element => {
  let paymentStatus;

  const queryParameters = new URLSearchParams(useLocation().search);
  const encodedPaymentParameters = queryParameters.get("encoded");

  if (encodedPaymentParameters) {
    const decodedPaymentParameters = Buffer.from(
      encodedPaymentParameters
        .replace(/,/g, "=")
        .replace(/-/g, "+")
        .replace(/_/g, "/"),
      "base64"
    ).toString();
    const URLPaymentParameters = new URLSearchParams(decodedPaymentParameters);
    const paymentParameters = Object.fromEntries(URLPaymentParameters);

    paymentStatus = paymentParameters.status;
  }

  return (
    <div className={styles.wrapper}>
      {paymentStatus === "1" ? <SuccessOrderPage /> : <ErrorPage />}
    </div>
  );
};

export default OpayPaymentResult;
