import config from "config";
import { logout } from "store/actions";
import { Language } from "types";

class HTTPService {
  private baseUrl: string;

  private headers: { [key: string]: string };

  constructor() {
    this.baseUrl = config.baseUrl;
    this.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  public setAuthHeader(token: string) {
    this.headers = {
      ...this.headers,
      Authorization: token,
    };
  }

  public setLanguageHeader(language: Language) {
    this.headers = {
      ...this.headers,
      "Accept-Language": language,
    };
  }

  public upload(endpoint: RequestInfo, options: RequestInit = {}) {
    return this.uploadFile(endpoint, { method: "POST", ...options });
  }

  public post(endpoint: RequestInfo, options: RequestInit = {}) {
    return this.customFetch(endpoint, { method: "POST", ...options });
  }

  public get(endpoint: RequestInfo, options: RequestInit = {}) {
    return this.customFetch(endpoint, { method: "GET", ...options });
  }

  public put(endpoint: RequestInfo, options: RequestInit = {}) {
    return this.customFetch(endpoint, { method: "PUT", ...options });
  }

  private async refreshToken() {
    try {
      const token = JSON.parse(localStorage.getItem("token") || "{}");
      if (token) {
        const preparedToken = JSON.stringify({
          refreshToken: token.refreshToken,
        });
        const response = await fetch(`${this.baseUrl}/auth/refresh`, {
          method: "POST",
          headers: { ...this.headers },
          body: preparedToken,
        });
        return response;
      }
    } catch (error) {
      return error;
    }
  }

  private async customFetch(
    endpoint: RequestInfo,
    options: RequestInit = {}
  ): Promise<any> {
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      ...options,
      headers: {
        ...this.headers,
        ...options.headers,
      },
    });

    return this.processResult(response, endpoint, options);
  }

  private async uploadFile(
    endpoint: RequestInfo,
    options: RequestInit = {}
  ): Promise<any> {
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      ...options,
      headers: {
        Accept: "multipart/form-data",
        Authorization: this.headers["Authorization"],
      },
    });

    return this.processResult(response, endpoint, options);
  }

  private async processResult(
    response: Response,
    endpoint: RequestInfo,
    options: RequestInit = {}
  ): Promise<any> {
    const json = await response.json();
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(json);
    }
    if (response.status === 401 && json.errors.message === "Unauthorized") {
      const refreshTokenResponse: any = await this.refreshToken();
      if (refreshTokenResponse.status === 200) {
        const refreshTokenResult = await refreshTokenResponse.json();
        localStorage.setItem("token", JSON.stringify(refreshTokenResult));
        this.setAuthHeader(`Bearer ${refreshTokenResult.accessToken}`);
        return this.customFetch(endpoint, options);
      } else {
        logout();
        window.location.reload();
      }
    }
    return Promise.reject(json);
  }
}

export default new HTTPService();
