import firebase from "firebaseApp";
import { Country } from "types";

class TestPackageService {
  testPackages: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor() {
    this.testPackages = firebase.firestore().collection("TestPackages");
  }

  async fetchTestPackagesList(
    currentMarketId: Country
  ): Promise<Array<firebase.firestore.DocumentData>> {
    const snapshot = await this.testPackages
      .where("isActive", "==", true)
      .where("market", "==", currentMarketId)
      .get();
    const result = snapshot.docs.map((doc) => doc.data());
    return result;
  }
}

export default new TestPackageService();
