import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import style from "./PackageItem.module.scss";
import { Laboratory } from "types";
import {
  revolabLogoWithText,
  medicinaPractica,
  synlab,
  anteja,
} from "assets/image";

type Props = {
  testPackage: string;
  resultDate: string;
  resultId: string;
  lab: Laboratory;
  isCustom: boolean;
};

const images = {
  Anteja: anteja,
  MedicinaPractica: medicinaPractica,
  Synlab: synlab,
};

export default function PackageItem({
  testPackage,
  resultDate,
  resultId,
  lab,
  isCustom,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const date: Date = new Date(resultDate);

  return (
    <div className={style.wrapper}>
      <div className={style.name}>{testPackage}</div>
      <div className={style.laboratoryWrapper}>
        <img
          className={style.laboratory}
          src={isCustom ? images[lab] : revolabLogoWithText}
          alt={lab || "Revolab"}
        />
      </div>
      <div className={style.date}>{dayjs(date).format(`YYYY.MM.DD`)}</div>
      <Link className={style.link} to={`/result/${resultId}`}>
        {t("Dashboard.ViewResult")}
      </Link>
    </div>
  );
}
