import { Currency } from "types";

export enum Language {
  en = "en",
  lt = "lt",
}

export type LanguageContextType = {
  currentLanguage: Language;
  setCurrentLanguage: React.Dispatch<React.SetStateAction<Language>>;
};

export enum Country {
  LT = "LT",
  US = "US",
}

export interface Locale {
  language: Language;
  country: Country;
  currency: Currency;
}
