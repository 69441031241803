import * as React from "react";
import { useLocation } from "react-router-dom";

import styles from "./Content.module.scss";

import Promo from "components/MainPage/Promo";
import ContentResearch from "components/MainPage/ContentResearch";
import Benefits from "components/MainPage/Benefits";
import Popular from "components/MainPage/Popular";
import HowItWorks from "components/MainPage/HowItWorks";
import Reviews from "components/MainPage/Reviews";
import Results from "components/MainPage/Results";
import Partners from "components/MainPage/Partners";
import ContentResearchRepeat from "components/MainPage/ContentResearchRepeat";
import YoutubeGuide from "components/MainPage/YoutubeGuide";
import StressHeading from "components/MainPage/Stressometer";
import HowItWorksUS from "components/MainPage/HowItWorksUS";
import HealthAppSlider from "components/MainPage/HealthAppSlider";
import { useTypedSelector } from "hooks";

const Content = (): JSX.Element => {
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const location = useLocation();
  const blocks = currentMarket.config.mainPageBlocks;

  React.useEffect(() => {
    if (location.hash === "#covid19") {
      document.querySelector("#covid19")?.scrollIntoView(true);
    }

    if (location.hash === "#howdoesitworks") {
      document.querySelector("#howdoesitworks")?.scrollIntoView(true);
    }

    if (location.hash === "#howdoesitworksvideo") {
      document.querySelector("#howdoesitworksvideo")?.scrollIntoView(true);
    }
  }, [location]);

  return (
    <div className={styles.content}>
      {blocks.includes("promo") && <Promo />}
      {blocks.includes("contentResearch") && <ContentResearch />}
      {blocks.includes("stressHeading") && <StressHeading />}
      {blocks.includes("benefits") && <Benefits />}
      {blocks.includes("popular") && <Popular />}
      {blocks.includes("howItWorks") && <HowItWorks />}
      {blocks.includes("howItWorksUS") && <HowItWorksUS />}
      {blocks.includes("healthAppSlider") && <HealthAppSlider />}
      {blocks.includes("youtubeGuide") && <YoutubeGuide />}
      {blocks.includes("reviews") && <Reviews />}
      {blocks.includes("results") && <Results />}
      {blocks.includes("partners") && <Partners />}
      {blocks.includes("contentResearchRepeat") && <ContentResearchRepeat />}
    </div>
  );
};

export default Content;
