import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ContactsPage.module.scss";
import Contacts from "./Contacts";

const ContactsPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.contacts}>
      <div className={styles.contacts__title}>{t("Contacts.Title")}</div>
      <Contacts />
    </div>
  );
};

export default ContactsPage;
