import * as React from "react";

import styles from "./Results.module.scss";

import {
  resultsExample1,
  resultsExample2,
  resultsExample3,
} from "assets/image";

const Results = (): JSX.Element => {
  return (
    <div className={styles.results}>
      <div className={styles.results__result}>
        <div className={styles.results__title}>Rezultatų pavyzdys</div>
        <img
          className={styles.results__image}
          src={resultsExample1}
          alt="Example of results"
        />
      </div>
      <div
        className={`${styles.results__result} ${styles.results__result_big}`}
      >
        <div className={styles.results__title}>Rezultatų pavyzdys</div>
        <img
          className={styles.results__image}
          src={resultsExample2}
          alt="Example of results"
        />
      </div>
      <div className={styles.results__result}>
        <div className={styles.results__title}>Rezultatų pavyzdys</div>
        <img
          className={styles.results__image}
          src={resultsExample3}
          alt="Example of results"
        />
      </div>
    </div>
  );
};

export default Results;
