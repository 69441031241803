import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Instruction.module.scss";

import {
  productOpenCollector,
  productScrewCollector,
  productCollectSaliva,
  productWashArms,
  productGetActivity,
  productCollectBlood,
  productArrow1,
  productArrow2,
  productQuotes,
} from "assets/image";
import { CollectionMethod } from "types";

type Props = {
  collectionMethod: CollectionMethod;
};

const Instruction = ({ collectionMethod }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {(collectionMethod === CollectionMethod.FingerPrickBlood ||
        collectionMethod === CollectionMethod.UrineSample) && (
        <div className={styles.bloodInstruction}>
          <div className={styles.instruction__title}>
            {t("Product.Instruction.FingerPrickBlood.Title")}
          </div>
          <div className={styles.instruction__steps}>
            <div className={styles.instruction__firstStep}>
              <div className={styles.instruction__number}>1</div>
              <div className={styles.instruction__numberText}>
                {t("Product.Instruction.Step")}
              </div>
            </div>
            <div className={styles.instruction__secondStep}>
              <div className={styles.instruction__number}>2</div>
              <div className={styles.instruction__numberText}>
                {t("Product.Instruction.Step")}
              </div>
            </div>
            <div className={styles.instruction__thirdStep}>
              <div className={styles.instruction__number}>3</div>
              <div className={styles.instruction__numberText}>
                {t("Product.Instruction.Step")}
              </div>
            </div>
          </div>
          <div className={styles.instruction__howToDo}>
            <div className={styles.instruction__step}>
              <div className={styles.instruction__imageHolder}>
                <img
                  className={styles.instruction__image}
                  src={productWashArms}
                  alt="Wash arms"
                />
              </div>
              <div className={styles.instruction__text}>
                {t("Product.Instruction.FingerPrickBlood.Step1")}
              </div>
            </div>
            <div className={styles.instruction__step}>
              <div className={styles.instruction__imageHolder}>
                <img
                  className={styles.instruction__image}
                  src={productGetActivity}
                  alt="Get activity"
                />
              </div>
              <div className={styles.instruction__text}>
                {t("Product.Instruction.FingerPrickBlood.Step2")}
              </div>
            </div>
            <div className={styles.instruction__step}>
              <div className={styles.instruction__imageHolder}>
                <img
                  className={styles.instruction__image}
                  src={productCollectBlood}
                  alt="Collect blood"
                />
              </div>
              <div className={styles.instruction__text}>
                {t("Product.Instruction.FingerPrickBlood.Step3")}
              </div>
            </div>
            <img
              className={styles.instruction__arrow1}
              src={productArrow1}
              alt="Arrow"
            />
            <img
              className={styles.instruction__arrow2}
              src={productArrow1}
              alt="Arrow"
            />
          </div>
          <div className={styles.instruction__description}>
            {t("Product.Instruction.FingerPrickBlood.Description")}
          </div>
          <div className={styles.instruction__quote}>
            <img
              className={styles.instruction__quoteIcon}
              src={productQuotes}
              alt="Quotes"
            />
            <div className={styles.instruction__quoteTitle}>
              {t("Product.Instruction.FingerPrickBlood.QuoteTitle")}
            </div>
            <div className={styles.instruction__quoteSubtitle}>
              {t("Product.Instruction.FingerPrickBlood.QuoteSubtitle")}
            </div>
          </div>
        </div>
      )}
      {collectionMethod === CollectionMethod.SalivaSample && (
        <div className={styles.salivaInstruction}>
          <div className={styles.instruction__background}></div>
          <div className={styles.instruction__content}>
            <div className={styles.instruction__title}>
              {t("Product.Instruction.SalivaSample.Title")}
            </div>
            <div className={styles.instruction__subtitle}>
              {t("Product.Instruction.SalivaSample.Subtitle")}
            </div>
            <div className={styles.instruction__steps}>
              <div className={styles.instruction__firstStep}>
                <div className={styles.instruction__number}>1</div>
                <div className={styles.instruction__numberText}>
                  {t("Product.Instruction.Step")}
                </div>
              </div>
              <div className={styles.instruction__secondStep}>
                <div className={styles.instruction__number}>2</div>
                <div className={styles.instruction__numberText}>
                  {t("Product.Instruction.Step")}
                </div>
              </div>
              <div className={styles.instruction__thirdStep}>
                <div className={styles.instruction__number}>3</div>
                <div className={styles.instruction__numberText}>
                  {t("Product.Instruction.Step")}
                </div>
              </div>
            </div>
            <div className={styles.instruction__howToDo}>
              <div className={styles.instruction__step}>
                <div className={styles.instruction__imageHolder}>
                  <img
                    className={styles.instruction__image}
                    src={productOpenCollector}
                    alt="Open collector"
                  />
                </div>
                <div className={styles.instruction__text}>
                  {t("Product.Instruction.SalivaSample.Step1")}
                </div>
              </div>
              <div className={styles.instruction__step}>
                <div className={styles.instruction__imageHolder}>
                  <img
                    className={styles.instruction__image}
                    src={productScrewCollector}
                    alt="Screw collector"
                  />
                </div>
                <div className={styles.instruction__text}>
                  {t("Product.Instruction.SalivaSample.Step2")}
                </div>
              </div>
              <div className={styles.instruction__step}>
                <div className={styles.background}></div>
                <img className={styles.arrow} src={productArrow2} alt="Arrow" />
                <div className={styles.instruction__imageHolder}>
                  <img
                    className={styles.instruction__image}
                    src={productCollectSaliva}
                    alt="Collect saliva"
                  />
                </div>
                <div className={styles.instruction__text}>
                  {t("Product.Instruction.SalivaSample.Step3")}
                </div>
              </div>
              <img
                className={styles.instruction__arrow1}
                src={productArrow1}
                alt="Arrow"
              />
              <img
                className={styles.instruction__arrow2}
                src={productArrow1}
                alt="Arrow"
              />
            </div>
            <div className={styles.instruction__description}>
              {t("Product.Instruction.SalivaSample.Description")}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Instruction;
