import {
  ResultsActionTypes,
  ResultItemType,
  ResultsAction,
  CalculatedResult,
} from "types";

interface ResultsState {
  allUserResultsLoading: boolean;
  resultLoading: boolean;
  customResultsLoading: boolean;
  allUserResultsErrorMessage: string;
  resultError: string;
  customResultsError: string;
  allUserResults: ResultItemType[];
  result: CalculatedResult;
  customResults: ResultItemType[];
}

const initialState: ResultsState = {
  allUserResultsLoading: false,
  resultLoading: false,
  customResultsLoading: false,
  allUserResultsErrorMessage: "",
  resultError: "",
  customResultsError: "",
  allUserResults: [],
  result: {} as CalculatedResult,
  customResults: [],
};

export const resultsReducer = (
  state: ResultsState = initialState,
  action: ResultsAction
): ResultsState => {
  switch (action.type) {
    case ResultsActionTypes.PENDING_ALL_USER_RESULTS: {
      return {
        ...state,
        allUserResultsLoading: true,
      };
    }

    case ResultsActionTypes.FULFILLED_ALL_USER_RESULTS: {
      return {
        ...state,
        allUserResultsLoading: false,
        allUserResults: action.payload,
        allUserResultsErrorMessage: "",
      };
    }

    case ResultsActionTypes.REJECTED_ALL_USER_RESULTS: {
      return {
        ...state,
        allUserResultsLoading: false,
        allUserResultsErrorMessage: action.payload,
      };
    }

    case ResultsActionTypes.PENDING_RESULT: {
      return {
        ...state,
        resultLoading: true,
      };
    }

    case ResultsActionTypes.FULFILLED_RESULT: {
      return {
        ...state,
        resultLoading: false,
        result: action.payload,
        resultError: "",
      };
    }

    case ResultsActionTypes.REJECTED_RESULT: {
      return {
        ...state,
        resultLoading: false,
        resultError: action.payload,
      };
    }

    case ResultsActionTypes.PENDING_UPLOADED_RESULTS: {
      return {
        ...state,
        customResultsLoading: true,
      };
    }

    case ResultsActionTypes.FULFILLED_UPLOADED_RESULTS: {
      return {
        ...state,
        customResultsLoading: false,
        customResults: action.payload,
      };
    }

    case ResultsActionTypes.REJECTED_UPLOADED_RESULTS: {
      return {
        ...state,
        customResultsLoading: false,
        customResultsError: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
