import * as React from "react";

import styles from "./NumericQuestion.module.scss";

import Radio from "components/Utilities/RadioButton";
import { NumericQuestionType } from "types";

type Props = {
  activeIndex: number | null;
  changeActive: (value: number) => void;
  question: NumericQuestionType;
};

const NumericQuestion = ({
  activeIndex,
  changeActive,
  question,
}: Props): JSX.Element => {
  return (
    <div className={styles.numeric}>
      <div className={styles.numeric__minValue}>
        {question.minValueDescriptionT}
      </div>
      <div className={styles.numeric__options}>
        {Array(10)
          .fill(0)
          .map((el, index) => (
            <label
              className={styles.numeric__label}
              onClick={() => changeActive(index + 1)}
              key={index}
            >
              <div className={styles.numeric__number}>{index + 1}</div>
              <Radio
                checked={activeIndex === index + 1}
                setChecked={() => changeActive(index + 1)}
                id="index"
                size={20}
              />
            </label>
          ))}
      </div>
      <div className={styles.numeric__maxValue}>
        {question.maxValueDescriptionT}
      </div>
      <div className={styles.numeric__valuesMobile}>
        <div className={styles.numeric__minValueMobile}>
          {question.minValueDescriptionT}
        </div>
        <div className={styles.numeric__maxValueMobile}>
          {question.maxValueDescriptionT}
        </div>
      </div>
    </div>
  );
};

export default NumericQuestion;
