import React, { useEffect, useState, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CookieConsent from "react-cookie-consent";
import { hotjar } from "react-hotjar";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

import style from "./App.module.scss";

import { useAppDispatch, useTypedSelector } from "hooks";
import httpService from "services/httpService";
import Header from "components/Header";
import LoginPage from "components/Login";
import SignupPage from "components/Signup";
import ResetPasswordPage from "components/ResetPassword";
import DashboardPage from "components/Dashboard";
import ResultPage from "components/Result";
import Footer from "components/Footer";
import MainPage from "components/MainPage";
import Product from "components/Product";
import ShoppingCart from "components/ShoppingCart";
import PrivateRoute from "components/PrivateRoute";
import PublicRoute from "components/PublicRoute";
import Checkout from "components/Checkout";
import MediaQueryProvider from "components/MediaQueryProvider";
import Profile from "components/Profile";
import Spinner from "components/Spinner";
import config from "config";
import PrivacyPolicy from "components/PrivacyPolicy";
import TermsOfUse from "components/TermsOfUse";
import FAQ from "components/Product/FAQ";
import ContactsPage from "components/ContactsPage";
import KitPurchaseRedirect from "components/KitPurchase";
import KitPurchaseChoice from "components/KitPurchase/Choice";
import IdentificationCompleted from "components/Identification/IdentificationCompleted";
import IdentificationProcessing from "components/Identification/IdentificationProcessing";
import ZendeskChat from "components/ZendeskChat";
import SurveyList from "components/Survey";
import SurveyPage from "components/Survey/SurveyPage";
import TroubleDocs from "components/TroubleDocs";
import Burnout from "components/TroubleDocs/Burnout";
import BurnoutSymptoms from "components/TroubleDocs/BurnoutSymptoms";
import CortisolActivity from "components/TroubleDocs/CortisolActivity";
import StressResistance from "components/TroubleDocs/StressResistance";
import BurnoutCompensation from "components/TroubleDocs/BurnoutCompensation";
import BurnoutSymptomsQuantity from "components/TroubleDocs/BurnoutSymptomsQuantity";
import BurnoutButtery from "components/TroubleDocs/BurnoutButtery";
import WrongMarketPopup from "components/Utilities/Popups/WrongMarketPopup";
import { fetchTestPackages, setAuthorized, getConsents } from "store/actions";
import OpayPaymentResult from "components/Checkout/OpayPaymentResult";
import { Country, Language } from "types";
import { LanguageContext } from "context";
import i18next from "internationalization";
import ForBusinessPage from "components/ForBusinessPage";
import { getDepartmentsList, getMarkets } from "store/thunks";
import { setCurrentMarket } from "store/slices";
import ContactInfo from "components/CardiacRisk/ContactInfo";
import { Result } from "components/CardiacRisk/Result";
import { CardiacRisk } from "components/CardiacRisk";
import TestUploadResult from "components/CardiacRisk/TestUploadResult";
import CardiacRiskSurvey from "../CardiacRisk/CardiacRiskSurvey";

function App(): JSX.Element {
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(Language.lt);
  const stripePromise = loadStripe(config.stripePublishableKey);
  const { isLoadingTestPackages, items, fetchTestPackagesError } =
    useTypedSelector((state) => state.testPackages);
  const { consentsLoading, consents, consentsError } = useTypedSelector(
    (state) => state.consents
  );
  const { currentMarket, stayOnIncorrectMarket } = useTypedSelector(
    (state) => state.markets
  );
  const { user } = useTypedSelector((state) => state.user);
  const { departmentsLoading, departmentsList, departmentsError } =
    useTypedSelector((state) => state.omnivaDepartments);
  const { marketsLoading, markets } = useTypedSelector(
    (state) => state.markets
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!marketsLoading && markets.length === 0) {
      dispatch(getMarkets());
    }
  }, [dispatch, marketsLoading, markets.length]);

  useEffect(() => {
    const redirectLink = localStorage.getItem("redirectLink");

    if (redirectLink && location.pathname) {
      localStorage.removeItem("redirectLink");
    }
  }, []);

  useEffect(() => {
    if (markets.length !== 0) {
      dispatch(
        setCurrentMarket(
          markets.find((market) =>
            window.location.origin.includes(market.domain)
          ) || markets[0]
        )
      );
    }
  }, [dispatch, markets]);

  useEffect(() => {
    const language = JSON.parse(localStorage.getItem("language") || "{}");
    const defaultLanguage =
      Object.keys(currentMarket).length === 0
        ? Language.en
        : currentMarket.languages[0];

    if (language in Language) {
      i18next.changeLanguage(language);
      httpService.setLanguageHeader(language);
      setCurrentLanguage(language);
    } else {
      localStorage.setItem("language", JSON.stringify(defaultLanguage));
      i18next.changeLanguage(defaultLanguage);
      httpService.setLanguageHeader(defaultLanguage);
    }
  }, [dispatch, currentMarket]);

  useEffect(() => {
    if (
      items.length === 0 &&
      !fetchTestPackagesError &&
      Object.keys(currentMarket).length !== 0
    ) {
      dispatch(fetchTestPackages(currentLanguage, currentMarket.id));
    }
  }, [
    dispatch,
    fetchTestPackagesError,
    currentLanguage,
    items.length,
    currentMarket,
  ]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token") || "{}");
    if (token.accessToken) {
      dispatch(setAuthorized(true));
      httpService.setAuthHeader(
        `${token.accessTokenType} ${token.accessToken}`
      );
    }
    setIsLoaded(true);
  }, [dispatch]);

  useEffect(() => {
    if (config.appEnvironment === "production") {
      hotjar.initialize(2526438, 6);
      ReactPixel.init("420491239599427", undefined, {
        autoConfig: true,
        debug: false,
      });
      ReactPixel.pageView();
      TagManager.initialize({
        gtmId: config.googleTagManagerId,
      });
    }
  }, []);

  useEffect(() => {
    if (!consentsLoading && consents.length === 0 && !consentsError) {
      dispatch(getConsents());
    }
  }, [dispatch, consentsLoading, consents, consentsError]);

  useEffect(() => {
    if (departmentsList.length === 0 && !departmentsError) {
      dispatch(getDepartmentsList());
    }
  }, [departmentsError, departmentsList.length, dispatch]);

  useEffect(() => {
    const language = new URLSearchParams(location.search).get("language");
    if (
      language &&
      language in Language &&
      currentMarket.languages &&
      currentMarket.languages.includes(language as Language)
    ) {
      i18next.changeLanguage(language);
      httpService.setLanguageHeader(language as Language);
      setCurrentLanguage(language as Language);
      localStorage.setItem("language", JSON.stringify(language));
    }
  }, [currentMarket.languages]);

  if (!isLoaded || isLoadingTestPackages || departmentsLoading) {
    return <Spinner />;
  }

  return (
    <Suspense fallback="loading">
      <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
        <MediaQueryProvider>
          <Elements stripe={stripePromise}>
            <div className={style.app}>
              <Header />
              <ZendeskChat />
              {isLoaded &&
                user.market &&
                user.market !== currentMarket.id &&
                !stayOnIncorrectMarket && <WrongMarketPopup />}
              {currentMarket.country === Country.LT && (
                <CookieConsent
                  buttonText="Sutinku"
                  containerClasses={style.cookieConsentContainer}
                  buttonClasses={style.cookieConsentButton}
                >
                  Mūsų interneto svetainėje yra naudojami slapukai. Paspaudę
                  „sutinku“ Jūs sutinkate su slapukų įdiegimu ir naudojimu. Savo
                  sutikimą galėsite atšaukti bet kuriuo metu, pakeisdami savo
                  interneto naršyklės nustatymus ir ištrindami įrašytus
                  slapukus. Daugiau informacijos -{" "}
                  <Link
                    to={"/privacypolicy#cookies"}
                    style={{ color: "#868686" }}
                  >
                    privatumo politikoje.
                  </Link>
                </CookieConsent>
              )}
              <Switch>
                <Route exact path="/">
                  <MainPage />
                </Route>
                <PublicRoute component={LoginPage} path="/login" exact />
                <PublicRoute component={SignupPage} path="/signup" exact />
                <PublicRoute
                  component={ResetPasswordPage}
                  path="/resetpassword"
                  exact
                />
                <PrivateRoute
                  component={DashboardPage}
                  path="/dashboard"
                  exact
                />
                <PrivateRoute
                  component={ResultPage}
                  path="/result/:resultId/"
                  exact
                />
                <Route exact path="/product/:productId">
                  <Product />
                </Route>
                <PrivateRoute
                  component={ShoppingCart}
                  path="/user/cart"
                  exact
                />
                <PrivateRoute component={Checkout} path="/checkout" exact />
                <PrivateRoute
                  component={OpayPaymentResult}
                  path="/checkout/opay/result/"
                  exact
                />
                <PrivateRoute component={Profile} path="/user/profile" />
                <Route path="/privacypolicy">
                  <PrivacyPolicy />
                </Route>
                <Route path="/termsofuse">
                  <TermsOfUse />
                </Route>
                <Route path="/faq">
                  <FAQ />
                </Route>
                <Route path="/contacts">
                  <ContactsPage />
                </Route>
                <Route path="/kitpurchase/:hash">
                  <KitPurchaseRedirect />
                </Route>
                <Route path="/kitpurchase_choice">
                  <KitPurchaseChoice />
                </Route>
                <Route exact path="/forbusiness">
                  <ForBusinessPage />
                </Route>
                <Route exact path="/identification-succeeded">
                  <IdentificationCompleted />
                </Route>
                <Route exact path="/identification-submitted">
                  <IdentificationProcessing />
                </Route>
                <Route exact path="/surveys">
                  <SurveyList />
                </Route>
                <Route path="/survey/cardiac-risk-assessment">
                  <CardiacRisk />
                </Route>
                <Route path="/survey/:surveyAlias/:surveyCampaign?">
                  <SurveyPage />
                </Route>
                <Route path="/cardiac-risk-assessment/contact-info">
                  <ContactInfo />
                </Route>
                <Route path="/cardiac-risk-assessment/questions">
                  <CardiacRiskSurvey />
                </Route>
                <Route path="/cardiac-risk-assessment/upload-file">
                  <TestUploadResult />
                </Route>
                <Route path="/cardiac-risk-assessment/result">
                  <Result success={true} />
                </Route>
                <Route path="/cardiac-risk-assessment/result-canceled">
                  <Result success={false} />
                </Route>
                <Route exact path="/docs">
                  <TroubleDocs />
                </Route>
                <Route path="/docs/burnout">
                  <Burnout />
                </Route>
                <Route path="/docs/burnoutsymptoms">
                  <BurnoutSymptoms />
                </Route>
                <Route path="/docs/cortisolactivity">
                  <CortisolActivity />
                </Route>
                <Route path="/docs/stressresistance">
                  <StressResistance />
                </Route>
                <Route path="/docs/burnoutcompensation">
                  <BurnoutCompensation />
                </Route>
                <Route path="/docs/burnoutsymptomsquantity">
                  <BurnoutSymptomsQuantity />
                </Route>
                <Route path="/docs/burnoutbuttery">
                  <BurnoutButtery />
                </Route>
              </Switch>
              <Footer />
            </div>
          </Elements>
        </MediaQueryProvider>
      </LanguageContext.Provider>
    </Suspense>
  );
}

export default App;
