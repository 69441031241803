import { Stripe } from "@stripe/stripe-js";
import { Dispatch } from "redux";

import httpService from "services/httpService";
import {
  DeliveryInformation,
  OrdersActionTypes,
  PaymentSystem,
  PaymentInformationDTO,
  CreateOrderResult,
  ConsentsDTO,
  DeliverySystem,
  KitPurchaseOrderDTO,
} from "types";

const fetchOrders = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: OrdersActionTypes.PENDING_ORDERS,
    });
    try {
      const orders = await httpService.get("/order/");
      dispatch({
        type: OrdersActionTypes.FULFILLED_ORDERS,
        payload: orders,
      });
    } catch (e: any) {
      dispatch({
        type: OrdersActionTypes.REJECTED_ORDERS,
        payload: e.message,
      });
    }
  };
};

const getOrderPaymentDetails = (orderId: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: OrdersActionTypes.PENDING_PAYMENT_DETAILS,
    });
    try {
      const paymentDetails = await httpService.get(
        `/order/${orderId}/payment-details`
      );

      dispatch({
        type: OrdersActionTypes.FULFILLED_PAYMENT_DETAILS,
        payload: paymentDetails,
      });
    } catch (error: any) {
      dispatch({
        type: OrdersActionTypes.REJECTED_PAYMENT_DETAILS,
        payload: error.message,
      });
    }
  };
};

const createOrder = (
  paymentInformation: PaymentInformationDTO,
  deliverySystem: DeliverySystem,
  deliveryInformation: DeliveryInformation,
  promoCodeId: string | undefined,
  paymentSystem: PaymentSystem,
  childId: string | undefined,
  consents: ConsentsDTO | Record<string, boolean>,
  stripe?: Stripe
) => {
  return async (dispatch: Dispatch): Promise<CreateOrderResult> => {
    dispatch({
      type: OrdersActionTypes.PENDING_CREATE_ORDER,
    });
    try {
      const response = await httpService.post("/order/", {
        body: JSON.stringify({
          paymentInformation,
          deliverySystem,
          deliveryInformation,
          paymentSystem,
          promoCodeId: promoCodeId || undefined,
          childId,
          consents,
        }),
      });

      const {
        clientSecret,
        requiresAction,
        opayEncodedString,
        identityVerificationUrl,
        calendlyEventUrl,
      } = response;
      const createOrderResult = { isSuccess: false, opayEncodedString };

      if (!requiresAction) {
        dispatch({
          type: OrdersActionTypes.FULFILLED_CREATE_ORDER,
          payload: { identityVerificationUrl, calendlyEventUrl },
        });
        createOrderResult.isSuccess = true;
      }
      if (clientSecret && requiresAction && stripe) {
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          clientSecret
        );

        if (paymentIntent && paymentIntent.status === "succeeded") {
          dispatch({
            type: OrdersActionTypes.FULFILLED_CREATE_ORDER,
            payload: { identityVerificationUrl, calendlyEventUrl },
          });
          createOrderResult.isSuccess = true;
        } else if (error) {
          dispatch({
            type: OrdersActionTypes.REJECTED_CREATE_ORDER,
            payload: { cardError: "User canceled payment" },
          });
        }
      } else {
        dispatch({
          type: OrdersActionTypes.REJECTED_CREATE_ORDER,
          payload: { cardError: "User canceled payment" },
        });
      }
      return createOrderResult;
    } catch (e: any) {
      console.error("CREATING ORDER ERROR:", e);

      dispatch({
        type: OrdersActionTypes.REJECTED_CREATE_ORDER,
        payload: { cardError: e.errors.message },
      });

      return { isSuccess: false, opayEncodedString: "" };
    }
  };
};

const clearCreateOrderState = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: OrdersActionTypes.CLEAR_CREATE_ORDER_STATE,
    });
  };
};

const storeKitPurchaseHash = (orderHash: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: OrdersActionTypes.STORE_KIT_PURCHASE_HASH,
      payload: orderHash,
    });
  };
};

const createKitPurchaseOrder = (orderData: KitPurchaseOrderDTO) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: OrdersActionTypes.PENDING_CREATE_KIT_PURCHASE_ORDER,
    });
    try {
      const response = await httpService.post("/order/kit-purchase/", {
        body: JSON.stringify(orderData),
      });

      const { identityVerificationUrl, calendlyEventUrl } = response;

      dispatch({
        type: OrdersActionTypes.FULFILLED_CREATE_KIT_PURCHASE_ORDER,
        payload: { identityVerificationUrl, calendlyEventUrl },
      });
    } catch (e: any) {
      console.error("CREATING KIT PURCHASE ORDER ERROR:", e);

      dispatch({
        type: OrdersActionTypes.REJECTED_CREATE_KIT_PURCHASE_ORDER,
        payload: e.errors.message,
      });
    }
  };
};

const clearHash = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: OrdersActionTypes.CLEAR_HASH,
    });
  };
};

const setNeedCalendlyVerification = (value: boolean) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: OrdersActionTypes.SET_NEED_CALENDLY_VERIFICATION,
      payload: value,
    });
  };
};

export {
  fetchOrders,
  getOrderPaymentDetails,
  createOrder,
  clearCreateOrderState,
  storeKitPurchaseHash,
  createKitPurchaseOrder,
  clearHash,
  setNeedCalendlyVerification,
};
