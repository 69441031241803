import React, { useContext, useEffect } from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
  VictoryLabel,
  VictoryContainer,
} from "victory";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";

dayjs.extend(dayOfYear);

import style from "./Chart.module.scss";

import { useMediaQueryContext } from "components/MediaQueryProvider";
import ColorAxis from "components/Chart/ColorAxis";
import CustomSelect from "components/CustomSelect";
import { CalculatedResult, ColorValues, FontFamily } from "types";
import chartService from "services/chartService";
import config from "config";
import { translateObject } from "helpers";
import { useAppDispatch, useTypedSelector } from "hooks";
import {
  setCurrentTestId,
  setCurrentYear,
  updateState,
  clearChartState,
} from "store/slices";
import ResizableSpinner from "components/ResizableSpinner";
import { LanguageContext } from "context";

type Props = {
  result: CalculatedResult;
};

function Chart({ result }: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    currentTestResults,
    currentTestId,
    currentYear,
    isLoadedResult,
    ranges,
    tickValues,
  } = useTypedSelector((state) => state.chart);
  const { isMobile } = useMediaQueryContext();
  const dispatch = useAppDispatch();
  const { currentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(updateState(result));

    return () => {
      dispatch(clearChartState());
    };
  }, [dispatch, result]);

  if (!isLoadedResult) {
    return (
      <div className={style.wrapper}>
        <ResizableSpinner />
      </div>
    );
  }

  return (
    <div className={style.wrapper}>
      <div className={style.headerWrapper}>
        <CustomSelect
          items={chartService.prepareTestsOptionItems(result)}
          header={t("Result.Research")}
          changeHandler={(e) => {
            dispatch(
              setCurrentTestId({
                result,
                currentTestId: e.value,
              })
            );
          }}
          value={currentTestId}
        />
        <CustomSelect
          items={chartService.prepareYearsOptionItems(
            result.testResults[currentTestId].chart
          )}
          header={t("Result.Year")}
          changeHandler={(e) => {
            dispatch(
              setCurrentYear({
                result,
                currentYear: parseInt(e.value, 10),
              })
            );
          }}
          value={currentYear.toString()}
        />
      </div>
      <div className={style.chartWrapper}>
        <VictoryChart
          containerComponent={<VictoryContainer responsive={false} />}
          width={isMobile ? 375 : 1160}
          height={isMobile ? 240 : 346}
          padding={isMobile ? 24 : 32}
          maxDomain={{ y: ranges[ranges.length - 1].max }}
          minDomain={{ y: ranges[0].min }}
        >
          <VictoryAxis
            padding={10}
            axisComponent={<ColorAxis ranges={ranges} />}
            dependentAxis
            tickLabelComponent={
              <VictoryLabel dx={isMobile ? 12 : 32} verticalAnchor="middle" />
            }
            offsetX={30}
            tickValues={tickValues}
            style={{
              tickLabels: {
                fontSize: `${isMobile ? "12px" : "16px"}`,
                fontFamily: FontFamily.rubik,
                fill: ColorValues.grey,
              },
            }}
          />
          <VictoryAxis
            tickValues={config.chartMonthTicksValues}
            tickFormat={translateObject(
              isMobile
                ? config.chartMonthTicksNames.mobile
                : config.chartMonthTicksNames.desktop,
              currentLanguage
            )}
            domain={[-30, 365]}
            style={{
              axis: { stroke: "transparent" },
              tickLabels: {
                fontSize: `${isMobile ? "12px" : "16px"}`,
                fontFamily: FontFamily.rubik,
                fill: ColorValues.grey,
              },
            }}
          />
          {tickValues.map((tick) => {
            return (
              <VictoryLine
                key={tick}
                style={{
                  data: { stroke: ColorValues.lightGrey, strokeWidth: 1 },
                }}
                data={[
                  { x: 0, y: tick },
                  { x: 365, y: tick },
                ]}
              />
            );
          })}
          <VictoryLine
            data={currentTestResults}
            interpolation="catmullRom"
            y="result"
            x={(d) => dayjs(d.date).dayOfYear()}
          />
          <VictoryScatter
            style={{ data: { fill: ColorValues.black } }}
            size={4}
            data={currentTestResults}
            labels={({ datum }) => datum.result}
            labelComponent={
              <VictoryLabel
                renderInPortal
                dy={-10}
                style={[
                  {
                    fontFamily: FontFamily.rubik,
                    fontSize: `${isMobile ? "12px" : "16px"}`,
                  },
                ]}
              />
            }
            y="result"
            x={(d) => dayjs(d.date).dayOfYear()}
          />
        </VictoryChart>
      </div>
    </div>
  );
}

export default Chart;
