import * as React from "react";
import ProgressBar from "react-customizable-progressbar";
import { useTranslation } from "react-i18next";

import styles from "./UploadPopup.module.scss";

import { addFile, cancel, success } from "assets/image";
import { useAppDispatch, useTypedSelector } from "hooks";
import { isFileEncrypted, isFilePasswordCorrect, filterFiles } from "helpers";
import CustomInput from "components/CustomInput";
import { clearUploadState, setIsUploadFileEncrypted } from "store/slices";
import { uploadFiles } from "store/thunks";

type Props = {
  close: () => void;
};

const UploadPopup = ({ close }: Props): JSX.Element => {
  const [progress, setProgress] = React.useState(1);
  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);
  const [filePassword, setFilePassword] = React.useState("");
  const [filePasswordError, setFilePasswordError] =
    React.useState<string | undefined>(undefined);
  const {
    fileUploaded,
    uploadFileLoading,
    uploadFileLoadingError,
    isUploadFileEncrypted,
  } = useTypedSelector((state) => state.upload);
  const { customResultsLoading } = useTypedSelector((state) => state.results);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (uploadFileLoading) {
      const timer = setTimeout(() => {
        if (progress < 99) {
          setProgress((progress) => progress + 1);
        }
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [uploadFileLoading, progress]);

  return (
    <div className={styles.uploadPopup}>
      <div className={styles.uploadPopup__background}></div>
      <div className={styles.uploadPopup__block}>
        <div className={styles.uploadPopup__head}>
          <div className={styles.uploadPopup__title_main}>
            {t("Dashboard.Upload")}
          </div>
          <div
            className={styles.uploadPopup__closeIcon}
            onClick={() => {
              close();
              dispatch(clearUploadState());
            }}
          ></div>
        </div>
        {(uploadFileLoading || customResultsLoading) && (
          <div className={styles.uploadPopup__loadingBlock}>
            <ProgressBar
              radius={50}
              progress={progress}
              strokeWidth={6}
              strokeColor="#45cb85"
              trackStrokeColor="#e6e8f1"
              strokeLinecap="square"
              trackStrokeWidth={6}
            >
              <div
                className={styles.uploadPopup__loadingProgress}
              >{`${progress}%`}</div>
            </ProgressBar>
            <div className={styles.uploadPopup__loadingText}>
              {t("Dashboard.Loading")}
            </div>
          </div>
        )}
        {isUploadFileEncrypted &&
          !uploadFileLoading &&
          !customResultsLoading &&
          !uploadFileLoadingError &&
          !fileUploaded && (
            <div className={styles.uploadPopup__passwordBlock}>
              <span className={styles.uploadPopup__passwordBlock__title}>
                {t("Dashboard.PasswordTitle")}
              </span>
              <div
                className={styles.uploadPopup__passwordBlock__inputContainer}
              >
                <CustomInput
                  contentContainerStyle="bigInput"
                  text={""}
                  value={filePassword}
                  error={filePasswordError}
                  touched={!!filePasswordError}
                  verticalError={true}
                  changeHandler={(e) => setFilePassword(e.target.value)}
                />
              </div>
              <div
                className={styles.uploadPopup__passwordBlock__button}
                onClick={async () => {
                  if (uploadedFile) {
                    const isPasswordCorrect = await isFilePasswordCorrect(
                      uploadedFile,
                      filePassword
                    );
                    if (isPasswordCorrect) {
                      dispatch(
                        uploadFiles({
                          file: uploadedFile,
                          pdfPassword: filePassword,
                        })
                      );
                    } else {
                      setFilePasswordError("InvalidPassword");
                    }
                  }
                }}
              >
                {t("Dashboard.PasswordSubmitButton")}
              </div>
              <div
                className={styles.uploadPopup__passwordBlock__button}
                onClick={() => {
                  dispatch(clearUploadState());
                  setUploadedFile(null);
                  setFilePassword("");
                  setFilePasswordError(undefined);
                }}
              >
                {t("Dashboard.CancelButton")}
              </div>
            </div>
          )}
        {fileUploaded && !uploadFileLoadingError && (
          <div className={styles.uploadPopup__successBlock}>
            <img
              className={styles.uploadPopup__successImage}
              src={success}
              alt="Success"
            />
            <div className={styles.uploadPopup__successTitle}>
              {t("Dashboard.UploadSuccessful")}
            </div>
            <div className={styles.uploadPopup__successText}>
              {t("Dashboard.UploadSuccessfulText")}
            </div>
          </div>
        )}
        {!fileUploaded && uploadFileLoadingError && (
          <div className={styles.uploadPopup__errorBlock}>
            <img
              className={styles.uploadPopup__errorImage}
              src={cancel}
              alt="Error"
            />
            <div className={styles.uploadPopup__errorTitle}>
              {t("Dashboard.UploadFailed")}
            </div>
            <div className={styles.uploadPopup__errorText}>
              {(uploadFileLoadingError ? t(uploadFileLoadingError) : "") ||
                t("Dashboard.UploadFailedText")}
            </div>
          </div>
        )}
        {!uploadFileLoading &&
          !fileUploaded &&
          !uploadFileLoadingError &&
          !isUploadFileEncrypted && (
            <div className={styles.uploadPopup__dropBlock}>
              <img
                className={styles.uploadPopup__image}
                src={addFile}
                alt="File"
              />
              <div className={styles.uploadPopup__title}>
                {t("Dashboard.UploadText")}
              </div>
              <div className={styles.uploadPopup__subTitle}>
                {t("Dashboard.Partners")}
              </div>
              <label
                className={styles.uploadPopup__button}
                htmlFor="fileUpload"
              >
                {t("Dashboard.ButtonUpload")}
              </label>
              <input
                className={styles.uploadPopup__input}
                id="fileUpload"
                type="file"
                name="file"
                accept=".pdf"
                tabIndex={-1}
                onChange={async (e) => {
                  const fileToUpload = filterFiles(
                    e.target.files ? e.target.files[0] : null
                  );

                  setUploadedFile(fileToUpload);

                  if (fileToUpload) {
                    const isEncrypted = await isFileEncrypted(fileToUpload);
                    if (!isEncrypted) {
                      dispatch(uploadFiles({ file: fileToUpload }));
                    } else {
                      dispatch(setIsUploadFileEncrypted(isEncrypted));
                    }
                  }
                }}
              />
              <div className={styles.uploadPopup__hint}>
                {t("Dashboard.Formats")}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default UploadPopup;
