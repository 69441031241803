import * as React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "formik";

import styles from "./ChildPersonalDataBlock.module.scss";
import CustomInput from "components/CustomInput";
import { useTypedSelector } from "hooks";
import { CheckoutFormDataAndFunctions } from "types";

type Props = {
  formDataAndFunctions: CheckoutFormDataAndFunctions;
  dateError: string;
  validateChildDateError: (inputs: {
    childYear: string;
    childMonth: string;
    childDay: string;
  }) => void;
};

const ChildPersonalDataBlock = ({
  formDataAndFunctions,
  dateError,
  validateChildDateError,
}: Props): JSX.Element => {
  const { values, errors, touched, handleChange, setValues } =
    formDataAndFunctions;
  const { createOrderErrorObject } = useTypedSelector((state) => state.orders);
  const birthdayError =
    errors.childYear || errors.childMonth || errors.childDay;
  const { t } = useTranslation();

  return (
    <div className={styles.block}>
      <>
        <div className={styles.block__head}>
          <div className={styles.block__headText}>
            {t("Checkout.UserInfo.ChildDetails")}
          </div>
        </div>
        <div className={styles.flexWrapper}>
          <div className={styles.block__detailsContent}>
            <CustomInput
              contentContainerStyle="mediumInput"
              text={t("Checkout.UserInfo.ChildFirstName")}
              value={values.childFirstName}
              error={
                errors.childFirstName || createOrderErrorObject.childFirstName
              }
              verticalError={true}
              touched={touched.childFirstName}
              changeHandler={handleChange("childFirstName")}
            />
            <CustomInput
              contentContainerStyle="mediumInput"
              text={t("Checkout.UserInfo.ChildLastName")}
              value={values.childLastName}
              error={
                errors.childLastName || createOrderErrorObject.childLastName
              }
              verticalError={true}
              touched={touched.childLastName}
              changeHandler={handleChange("childLastName")}
            />
            <div className={styles.block__inputWrapper}>
              <span className={styles.block__inputText}>
                {t("Signup.BirthDate")}
              </span>
              <div
                className={
                  (touched.childYear && errors.childYear) ||
                  (touched.childMonth && errors.childMonth) ||
                  (touched.childDay && errors.childDay) ||
                  dateError
                    ? `${styles.birthDate} ${styles.birthDate_hasError}`
                    : styles.birthDate
                }
              >
                <input
                  className={styles.birthDate__input}
                  placeholder={t("Signup.Year")}
                  value={values.childYear}
                  maxLength={4}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      childYear: e.target.value,
                    });

                    validateChildDateError({
                      childYear: e.target.value,
                      childMonth: values.childMonth,
                      childDay: values.childDay,
                    });
                  }}
                />
                <input
                  className={styles.birthDate__input}
                  placeholder={t("Signup.Month")}
                  value={values.childMonth}
                  onChange={handleChange("childMonth")}
                  maxLength={2}
                />
                <input
                  className={styles.birthDate__input}
                  placeholder={t("Signup.Day")}
                  value={values.childDay}
                  onChange={handleChange("childDay")}
                  maxLength={2}
                />
              </div>
              <div
                className={
                  (touched.childYear && errors.childYear) ||
                  (touched.childMonth && errors.childMonth) ||
                  (touched.childDay && errors.childDay) ||
                  dateError
                    ? styles.block__birthDateError
                    : styles.hidden
                }
              >
                {dateError || (birthdayError && t(birthdayError))}
              </div>
            </div>
            <CustomInput
              contentContainerStyle="mediumInput"
              text={t("Checkout.UserInfo.ChildPersonalCode")}
              subText={t("Checkout.UserInfo.ChildPersonalCodeSubText")}
              value={values.childIdentificationCode}
              error={
                errors.childIdentificationCode ||
                createOrderErrorObject.childIdentificationCode
              }
              verticalError={true}
              touched={touched.childIdentificationCode}
              changeHandler={handleChange("childIdentificationCode")}
            />
            <div className={styles.block__sexWrapper}>
              <span className={styles.block__inputText}>
                {t("Checkout.UserInfo.Sex")}
              </span>
              <div className={styles.block__sexRadioWrapper}>
                <Field
                  type="radio"
                  className={styles.block__sexRadio}
                  id="female"
                  name="childSex"
                  value="female"
                />
                <label htmlFor="female" className={styles.block__sexLabel}>
                  {t("Checkout.UserInfo.Female")}
                </label>
                <Field
                  type="radio"
                  className={styles.block__sexRadio}
                  id="male"
                  name="childSex"
                  value="male"
                />
                <label htmlFor="male" className={styles.block__sexLabel}>
                  {t("Checkout.UserInfo.Male")}
                </label>
              </div>
              <div
                className={
                  touched.childSex && errors.childSex
                    ? `${styles.block__error} ${styles.block__error__sex}`
                    : styles.hidden
                }
              >
                {touched.childSex && errors.childSex && t(errors.childSex)}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ChildPersonalDataBlock;
