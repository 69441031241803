import { Consent, ConsentsAction, ConsentsActionTypes } from "types";

interface ConsentsState {
  consentsLoading: boolean;
  consents: Consent[];
  consentsError: string;
}

const initialState: ConsentsState = {
  consentsLoading: false,
  consents: [],
  consentsError: "",
};

export const consentsReducer = (
  state: ConsentsState = initialState,
  action: ConsentsAction
): ConsentsState => {
  switch (action.type) {
    case ConsentsActionTypes.PENDING_CONSENTS: {
      return {
        ...state,
        consentsLoading: true,
      };
    }

    case ConsentsActionTypes.FULFILLED_CONSENTS: {
      return {
        ...state,
        consentsLoading: false,
        consents: action.payload,
        consentsError: "",
      };
    }

    case ConsentsActionTypes.REJECTED_CONSENTS: {
      return {
        ...state,
        consentsLoading: false,
        consentsError: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
