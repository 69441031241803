import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./WrongMarketPopup.module.scss";
import { useAppDispatch, useTypedSelector } from "hooks";
import { setStayOnIncorrectMarket } from "store/slices/markets";

const WrongMarketPopup = (): JSX.Element => {
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.background}
        onClick={() => dispatch(setStayOnIncorrectMarket(true))}
      ></div>
      <div className={styles.popup}>
        <div
          className={styles.popup__closeIcon}
          onClick={() => dispatch(setStayOnIncorrectMarket(true))}
        ></div>
        <div className={styles.popup__title}>
          {t("Utilities.WrongMarketPopup.Title")}
        </div>
        <div className={styles.popup__buttons}>
          <a
            href={`https://${currentMarket.domain}`}
            className={styles.popup__continueButton}
          >
            {t("Utilities.WrongMarketPopup.ContinueButton")}
          </a>
          <div
            className={styles.popup__closeButton}
            onClick={() => dispatch(setStayOnIncorrectMarket(true))}
          >
            {t("Utilities.WrongMarketPopup.CloseButton")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WrongMarketPopup;
