import React from "react";

import style from "./TermsOfUse.module.scss";

import config from "config";

function TermsOfUse(): JSX.Element {
  const { onlineDisputeResolutionLink } = config;

  return (
    <div className={style.wrapper}>
      <span className={style.header}>Tinklapio naudojimo sąlygos</span>
      <p className={style.text}>
        <b>UAB „REVOLAB“ KLIENTŲ SĄLYGOS</b>
      </p>
      <p className={style.text}>Paskutinį kartą atnaujinta [2022-06-27]</p>
      <p className={style.text}>
        Šios bendrovės <b>„REVOLAB“ klientų sąlygos</b> nustato tai, kaip jūs
        užsakote, o mes teikiame paslaugas, siūlomas tinklapyje{" "}
        <b>www.revolab.eu</b>
        (toliau – <b>Tinklapis</b>) ir mobiliojoje programėlėje, kurią valdo{" "}
        <b>UAB „REVOLAB“</b> (toliau – <b>mes</b>).
      </p>
      <p className={style.text}>
        <b>
          PRAŠOME ATIDŽIAI PERSKAITYTI ŠIAS SĄLYGAS. PATEIKDAMI MUMS PASLAUGŲ
          UŽSAKYMĄ, JŪS SUTINKATE VADOVAUTIS ŠIOMIS SĄLYGOMIS. JEI ŠIOMIS
          SĄLYGOMIS VADOVAUTIS NEPAGEIDAUJATE, JOKIŲ PASLAUGŲ UŽSAKYMŲ MUMS
          TEIKTI NEGALITE.
        </b>
      </p>
      <p className={style.text}>
        <b>
          Jūs patvirtinate ir sutinkate, kad Paslaugos nepakeičia skubiosios
          medicininės pagalbos, faktinės medicininės diagnozės arba gydytojo
          konsultacijos, o Ataskaitoje esanti informacija nėra medicininiai
          patarimai ir (arba) rekomendacijos dėl medicininio gydymo, todėl
          negalite ja vadovautis šia prasme.
        </b>
      </p>
      <p className={style.text}>
        <b>INFORMACIJA APIE MUS IR BŪDAI SU MUMIS SUSISIEKTI</b>
      </p>
      <p className={style.text}>
        1. Mes esame Vilniuje (Lietuva) įregistruota bendrovė. Mūsų įmonės kodas
        yra 305579878, registruotosios buveinės adresas – J.Basanavičiaus g. 26,
        Vilnius
      </p>
      <p className={style.text}>
        2. Su mumis susisiekti galite el. paštu{" "}
        <a href={config.revolabEmail}>{config.revolabEmail}</a>.
      </p>
      <p className={style.text}>
        3. Jei mums prireiktų su jumis susisiekti, tai padarysime jūsų užsakyme
        nurodytu el. pašto adresu.
      </p>
      <p className={style.text}>
        <b>INFORMACIJA APIE ŠIAS SĄLYGAS</b>
      </p>
      <p className={style.text}>
        4. Informaciją, kaip tvarkome asmens duomenis, kuriuos gauname iš jūsų
        arba kuriuos mums pateikiate, prašome skaityti mūsų{" "}
        <b>Privatumo politikos dokumente</b>
      </p>
      <p className={style.text}>
        5. Mes pasiliekame teisę keisti sąlygas, o įvykus pokyčiams pažymime
        pakeitimų atlikimo datą. Paslaugų teikimo sutarčiai yra taikoma ta
        Sąlygų versija, kuri galioja jums pateikiant užsakymą. Prieš pateikiant
        Paslaugų užsakymą, prašome apsilankyti šiame puslapyje, kadangi ši
        Sąlygų versija gali skirtis nuo tos versijos, kuri galiojo jūsų pirmiau
        pateiktiems Paslaugų užsakymams. Šiame dokumente minimos Sąlygos reiškia
        laikas nuo laiko pakeistas, atnaujintas ir galiojančias Sąlygas.
      </p>
      <p className={style.text}>
        <b>GEOGRAFINIAI APRIBOJIMAI</b>
      </p>
      <p className={style.text}>
        6. UAB „Revolab“ Paslaugos neteikiamos klientams, esantiems už ES ribų.
        Klientai mūsų Tinklapiu ir Paslaugomis naudojasi savo iniciatyva ir
        privalo laikytis vietos įstatymų.
      </p>
      <p className={style.text}>
        <b>INFORMACIJA APIE PASLAUGAS</b>
      </p>
      <p className={style.text}>
        7. Jūsų užsakymą sudaro tokios paslaugos, komplektacija gali skirtis nuo
        Jūsų prekės:
      </p>
      <ol type="a" className={style.orderedList}>
        <li className={style.listItem}>
          a) rinkinys mėginiui surinkti (toliau – <b>Rinkinys</b>) pateikimas;
        </li>
        <li className={style.listItem}>
          b) logistikos ir transportavimo paslaugos (toliau - <b>Logistika</b>)
        </li>
        <li className={style.listItem}>
          c) laboratorinių tyrimų atlikimas (toliau – <b>Paslaugos</b>)
        </li>
        <li className={style.listItem}>
          d) ataskaitos ir bendrojo pobūdžio patarimų dėl sveikatos priežiūros
          ir gyvensenos, atsižvelgiant į atliktų laboratorinių kraujo tyrimų
          rezultatus (toliau – <b>Ataskaita</b>), kuri jums pateikiama jūsų
          Paskyros valdymo skiltyje, parengimas
        </li>
      </ol>
      <p className={style.text}>
        ! Prašome atkreipti dėmesį, kad vykdydami prekybines ar kitokias
        panašaus pobūdžio akcijas, tiek Tinklapyje, tiek programėlėje galime
        pardavinėti ar nemokamai dalinti Rinkinius dar neregistruotiems
        vartotojams
      </p>
      <p className={style.text}>
        8. Tyrimai atliekami tik akredituotose, licencijuotose laboratorijose,
        kurių paslaugomis naudojames. Mes pasiliekame teisę, nepranešę, pakeisti
        į kitą licencijuotą ir akredituotą Laboratoriją.
      </p>
      <p className={style.text}>
        9. Jei turite kokių nors klausimų apie Paslaugas, prašome kreiptis el.
        paštu revolab@revolab.eu. Telefoninės arba kontaktinės konsultacijos su
        gydytoju į Paslaugas sudėtį neįskaičiuotos
      </p>
      <p className={style.text}>
        <b>UŽSAKYMŲ TEIKIMAS IR SUTARTIES SUDARYMAS</b>
      </p>
      <p className={style.text}>
        10. Norėdami pateikti pirmąjį užsakymą, turite užsiregistruoti arba
        prisijungti prie savo paskyros Tinklapyje ir asmeninėje profilio valdymo
        skiltyje pateikti prašomą informaciją (toliau – Paskyra).
      </p>
      <p className={style.text}>
        11. Jūsų Paslaugų užsakymas bus laikomas priimtu, kai išsiųsime jums tai
        patvirtinantį el. laišką – tuo metu įsigalios jūsų ir mūsų sudaryta
        Paslaugų teikimo sutartis (toliau- Sutartis), kuriai taikomos bendrovės
        „Revolab“ Sąlygos.
      </p>
      <p className={style.text}>
        12. Negalėdami priimti jūsų užsakymo pagal sutartas sąlygas, Jus apie
        tai informuosime el. Paštu. Neimsime mokesčio už Paslaugas (arba
        grąžinsime jau sumokėtą mokestį). Pavyzdžiui, netikėtai atsiradus
        išteklių trūkumui, kurio nei mes, nei Laboratorija negalėjo pagrįstai
        numatyti. Jūs patvirtinate, kad jei mes negalime priimti jūsų pateikto
        užsakymo – bet kokių sumokėtų sumų grąžinimas yra vienintelė jūsų teisių
        gynimo priemonė.
      </p>
      <p className={style.text}>
        13. Klientų, kurie nepasirenka mūsų produktų prenumeratos, atveju,
        prireikus papildomų rinkinių tyrimams (įskaitant pakartotinius tyrimus),
        kiekvienas toks užsakymas yra papildomas ir atskiras užsakymas, apie
        kurio patvirtinimą informuosime jus šiose bendrovės „Revolab“ Sąlygose
        nustatyta tvarka.
      </p>
      <p className={style.text}>
        14. Klientų, kurie pasirenka mūsų produktų prenumeratą, atveju, pateikus
        pirmąjį užsakymą reiškia, kad klientas sutiko su bendrovės „Revolab“
        sąlygomis visų paskesnių tyrimų atžvilgiu.
      </p>
      <p className={style.text}>
        <b>KAINA IR ATSISKAITYMAS</b>
      </p>
      <p className={style.text}>
        15. Paslaugų kaina jums pateikus užsakymą yra nurodoma Tinklapio
        užsakymų puslapiuose. Paprašius išrašome jums sąskaitą faktūrą.
      </p>
      <p className={style.text}>
        16. Paslaugų kaina sumokama jums pateikiant užsakymą. Paslaugų užsakymą
        priimsime tik gavę apmokėjimą už Paslaugas
      </p>
      <p className={style.text}>
        17. Klientui pasirenkant mūsų produktų prenumeratą, už paskesnius
        tyrimus atsiskaitoma jūsų paskyros valdymo skiltyje nurodytą kito tyrimo
        dieną.
      </p>
      <p className={style.text}>
        <b>TYRIMŲ ATLIKIMO TVARKA</b>
      </p>
      <p className={style.text}>
        18. Jeigu nesate gave ar kitaip įsigiję bendrovės Rinkinio, Rinkinį Jums
        išsiųsime per 2 d.d. po užsakymo patvirtinimo Paskyroje. Šis Rinkinys
        yra skirtas tam, kad galėtumėte instrukcijos nurodytu būdu paimti savo
        mėginį, rinkinyje taip pat yra instrukcijos, kaip pateikti mėginį.
      </p>
      <p className={style.text}>
        19. Rinkinys galioja apie metus, tiksli galiojimo data nurodyta ant
        pakuotės, bet tai yra ne mažiau 90 dienų. Todėl savo kraujo mėginį
        paimti ir Laboratorijai išsiųsti nurodytu adresu privalote per šį
        laikotarpį, neatlikus procedūros per šį laikotarpį laikoma jog Kliento
        užsakytos paslaugos iš Revolab pusės yra suteiktos pilnumoje, jeigu
        nebuvo sutarta kitaip.
      </p>
      <p className={style.text}>
        20.Jūs suprantate, kad tyrimo rezultatai priklauso nuo jūsų pateikto
        mėginio kokybės, todėl sutinkate laikytis pateiktų mėginio paėmimo
        instrukcijų ir taisyklių. Nesilaikant šių instrukcijų ir taisyklių (pvz.
        neatlikti asmens verifikavimo procedūros; neatliekant video skambučio
        jeigu taip nurodoma instrukcijose), tyrimo rezultatai gali būti
        netinkami arba neišsamūs. Laboratorijai pranešus apie neišsamius tyrimo
        rezultatus ir (arba) tuo atveju, jeigu jūsų mėginys tuo metu, kai jis
        buvo gautas Laboratorijoje, buvo netinkamos kokybės, mes turime teisę
        savo nuožiūra suteikti jums galimybę nemokamai pakartoti tyrimą dar
        kartą.
      </p>
      <p className={style.text}>
        21. Jeigu užsakovas nesilaiko instrukcijų antrą kartą (atlikdamas
        procedūrą su pakartotinai išsiųstų rinkiniu), mes turime teisę savo
        nuožiūra nesuteikti galimybės pakartoti tyrimą ir laikyti jog užsakovo
        paslaugos iš Revolab pusės yra suteiktos pilnumoje.
      </p>
      <p className={style.text}>
        22. Mes įsipareigojame informuoti Jus jeigu keičiasi tyrimų atlikimo
        sąlygos.
      </p>
      <p className={style.text}>
        <b>ATASKAITA</b>
      </p>
      <p className={style.text}>
        23.Mes prognozuojame, kad paprastai Ataskaita būna parengta per 2 darbo
        dienas po jūsų kraujo mėginio gavimo Laboratorijoje dienos. Mes el.
        paštu jums pranešime, kad jūsų Paskyros valdymo skiltis buvo tinkamai
        atnaujinta.
      </p>
      <p className={style.text}>
        24.Ataskaita rengiama remiantis tik Jūsų mėginio laboratorinių tyrimų ir
        informacija, kurią mums pateikiate, nesinaudojant visa Jūsų sveikatos
        istorija. Tačiau Ataskaita skirta išimtinai Jums.
      </p>
      <p className={style.text}>
        25.Neturėtumėte vadovautis Ataskaita įtariamiems arba faktiniams
        sveikatos sutrikimams diagnozuoti.
      </p>
      <p className={style.text}>
        26.Jūs pats esate atsakingas už bet kokius veiksmus, kuriuos atliekate
        (arba kurių neatliekate) iki gaunant ir gavus Ataskaitą, taip pat už
        tai, kada tokius veiksmus atliekate (arba jų neatliekate).
      </p>
      <p className={style.text}>
        27. Mes rekomenduojame jums visuomet pasikonsultuoti su atitinkamu
        sveikatos priežiūros specialistu, atsižvelgiant į konkrečias jūsų
        aplinkybes ir situaciją, ypač prieš imant vadovautis bet kokiais
        Ataskaitoje esančiais arba teikiant Paslaugas suteikiamais bendrojo
        pobūdžio patarimais dėl sveikatos priežiūros ir gyvensenos.
      </p>
      <p className={style.text}>
        28.Jūs sutinkate, kad jeigu bet kokia jūsų sveikatos aprašyme esanti
        informacija būtų neišsami arba netiksli, tiek interpretacija, tiek
        patarimai dėl sveikatos priežiūros ir gyvensenos taip pat gali būti
        neišsamūs ar netikslūs.
      </p>
      <p className={style.text}>
        29.Jūs prisiimate visą atsakomybę už tai, kad susipažinsite su tyrimų
        rezultatais ir su Ataskaita, kuri jums bus pateikta naudojantis
        Paslaugomis (įskaitant Ataskaitą ir jūsų Paskyros valdymo sritį) ir
        padarysite atitinkamas išvadas, taip pat suprantate, kad mes neteikiame
        paskesnių konsultacijų jūsų tyrimų rezultatų pagrindu.
      </p>
      <p className={style.text}>
        <b>SUTARTIES NUTRAUKIMO SĄLYGOS</b>
      </p>
      <p className={style.text}>
        30.Rinkinys, kurio sudėtį sudaro būtinos priemonės Paslaugų teikimui,
        yra pritaikytas konkretaus Kliento naudojimui (t.y. individualizuotas).
        Rinkinys (jo priemonės) yra susietas su konkretaus Kliento asmeniniais
        duomenimis, pagal kuriuos yra identifikuojamas asmuo, pateikęs mėginį
        ištyrimui, bei atliktų tyrimų rezultatai yra priskiriami tam asmeniui
        tiek Paskyroje, tiek išorinėse sistemose (pvz., portale e.sveikata).
        Klientui suteikiama teisė atsisakyti Sutarties vadovaujantis Lietuvos
        Respublikos civilinio kodekso 6.228 (10) str.
      </p>
      <p className={style.text}>
        31. Klientui yra suteikiama teisė nutraukti Sutartį šiais atvejais iš
        anksto numatytais atvejais:
        <ol type="a" className={style.orderedList}>
          <li className={style.listItem}>
            a. Jeigu po Sutarties sudarymo, mes neišsiuntėme Rinkinio per 2 d.
            d. ir neinformavome Kliento apie tam tikslui skirtų priemonių
            teikimo sutrikimus;
          </li>
          <li className={style.listItem}>
            b. Klientas pageidauja atsisakyti Paslaugų prenumeratos.
          </li>
          <li className={style.listItem}>c. Per 14 dienų nuo jos sudarymo.</li>
        </ol>
      </p>
      <p className={style.text}>
        32.Klientas turi teisę nutraukti Sutartį dėl Paslaugų prenumeratos
        (sąlygų 31-b punktas) dėl to laikotarpio, kurio periodiniam užsakytų
        tyrimų atlikimui Rinkinys (-iai) dar nėra išsiųstas (-i) ir panaudotas
        (-i). Tokiu atveju Klientas apie Sutarties nutraukimą mus informuoja iki
        Rinkinio už būsimus užsakytus periodinius tyrimus išsiuntimo jam dienos.
      </p>
      <p className={style.text}>
        33.Jei Klientas ketina pasinaudoti teise nutraukti Sutartį, jis privalo
        parašyti mums el. laišką adresu revolab@revolab.eu, nurodant užsakymo
        duomenis (įskaitant jo pateikimo / priėmimo dieną; savo vardą, pavardę;
        suteiktą užsakymo numerį).
      </p>
      <p className={style.text}>
        34.Jeigu iki Kliento pranešimo apie Sutarties nutraukimą, Paskyros
        valdymo skiltyje yra pateikta Ataskaita, Sutartis yra laikoma įvykdyta.
        Tokiu atveju Sutartis negali būti nutraukiama.
      </p>
      <p className={style.text}>
        35. Klientui įgyvendinus teisę nutraukti Sutartį, mes bendru atveju
        grąžiname visas Kliento sumokėtas sumas (įskaitant Kliento apmokėtas
        Rinkinio pristatymo išlaidas). Tuo atveju, jei yra nutraukiama Paslaugų
        prenumerata, mes grąžiname visas Kliento sumokėtas sumas tik už užsakytą
        ir nepanaudotą tyrimų laikotarpį (įskaitant Kliento apmokėtas Rinkinio
        pristatymo išlaidas už tą laikotarpį). Mes pinigus grąžiname į Kliento
        nurodytą banko sąskaitą ne vėliau kaip per 14 dienų, skaičiuojant nuo
        Kliento pranešimo apie Sutarties nutraukimą pateikimo dienos.
      </p>
      <p className={style.text}>
        36. Tuo atveju, jei mes per šiose sąlygose numatytą terminą neišsiuntėme
        Rinkinio Klientui, ir pastarasis subjektas nepasinaudojo teise Sutartį
        nutraukti šių sąlygų 31-a punkte įtvirtintu pagrindu, mes įsipareigojame
        Rinkinį išsiųsti ne vėliau kaip per papildomą 2 darbo dienų terminą,
        skaičiuojamą nuo Kliento teisės nutraukti Sutartį pabaigos. Jei mes
        negalime to užtikrinti, įsipareigojame per šiame punkte nurodytą terminą
        grąžinti Kliento sumokėtus pinigus (įskaitant Kliento apmokėtas Rinkinio
        pristatymo išlaidas).
      </p>
      <p className={style.text}>
        37. Tuo atveju, jei Rinkinys yra brokuotas ir netinkamas naudojimui
        pagal instrukcija, Klientas, laikantis šių sąlygų 19 p. nurodyto
        termino, privalo apie tai pranešti mums, kartu pateikiant įrodymus,
        liudijančius Rinkinio kokybės trūkumus. Jei yra nustatoma, kad Rinkinys
        yra brokuotas, Klientui nemokamai yra pateikiamas kitas (pakaitinis)
        Rinkinys. Tuo atveju, jei kito (pakaitinio) Rinkinio per protingą
        terminą, kuris visais atvejais negali būti ilgesnis kaip 3 darbo dienos,
        skaičiuojant nuo įrodymais pagrįsto pranešimo apie Rinkinio kokybės
        trūkumus pateikimo), mes pristatyti Klientui negalime, įsipareigojame
        per šiame punkte nurodytą terminą grąžinti Kliento sumokėtus pinigus
        (įskaitant Kliento apmokėtas Rinkinio pristatymo išlaidas).
      </p>
      <p className={style.text}>
        38. Tuo atveju, jeigu po to, kai Klientas, laikydamasis šių sąlygų 19 p.
        nustatyto termino išsiunčią mėginį mums (ar laboratorijai), Paskyros
        valdymo skiltyje Ataskaita nėra pateikiama per 7 d. d., skaičiuojant nuo
        mėginio išsiuntimo dienos, mes išsiaiškiname to priežastis ir apie tai
        pranešame Klientui. Jei tokio uždelsimo priežastys yra susijusios su
        tuo, jog Kliento išsiųstas mėginys buvo prarastas ar pasimetė (ar kitos
        panašaus pobūdžio priežastys, dėl kurių yra būtinas pakartotinio mėginio
        paėmimas), mes Klientui pasiūlysime Paslaugas suteikti pakartotinai,
        kartu atsiunčiant kitą (pakaitinį) Rinkinį. Jei atsiųsti Rinkinio ar
        užtikrinti Ataskaitos pateikimo Paskyros valdymo skiltyje per protingą
        terminą, kuris visais atvejais negali būti ilgesnis, kaip 7 d.d.,
        skaičiuojant nuo uždelsimo priežasčių paaiškėjimo (išsiaiškinimo),
        negalėsime, mes įsipareigojame per šį terminą grąžinti Kliento sumokėtus
        pinigus (įskaitant Kliento apmokėtas Rinkinio pristatymo išlaidas).
      </p>
      <p className={style.text}>
        39.Ne viena šių bendrovės „Revolab“ Sąlygų nuostata neturi įtakos
        jokioms kitoms įstatymuose numatytoms jūsų teisėms.
      </p>
      <p className={style.text}>
        40.Po Sutarties sudarymo, mes Rinkinį išsiunčiame, vadovaujantis
        užsakyme pateikiamais Kliento kontaktiniais duomenimis (įskaitant
        adresą). Mums išsiuntus Rinkinį pagal Kliento nurodytus kontaktinius
        duomenis, yra laikoma, kad mes savo įsipareigojimą jį pateikti Klientui
        tinkamai įvykdėme, išskyrus atvejus, kai siunta yra prarandama dėl
        siuntimo paslaugas teikiančio subjekto kaltės. Siuntos atsiėmimo rizika
        tenka pačiam Klientui (t.y. už tai, kad išsiųsto ir pristatyto Rinkinio
        Klientas neatsiima, mes neatsakome).
      </p>
      <p className={style.text}>
        41. Klientas privalo Rinkinį panaudoti ir mėginį išsiųsti mums (arba
        laboratorijai), laikantis šių sąlygų 19 p. nurodyto termino. Jei mėginys
        yra išsiunčiamas vėliau (t.y. pažeidus šiame punkte nurodytą terminą),
        tyrimai nėra atliekami ir jokios Kliento sumokėtos sumos jam nėra
        grąžinamos. Tokiu atveju yra laikoma, kad mes iki nurodyto termino
        pabaigos Sutartį tinkamai įvykdėme.
      </p>
      <p className={style.text}>
        42. Tuo atveju, jei po Sutarties sudarymo atsiranda poreikis papildomų
        duomenų / informacijos iš Kliento pusės pateikimui, siekiant įvykdyti
        Sutartį, Klientas tą įsipareigoja padaryti per protingą terminą po to,
        kai mes į jį kreipiamės dėl tokių duomenų / informacijos pateikimo ar
        patikslinimo. Jei Klientas šios pareigos nevykdo, jis prisiima riziką,
        kad Sutarties objektą sudarantys tyrimų atlikimas gali vėluoti arba jie
        apskritai gali būti neatlikti, tačiau tai nereiškia įsipareigojimų iš
        mūsų pusės pažeidimo.
      </p>
      <p className={style.text}>
        <b>JŪSŲ PATVIRTINIMAI</b>
      </p>
      <p className={style.text}>
        43.Pateikdami užsakymą, jūs patvirtinate ir pareiškiate mums, kad galite
        teisiškai sudaryti šį abipusiai įpareigojantį susitarimą, kad esate ne
        jaunesnis nei 18 metų amžiaus ir kad užpildėte tikslią ir aktualią
        prašymo formą, kurioje nurodėte, kad kraujo mėginys yra jūsų.
      </p>
      <p className={style.text}>
        44.Mes neprisiimame jokios atsakomybės už bet kokius nuostolius arba
        žalą, kurie buvo patirti dėl to, kad teikiant Paslaugas Jūs pateikėte
        klaidingą arba neišsamią informaciją arba dėl to, kad jūsų nurodymu mes
        arba Laboratorija atlikome kokius nors veiksmus.
      </p>
      <p className={style.text}>
        45. Pateikdami užsakymą, jūs patvirtinate, kad jums yra žinomas galimas
        poveikis ir rizika, susijusi su jūsų paties kraujo mėginio paėmimu ir
        kad (atsižvelgiant į 38 punkto nuostatas), imdamas mėginį jūs prisiimate
        visą atsakomybę už bet kokias su tuo susijusias pasekmes.
      </p>
      <p className={style.text}>
        46.Jūsų patvirtinate ir sutinkate, kad Paslaugos nepakeičia faktinės
        medicininės diagnozės arba gydytojo konsultacijos, o Ataskaitoje esanti
        informacija nėra medicininiai patarimai ir (arba) rekomendacijos dėl
        medicininio gydymo, susijusio su bet kokiais jūsų sveikatos, vartojamų
        vaistų, mitybos arba gyvensenos aspektais.
      </p>
      <p className={style.text}>
        47. Jūs patvirtinate, kad Ataskaita ir joje esanti informacija gali būti
        neišsami (įskaitant informaciją, kurią galima gauti atliekant kraujo
        tyrimus, tačiau jūsų Ataskaitoje ji dėl kažkokių priežasčių nebuvo
        nurodyta).
      </p>
      <p className={style.text}>
        48.Jūs patvirtinate ir sutinkate, kad tarp skirtingų laboratorijų
        atliktų tyrimų rezultatų gali būti nedidelių skirtumų. Atitinkamai,
        pastebėjus nedidelius rezultatų skirtumus, pavyzdžiui, stebint atskiro
        tyrimo rezultatus laikui bėgant, tokie nedideli skirtumai yra leistini,
        o jūs prisiimate bendrą atsakomybę už savo ilgalaikių tendencijų
        stebėjimą. Be to, jūs sutinkate, kad kartais dėl skirtingų laboratorijų
        atliktų tyrimų rezultatų skirtumų nedideli pokyčiai gali lemti, kad
        gretimi tyrimo taškai gali atsidurti normos ribose arba už jų, tačiau iš
        esmės tai yra nereikšmingas skirtumas.
      </p>
      <p className={style.text}>
        49. Mums nesilaikant šių bendrovės „Revolab“ sąlygų, mes nesame
        atsakingi už jūsų patirtus tiesioginius nuostolius arba žalą, jeigu
        galima buvo numatyti, kad juos patirsite dėl to, kad nutraukėme šią
        sutartį arba nebuvome pagrįstai atidūs ir kvalifikuoti. Nuostolius arba
        žalą galima numatyti, jeigu yra akivaizdu, kad jie bus patirti arba
        jeigu sudarant sutartį ir jūs, ir mes žinojome, kad taip nutiks.
      </p>
      <p className={style.text}>
        <b>SĄLYGOS DĖL TREČIŲJŲ ŠALIŲ SĄLYGOS DĖL TREČIŲJŲ ŠALIŲ</b>
      </p>
      <p className={style.text}>
        50.Paslaugos teikiamos išimtinai buitiniam ir asmeniniam naudojimui.
        Jeigu Paslaugomis su mūsų rašytiniu arba kitokiu sutikimu naudojatės
        komerciniais, verslo ar perpardavimo tikslais, mes neprisiimame jokios
        atsakomybės už bet kokį negautą pelną, prarastus klientus, veiklos
        pertrūkius, prarastas verslo galimybes arba kitokius netiesioginius
        nuostolius.
      </p>
      <p className={style.text}>
        51. Jeigu Paslaugas gaunate bet kokiais trečiųjų šalių komerciniais,
        verslo arba perpardavimo kanalais, mes nesame atsakingi jums už tokios
        trečiosios šalies jums teikiamas Paslaugas, kurioms taikomos jūsų ir
        tokios trečiosios šalies sutarties nuostatos.
      </p>
      <p className={style.text}>
        52. Jeigu trečiosios šalies gydytojo konsultaciją gavote kaip tęstines
        paslaugas po to, kai pasinaudojus „Revolab“ Paslaugomis gavote
        rezultatus ar gydytojo konsultaciją, taikomos 48 punkto nuostatos.
      </p>
      <p className={style.text}>
        <b>KITOS SVARBIOS SĄLYGOS</b>
      </p>
      <p className={style.text}>
        53. Teisių ir prievolių perleidimas ir santykiai su kitomis šalimis. Jūs
        neturite teisės perleisti šiose bendrovės „Revolab“ Sąlygose numatytų
        savo teisių arba prievolių be išankstinio rašytinio mūsų sutikimo.
      </p>
      <p className={style.text}>
        54. Reikalavimo teisės atsisakymas. Jei mes nedelsdami nereikalaujame,
        kad jūs atliktumėte kokį nors veiksmą, kurį turite atlikti pagal šias
        bendrovės „Revolab“ Sąlygas, arba jeigu delsiame jūsų atžvilgiu imtis
        veiksmų dėl to, kad pažeidėte Paslaugų teikimo sutartį, tai nereiškia,
        kad atsisakome teisės tai padaryti ir prarandame galimybę tai padaryti
        vėliau.
      </p>
      <p className={style.text}>
        55. Atskirų nuostatų negaliojimas. Kiekvienas šių bendrovės „Revolab“
        Sąlygų punktas galioja atskirai. Bet kokiam teismui arba valdžios
        institucijai priėmus sprendimą, kad bet kuris iš jų yra negaliojantis,
        neteisėtas arba neįgyvendinamas, likę punktai lieka galioti visa
        apimtimi.
      </p>
      <p className={style.text}>
        56. Trečiųjų šalių teisės. Paslaugų teikimo sutartis yra sudaryta tarp
        mūsų ir jūsų. Joks kitas asmuo neturi jokios teisės reikalauti įvykdyti
        jos sąlygas.
      </p>
      <p className={style.text}>
        57. Taikoma teisė / jurisdikcija. Šioms bendrovės „Revolab“ Sąlygoms yra
        taikoma Lietuvos teisė.
      </p>
      <p className={style.text}>
        58. <em>Ginčų sprendimas internetu.</em> Jeigu nesutinkate su tuo, kaip
        išsprendėme su Paslaugomis susijusią pretenziją, galite per Europos
        Komisijos internetinę ginčų sprendimo platformą pateikti ginčą spręsti
        internetu. Šią platformą ir daugiau informacijos apie ją rasite
        internete{" "}
        <a className={style.link_orange} href={onlineDisputeResolutionLink}>
          {onlineDisputeResolutionLink}
        </a>
        .
      </p>
    </div>
  );
}

export default TermsOfUse;
