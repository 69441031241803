import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./SurveyQuestion.module.scss";

import NumericQuestion from "components/Survey/SurveyQuestion/NumericQuestion";
import CheckboxQuestion from "components/Survey/SurveyQuestion/CheckboxQuestion";
import DropdownQuestion from "components/Survey/SurveyQuestion/DropdownQuestion";
import GenderQuestion from "components/Survey/SurveyQuestion/GenderQuestion";
import NumberQuestion from "components/Survey/SurveyQuestion/NumberQuestion";
import {
  Answer,
  CheckboxQuestionType,
  DropdownQuestionType,
  GenderQuestionType,
  NumberQuestionType,
  NumericQuestionType,
  Question,
} from "types";

type Props = {
  submitCount: number | null;
  question: Question;
  answers: { [key: string]: string | number | null };
  setAnswers: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | number | null;
    }>
  >;
};

const SurveyQuestion = ({
  submitCount,
  question,
  answers,
  setAnswers,
}: Props): JSX.Element => {
  const hasError = !!submitCount && submitCount > 0 && !answers[question.id];
  const { t } = useTranslation();

  return (
    <div className={styles.question}>
      <div
        className={
          hasError
            ? `${styles.question__title} ${styles.question__title_hasError}`
            : styles.question__title
        }
      >
        {question.questionT}
      </div>
      {question.answerType === Answer.gender && (
        <GenderQuestion
          question={question as GenderQuestionType}
          setAnswers={setAnswers}
          answers={answers}
        />
      )}
      {question.answerType === Answer.number && (
        <NumberQuestion
          question={question as NumberQuestionType}
          setAnswers={setAnswers}
          answers={answers}
        />
      )}
      {question.answerType === Answer.numeric && (
        <NumericQuestion
          activeIndex={Number(answers[question.id])}
          changeActive={(value: number) =>
            setAnswers({ ...answers, [question.id]: value })
          }
          question={question as NumericQuestionType}
        />
      )}
      {question.answerType === Answer.checkbox && (
        <CheckboxQuestion
          question={question as CheckboxQuestionType}
          setAnswers={setAnswers}
          answers={answers}
        />
      )}
      {question.answerType === Answer.dropdown && (
        <DropdownQuestion
          question={question as DropdownQuestionType}
          setAnswers={setAnswers}
          answers={answers}
        />
      )}
      {hasError && (
        <div className={styles.question__error}>{t("Required")}</div>
      )}
    </div>
  );
};

export default SurveyQuestion;
