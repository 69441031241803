import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./CartNotificationPopup.module.scss";

import { success, errorIcon } from "assets/image";

type Props = {
  isSuccessful: boolean;
  close: () => void;
};

const CartNotificationPopup = ({ isSuccessful, close }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper} onClick={() => close()}>
      <div className={styles.popup}>
        <img
          className={styles.popup__image}
          src={isSuccessful ? success : errorIcon}
          alt={isSuccessful ? "Success" : "Fail"}
        />
        <div className={styles.popup__title}>
          {isSuccessful
            ? t("Cart.NotificationPopup.SuccessText")
            : t("Cart.NotificationPopup.FailureText")}
        </div>
        <div className={styles.popup__buttons}>
          <Link className={styles.popup__backButton} to={`/#covid19`}>
            {t("Cart.NotificationPopup.ButtonContinue")}
          </Link>
          <Link className={styles.popup__buyButton} to={`/user/cart`}>
            {t("Cart.NotificationPopup.ButtonBuy")}
          </Link>
        </div>
        <div className={styles.popup__closeIcon} onClick={() => close()}></div>
      </div>
    </div>
  );
};

export default CartNotificationPopup;
