import * as React from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { cardiacRisk } from "assets/image";

import styles from "./CardiacRisk.module.scss";
import { useAppDispatch } from "hooks";
import { allowContactInfo } from "store/slices";

export const CardiacRisk = (): JSX.Element => {
  const [redirect, setRedirect] = React.useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = () => {
    setRedirect(true);
    dispatch(allowContactInfo(true));
  };

  return (
    <div className={styles.cardiac__risk}>
      <div className={styles.cardiac__risk__shape}>
        <img
          alt="icon"
          src={cardiacRisk}
          className={styles.cardiac__risk__shape__img}
        />
      </div>
      <div>
        <h1 className={styles.cardiac__risk__title}>
          {t("Docs.CardiacRiskAssessment.Title")}
        </h1>
        <p className={styles.cardiac__risk__text}>
          {t("Docs.CardiacRiskAssessment.Discription.Block1")}
        </p>
        <p className={styles.cardiac__risk__text}>
          {t("Docs.CardiacRiskAssessment.Discription.Block2")}
        </p>
        <p className={styles.cardiac__risk__text}>
          {t("Docs.CardiacRiskAssessment.Discription.Block3")}
        </p>
        <button className={styles.cardiac__risk__button} onClick={handleSubmit}>
          {redirect && (
            <Redirect to={"/cardiac-risk-assessment/contact-info"} />
          )}
          {t("Docs.CardiacRiskAssessment.MainButton")}
        </button>
      </div>
    </div>
  );
};
