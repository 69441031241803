import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./HowItWorks.module.scss";

const HowItWorks = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.howItWorks} id="howdoesitworks">
      <div className={styles.howItWorks__title}>
        {t("Main.HowItWorks.Title")}
      </div>
      <div className={styles.howItWorks__list}>
        <div className={`${styles.step} ${styles.firstStep}`}>
          <div className={styles.step__number}>1</div>
          <div className={styles.step__title}>
            {t("Main.HowItWorks.Step1Title")}
          </div>
          <div className={styles.step__subtitle}>
            {t("Main.HowItWorks.Step1Text")}
          </div>
        </div>
        <div className={`${styles.step} ${styles.secondStep}`}>
          <div className={styles.step__number}>2</div>
          <div className={styles.step__title}>
            {t("Main.HowItWorks.Step2Title")}
          </div>
          <div className={styles.step__subtitle}>
            {t("Main.HowItWorks.Step2Text")}
          </div>
        </div>
        <div className={`${styles.step} ${styles.thirdStep}`}>
          <div className={styles.step__number}>3</div>
          <div className={styles.step__title}>
            {t("Main.HowItWorks.Step3Title")}
          </div>
          <div className={styles.step__subtitle}>
            {t("Main.HowItWorks.Step3Text")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
