import * as React from "react";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SurveySchema } from "validation";
import { useAppDispatch, useTypedSelector } from "hooks";
import { setEmail } from "store/slices";
import { StagePhase } from "types";
import CustomInput from "components/CustomInput";
import Stage from "components/Stage";

import style from "./ContactInfo.module.scss";

const ContactInfo = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { email, isContactInfoAllowed } = useTypedSelector(
    (state) => state.survey
  );

  if (!isContactInfoAllowed) {
    return <Redirect to={"/survey/cardiac-risk-assessment"} />;
  }

  if (email) {
    return <Redirect to={"/cardiac-risk-assessment/questions"} />;
  }

  return (
    <div className={style.wrapper}>
      <Stage stage={StagePhase.firstStage} />
      <div className={style.menu}>
        <h1 className={style.menu__header}>{t("ContactInfo.Header")}</h1>
        <p className={style.menu__description}>
          {t("ContactInfo.Description")}
        </p>
      </div>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values) => {
          dispatch(setEmail(values.email));
        }}
        validationSchema={SurveySchema}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => {
          return (
            <>
              <CustomInput
                text={""}
                type={"text"}
                value={values.email}
                error={errors.email}
                changeHandler={handleChange("email")}
                verticalError={false}
                touched={touched.email}
              />
              <div className={style.menu__buttonWrap}>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className={style.menu__btn}
                >
                  {t("ContactInfo.Btn")}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ContactInfo;
