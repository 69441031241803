import * as React from "react";

import styles from "./HealthAppSlider.module.scss";
import {
  sliderBloodTestResults,
  sliderDoctor,
  sliderTrackingHealth,
  sliderKnowAction,
  sliderKnowAboutHealth,
} from "assets/image";

const slideList = [
  { image: sliderBloodTestResults, alt: "Blood test results on phone" },
  { image: sliderDoctor, alt: "Doctor" },
  { image: sliderTrackingHealth, alt: "Track health on phone" },
  { image: sliderKnowAction, alt: "Know when take action" },
  { image: sliderKnowAboutHealth, alt: "Know about health in app" },
];

const HealthAppSlider = (): JSX.Element => {
  const [activeSlide, setActiveSlide] = React.useState(2);
  const visibleSlides = [activeSlide - 1, activeSlide, activeSlide + 1];

  return (
    <div className={styles.slider}>
      <div className={styles.slider__slideList}>
        {activeSlide === 0 && <div className={styles.slider__emptySlide}></div>}
        {slideList.map(({ image, alt }, index) => (
          <div
            key={alt}
            className={`${styles.slider__slide}
              ${activeSlide === index && styles.slider__slide_active}
              ${!visibleSlides.includes(index) && styles.slider__slide_hidden}
            `}
            onClick={() => setActiveSlide(index)}
          >
            <img className={styles.slider__image} src={image} alt={alt} />
          </div>
        ))}
        {activeSlide === slideList.length - 1 && (
          <div className={styles.slider__emptySlide}></div>
        )}
      </div>
      <div className={styles.slider__dotsList}>
        {Array.from(Array(slideList.length).keys()).map((item, index) => (
          <div
            key={item}
            className={
              activeSlide === index
                ? `${styles.slider__dot} ${styles.slider__dot_active}`
                : styles.slider__dot
            }
            onClick={() => setActiveSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default HealthAppSlider;
