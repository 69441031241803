import * as Yup from "yup";

export const SurveySchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email("InvalidEmail")
    .required("Required"),
});

export const NumberQuestionSchema = (min: number, max: number) => {
  return Yup.object().shape({
    numberQuestion: Yup.number()
      .min(min, "MinimalNum")
      .max(max, "MaximalNum")
      .required("RequiredNum"),
  });
};
