import * as React from "react";
import Zendesk from "react-zendesk";

import config from "config";

const setting = {
  color: {
    theme: "#f95b3d",
  },
  contactForm: {
    fields: [
      { id: "name", prefill: { "*": "Enter your name" } },
      { id: "email", prefill: { "*": "Enter your email" } },
      { id: "description", prefill: { "*": "Describe the problem" } },
    ],
  },
};

const ZendeskChat = (): JSX.Element => {
  return <Zendesk defer={false} zendeskKey={config.zendeskKey} {...setting} />;
};

export default ZendeskChat;
