import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./YoutubeGuide.module.scss";

const YoutubeGuide = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper} id="howdoesitworksvideo">
      <div className={styles.title}>{t("Main.YoutubeGuide.Title")}</div>
      <iframe
        className={styles.frame}
        src="https://www.youtube.com/embed/b-VlrD852qk"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default YoutubeGuide;
