import firebase from "firebaseApp";
import { Consent } from "types";

class ConsentsService {
  consents: firebase.firestore.Query<any>;

  constructor() {
    this.consents = firebase.firestore().collection("UserConsents");
  }

  async fetchConsents(): Promise<Array<Consent>> {
    const snapshot = await this.consents.get();
    const result = snapshot.docs.map((doc) => doc.data());

    return result;
  }
}

export default new ConsentsService();
