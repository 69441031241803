import firebase from "firebaseApp";
import { OmnivaDepartment } from "types";

class OmnivaService {
  omnivaDepartments: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor() {
    this.omnivaDepartments = firebase
      .firestore()
      .collection("OmnivaDepartments");
  }

  async fetchOmnivaDepartmentsList(): Promise<Array<OmnivaDepartment>> {
    const snapshot = await this.omnivaDepartments.get();
    const result = snapshot.docs.map((doc) => doc.data());
    return result as OmnivaDepartment[];
  }
}

export default new OmnivaService();
