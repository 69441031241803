import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ContentResearchRepeat.module.scss";

import { Research } from "components/Research";

const ContentResearchRepeat = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.researchRepeat}>
      <div className={styles.researchRepeat__background}></div>
      <div className={styles.researchRepeat__content}>
        <div className={styles.researchRepeat__title}>
          {t("Main.ContentResearchRepeat.Header")}
        </div>
        <Research acceptGlobalValues={false} />
      </div>
    </div>
  );
};

export default ContentResearchRepeat;
