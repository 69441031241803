import firebase from "firebaseApp";

class PromoCodesService {
  promoCodes: firebase.firestore.Query<firebase.firestore.DocumentData>;

  constructor() {
    this.promoCodes = firebase
      .firestore()
      .collection("PromoCodes")
      .where("enabled", "==", true);
  }

  async fetchPromoCodes(): Promise<Array<firebase.firestore.DocumentData>> {
    const snapshot = await this.promoCodes.get();
    const result = snapshot.docs.map((doc) => doc.data());

    return result;
  }
}

export default new PromoCodesService();
