import { ConsentsDTO, Locale, Parcel } from ".";

export type OrderItemType = {
  id: string;
  orderDate: string;
  testPackages: OrderTestPackage[];
  orderType: OrderType;
  status: OrderStatus;
  sessionId: string;
  paymentIntentId: string;
  subtotalCost: number;
  totalCost: number;
  lab: string;
  deliverySystem: DeliverySystem;
  deliveryInformation: DeliveryInformation;
  paymentSystem: PaymentSystem;
  paymentInformation: {
    paymentChannel: OpayPaymentChannel;
    companyName?: InsuranceCompany;
    identificationCode?: string;
    insuranceNumber?: string;
  };
  parcelInformation?: {
    fromRevolabToUser?: Parcel;
    fromUserToLab?: Parcel;
  };
};

export type OrderTestPackage = {
  testPackageId: string;
  testPackageLabId: string;
  labId: string | null; // id?
  labStatus: LabStatus | null; // depends on lab
  testPackageName: string;
};

export enum LabStatus {
  waiting = "waiting",
  processing = "processing",
  completed = "completed",
  canceled = "canceled",
  unprocessable = "unprocessable",
}

export enum OrderType {
  OneTimePayment = "OneTimePayment",
  Subscription = "Subscription",
}

export enum OrderStatus {
  new = "new",
  paid = "paid",
  processed = "processed",
  delivering = "delivering",
  processingInLab = "processingInLab",
  completed = "completed",
}

export enum DeliverySystem {
  Courier = "Courier",
  Omniva = "Omniva",
  KitPurchase = "KitPurchase",
  USCourier = "USCourier",
}

export type DeliveryInformation =
  | CourierDeliveryInformation
  | OmnivaDeliveryInformation
  | USCourierDeliveryInformation;

export type CourierDeliveryInformation = {
  city: string;
  address: string;
  mobileNumber: string;
  postalCode: string;
  identificationCode?: string;
};

export type OmnivaDeliveryInformation = {
  name: string;
  county: string;
  address: string;
  mobileNumber: string;
  postalCode: string;
  identificationCode?: string;
};

export type USCourierDeliveryInformation = {
  address: string;
  city: string;
  postalCode: string;
  state: string;
};

export type PaymentDetailsType = {
  card: {
    last4: string;
    brand: PaymentSystemBrand;
  };
  receipt: string;
  status: PaymentStatus;
  invoice: {
    status: string;
    url: string;
  };
  locale: Locale;
};

export enum PaymentStatus {
  succeeded = "succeeded",
  pending = "pending",
  failed = "failed",
}

export enum PaymentSystemBrand {
  amex = "amex",
  diners = "diners",
  discover = "discover",
  jcb = "jcb",
  mastercard = "mastercard",
  unionpay = "unionpay",
  visa = "visa",
  unknown = "unknown",
}

export enum PaymentAggregators {
  visa = "Visa",
  mastercard = "MasterCard",
}

export enum PaymentSystem {
  Stripe = "Stripe",
  Insurance = "Insurance",
  Opay = "Opay",
  KitPurchase = "KitPurchase",
}

export enum InsuranceCompany {
  GjensidigeInsurance = "GjensidigeInsurance",
  CompensaVienna = "CompensaVienna",
  Seesam = "Seesam",
}

export type StripePaymentInformationDTO = {
  paymentMethodId: string;
};

export type InsurancePaymentInformationDTO = {
  insuranceNumber: string;
  identificationCode: string;
  companyName: InsuranceCompany;
};

export type OpayPaymentInformationDTO = {
  userEmail: string;
  paymentChannel: OpayPaymentChannel;
};

export type PaymentInformationDTO =
  | OpayPaymentInformationDTO
  | InsurancePaymentInformationDTO
  | StripePaymentInformationDTO;

export enum OpayPaymentChannel {
  swedbank = "banklink_swedbank",
  seb = "banklink_seb",
  dnb = "banklink_dnb",
  citadele = "banklink_citadele",
  sb = "banklink_sb",
  medbank = "banklink_medbank",
  banktransfer = "banktransfer",
}

export type CreateOrderResult = {
  isSuccess: boolean;
  opayEncodedString: any;
};

export type KitPurchaseOrderDTO = {
  testKitHash: string;
  deliveryInformation: {
    identificationCode?: string;
  };
  consents: ConsentsDTO | Record<string, boolean>;
};

export interface PaymentDTO {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  identificationCode: string;
  address: string;
  county: string;
  name: string;
  postalCode: string;
  childFirstName: string;
  childLastName: string;
  childYear: string;
  childMonth: string;
  childDay: string;
  childIdentificationCode: string;
  childSex: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
}
