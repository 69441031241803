import { translateObject } from "helpers";
import {
  TestPackagesActionTypes,
  TestPackagesAction,
  TestPackagesState,
} from "types";

const initialState: TestPackagesState = {
  isLoadingTestPackages: true,
  items: [],
  multiLanguageItems: [],
  fetchTestPackagesError: "",
  isOpenCovidTestPackage: false,
  isOpenManHealthTestPackage: false,
  isOpenWomanHealthTestPackage: false,
  isOpenWellnessTestPackage: false,
};

export const testPackagesReducer = (
  state: TestPackagesState = initialState,
  action: TestPackagesAction
): TestPackagesState => {
  switch (action.type) {
    case TestPackagesActionTypes.TRANSLATE_TEST_PACKAGES: {
      return {
        ...state,
        items: translateObject(state.multiLanguageItems, action.payload),
      };
    }

    case TestPackagesActionTypes.PENDING_TEST_PACKAGES: {
      return {
        ...state,
        isLoadingTestPackages: true,
      };
    }

    case TestPackagesActionTypes.FULFILLED_TEST_PACKAGES: {
      return {
        ...state,
        isLoadingTestPackages: false,
        multiLanguageItems: action.payload,
      };
    }

    case TestPackagesActionTypes.REJECTED_TEST_PACKAGES: {
      return {
        ...state,
        isLoadingTestPackages: false,
        fetchTestPackagesError: action.payload,
      };
    }

    case TestPackagesActionTypes.SET_IS_OPEN_COVID_TEST_PACKAGE: {
      return { ...state, isOpenCovidTestPackage: action.payload };
    }
    case TestPackagesActionTypes.SET_IS_OPEN_MANHEALTH_TEST_PACKAGE: {
      return { ...state, isOpenManHealthTestPackage: action.payload };
    }
    case TestPackagesActionTypes.SET_IS_OPEN_WOMANHEALTH_TEST_PACKAGE: {
      return { ...state, isOpenWomanHealthTestPackage: action.payload };
    }
    case TestPackagesActionTypes.SET_IS_OPEN_WELLNESS_TEST_PACKAGE: {
      return { ...state, isOpenWellnessTestPackage: action.payload };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
