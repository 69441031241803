import * as React from "react";

import styles from "./DropdownQuestion.module.scss";

import { DropdownQuestionType, SelectItemType } from "types";
import CustomSelect from "components/CustomSelect";
import { prepareValuesForDropdown } from "helpers";

type Props = {
  question: DropdownQuestionType;
  answers: { [key: string]: string | number | null };
  setAnswers: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | number | null;
    }>
  >;
};

const DropdownQuestion = ({
  question,
  setAnswers,
  answers,
}: Props): JSX.Element => {
  const changeHandler = (e: SelectItemType) => {
    if (e.value === "--") {
      const { [question.id]: currentAnswer, ...otherAnswers } = answers;
      setAnswers(otherAnswers);
    } else {
      setAnswers({ ...answers, [question.id]: e.value });
    }
  };

  return (
    <div className={styles.wrapper}>
      <CustomSelect
        items={prepareValuesForDropdown(question.answerVariants)}
        contentContainerStyle={"fullWidthWithoutMargin"}
        changeHandler={changeHandler}
        value={answers[question.id] || "--"}
      />
    </div>
  );
};

export default DropdownQuestion;
