import React, { useEffect, useState } from "react";

import style from "./CustomSelect.module.scss";

import { SelectItemType } from "types";

type Props = {
  items: SelectItemType[];
  header?: string;
  changeHandler: (e: SelectItemType) => void;
  contentContainerStyle?: string;
  placeholder?: string;
  disabled?: boolean;
  onBlurHandler?: () => void;
  value?: string | number;
  error?: string;
  touched?: boolean;
};

export default function CustomSelect({
  items,
  header,
  changeHandler,
  contentContainerStyle,
  placeholder,
  disabled,
  onBlurHandler,
  value,
  error,
  touched,
}: Props): JSX.Element {
  const options = placeholder
    ? [{ label: placeholder, value: "" }, ...items]
    : items;
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SelectItemType>(options[0]);
  const hasError = error && touched;

  useEffect(() => {
    if (value !== undefined) {
      setSelectedItem(options.find((option) => option.value === value)!);
    }
  }, [value]);

  return (
    <div
      className={
        contentContainerStyle
          ? `${style.selectContainer} ${style[contentContainerStyle]}`
          : `${style.selectContainer}`
      }
    >
      {header && <div className={style.selectHeader}>{header}</div>}
      <div
        className={`${style.select} ${hasError ? style.selectError : ""} ${
          disabled ? style.selectDisabled : ""
        }`}
        onClick={() => {
          if (!disabled) {
            setIsOpenDropdown(!isOpenDropdown);
          }
        }}
        tabIndex={-1}
        onBlur={() => {
          if (onBlurHandler) {
            onBlurHandler();
          }
          setIsOpenDropdown(false);
        }}
      >
        <div className={style.selectText}>{selectedItem.label}</div>
      </div>
      {isOpenDropdown && (
        <ul
          className={`${style.selectOptionContainer} ${
            header ? "" : style.selectOptionContainer__withoutHeader
          }`}
        >
          {options
            .filter((item: SelectItemType) => !!item.value)
            .map((item: SelectItemType) => {
              return (
                <li
                  key={`${item.value} ${item.label}`}
                  value={item.value}
                  className={style.selectOption}
                  onMouseDown={() => {
                    changeHandler(item);
                    setIsOpenDropdown(false);
                  }}
                >
                  {item.label}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}
