import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./SuccessOrderPage.module.scss";
import { useTypedSelector } from "hooks";
import { success } from "assets/image";

const SuccessOrder = (): JSX.Element => {
  const {
    calendlyEventUrl,
    identityVerificationUrl,
    needCalendlyVerification,
  } = useTypedSelector((state) => state.orders);
  const { t } = useTranslation();

  return (
    <div className={styles.successOrder}>
      <div className={styles.successOrder__imageHolder}>
        <img
          className={styles.successOrder__image}
          src={success}
          alt="Success"
        />
      </div>
      <div className={styles.successOrder__title}>
        {t("Checkout.SuccessOrder.Title")}
      </div>
      <div className={styles.successOrder__subtitle}>
        {t("Checkout.SuccessOrder.Text")}
      </div>
      <Link className={styles.successOrder__link} to={`/user/profile/orders`}>
        {t("Checkout.SuccessOrder.Button")}
      </Link>
      {calendlyEventUrl && needCalendlyVerification && (
        <div className={styles.requiresConfirmation}>
          <div className={styles.successOrder__subtitle}>
            {t("Checkout.SuccessOrder.CalendlyText")}
          </div>
          <a
            className={styles.successOrder__link}
            href={calendlyEventUrl}
            target="_self"
            rel="noreferrer"
          >
            {t("Checkout.SuccessOrder.CalendlyButton")}
          </a>
        </div>
      )}
      {identityVerificationUrl && !needCalendlyVerification && (
        <div className={styles.requiresConfirmation}>
          <div className={styles.requiresConfirmation__title}>
            {t("Checkout.SuccessOrder.RequiresIdentificationText")}
          </div>
          <a
            className={styles.requiresConfirmation__link}
            href={identityVerificationUrl}
            target="_self"
            rel="noreferrer"
          >
            {t("Checkout.SuccessOrder.RequiresIdentificationButton")}
          </a>
        </div>
      )}
    </div>
  );
};

export default SuccessOrder;
