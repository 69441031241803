import { combineReducers } from "redux";
import { testPackagesReducer } from "./test-packages-reducer";
import { authReducer } from "./auth-reducer";
import { signupReducer } from "./signup-reducer";
import { ordersReducer } from "./orders-reducer";
import { resultsReducer } from "./result-reducer";
import {
  userReducer,
  cartReducer,
  chartReducer,
  childReducer,
  omnivaDepartmentsReducer,
  surveyReducer,
  uploadReducer,
  marketsReducer,
} from "../slices";
import { promoCodesReducer } from "./promo-code-reducer";
import { testKitsReducer } from "./test-kits-reducer";
import { consentsReducer } from "./consents-reduces";

export const rootReducer = combineReducers({
  testPackages: testPackagesReducer,
  auth: authReducer,
  signup: signupReducer,
  cart: cartReducer,
  orders: ordersReducer,
  results: resultsReducer,
  chart: chartReducer,
  user: userReducer,
  upload: uploadReducer,
  promoCodes: promoCodesReducer,
  testKits: testKitsReducer,
  consents: consentsReducer,
  child: childReducer,
  omnivaDepartments: omnivaDepartmentsReducer,
  survey: surveyReducer,
  markets: marketsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
