import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ConsentsBlock.module.scss";
import ConsentElement from "components/Consent";
import { useTypedSelector } from "hooks";
import { ErrorMessages, ErrorsObjectType, Consent } from "types";

type Props = {
  stripeFormError: string;
  pageConsents: Record<string, boolean>;
  setPageConsents: (value: Record<string, boolean>) => void;
  requiredConsents: Consent[];
};

const ConsentsBlock = ({
  stripeFormError,
  pageConsents,
  setPageConsents,
  requiredConsents,
}: Props): JSX.Element => {
  const { createOrderErrorObject } = useTypedSelector((state) => state.orders);
  const { t } = useTranslation();

  const prepareErrorMessage = (createOrderErrorObject: ErrorsObjectType) => {
    if (
      createOrderErrorObject.cardError === ErrorMessages.maxQuantityExceeded
    ) {
      return t("Checkout.Errors.MaxQuantityExceeded");
    }

    return createOrderErrorObject.cardError;
  };

  return (
    <div className={styles.block}>
      {(createOrderErrorObject.cardError || stripeFormError) && (
        <div className={styles.block__cardError}>
          <div className={styles.block__cardErrorText}>
            {createOrderErrorObject.cardError
              ? prepareErrorMessage(createOrderErrorObject)
              : stripeFormError}
          </div>
        </div>
      )}
      {requiredConsents?.map((consent) => {
        return (
          <ConsentElement
            key={consent.id}
            id={consent.id}
            checked={pageConsents[consent.name]}
            setChecked={() => {
              setPageConsents({
                ...pageConsents,
                [consent.name]: !pageConsents[consent.name],
              });
            }}
          />
        );
      })}
    </div>
  );
};

export default ConsentsBlock;
