import * as React from "react";
import { Route } from "react-router-dom";

import styles from "./Profile.module.scss";

import Settings from "components/Profile/Settings";
import Orders from "components/Profile/Orders";
import OrderInfo from "components/Profile/OrderInfo";
import Contacts from "components/Profile/Contacts";
import SideMenu from "components/Profile/SideMenu";

const Profile = (): JSX.Element => {
  return (
    <div className={styles.profile}>
      <div className={styles.profile__content}>
        <Route component={Settings} path="/user/profile/settings" exact />
        <Route component={Orders} path="/user/profile/orders" exact />
        <Route
          component={OrderInfo}
          path="/user/profile/orders/:orderId"
          exact
        />
        <Route component={Contacts} path="/user/profile/contacts" exact />
        <SideMenu />
      </div>
    </div>
  );
};

export default Profile;
