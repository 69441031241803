import { checkSolid } from "assets/image";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import styles from "./Result.module.scss";

type Props = {
  success: boolean;
};

export const Result = ({ success }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = React.useState(false);

  const handleSubmit = () => {
    setRedirect(true);
  };

  return (
    <div className={styles.result}>
      <div className={styles.result__check}>
        <img
          alt="icon"
          src={checkSolid}
          className={styles.result__check__img}
        />
      </div>
      <p className={styles.result__title}>
        {t("Survey.CardiacRiskResult.Title")}
      </p>
      <p className={styles.result__text}>
        {t("Survey.CardiacRiskResult.Text")}
      </p>
      {!success && (
        <p className={styles.result__failed}>
          {t("Survey.CardiacRiskResult.Failed")}
        </p>
      )}
      <button className={styles.result__btn} onClick={handleSubmit}>
        {redirect && <Redirect to="/" />}
        {t("Survey.CardiacRiskResult.Btn")}
      </button>
    </div>
  );
};
