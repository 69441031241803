import { Dispatch } from "redux";

import testKitsService from "services/testKitsService";
import { TestKitsActionTypes } from "types";

const getActiveTestKit = (hash: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: TestKitsActionTypes.PENDING_ACTIVE_TEST_KITS,
    });

    try {
      const result = await testKitsService.fetchTestKitByHash(hash);

      const resultWithValidDate = {
        ...result,
        creationDate: result.creationDate.seconds * 1000,
        usedDate: result.usedDate ? result.usedDate.seconds * 1000 : null,
      };

      dispatch({
        type: TestKitsActionTypes.FULFILLED_ACTIVE_TEST_KITS,
        payload: resultWithValidDate,
      });
    } catch (e: any) {
      console.error("GETTING ACTIVE TEST KIT ERROR: ", e);

      dispatch({
        type: TestKitsActionTypes.REJECTED_ACTIVE_TEST_KITS,
        payload: e.message || e.errors.message,
      });
    }
  };
};

const clearTestKitsState = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: TestKitsActionTypes.CLEAR_TEST_KITS_STATE,
    });
  };
};

export { getActiveTestKit, clearTestKitsState };
