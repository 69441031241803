import { createSlice } from "@reduxjs/toolkit";

import chartService from "services/chartService";
import {
  CalculatedResult,
  ChartItemType,
  PackageType,
  ScaleItemType,
} from "types";

interface ChartState {
  currentTestResults: ChartItemType[];
  currentTestId: string;
  currentYear: number;
  ranges: ScaleItemType[];
  tickValues: number[];
  isLoadedResult: boolean;
}

const initialState: ChartState = {
  currentTestResults: [],
  currentTestId: "",
  currentYear: 2021,
  ranges: [],
  tickValues: [],
  isLoadedResult: false,
};

const chartSlice = createSlice({
  name: "cartReducer",
  initialState,
  reducers: {
    updateState: (state, action) => {
      const result = action.payload as CalculatedResult;
      const firstResult = Object.values(result.testResults).filter(
        (testResult) => testResult.testType === PackageType.Numeric
      )[0];
      const currentYear = chartService.calculateCurrentResultYear(firstResult);
      const currentTestId = firstResult.testId;
      const currentTestResults = chartService.filterChartDataByYear(
        result.testResults[currentTestId].chart,
        currentYear
      );
      const ranges: ScaleItemType[] = result.testResults[currentTestId].scale;
      const tickValues = chartService.calculateTickValues(ranges);
      return {
        currentTestId,
        currentYear,
        currentTestResults,
        ranges,
        tickValues,
        isLoadedResult: true,
      };
    },
    setCurrentYear: (state, action) => {
      const { result, currentYear } = action.payload;
      const currentTestResults = chartService.filterChartDataByYear(
        result.testResults[state.currentTestId].chart,
        currentYear
      );
      return {
        ...state,
        currentYear,
        currentTestResults,
      };
    },
    setCurrentTestId: (state, action) => {
      const { result, currentTestId } = action.payload;
      const currentYear = chartService.calculateCurrentResultYear(
        result.testResults[currentTestId]
      );
      const currentTestResults = chartService.filterChartDataByYear(
        result.testResults[currentTestId].chart,
        currentYear
      );
      const ranges: ScaleItemType[] = result.testResults[currentTestId].scale;
      const tickValues = chartService.calculateTickValues(ranges);
      return {
        ...state,
        currentTestId,
        currentYear,
        currentTestResults,
        ranges,
        tickValues,
      };
    },
    clearChartState: () => {
      return initialState;
    },
  },
});

export default chartSlice.reducer;

export const {
  setCurrentTestId,
  setCurrentYear,
  updateState,
  clearChartState,
} = chartSlice.actions;
