import { createAsyncThunk } from "@reduxjs/toolkit";

import omnivaService from "services/omnivaService";
import { OmnivaDepartment } from "types";

export const getDepartmentsList = createAsyncThunk<
  OmnivaDepartment[],
  void,
  { rejectValue: string }
>("omniva/getDepartmentsList", async (_, { rejectWithValue }) => {
  try {
    const departmentsList = await omnivaService.fetchOmnivaDepartmentsList();
    return departmentsList;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
