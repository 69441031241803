import { convertAnswersToArrayOfObjects } from "./survey";
import { Survey } from "types";

export const isCardiacButtonDisabled = (
  survey: Survey,
  answers: { [p: string]: string | number | null }
): boolean => {
  return Object.values(survey.questions).length ===
    Object.values(answers).length
    ? convertAnswersToArrayOfObjects(answers)
        .map((value) => Boolean(value.answer))
        .every((value) => value)
    : false;
};
