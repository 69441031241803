import * as React from "react";
import { NavLink, useHistory, RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./SideMenu.module.scss";

import {
  profileSettings,
  profileFolder,
  profileContacts,
  profileExit,
} from "assets/image";
import { useTypedSelector } from "hooks";
import { logout } from "store/actions";

const SideMenu = (): JSX.Element => {
  const { user } = useTypedSelector((state) => state.user);
  const history = useHistory();
  const { t } = useTranslation();

  const logoutWithReload = (
    e: React.MouseEvent,
    history: RouteComponentProps["history"]
  ): void => {
    e.preventDefault();
    logout();
    history.push("/");
    window.location.reload();
  };

  return (
    <div className={styles.sideMenu}>
      <div className={styles.sideMenu__titleBlock}>
        <div className={styles.sideMenu__titleText}>
          {`${user.firstName} ${user.lastName}`}
        </div>
      </div>
      <div className={styles.sideMenu__tabs}>
        <div className={styles.sideMenu__tabBlock}>
          <NavLink
            className={styles.sideMenu__link}
            activeClassName={styles.sideMenu__activeLink}
            to={"/user/profile/orders"}
          >
            <img
              className={`${styles.sideMenu__linkImage} ${styles.profileImage}`}
              src={profileFolder}
              alt="Orders"
            />
            <div className={styles.sideMenu__linkText}>
              {t("Profile.OrderList")}
            </div>
          </NavLink>
        </div>
        <div className={styles.sideMenu__tabBlock}>
          <NavLink
            className={styles.sideMenu__link}
            activeClassName={styles.sideMenu__activeLink}
            to={"/user/profile/settings"}
          >
            <img
              className={`${styles.sideMenu__linkImage} ${styles.profileSettings}`}
              src={profileSettings}
              alt="Settings"
            />
            <div className={styles.sideMenu__linkText}>
              {t("Profile.SideMenu.Settings")}
            </div>
          </NavLink>
        </div>
        <div className={styles.sideMenu__tabBlock}>
          <NavLink
            className={styles.sideMenu__link}
            activeClassName={styles.sideMenu__activeLink}
            to={"/user/profile/contacts"}
          >
            <img
              className={`${styles.sideMenu__linkImage} ${styles.profileContacts}`}
              src={profileContacts}
              alt="Contacts"
            />
            <div className={styles.sideMenu__linkText}>
              {t("Contacts.Title")}
            </div>
          </NavLink>
        </div>
        <div className={styles.sideMenu__tabBlock}>
          <NavLink
            className={styles.sideMenu__link}
            activeClassName={styles.sideMenu__activeLink}
            to={"/user/profile/logout"}
            onClick={(e) => logoutWithReload(e, history)}
          >
            <img
              className={`${styles.sideMenu__linkImage} ${styles.profileExit}`}
              src={profileExit}
              alt="Exit"
            />
            <div className={styles.sideMenu__linkText}>
              {t("Profile.SideMenu.Logout")}
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
