import * as React from "react";

import styles from "./Benefits.module.scss";

import i18next from "internationalization";
import {
  productApprove,
  productMicroscope,
  productDNA,
  productHouse,
  productDocument,
  productAward,
  productPeople,
} from "assets/image";
import { CollectionMethod } from "types";

type Props = {
  collectionMethod: CollectionMethod;
};

const Benefits = ({ collectionMethod }: Props): JSX.Element => {
  const fromSaliva = [
    {
      id: 0,
      text: i18next.t("Product.Benefits.FromSaliva.Text0"),
      icon: productApprove,
    },
    {
      id: 1,
      text: i18next.t("Product.Benefits.FromSaliva.Text1"),
      icon: productMicroscope,
    },
    {
      id: 2,
      text: i18next.t("Product.Benefits.FromSaliva.Text2"),
      icon: productDNA,
    },
    {
      id: 3,
      text: i18next.t("Product.Benefits.FromSaliva.Text3"),
      icon: productHouse,
    },
    {
      id: 4,
      text: i18next.t("Product.Benefits.FromSaliva.Text4"),
      icon: productDocument,
    },
    {
      id: 5,
      text: i18next.t("Product.Benefits.FromSaliva.Text5"),
      icon: productAward,
    },
  ];

  const fromBlood = [
    {
      id: 0,
      text: i18next.t("Product.Benefits.FromBlood.Text0"),
      icon: productHouse,
    },
    {
      id: 1,
      text: i18next.t("Product.Benefits.FromBlood.Text1"),
      icon: productDocument,
    },
    {
      id: 2,
      text: i18next.t("Product.Benefits.FromBlood.Text2"),
      icon: productPeople,
    },
  ];

  return (
    <>
      {(collectionMethod === CollectionMethod.FingerPrickBlood ||
        collectionMethod === CollectionMethod.UrineSample) && (
        <>
          <div className={`${styles.benefits} ${styles.benefits__fromBlood}`}>
            <div className={styles.benefits__list}>
              {fromBlood.map((benefit) => {
                return (
                  <div className={styles.benefits__item} key={benefit.id}>
                    <div className={styles.benefits__imageHolder}>
                      <img
                        className={styles.benefits__image}
                        src={benefit.icon}
                        alt="Benefit"
                      />
                    </div>
                    <div className={styles.benefits__description}>
                      {benefit.text}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      {collectionMethod === CollectionMethod.SalivaSample && (
        <>
          <div className={`${styles.benefits} ${styles.benefits__fromSaliva}`}>
            <div className={styles.benefits__list}>
              {fromSaliva.map((benefit) => {
                return (
                  <div className={styles.benefits__item} key={benefit.id}>
                    <div className={styles.benefits__imageHolder}>
                      <img
                        className={styles.benefits__image}
                        src={benefit.icon}
                        alt="Benefit"
                      />
                    </div>
                    <div className={styles.benefits__description}>
                      {benefit.text}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Benefits;
