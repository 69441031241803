import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Contacts.module.scss";

import ContactsComponent from "components/ContactsPage/Contacts";

const Contacts = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.contacts}>
      <div className={styles.contacts__title}>{t("Contacts.Title")}</div>
      <ContactsComponent onProfilePage />
    </div>
  );
};

export default Contacts;
