import { Dispatch } from "redux";

import consentsService from "services/consentsService";
import { ConsentsActionTypes } from "types";

const getConsents = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ConsentsActionTypes.PENDING_CONSENTS,
    });

    try {
      const consents = await consentsService.fetchConsents();

      dispatch({
        type: ConsentsActionTypes.FULFILLED_CONSENTS,
        payload: consents,
      });
    } catch (e: any) {
      console.error("GETTING CONSENTS ERROR: ", e);

      dispatch({
        type: ConsentsActionTypes.REJECTED_CONSENTS,
        payload: e.message || e.errors.message,
      });
    }
  };
};

export { getConsents };
