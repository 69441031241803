import { PriceView, PromoCode, CartItemType, PromoCodeOperation } from "types";

export const getPrice = (
  type: PriceView,
  activePromoCode: PromoCode | null,
  cart: CartItemType[]
): number => {
  if (type == PriceView.default) {
    return cart.reduce((value, { prices, quantity }) => {
      return value + (prices.OneTime.price * quantity) / 100;
    }, 0);
  } else if (type == PriceView.withDiscount) {
    const cartPrice = cart.reduce((value, { price, quantity }) => {
      return value + (price * quantity) / 100;
    }, 0);

    if (activePromoCode?.operation === PromoCodeOperation.percentage) {
      return cartPrice * ((100 - activePromoCode.valueOff) / 100);
    } else if (activePromoCode?.operation === PromoCodeOperation.subtraction) {
      return cartPrice - activePromoCode.valueOff / 100;
    }

    return cartPrice;
  } else {
    return 0;
  }
};
