import { createSlice } from "@reduxjs/toolkit";

import {
  getSurvey,
  getSurveyList,
  setIsFileEncrypted,
  setIsFilePasswordCorrect,
  uploadSurveyResult,
} from "store/thunks";
import { Survey, SurveyListItem } from "types";

interface SurveyState {
  isSurveyListLoading: boolean;
  isContactInfoAllowed: boolean;
  surveyList: SurveyListItem[];
  surveyListError: string | undefined;
  isSurveyLoading: boolean;
  survey: Survey;
  surveyError: string | undefined;
  isPendingSurveyResult: boolean;
  surveyResultSended: boolean;
  pendingSurveyResultError: any;
  isFileUploaded: { [key: string]: boolean };
  triedUploadFile: { [key: string]: boolean };
  isFileEncrypted: { [key: string]: boolean };
  filePassword: { [key: string]: string };
  filePasswordError: { [key: string]: string | undefined };
  isFilePasswordCorrect: { [key: string]: boolean };
  email: string;
  surveyAnswers: Array<{ [key: string]: number | string | null }>;
  sessionUrl: string | null;
  isPendingCheckoutSession: boolean;
  pendingCheckoutSessionError: string | undefined;
}

const initialState: SurveyState = {
  isSurveyListLoading: false,
  isContactInfoAllowed: false,
  surveyList: [],
  surveyListError: "",
  isSurveyLoading: false,
  survey: {} as Survey,
  surveyError: "",
  isPendingSurveyResult: false,
  surveyResultSended: false,
  pendingSurveyResultError: "",
  isFileUploaded: {},
  triedUploadFile: {},
  isFileEncrypted: {},
  filePassword: {},
  filePasswordError: {},
  isFilePasswordCorrect: {},
  email: "",
  surveyAnswers: [],
  sessionUrl: null,
  isPendingCheckoutSession: false,
  pendingCheckoutSessionError: "",
};

const surveySlice = createSlice({
  name: "surveyReducer",
  initialState,
  reducers: {
    clearCurrentSurveyState: (state) => {
      state.surveyResultSended = false;
      state.isSurveyLoading = false;
      state.isContactInfoAllowed = false;
      state.email = "";
      state.surveyAnswers = [];
      state.survey = {} as Survey;
      state.surveyError = "";
      state.isPendingSurveyResult = false;
      state.surveyResultSended = false;
      state.pendingSurveyResultError = "";
      state.isFileUploaded = {};
      state.triedUploadFile = {};
      state.isFileEncrypted = {};
      state.filePassword = {};
      state.isFilePasswordCorrect = {};
      state.filePasswordError = {};
      state.sessionUrl = null;
      state.isPendingCheckoutSession = false;
      state.pendingCheckoutSessionError = "";
    },
    setIsFileUploaded: (state, action) => {
      state.isFileUploaded = action.payload;
    },
    setFilePassword: (state, action) => {
      state.filePassword = {
        ...state.filePassword,
        [action.payload.fileId]: action.payload.password
          ? action.payload.password
          : undefined,
      };
    },
    setTriedUploadFile: (state, action) => {
      state.triedUploadFile = action.payload;
    },
    setPendingSurveyResultError: (state, action) => {
      state.pendingSurveyResultError = action.payload;
    },
    unloadFile: (state, action) => {
      state.isFileUploaded = {
        ...state.isFileUploaded,
        [action.payload]: false,
      };
      state.triedUploadFile = {
        ...state.triedUploadFile,
        [action.payload]: false,
      };
      state.isFileEncrypted = {
        ...state.isFileEncrypted,
        [action.payload]: false,
      };
      state.filePassword = {
        ...state.filePassword,
        [action.payload]: "",
      };
      state.isFilePasswordCorrect = {
        ...state.isFilePasswordCorrect,
        [action.payload]: false,
      };
      state.filePasswordError = {
        ...state.filePasswordError,
        [action.payload]: undefined,
      };
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setSurveyAnswers: (state, action) => {
      state.surveyAnswers = action.payload;
    },
    allowContactInfo: (state, action) => {
      state.isContactInfoAllowed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setIsFileEncrypted.fulfilled, (state, action) => {
        state.isFileEncrypted = {
          ...state.isFileEncrypted,
          [action.payload.fileId]: action.payload.isEncrypted,
        };
      })
      .addCase(setIsFilePasswordCorrect.fulfilled, (state, action) => {
        state.isFilePasswordCorrect = {
          ...state.isFilePasswordCorrect,
          [action.payload.fileId]: action.payload.isPasswordCorrect,
        };
        state.filePasswordError = {
          ...state.filePasswordError,
          [action.payload.fileId]: action.payload.isPasswordCorrect
            ? undefined
            : "InvalidPassword",
        };
      })
      .addCase(getSurveyList.pending, (state) => {
        state.isSurveyListLoading = true;
      })
      .addCase(getSurveyList.fulfilled, (state, action) => {
        state.isSurveyListLoading = false;
        state.surveyList = action.payload;
      })
      .addCase(getSurveyList.rejected, (state, action) => {
        state.isSurveyListLoading = false;
        state.surveyListError = action.payload;
      })
      .addCase(getSurvey.pending, (state) => {
        state.isSurveyLoading = true;
      })
      .addCase(getSurvey.fulfilled, (state, action) => {
        state.isSurveyLoading = false;
        state.survey = action.payload;
      })
      .addCase(getSurvey.rejected, (state, action) => {
        state.isSurveyLoading = false;
        state.surveyError = action.payload;
      })
      .addCase(uploadSurveyResult.pending, (state) => {
        state.isPendingSurveyResult = true;
        state.sessionUrl = null;
      })
      .addCase(uploadSurveyResult.fulfilled, (state, action) => {
        state.isPendingSurveyResult = false;
        state.surveyResultSended = true;
        state.sessionUrl = action.payload.surveySessionUrl;
      })
      .addCase(uploadSurveyResult.rejected, (state, action) => {
        state.isPendingSurveyResult = false;
        state.pendingSurveyResultError = action.payload;
        state.isFileUploaded = {};
        state.sessionUrl = null;
      });
  },
});

export default surveySlice.reducer;
export const {
  clearCurrentSurveyState,
  setIsFileUploaded,
  setPendingSurveyResultError,
  setTriedUploadFile,
  setFilePassword,
  setEmail,
  setSurveyAnswers,
  unloadFile,
  allowContactInfo,
} = surveySlice.actions;
