import * as React from "react";

import styles from "./Tag.module.scss";

type Props = {
  testId: string;
  testName: string;
  description: string;
};

const Tag = ({ testId, testName, description }: Props): JSX.Element => {
  const [hintVisible, setHintVisible] = React.useState(false);

  return (
    <div className={styles.tagWrapper}>
      <div
        className={styles.tag}
        onMouseOver={() => setHintVisible(true)}
        onFocus={() => setHintVisible(true)}
        onMouseOut={() => setHintVisible(false)}
        onBlur={() => setHintVisible(false)}
      >
        {testName}
      </div>
      {hintVisible && <div className={styles.hint}>{description}</div>}
    </div>
  );
};

export default Tag;
