import {
  PromoCodesActionTypes,
  PromoCodesAction,
  PromoCodesState,
} from "types";

const initialState: PromoCodesState = {
  isLoadingPromoCodes: true,
  promoCodes: [],
  getPromoCodesError: "",
  activePromoCode: null,
};

export const promoCodesReducer = (
  state: PromoCodesState = initialState,
  action: PromoCodesAction
): PromoCodesState => {
  switch (action.type) {
    case PromoCodesActionTypes.PENDING_PROMO_CODES: {
      return {
        ...state,
        isLoadingPromoCodes: true,
      };
    }

    case PromoCodesActionTypes.FULFILLED_PROMO_CODES: {
      return {
        ...state,
        isLoadingPromoCodes: false,
        promoCodes: action.payload,
      };
    }

    case PromoCodesActionTypes.REJECTED_PROMO_CODES: {
      return {
        ...state,
        isLoadingPromoCodes: false,
        getPromoCodesError: action.payload,
      };
    }

    case PromoCodesActionTypes.SET_ACTIVE_PROMO_CODE: {
      return {
        ...state,
        activePromoCode: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
