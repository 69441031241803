import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./SurveyItem.module.scss";

type Props = {
  nameT: string;
  link: string;
};

const SurveyItem = ({ nameT, link }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.surveyItem}>
      <div className={styles.surveyItem__name}>{nameT}</div>
      <Link className={styles.surveyItem__link} to={link}>
        {t("Survey.SurveyItem.View")}
      </Link>
    </div>
  );
};

export default SurveyItem;
