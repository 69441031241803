import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./ProcessingPage.module.scss";

import { clock } from "assets/image";

type Props = {
  titleTextKeys: string[];
  descriptionTextKeys: string[];
  customUrl?: string;
  buttonTextKey?: string;
};

const ProcessingPage = ({
  titleTextKeys,
  descriptionTextKeys,
  customUrl,
  buttonTextKey,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.processingPage}>
      <div className={styles.processingPage__imageHolder}>
        <img className={styles.processingPage__image} src={clock} alt="Clock" />
      </div>
      <div className={styles.processingPage__title}>
        {titleTextKeys.map((key) => {
          return t(`StatusPages.ProcessingPage.${key}`);
        })}
      </div>
      <div className={styles.processingPage__subtitle}>
        {descriptionTextKeys.map((key) => {
          return t(`StatusPages.ProcessingPage.${key}`);
        })}
      </div>
      <Link
        className={styles.processingPage__link}
        to={customUrl ? customUrl : "/"}
      >
        {t(
          `StatusPages.ProcessingPage.${
            buttonTextKey ? buttonTextKey : "Button"
          }`
        )}
      </Link>
    </div>
  );
};

export default ProcessingPage;
