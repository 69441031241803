import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { useTypedSelector } from "hooks";

interface PublicRouteRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}
const getRedirectLink = (hash: string): string => {
  const redirectLink = localStorage.getItem("redirectLink");

  if (redirectLink) {
    return redirectLink;
  }

  if (hash) {
    return "/checkout";
  }

  return "/dashboard";
};

const PublicRoute = ({
  component: Component,
  ...rest
}: PublicRouteRouteProps): JSX.Element => {
  const { isAuthorized } = useTypedSelector((state) => state.auth);
  const { hash } = useTypedSelector((state) => state.orders);
  const redirectLink = getRedirectLink(hash);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized ? <Redirect to={redirectLink} /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
