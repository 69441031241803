import ReactPixel from "react-facebook-pixel";

import config from "config";
import { firebaseAnalytics } from "firebaseApp";

class AnalyticsService {
  createGAEvent = (name: string, parameters: { [key: string]: any }): void => {
    firebaseAnalytics.logEvent(name, { ...parameters, currency: "EUR" });
  };

  createFacebookEvent = (
    type: "custom" | "default",
    eventName: string,
    data?: {
      contents: { id: string; name: string; quantity: number }[];
      value: string;
      currency: "EUR" | "USD";
    }
  ): void => {
    if (config.appEnvironment === "production") {
      if (type === "custom") {
        ReactPixel.trackCustom(eventName, data);
      }

      if (type === "default") {
        ReactPixel.track(eventName, data);
      }
    }
  };
}

export default new AnalyticsService();
