import { createSlice } from "@reduxjs/toolkit";

import { getUser, setUser } from "store/thunks";
import { UserType, ErrorsObjectType } from "types";

interface UserState {
  userLoading: boolean;
  isOpenUserPopup: boolean;
  user: UserType | Record<string, never>;
  userError: ErrorsObjectType | Record<string, never> | undefined;
}

const initialState: UserState = {
  userLoading: false,
  user: {},
  isOpenUserPopup: false,
  userError: {},
};

const userSlice = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setIsOpenUserPopup: (state, action) => {
      state.isOpenUserPopup = action.payload;
    },
    clearUserState: (state) => {
      state.isOpenUserPopup = false;
      state.userLoading = false;
      state.userError = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userLoading = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload;
      })
      .addCase(setUser.pending, (state) => {
        state.userLoading = true;
        state.userError = {};
      })
      .addCase(setUser.fulfilled, (state, action) => {
        state.userLoading = false;
        state.user = action.payload;
      })
      .addCase(setUser.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload;
      });
  },
});

export default userSlice.reducer;

export const { setIsOpenUserPopup, clearUserState } = userSlice.actions;
