import React from "react";
import "./ResizableSpinner.scss";

const ResizableSpinner = (): JSX.Element => (
  <div className="spinnerWrapper">
    <div className="loadingio-spinner-spinner-yu86wwsp84r">
      <div className="ldio-7v0ai4u9zf5">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default ResizableSpinner;
