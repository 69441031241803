import React from "react";
import { useTranslation } from "react-i18next";

import style from "./TestResultNumeric.module.scss";

import {
  TestResultItemType,
  ChartItemType,
  ScaleItemType,
  NormStatus,
  ColorValues,
} from "types";
import { useMediaQueryContext } from "components/MediaQueryProvider";
import { testOk, testNotOk } from "assets/image";

type Props = {
  item: TestResultItemType;
};

export default function TestResultNumeric({ item }: Props): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useMediaQueryContext();
  const currentResult = item.chart.find((res: ChartItemType) => res.current);
  const prevResult = item.chart.find((res: ChartItemType) => res.previous);
  const lineWidth = isMobile ? 343 : 696;
  const textBlockWidth = 30;
  const colorBarsTotalWidth =
    lineWidth - textBlockWidth * (item.scale.length + 1);
  const minRangeValue = item.scale[0].min;
  const maxRangeValue = item.scale[item.scale.length - 1].max;
  const rangeInPoints = maxRangeValue - minRangeValue;

  function calculateWidth(range: ScaleItemType): number {
    return ((range.max - range.min) / rangeInPoints) * colorBarsTotalWidth;
  }

  function calculateRangeXCoord(range: ScaleItemType, index: number): number {
    return (
      (range.min / rangeInPoints) * colorBarsTotalWidth +
      textBlockWidth * (index + 1)
    );
  }

  function calculateResultXCoord(
    range: ScaleItemType,
    rangeXCoord: number,
    width: number,
    result: number
  ): number {
    return (
      rangeXCoord +
      6 +
      ((width - 12) * (result - range.min)) / (range.max - range.min)
    );
  }

  return (
    <div className={style.wrapper}>
      <div className={style.header}>{item.testName}</div>
      {item.status === NormStatus.notCompleted ? (
        <div className={`${style.pannel} ${style.pannel__testFailed}`}>
          {t("Result.Failed")}
        </div>
      ) : (
        <>
          <div
            className={`${style.pannel} ${
              item.status === NormStatus.normal
                ? style.pannel__testNegative
                : style.pannel__testPositive
            }`}
          >
            <img
              src={item.status === NormStatus.normal ? testOk : testNotOk}
              alt="test result"
              className={style.pannel__image}
            ></img>
            <span className={style.pannel__text}>
              {t(`Result.${item.status}`)}
            </span>
            <span className={style.pannel__value}>{`${
              currentResult ? currentResult.result : ""
            } ${item.dimension}`}</span>
          </div>
          <div className={style.colorAxisWrapper}>
            <svg width={lineWidth} height="33">
              {item.scale.map((range: ScaleItemType, index: number) => {
                let resultX = 0;
                const width = calculateWidth(range);
                const rangeXCoord = calculateRangeXCoord(range, index);
                if (item.status === range.status && currentResult) {
                  resultX = calculateResultXCoord(
                    range,
                    rangeXCoord,
                    width,
                    currentResult.result
                  );
                }
                return (
                  <svg key={`${range.min} ${range.status} ${range.max}`}>
                    <text
                      textAnchor="middle"
                      x={rangeXCoord - textBlockWidth / 2}
                      y="26"
                      className={style.colorAxisWrapper__text}
                    >
                      {range.min}
                    </text>
                    <rect
                      x={rangeXCoord}
                      y="17"
                      rx="4"
                      height="8"
                      width={width}
                      fill={ColorValues[range.color]}
                    />
                    {item.status === range.status && currentResult && (
                      <svg>
                        <circle
                          cx={resultX}
                          cy="21"
                          r="12"
                          fillOpacity="0.5"
                          fill={ColorValues.white}
                        />
                        <circle
                          cx={resultX}
                          cy="21"
                          r="10"
                          fill={ColorValues.white}
                        />
                        <circle
                          cx={resultX}
                          cy="21"
                          r="8"
                          fill={ColorValues[range.color]}
                        />
                      </svg>
                    )}
                  </svg>
                );
              })}
              <text
                textAnchor="middle"
                x={lineWidth - textBlockWidth / 2}
                y="26"
                className={style.colorAxisWrapper__text}
              >
                {maxRangeValue}
              </text>
            </svg>
          </div>
        </>
      )}

      <div className={style.footer}>
        {t("Result.Previous")}
        <span className={style.footer__result}>
          {prevResult ? prevResult.result : t("Result.NotStudied")}
        </span>
      </div>
    </div>
  );
}
