import * as React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import FileUploadForm from "components/Survey/FileUploadForm";
import CustomConsent from "components/Consent";
import ResizableSpinner from "components/ResizableSpinner";
import Stage from "components/Stage";
import { useAppDispatch, useTypedSelector } from "hooks";
import { Consent, StagePhase } from "types";
import { LanguageContext } from "context";
import { getSurvey, getSurveyList, uploadSurveyResult } from "store/thunks";
import { isFileAllowed } from "helpers";

import style from "./TestUploadResult.module.scss";

const TestUploadResult = (): JSX.Element => {
  const { currentLanguage } = React.useContext(LanguageContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    email,
    isSurveyListLoading,
    isSurveyLoading,
    survey,
    surveyResultSended,
    isFileUploaded,
    isPendingSurveyResult,
    isFileEncrypted,
    filePassword,
    isFilePasswordCorrect,
    surveyList,
    surveyAnswers,
    isPendingCheckoutSession,
    sessionUrl,
  } = useTypedSelector((state) => state.survey);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const { consents } = useTypedSelector((state) => state.consents);

  const [files, setFiles] = React.useState<{ [key: string]: File | null }>({});
  const [consentsAgreements, setConsentsAgreements] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [requiredConsents, setRequiredConsents] = React.useState<Consent[]>([]);
  const [checkoutRedirect, setCheckoutRedirect] = React.useState(false);

  const isSubmitEnabled =
    Boolean(email) &&
    isFileAllowed(
      isFileUploaded,
      isFileEncrypted,
      isFilePasswordCorrect,
      "lipidograma"
    ) &&
    Boolean(Object.values(consentsAgreements)[0]);

  React.useEffect(() => {
    if (surveyList.length === 0 && !isSurveyListLoading) {
      dispatch(
        getSurveyList({
          currentLanguage: currentLanguage,
          marketId: currentMarket.country,
        })
      );
    }
  }, [
    dispatch,
    currentLanguage,
    isSurveyListLoading,
    currentMarket.country,
    surveyList,
  ]);

  React.useEffect(() => {
    if (surveyList.length > 0) {
      const currentSurveyId =
        surveyList.find((survey) => survey.alias === "cardiac-risk-assessment")
          ?.id || "";
      dispatch(getSurvey(currentSurveyId));
    }
  }, [dispatch, surveyList, currentLanguage]);

  React.useEffect(() => {
    if (Object.keys(survey).length !== 0) {
      setRequiredConsents(
        survey.consents.map(
          (surveyConsent) =>
            consents.find((consent) => consent.id === surveyConsent)!
        )
      );
    }
  }, [survey, survey.consents, consents]);

  React.useEffect(() => {
    if (surveyResultSended && sessionUrl && !isPendingCheckoutSession) {
      window.location.replace(sessionUrl);
    }
  }, [surveyResultSended, sessionUrl, isPendingCheckoutSession, dispatch]);

  if (!email && !Object.keys(surveyAnswers).length) {
    return <Redirect to={"/survey/cardiac-risk-assessment"} />;
  }

  if (
    isSurveyListLoading ||
    isSurveyLoading ||
    isPendingCheckoutSession ||
    isPendingSurveyResult ||
    Object.entries(survey).length === 0 ||
    checkoutRedirect
  ) {
    return (
      <div className={style.surveyList}>
        <div className={style.survey}>
          <ResizableSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className={style.wrapper}>
      <Stage stage={StagePhase.thirdStage} />
      <div className={style.uploadMenu}>
        <>
          <FileUploadForm
            files={files}
            setFiles={setFiles}
            file={survey.files[0]}
            isBigHeader={true}
            noBottomBorder={true}
          />

          <div className={style.uploadMenu__consentWrap}>
            <div className={style.uploadMenu__consent}>
              {requiredConsents.map((consent) => (
                <CustomConsent
                  key={consent.id}
                  id={consent.id}
                  checked={consentsAgreements[consent.name] || false}
                  setChecked={() =>
                    setConsentsAgreements({
                      ...consentsAgreements,
                      [consent.name]: !consentsAgreements[consent.name],
                    })
                  }
                />
              ))}
            </div>
          </div>

          <div className={style.uploadMenu__btnWrap}>
            <button
              disabled={!isSubmitEnabled}
              onClick={() => {
                dispatch(
                  uploadSurveyResult({
                    files: Object.fromEntries(
                      Object.entries(files).filter(
                        ([fileId, file]) => file != null
                      ) as [string, File][]
                    ),
                    answers: JSON.stringify(surveyAnswers),
                    templateId: survey.id,
                    email,
                    pdfPassword: filePassword,
                    consents: consentsAgreements,
                    market: currentMarket.country,
                  })
                );
                setCheckoutRedirect(true);
              }}
              className={
                isSubmitEnabled
                  ? style.uploadMenu__btn
                  : `${style.uploadMenu__btn} ${style.uploadMenu__btn_disabled}`
              }
            >
              {t("Survey.Button")}
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default TestUploadResult;
