import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { NumberQuestionType } from "types";
import { NumberQuestionSchema } from "validation";

import styles from "./NumberQuestion.module.scss";

type Props = {
  question: NumberQuestionType;
  answers: { [key: string]: string | number | null };
  setAnswers: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string | number | null;
    }>
  >;
};

const NumberQuestion = ({
  question,
  setAnswers,
  answers,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const { minNum, maxNum } = question.range;

  const formik = useFormik({
    initialValues: {
      numberQuestion: "",
    },
    onSubmit: (values) => {
      values;
    },
    validationSchema: NumberQuestionSchema(minNum, maxNum),
    validateOnChange: true,
    validateOnBlur: true,
  });

  const numberQuestion =
    typeof formik.values.numberQuestion === "number" &&
    formik.values.numberQuestion >= minNum &&
    formik.values.numberQuestion <= maxNum
      ? formik.values.numberQuestion
      : false;

  React.useEffect(() => {
    setAnswers({ ...answers, [question.id]: Number(numberQuestion) });
  }, [numberQuestion]);

  return (
    <div>
      <input
        id={"numberQuestion"}
        name={"numberQuestion"}
        className={styles.num}
        placeholder={t(question.placeholder)}
        type={"number"}
        value={formik.values.numberQuestion}
        onChange={formik.handleChange}
      />
      <p className={styles.dimension}>{question.dimension}</p>
      {formik.errors.numberQuestion && (
        <div className={styles.error}>
          <div className={styles.error__text}>
            {t(formik.errors.numberQuestion)}{" "}
            {formik.errors.numberQuestion === "MaximalNum" && maxNum}
            {formik.errors.numberQuestion === "MinimalNum" && minNum}
          </div>
        </div>
      )}
    </div>
  );
};

export default NumberQuestion;
