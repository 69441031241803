import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import styles from "./PaymentBlock.module.scss";

import RadioButton from "components/Utilities/RadioButton";
import { useTypedSelector } from "hooks";
import {
  PaymentSystem,
  PaymentType,
  OpayPaymentChannel,
  InsuranceCompany,
} from "types";
import {
  Gjensidige,
  Swedbank,
  SEB,
  Luminor,
  Citadele,
  SiauliuBankas,
  MedicinosBankas,
  bankTransferArrow,
  Seesam,
} from "assets/image";

type Props = {
  insuranceNumber: string;
  setInsuranceNumber: (value: string) => void;
  insuranceNumberTouched: boolean;
  privateCodeTouched: boolean;
  privateCode: string;
  setPrivateCode: (value: string) => void;
  selectedPaymentSystem: PaymentSystem;
  setSelectedPaymentSystem: (value: PaymentSystem) => void;
  activeBank: OpayPaymentChannel;
  setActiveBank: (value: OpayPaymentChannel) => void;
  activeInsuranceCompany: InsuranceCompany;
  setActiveInsuranceCompany: (value: InsuranceCompany) => void;
};

const PaymentBlock = ({
  insuranceNumber,
  insuranceNumberTouched,
  privateCodeTouched,
  privateCode,
  selectedPaymentSystem,
  activeInsuranceCompany,
  activeBank,
  setInsuranceNumber,
  setPrivateCode,
  setSelectedPaymentSystem,
  setActiveBank,
  setActiveInsuranceCompany,
}: Props): JSX.Element => {
  const { cart } = useTypedSelector((state) => state.cart);
  const { currentMarket } = useTypedSelector((state) => state.markets);
  const { t } = useTranslation();
  const cartIncludesTestWithOneTimePaymentType = cart.some(
    (test) => test.paymentType === PaymentType.OneTime
  );
  const acceptablePaymentMethods = currentMarket.config.paymentSystems;

  return (
    <div className={styles.block}>
      <div className={styles.block__head}>
        <div className={styles.block__headText}>
          {t("Checkout.UserInfo.PaymentDetails")}
        </div>
      </div>
      <div className={styles.block__paymentBlock}>
        <div className={styles.block__paymentMethods}>
          {cartIncludesTestWithOneTimePaymentType && (
            <>
              {acceptablePaymentMethods.length > 1 && (
                <label
                  className={
                    selectedPaymentSystem === PaymentSystem.Opay
                      ? `${styles.inputWrapper} ${styles.inputWrapper_selected}`
                      : styles.inputWrapper
                  }
                  onClick={() => setSelectedPaymentSystem(PaymentSystem.Opay)}
                >
                  <RadioButton
                    id="bankPayment"
                    checked={selectedPaymentSystem === PaymentSystem.Opay}
                    setChecked={() =>
                      setSelectedPaymentSystem(PaymentSystem.Opay)
                    }
                  />
                  <div className={styles.block__paymentMethodText}>
                    {t("Checkout.UserInfo.ElBanking")}
                  </div>
                </label>
              )}
              {acceptablePaymentMethods.includes(PaymentSystem.Opay) && (
                <div className={styles.block__paymentData}>
                  {selectedPaymentSystem === PaymentSystem.Opay && (
                    <div className={styles.bankBlock}>
                      <div
                        className={
                          activeBank === OpayPaymentChannel.swedbank
                            ? `${styles.bankBlock__bank} ${styles.bankBlock__bank_active}`
                            : styles.bankBlock__bank
                        }
                        onClick={() =>
                          setActiveBank(OpayPaymentChannel.swedbank)
                        }
                      >
                        <img
                          className={styles.bankBlock__image}
                          src={Swedbank}
                          alt="Swedbank"
                        />
                      </div>
                      <div
                        className={
                          activeBank === OpayPaymentChannel.seb
                            ? `${styles.bankBlock__bank} ${styles.bankBlock__bank_active}`
                            : styles.bankBlock__bank
                        }
                        onClick={() => setActiveBank(OpayPaymentChannel.seb)}
                      >
                        <img
                          className={styles.bankBlock__image}
                          src={SEB}
                          alt="SEB"
                        />
                      </div>
                      <div
                        className={
                          activeBank === OpayPaymentChannel.dnb
                            ? `${styles.bankBlock__bank} ${styles.bankBlock__bank_active}`
                            : styles.bankBlock__bank
                        }
                        onClick={() => setActiveBank(OpayPaymentChannel.dnb)}
                      >
                        <img
                          className={styles.bankBlock__image}
                          src={Luminor}
                          alt="Luminor"
                        />
                      </div>
                      <div
                        className={
                          activeBank === OpayPaymentChannel.citadele
                            ? `${styles.bankBlock__bank} ${styles.bankBlock__bank_active}`
                            : styles.bankBlock__bank
                        }
                        onClick={() =>
                          setActiveBank(OpayPaymentChannel.citadele)
                        }
                      >
                        <img
                          className={styles.bankBlock__image}
                          src={Citadele}
                          alt="Citadele"
                        />
                      </div>
                      <div
                        className={
                          activeBank === OpayPaymentChannel.sb
                            ? `${styles.bankBlock__bank} ${styles.bankBlock__bank_active}`
                            : styles.bankBlock__bank
                        }
                        onClick={() => setActiveBank(OpayPaymentChannel.sb)}
                      >
                        <img
                          className={styles.bankBlock__image}
                          src={SiauliuBankas}
                          alt="Siauliu Bankas"
                        />
                      </div>
                      <div
                        className={
                          activeBank === OpayPaymentChannel.medbank
                            ? `${styles.bankBlock__bank} ${styles.bankBlock__bank_active}`
                            : styles.bankBlock__bank
                        }
                        onClick={() =>
                          setActiveBank(OpayPaymentChannel.medbank)
                        }
                      >
                        <img
                          className={styles.bankBlock__image}
                          src={MedicinosBankas}
                          alt="Medicinos Bankas"
                        />
                      </div>
                      <div
                        className={
                          activeBank === OpayPaymentChannel.banktransfer
                            ? `${styles.bankBlock__bank} ${styles.bankBlock__bank_active}`
                            : styles.bankBlock__bank
                        }
                        onClick={() =>
                          setActiveBank(OpayPaymentChannel.banktransfer)
                        }
                      >
                        <img
                          className={styles.bankBlock__image}
                          src={bankTransferArrow}
                          alt="Bank Transfer Arrow"
                        />
                        <span className={styles.bankBlock__text}>
                          {t("Checkout.UserInfo.BankTransfer")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {acceptablePaymentMethods.length > 1 && (
            <label
              className={
                selectedPaymentSystem === PaymentSystem.Stripe
                  ? `${styles.inputWrapper} ${styles.inputWrapper_selected}`
                  : styles.inputWrapper
              }
              onClick={() => setSelectedPaymentSystem(PaymentSystem.Stripe)}
            >
              <RadioButton
                id="cardPayment"
                checked={selectedPaymentSystem === PaymentSystem.Stripe}
                setChecked={() =>
                  setSelectedPaymentSystem(PaymentSystem.Stripe)
                }
              />
              <div className={styles.block__paymentMethodText}>
                {t("Checkout.UserInfo.PayingCard")}
              </div>
            </label>
          )}
          <div
            className={styles.block__paymentData}
            hidden={!acceptablePaymentMethods.includes(PaymentSystem.Stripe)}
          >
            {selectedPaymentSystem === PaymentSystem.Stripe && (
              <div className={styles.cardForm}>
                <form id="payment-form">
                  <label>
                    <div className={styles.cardForm__numberBlock}>
                      <div className={styles.cardForm__blockTitle}>
                        {t("Checkout.UserInfo.CardNumber")}
                      </div>
                      <div className={styles.cardForm__numberHolder}>
                        <CardNumberElement
                          options={{
                            showIcon: true,
                            iconStyle: "solid",
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.cardForm__expiryAndCvc}>
                      <div className={styles.cardForm__expiryBlock}>
                        <div className={styles.cardForm__blockTitle}>
                          {t("Checkout.UserInfo.DateOfExpire")}
                        </div>
                        <div className={styles.cardForm__expiryHolder}>
                          <CardExpiryElement />
                        </div>
                      </div>
                      <div className={styles.cardForm__cvcBlock}>
                        <div className={styles.cardForm__blockTitle}>
                          {t("Checkout.UserInfo.CVVCode")}
                        </div>
                        <div className={styles.cardForm__cvcHolder}>
                          <CardCvcElement />
                        </div>
                      </div>
                    </div>
                  </label>
                </form>
              </div>
            )}
          </div>
          {cartIncludesTestWithOneTimePaymentType && (
            <>
              {acceptablePaymentMethods.length > 1 && (
                <label
                  className={
                    selectedPaymentSystem === PaymentSystem.Insurance
                      ? `${styles.inputWrapper} ${styles.inputWrapper_selected}`
                      : styles.inputWrapper
                  }
                  onClick={() =>
                    setSelectedPaymentSystem(PaymentSystem.Insurance)
                  }
                >
                  <RadioButton
                    id="insurance"
                    checked={selectedPaymentSystem === PaymentSystem.Insurance}
                    setChecked={() =>
                      setSelectedPaymentSystem(PaymentSystem.Insurance)
                    }
                  />
                  <div className={styles.block__paymentMethodText}>
                    {t("Checkout.UserInfo.HealthInsurance")}
                  </div>
                </label>
              )}
              {acceptablePaymentMethods.includes(PaymentSystem.Insurance) && (
                <div className={styles.block__paymentData}>
                  {selectedPaymentSystem === PaymentSystem.Insurance && (
                    <div className={styles.insuranceBlock}>
                      <div className={styles.insuranceBlock__companies}>
                        <div
                          className={
                            activeInsuranceCompany ===
                            InsuranceCompany.GjensidigeInsurance
                              ? `${styles.insuranceBlock__company} ${styles.insuranceBlock__company_active}`
                              : styles.insuranceBlock__company
                          }
                          onClick={() =>
                            setActiveInsuranceCompany(
                              InsuranceCompany.GjensidigeInsurance
                            )
                          }
                        >
                          <img
                            className={styles.insuranceBlock__companyLogo}
                            src={Gjensidige}
                            alt="Gjensidige"
                          />
                        </div>
                        <div
                          className={
                            activeInsuranceCompany === InsuranceCompany.Seesam
                              ? `${styles.insuranceBlock__company} ${styles.insuranceBlock__company_active}`
                              : styles.insuranceBlock__company
                          }
                          onClick={() =>
                            setActiveInsuranceCompany(InsuranceCompany.Seesam)
                          }
                        >
                          <img
                            className={styles.insuranceBlock__companyLogo}
                            src={Seesam}
                            alt="Seesam"
                          />
                        </div>
                      </div>
                      <div className={styles.insuranceBlock__data}>
                        <div className={styles.insuranceBlock__title}>
                          {t("Checkout.UserInfo.EnterInsuranceCode")}
                          <span className={styles.insuranceBlock__title_red}>
                            *
                          </span>
                        </div>
                        <input
                          className={styles.insuranceBlock__input}
                          value={insuranceNumber}
                          onChange={(e) => setInsuranceNumber(e.target.value)}
                        />
                        {insuranceNumberTouched &&
                          insuranceNumber.length < 4 && (
                            <div className={styles.error}>
                              {insuranceNumber.length === 0
                                ? t("Required")
                                : t("Min4")}
                            </div>
                          )}
                      </div>
                      <div className={styles.insuranceBlock__data}>
                        <div className={styles.insuranceBlock__title}>
                          {t("Checkout.UserInfo.PersonalCode")}
                          <span className={styles.insuranceBlock__title_red}>
                            *
                          </span>
                        </div>
                        <input
                          className={styles.insuranceBlock__input}
                          value={privateCode}
                          onChange={(e) => setPrivateCode(e.target.value)}
                        />
                        {privateCodeTouched && privateCode.length !== 11 && (
                          <div className={styles.error}>
                            {privateCode.length === 0
                              ? t("Required")
                              : t("IdentificationCode")}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentBlock;
