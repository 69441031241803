import { TestPackagesAction, Locale } from "types";

export type InternationalizationStringType = {
  en: string;
  lt: string;
};

export type TestPackageType = {
  id: string;
  packageLabId: string;
  name: string;
  nameT: string;
  title: string;
  description: string;
  descriptionT: string;
  shortDescriptionT: string;
  subDescriptionT: string;
  category: PackageCategory;
  testCode: string;
  collectionMethod: CollectionMethod;
  photos: Array<string>;
  packageType: PackageType;
  tests: Test[];
  prices: Prices;
  priority: number;
  isActive: boolean;
  requiresIdentificationCode: boolean;
  consents: string[];
  maxQuantityForPurchase: null | number;
  locale: Omit<Locale, "language">;
  sampleReport?: string;
};

export type MultiLanguageTestPackageType = {
  id: string;
  packageLabId: string;
  name: string;
  nameT: InternationalizationStringType;
  title: string;
  description: string;
  descriptionT: InternationalizationStringType;
  category: PackageCategory;
  testCode: string;
  collectionMethod: CollectionMethod;
  photos: Array<string>;
  packageType: PackageType;
  tests: MultiLanguageTag[];
  prices: Prices;
  priority: number;
};

export type ResearchItemType = {
  id: number;
  nameT: string;
  divId: string;
  image: string;
  hasDescription: boolean;
  description: string;
  boldDescription: string;
  popular: boolean;
  isNew: boolean;
  researches: any;
  setOpened: (value: boolean) => TestPackagesAction;
  opened: boolean;
};

export type Test = {
  testName: string;
  testNameT: string;
  testId: string;
  description: string;
  descriptionT: string;
};

type MultiLanguageTag = {
  testName: string;
  testNameT: InternationalizationStringType;
  testId: string;
  description: string;
  descriptionT: InternationalizationStringType;
};

export enum PackageCategory {
  Wellness = "Wellness",
  WomensHealth = "WomensHealth",
  MensHealth = "MensHealth",
  Covid = "Covid",
}

export enum CollectionMethod {
  FingerPrickBlood = "FingerPrickBlood",
  UrineSample = "UrineSample",
  SalivaSample = "SalivaSample",
}

export enum PackageType {
  Numeric = "Numeric",
  Boolean = "Boolean",
}

export enum PaymentType {
  OneTime = "OneTime",
  Monthly = "Monthly",
  Every2Months = "Every2Months",
  Every3Months = "Every3Months",
  Every6Months = "Every6Months",
}

export type SubscriptionType = PaymentType.Monthly | PaymentType.Every3Months;

export interface Price {
  price: number;
  stripePriceId: string;
}

export interface Prices {
  [PaymentType.OneTime]: Price;
  [PaymentType.Monthly]: Price;
  [PaymentType.Every2Months]: Price;
  [PaymentType.Every3Months]: Price;
  [PaymentType.Every6Months]: Price;
}
