import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Popular.module.scss";

import config from "config";

const Popular = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.popular}>
      <div className={styles.popular__title}>{t("Main.Popular.Title")}</div>
      <div className={styles.popular__list}>
        <div className={styles.popular__item}>
          <div className={styles.popular__name}>{t("Main.Popular.Name1")}</div>
          <div className={styles.popular__description}>
            {t("Main.Popular.Description1")}
          </div>
          <Link
            className={styles.popular__link}
            to={`/product/${config.popularTestId.covidAntibodies}`}
          >
            {t("Main.Popular.ButtonLearnMore")}
          </Link>
        </div>
        <div className={styles.popular__item}>
          <div className={styles.popular__name}>{t("Main.Popular.Name2")}</div>
          <div className={styles.popular__description}>
            {t("Main.Popular.Description2")}
          </div>
          <Link
            className={styles.popular__link}
            to={`/product/${config.popularTestId.stressHormone}`}
          >
            {t("Main.Popular.ButtonLearnMore")}
          </Link>
        </div>
        <div className={styles.popular__item}>
          <div className={styles.popular__name}>{t("Main.Popular.Name3")}</div>
          <div className={styles.popular__description}>
            {t("Main.Popular.Description3")}
          </div>
          <Link
            className={styles.popular__link}
            to={`/product/${config.popularTestId.energyLevel}`}
          >
            {t("Main.Popular.ButtonLearnMore")}
          </Link>
        </div>
        <div className={styles.popular__item}>
          <div className={styles.popular__name}>{t("Main.Popular.Name4")}</div>
          <div className={styles.popular__description}>
            {t("Main.Popular.Description4")}
          </div>
          <Link
            className={styles.popular__link}
            to={`/product/${config.popularTestId.femaleFertility}`}
          >
            {t("Main.Popular.ButtonLearnMore")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Popular;
