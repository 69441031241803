import { SelectItemType } from "types";

export const filterFiles = (file: File | null): File | null => {
  if (
    file &&
    file.size < 10000000 &&
    file.name.includes(".pdf") &&
    file.name.length < 100
  ) {
    return file;
  }

  return null;
};

export const convertAnswersToArrayOfObjects = (answers: {
  [key: string]: number | string | null;
}): { id: string; answer: number | string | null }[] => {
  return Object.entries(answers).map(([answerId, answerValue]) => ({
    id: answerId,
    answer: answerValue,
  }));
};

export const prepareValuesForDropdown = (
  answerVariants: string[]
): SelectItemType[] => {
  const dropdownAnswerVariants = answerVariants.map((answerVariant) => ({
    label: answerVariant,
    value: answerVariant,
  }));

  dropdownAnswerVariants.unshift({ label: "--", value: "--" });

  return dropdownAnswerVariants;
};
