import { createAsyncThunk } from "@reduxjs/toolkit";

import httpService from "services/httpService";
import { CartItemType } from "types";

export const getCart = createAsyncThunk<
  CartItemType[],
  void,
  { rejectValue: string }
>("cart/getCart", async (_, { rejectWithValue }) => {
  try {
    const cart = await httpService.get("/user/cart");
    return cart;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const setCart = createAsyncThunk<
  CartItemType[],
  any,
  { rejectValue: string }
>("cart/setCart", async (updatedCart: CartItemType[], { rejectWithValue }) => {
  try {
    const newCart = await httpService.put("/user/cart", {
      body: JSON.stringify(updatedCart),
    });
    return newCart;
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
