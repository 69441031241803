import * as React from "react";
import { useTranslation } from "react-i18next";

import styles from "./CortisolActivity.module.scss";

import { cortisolChart, cortisolFormula, cortisolKidney } from "assets/image";

const CortisolActivity = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.title}>
        <img className={styles.titleImage} src={cortisolKidney} alt="Kidney" />
        {t("Docs.CortisolActivity.Title")}
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.column}>
          <div className={styles.text}>
            <span className={styles.text_blue}>
              {t("Docs.CortisolActivity.Blocks.Block1.TextBlue")}
            </span>
            {t("Docs.CortisolActivity.Blocks.Block1.Text")}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.text}>
            <span className={styles.text_blue}>
              {t("Docs.CortisolActivity.Blocks.Block2.TextBlue")}
            </span>
            {t("Docs.CortisolActivity.Blocks.Block2.Text")}
          </div>
        </div>
      </div>
      <img
        className={styles.blockImage}
        src={cortisolChart}
        alt="Cortisol chart"
      />
      <div className={styles.blockContainer}>
        <div className={styles.column}>
          <div className={styles.text}>
            {t("Docs.CortisolActivity.Blocks.Block3.Text")}
            <span className={styles.text_blue}>
              {t("Docs.CortisolActivity.Blocks.Block3.TextBlue")}
            </span>
          </div>
          <img
            className={styles.image}
            src={cortisolFormula}
            alt="Cortisol formula"
          />
        </div>
        <div className={styles.column}>
          <div className={styles.text}>
            <span className={styles.text_blue}>
              {t("Docs.CortisolActivity.Blocks.Block4.TextBlue")}
            </span>
            {t("Docs.CortisolActivity.Blocks.Block4.Text")}
          </div>
          <div className={styles.text}>
            <span className={styles.text_blue}>
              {t("Docs.CortisolActivity.Blocks.Block5.TextBlue")}
            </span>
            {t("Docs.CortisolActivity.Blocks.Block5.Text")}
          </div>
          <div className={styles.text}>
            <span className={styles.text_blue}>
              {t("Docs.CortisolActivity.Blocks.Block6.TextBlue")}
            </span>
            {t("Docs.CortisolActivity.Blocks.Block6.Text")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CortisolActivity;
