import dayjs from "dayjs";

import {
  CalculatedResult,
  ChartItemType,
  PackageType,
  ScaleItemType,
  SelectItemType,
  TestResultItemType,
} from "types";

class ChartService {
  filterChartDataByYear = (
    chart: ChartItemType[],
    year: number
  ): ChartItemType[] => {
    return chart.filter((item) => dayjs(item.date).year() === year);
  };

  calculateCurrentResultYear = (firstTest: TestResultItemType): number => {
    const currentResult = firstTest.chart.find((item) => item.current)!;

    return dayjs(currentResult.date).year();
  };

  prepareYearsOptionItems = (chart: ChartItemType[]): SelectItemType[] => {
    const years = [] as number[];
    chart.forEach((item) => {
      const currentYear = dayjs(item.date).year();
      if (years.indexOf(currentYear) === -1) {
        years.push(currentYear);
      }
    });
    const yearsOptionItems = years.map((item) => ({
      label: item.toString(),
      value: item.toString(),
    }));
    return yearsOptionItems;
  };

  prepareTestsOptionItems = (result: CalculatedResult): SelectItemType[] => {
    const testsOptionItems = Object.values(result.testResults)
      .filter((testResult) => testResult.testType === PackageType.Numeric)
      .map((item) => ({
        label: item.testName,
        value: item.testId,
      }));
    return testsOptionItems;
  };

  calculateTickValues = (ranges: ScaleItemType[]) => {
    const result = [ranges[0].min];
    const delta = (ranges[ranges.length - 1].max - ranges[0].min) / 5;
    for (let i = 1; i < 6; i += 1) {
      result.push(Math.floor(result[0] * 1000 + delta * 1000 * i) / 1000);
    }
    return result;
  };
}

export default new ChartService();
