import { AuthActionTypes, ErrorsObjectType, AuthAction } from "types";

interface AuthState {
  authLoading: boolean;
  isAuthorized: boolean;
  authErrorsObject: ErrorsObjectType;
}

const initialState: AuthState = {
  authLoading: false,
  isAuthorized: false,
  authErrorsObject: {},
};

export const authReducer = (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.SET_IS_AUTHORIZED: {
      return {
        authLoading: false,
        authErrorsObject: {},
        isAuthorized: action.payload,
      };
    }

    case AuthActionTypes.SET_AUTH_ERRORS_OBJECT: {
      return {
        ...state,
        authLoading: false,
        authErrorsObject: action.payload,
      };
    }

    case AuthActionTypes.SET_AUTH_LOADING: {
      return {
        ...state,
        authLoading: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
