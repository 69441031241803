import { createAsyncThunk } from "@reduxjs/toolkit";

import httpService from "services/httpService";
import { SurveyListItem, Survey, SurveyDTO, Language, Country } from "types";
import {
  isFileEncrypted,
  isFilePasswordCorrect,
  translateObject,
} from "helpers";
import analyticsService from "services/analyticsService";

export const getSurveyList = createAsyncThunk<
  SurveyListItem[],
  { currentLanguage: Language; marketId: Country },
  { rejectValue: string }
>(
  "survey/getSurveyList",
  async (
    {
      currentLanguage,
      marketId,
    }: { currentLanguage: Language; marketId: Country },
    { rejectWithValue }
  ) => {
    try {
      const surveyList = await httpService.get("/survey/");

      const filteredSurveyList = surveyList.filter(
        (survey: Survey) => survey.market === marketId
      );

      return translateObject(filteredSurveyList, currentLanguage);
    } catch (e: any) {
      console.error("GET SURVEY LIST ERROR: ", e);

      return rejectWithValue(e);
    }
  }
);

export const getSurvey = createAsyncThunk<
  Survey,
  string,
  { rejectValue: string }
>("survey/getSurvey", async (surveyId: string, { rejectWithValue }) => {
  try {
    const survey = await httpService.get(`/survey/${surveyId}`);

    return survey;
  } catch (e: any) {
    console.error("GET SURVEY ERROR: ", e);

    return rejectWithValue(e);
  }
});

export const uploadSurveyResult = createAsyncThunk<
  { surveySessionUrl: string | null },
  SurveyDTO,
  { rejectValue: string }
>(
  "survey/uploadSurveyResult",
  async (formDTO: SurveyDTO, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      Object.entries(formDTO.files).forEach(([fileId, file]) =>
        formData.append(fileId, file)
      );
      formData.append("answers", formDTO.answers);
      formData.append("templateId", formDTO.templateId);
      formData.append("email", formDTO.email);
      formData.append("consents", JSON.stringify(formDTO.consents));
      formData.append("pdfPassword", JSON.stringify(formDTO.pdfPassword));
      formData.append("market", formDTO.market.toString());

      const response = await httpService.upload(`/survey/`, {
        body: formData,
      });

      if (response.surveyResultId) {
        analyticsService.createFacebookEvent("custom", "BurnoutSurveySubmit");
      }

      return { surveySessionUrl: response.surveySessionUrl };
    } catch (e: any) {
      console.error("UPLOADING SURVEY RESULTS ERROR: ", e);

      return rejectWithValue(e);
    }
  }
);

export const setIsFileEncrypted = createAsyncThunk<
  { isEncrypted: boolean; fileId: string },
  { file: File; fileId: string }
>("survey/setIsFileEncrypted", async ({ file, fileId }) => {
  const isEncrypted = await isFileEncrypted(file);

  return { isEncrypted, fileId };
});

export const setIsFilePasswordCorrect = createAsyncThunk<
  { isPasswordCorrect: boolean; fileId: string },
  { file: File; password: string; fileId: string }
>(
  "survey/setIsFilePasswordCorrect",
  async ({
    file,
    password,
    fileId,
  }: {
    file: File;
    password: string;
    fileId: string;
  }) => {
    const isPasswordCorrect = await isFilePasswordCorrect(file, password);

    return { isPasswordCorrect, fileId };
  }
);
