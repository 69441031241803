import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import style from "./Footer.module.scss";

import {
  facebook,
  instagram,
  youtube,
  footerLogoText,
  footerLogo,
} from "assets/image";
import config from "config";

export default function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <Link className={style.logoWrapper} to="/#header">
        <img
          src={footerLogo}
          className={style.footerLogoImage}
          alt="header logo"
        />
        <img src={footerLogoText} alt="header logo text" />
      </Link>
      <span className={style.text}>
        Copyright © 2020 Revolab. All Rights Reserved.
      </span>
      <div className={style.menu}>
        <Link className={style.menuItem} to="/forbusiness">
          {t("Footer.ForBusiness")}
        </Link>
        <Link className={style.menuItem} to="/faq">
          {t("Footer.FAQ")}
        </Link>
        <Link className={style.menuItem} to="/contacts">
          {t("Footer.Contacts")}
        </Link>
        <Link className={style.menuItem} to="/privacypolicy">
          {t("Footer.PrivacyPolicy")}
        </Link>
        <Link className={style.menuItem} to="/termsofuse">
          {t("Footer.TermsOfUse")}
        </Link>
      </div>
      <div className={style.iconsWrapper}>
        <a
          href={config.socialMediaLinks.facebook}
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="facebook logo" />
        </a>
        <a
          href={config.socialMediaLinks.instagram}
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="instagram logo" />
        </a>
        <a
          href={config.socialMediaLinks.youTube}
          target="_blank"
          rel="noreferrer"
        >
          <img src={youtube} alt="youtube logo" />
        </a>
      </div>
    </div>
  );
}
