import React from "react";

import { ScaleItemType, ColorValues } from "types";
import { useMediaQueryContext } from "components/MediaQueryProvider";

type Props = {
  ranges: ScaleItemType[];
};

export default function ColorAxis({ ranges }: Props): JSX.Element {
  const { isMobile } = useMediaQueryContext();
  const axisHeight = isMobile ? 300 : 400;
  const maxheight = isMobile ? 196 : 290;
  const startY = isMobile ? 24 : 32;
  const blockPadding = isMobile ? 4 : 8;
  const rangeInPoints = ranges[ranges.length - 1].max - ranges[0].min;

  function calculateHeight(range: ScaleItemType): number {
    return ((range.max - range.min) / rangeInPoints) * maxheight - blockPadding;
  }

  function calculateRangeYCoord(range: ScaleItemType): number {
    return (
      ((ranges[ranges.length - 1].max - range.max) / rangeInPoints) *
        maxheight +
      startY
    );
  }

  return (
    <svg width={isMobile ? "44" : "88"} height={axisHeight}>
      {ranges.map((range: ScaleItemType) => {
        return (
          <rect
            key={`${range.status} ${range.max} ${range.min}`}
            x={isMobile ? "36" : "80"}
            y={calculateRangeYCoord(range)}
            rx="4"
            width="8"
            height={calculateHeight(range)}
            fill={ColorValues[range.color]}
          />
        );
      })}
    </svg>
  );
}
